import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Padding,
  Text,
  SectionTitle,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

import {
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
  convertStateAbbreviation,
  formatPhoneNumber,
  formatName,
} from "../../../utils";
import {
  BusinessAffiliation,
  CorporateAffiliation,
  Employer,
  ProfessionalLicense,
} from "../../../features/reports/reportTypes";
import { usePhoneReportDispatch } from "../../../hooks/phoneReportHook";

const CareerDetails: React.FC<{ sectionId: string; printReady?: boolean }> = ({
  sectionId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );

  const pullPhoneReport = usePhoneReportDispatch();

  const loading = peopleReportStatus === "loading";

  /* generate employer Object */
  const employerObjects = renderArrayOfObjectsPrep(
    pulledReport?.employers || [],
    [],
    [
      {
        key: ["phone"],
        valueFormat: (value, currentObject: Employer) => {
          return (
            <ReportLink
              inputValue={value}
              smallText
              handleClick={pullPhoneReport}
              text={formatPhoneNumber(value)}
            />
          );
        },
      },
      {
        key: ["businessName"],
        keyFormat: () => {
          return "Owner";
        },
      },
    ],
  );

  /* generate businessAffiliation Object */
  const businessAffiliationObjects = renderArrayOfObjectsPrep(
    pulledReport?.businessAffiliations || [],
    ["name", "type"],
    [
      {
        key: ["businessName"],
        keyFormat: () => {
          return "Business Names";
        },
        valueFormat: (value, currentObject: BusinessAffiliation) => {
          if (currentObject.businessNames.length === 0) {
            return (
              <Text tag="b" size="sm">
                {value}
              </Text>
            );
          }
          const jsxElements = [];
          for (let i = 0; i < currentObject.businessNames.length; i++) {
            jsxElements.push(
              <Text
                key={`${currentObject.businessNames[i].name}-${i}`}
                tag="b"
                size="sm"
              >
                {currentObject.businessNames[i].name} (
                {currentObject.businessNames[i].type})
              </Text>,
            );
          }
          return <>{jsxElements}</>;
        },
      },
    ],
  );

  /* generate professionalLicence Object */
  const professionalLicenceObjects = renderArrayOfObjectsPrep(
    pulledReport?.professionalLicenses || [],
    [],
    [
      {
        key: ["phone"],
        valueFormat: (value, currentObject: ProfessionalLicense) => {
          return (
            <ReportLink
              inputValue={value}
              handleClick={pullPhoneReport}
              smallText
              text={formatPhoneNumber(value)}
            />
          );
        },
      },
      {
        key: ["businessName"],
        keyFormat: () => {
          return "Owner";
        },
      },
    ],
  );

  /* generate corporateFiling Object */
  const corporateAffiliationsObjects = renderArrayOfObjectsPrep(
    pulledReport?.corporateAffiliations || [],
    ["businessName", "type"],
    [
      {
        key: ["businessName"],
        keyFormat: () => {
          return "Business Names";
        },
        valueFormat: (value, currentObject: CorporateAffiliation) => {
          if (currentObject.businessNames.length === 0) {
            return (
              <Text tag="b" size="sm">
                {value}
              </Text>
            );
          }
          const jsxElements = [];
          for (let i = 0; i < currentObject.businessNames.length; i++) {
            jsxElements.push(
              <Text
                key={`${currentObject.businessNames[i].name}-${i}`}
                tag="b"
                size="sm"
              >
                {currentObject.businessNames[i].name} (
                {currentObject.businessNames[i].type})
              </Text>,
            );
          }
          return <>{jsxElements}</>;
        },
      },
    ],
  );

  return (
    <>
      <>
        <SectionTitle
          sectionTitle="Career Details"
          headingLevel={2}
          sectionId={sectionId}
        />
        <Container
          containerTitle={`Possible Employers ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.employers
                    ? pulledReport.employers.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.employers}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.employers &&
            pulledReport.employers[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.employers.map((employer, index) => (
                <ContainerLineItem
                  lineItemHeader={employer.businessName}
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: generateList(
                          [
                            {
                              value: employer.dateFirstSeen
                                ? parseDate(employer.dateFirstSeen, "year")
                                : null,
                              label: "",
                            },
                            {
                              value: employer.dateLastSeen
                                ? parseDate(employer.dateLastSeen, "year")
                                : null,
                              label: "",
                            },
                          ],
                          " - ",
                        ),
                        label: "",
                      },
                      {
                        value: employer.state
                          ? convertStateAbbreviation(employer.state)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {employerObjects[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={employerObjects[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Employers Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Business Affiliations ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.businessAffiliations
                    ? pulledReport.businessAffiliations.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.businessAffiliations}
          tooltipContent="Business Affiliations refer to individuals or entities that are connected through professional relationships, partnerships, or shared business interests. These affiliates could include business partners, co-founders, co-owners, or collaborators."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.businessAffiliations &&
            pulledReport.businessAffiliations[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.businessAffiliations.map(
                (businessAffiliation, index) => (
                  <ContainerLineItem
                    lineItemHeader={businessAffiliation.businessName}
                    lineItemSubHeader={generateList(
                      [
                        {
                          value: generateList(
                            [
                              {
                                value: businessAffiliation.dateFirstSeen
                                  ? parseDate(
                                      businessAffiliation.dateFirstSeen,
                                      "year",
                                    )
                                  : null,
                                label: "",
                              },
                              {
                                value: businessAffiliation.dateLastSeen
                                  ? parseDate(
                                      businessAffiliation.dateLastSeen,
                                      "year",
                                    )
                                  : null,
                                label: "",
                              },
                            ],
                            " - ",
                          ),
                          label: "",
                        },
                        {
                          value: businessAffiliation.state
                            ? convertStateAbbreviation(
                                businessAffiliation.state,
                              )
                            : null,
                          label: "",
                        },
                      ],
                      " | ",
                    )}
                    isOpen={printReady ? true : false}
                    key={index}
                  >
                    {businessAffiliationObjects[index] ? (
                      <Padding space="md">
                        <Table
                          tableDataObject={businessAffiliationObjects[index]}
                          orderOfProperties={["Owner"]}
                        />
                      </Padding>
                    ) : null}
                  </ContainerLineItem>
                ),
              )}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Business Affiliations Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Professional Licenses ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.professionalLicenses
                    ? pulledReport.professionalLicenses.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.professionalLicenses}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.professionalLicenses &&
            pulledReport.professionalLicenses[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.professionalLicenses.map(
                (professionalLicense, index) => (
                  <ContainerLineItem
                    lineItemHeader={professionalLicense.licenseType}
                    lineItemSubHeader={generateList(
                      [
                        {
                          value: professionalLicense.issueDate
                            ? parseDate(professionalLicense.issueDate, "year")
                            : null,
                          label: "",
                        },
                        {
                          value: professionalLicense.licenseState
                            ? convertStateAbbreviation(
                                professionalLicense.licenseState,
                              )
                            : null,
                          label: "",
                        },
                      ],
                      " | ",
                    )}
                    isOpen={printReady ? true : false}
                    key={index}
                  >
                    {professionalLicenceObjects[index] ? (
                      <Padding space="md">
                        <Table
                          tableDataObject={professionalLicenceObjects[index]}
                          orderOfProperties={["Owner"]}
                        />
                      </Padding>
                    ) : null}
                  </ContainerLineItem>
                ),
              )}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Professional Licenses Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Corporate Filings ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.corporateAffiliations
                    ? pulledReport.corporateAffiliations.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.corporateAffiliations}
          tooltipContent="Corporate Filings refer to official documents and records submitted by businesses or corporations to government agencies. These filings provide crucial information about a company's legal structure, financial status, ownership, and operational activities."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.corporateAffiliations &&
            pulledReport.corporateAffiliations[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.corporateAffiliations.map(
                (corporateAffiliation, index) => (
                  <ContainerLineItem
                    lineItemHeader={corporateAffiliation.businessName}
                    lineItemSubHeader={generateList(
                      [
                        {
                          value: corporateAffiliation.fileDate
                            ? parseDate(corporateAffiliation.fileDate, "year")
                            : null,
                          label: "",
                        },
                        {
                          value: corporateAffiliation.state
                            ? convertStateAbbreviation(
                                corporateAffiliation.state,
                              )
                            : null,
                          label: "",
                        },
                      ],
                      " | ",
                    )}
                    isOpen={printReady ? true : false}
                    key={index}
                  >
                    {corporateAffiliationsObjects[index] ? (
                      <Padding space="md">
                        <Table
                          tableDataObject={corporateAffiliationsObjects[index]}
                          orderOfProperties={[]}
                        />
                      </Padding>
                    ) : null}
                  </ContainerLineItem>
                ),
              )}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Corporate Affiliations Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
      </>
    </>
  );
};

export default CareerDetails;
