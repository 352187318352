import React from "react";
import { useRollbar } from "@rollbar/react";
import { LogArgument } from "rollbar";
import { Icon, IconList, Layout, Padding, Sheet, Text } from "@kidslivesafe/sc-react";

interface ErrorPageProps {
  error?: LogArgument;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const rollbar = useRollbar();

  const errorMessage =
    error && typeof error === "object" && "message" in error
      ? (error as { message: string }).message
      : "The page you’re looking for doesn’t exist.";

  rollbar.error(error || new Error("Navigated to a non-existent page."));

  return (
    <Sheet>
      <Padding space="md" top="xxl">
        <Layout
          type="flex"
          flexProps={{ alignItems: "center", direction: "column" }}
        >
          <Icon name={IconList.IconWarning} size="80px" />
          <Text tag="h2">Something went wrong</Text>
          <Text size="base">{errorMessage}</Text>
        </Layout>
      </Padding>
    </Sheet>
  );
};

export default ErrorPage;
