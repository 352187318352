import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

// components
import {
  Container,
  Layout,
  Padding,
  Tag,
  Text,
  SectionTitle,
  CollapsableItemList,
  ContainerLineItem,
  ReportLink,
  Table,
  Map,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

// pull report hook
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import { usePropertySearchDispatch } from "../../../hooks/propertyReportHook";

// redux getters
import {
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  selectBrandInfo,
  selectMapboxKey,
} from "../../../features/brand/brandSlice";

// utils
import {
  generateList,
  parseDate,
  formatPhoneNumber,
  renderArrayOfObjectsPrep,
  convertToFormattedNumber,
  formatName,
  formatAddress,
} from "../../../utils";

// types
import {
  Address,
  IndividualPropertyOwner,
} from "../../../features/reports/reportTypes";
import { usePhoneReportDispatch } from "../../../hooks/phoneReportHook";

const ContactInformation: React.FC<{
  sectionId: string;
  printReady?: boolean;
}> = ({ sectionId, printReady }) => {
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );
  const brandInfo = useAppSelector(selectBrandInfo);

  const loading = peopleReportStatus === "loading";

  const pullPeopleReport = usePeopleReportDispatch();
  const pullPhoneReport = usePhoneReportDispatch();

  const mapboxToken = useAppSelector(selectMapboxKey);

  /* Map Prep */
  const preparePinData = (addresses: Address[]) => {
    const pinData = addresses.map(address => {
      return {
        latitude: address.latitude,
        longitude: address.longitude,
        popup: (
          <Layout type="flex" flexProps={{ direction: "column" }}>
            <Text fontWeight="bold" size="sm">
              {address.fullAddress},
              {generateList(
                [
                  {
                    value: address.city ? address.city : null,
                    label: " ",
                  },
                  {
                    value: generateList(
                      [
                        {
                          value: address.state ? address.state : null,
                          label: " ",
                        },
                        {
                          value: address.postalCode
                            ? address.postalCode.split("-")[0]
                            : null,
                          label: " ",
                        },
                      ],
                      " ",
                    ),
                    label: "",
                  },
                ],
                ", ",
              )}
            </Text>
            <Text size="xxs">
              {generateList(
                [
                  {
                    value: address.firstReported
                      ? parseDate(address.firstReported, "year")
                      : null,
                    label: "",
                  },
                  {
                    value: address.lastReported
                      ? parseDate(address.lastReported, "year")
                      : null,
                    label: "",
                  },
                ],
                "-",
              )}
            </Text>
          </Layout>
        ),
      };
    });
    return pinData;
  };

  const isOwner = (address: any, token: string): boolean => {
    return (
      address.propertyOwner?.individualPropertyOwners?.some(
        (owner: any) => owner.reportTokens?.[0] === token,
      ) ?? false
    );
  };

  /* generate address Object */
  const addressObjects = renderArrayOfObjectsPrep(
    pulledReport?.addresses || [],
    [
      "middleName",
      "lastName",
      "latitude",
      "longitude",
      "reportTokens",
      "fullAddress",
      "city",
      "state",
      "postalCode",
      "lastReported",
    ],
    [
      {
        key: ["firstName"],
        keyFormat: () => "Owner",
        valueFormat: (value, currentObject: IndividualPropertyOwner) => {
          return currentObject.reportTokens ? (
            currentObject.reportTokens[0] ? (
              <ReportLink<PullPeopleReportParams>
                smallText
                inputValue={currentObject.reportTokens[0]}
                handleClick={pullPeopleReport}
                text={formatName(currentObject)}
              />
            ) : (
              formatName(currentObject)
            )
          ) : (
            formatName(currentObject)
          );
        },
      },
      {
        key: ["landSquareFeet"],
        keyFormat: () => "Lot Size",
        valueFormat: (value, currentObject) => {
          return (
            <Layout type="flex" flexProps={{ gap: "xxs" }}>
              <Text tag="b" size="sm">
                {convertToFormattedNumber(value)} sq ft
              </Text>
              <Text tag="b" size="sm">
                ({(parseInt(value) / 43560).toString().substring(0, 4)} acres)
              </Text>
            </Layout>
          );
        },
      },
      {
        key: ["livingSquareFeet"],
        keyFormat: () => "Living Space",
        valueFormat: (value, currentObject) => {
          return `${convertToFormattedNumber(value)} sq ft`;
        },
      },
      {
        key: ["purchasePrice", "assessedValue", "mortgageAmount"],
        valueFormat: (value, currentObject) => {
          return `$${convertToFormattedNumber(value)}`;
        },
      },
      {
        key: ["firstReported"],
        keyFormat: () => "Dates Seen",
        valueFormat: (value, currentObject) => {
          return generateList(
            [
              {
                value: value ? parseDate(value, "MM/YYYY") : null,
                label: "",
              },
              {
                value: currentObject.lastReported
                  ? parseDate(currentObject.lastReported, "MM/YYYY")
                  : null,
                label: "",
              },
            ],
            " - ",
          );
        },
      },
      {
        key: ["lastReported"],
        keyFormat: () => "Estimated End",
      },
      {
        key: ["lastReported", "purchaseDate"],
        valueFormat: (value, currentObject) => {
          return parseDate(value, "MM/YYYY");
        },
      },
    ],
  );

  // helper functions
  const pullPropertyReport = usePropertySearchDispatch();

  return (
    <>
      <>
        <SectionTitle
          sectionTitle="Contact Information"
          headingLevel={2}
          sectionId={sectionId}
        />
        <Container
          containerTitle={`Possible Aliases ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.aliases
                    ? pulledReport.aliases.length
                    : 0
                })`
          }`}
          tooltipContent="Aliases refer to alternative names that an individual may have used at different points in time. These names might include nicknames, maiden names, or variations of their legal name which may have appeared on public documents."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
            />
          ) : pulledReport &&
            pulledReport.aliases &&
            pulledReport.aliases[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.aliases.map((alias, index) => (
                <ContainerLineItem
                  lineItemHeader={formatName(alias)}
                  lineItemSubHeader={`${generateList(
                    [
                      {
                        value: alias.dateFirstSeen
                          ? parseDate(alias.dateFirstSeen, "month-year")
                          : null,
                        label: "",
                      },
                      {
                        value: alias.dateLastSeen
                          ? parseDate(alias.dateLastSeen, "month-year")
                          : null,
                        label: "",
                      },
                    ],
                    " - ",
                  )} (dates alias seen)`}
                  isOpen={printReady ? true : false}
                  key={index}
                />
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Aliases Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Address History ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.addresses
                    ? pulledReport.addresses.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.addresses}
          tooltipContent="An existing address may be displayed as current until new information is found in an official document."
        >
          {pulledReport &&
          pulledReport.addresses &&
          pulledReport.addresses.length > 0 &&
          !printReady ? (
            loading ? (
              <LoadingPlaceholder width="100%" height="500px" />
            ) : (
              <Map
                mapboxToken={mapboxToken}
                markerData={
                  pulledReport.addresses
                    ? preparePinData(pulledReport.addresses)
                    : undefined
                }
                center={
                  pulledReport.addresses &&
                  pulledReport.addresses[0].latitude !== 0
                    ? [
                        pulledReport.addresses[0].longitude,
                        pulledReport.addresses[0].latitude,
                      ]
                    : undefined
                }
              />
            )
          ) : null}

          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
            />
          ) : pulledReport &&
            pulledReport.addresses &&
            pulledReport.addresses[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.addresses.map((address, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    <ReportLink
                      text={formatAddress(address, "with-postalCode")}
                      inputValue={{
                        addressLine1: address.fullAddress,
                        addressLine2: "",
                        state: address.state,
                        city: address.city,
                        zip: address.postalCode,
                        latitude: address.latitude,
                        longitude: address.longitude,
                        nearbySchoolRadius: 5,
                      }}
                      handleClick={pullPropertyReport}
                    />
                  }
                  lineItemDetails={
                    <Layout
                      type="flex"
                      flexProps={{
                        gap: "xxs",
                        alignItems: "flex-end",
                        direction: "column",
                        justifyContent: "center",
                      }}
                    >
                      {generateList(
                        [
                          {
                            value: address.firstReported
                              ? pulledReport.deceased !== "No" &&
                                pulledReport.dateOfDeath
                                ? parseDate(pulledReport.dateOfDeath, "year")
                                : parseDate(address.firstReported, "year")
                              : null,
                            label: "",
                          },
                          {
                            value: address.lastReported
                              ? parseDate(address.lastReported, "year")
                              : null,
                            label: "",
                          },
                        ],
                        " - ",
                      )}
                      {pulledReport.reportToken &&
                      isOwner(address, pulledReport.reportToken) ? (
                        <Tag type="primary" tagText="owner" />
                      ) : null}
                    </Layout>
                  }
                  isOpen={index === 0 || printReady ? true : false}
                  key={index}
                >
                  {addressObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[
                          "Owner",
                          "Purchase Date",
                          "Purchase Price",
                          "Mortgage Amount",
                          "Assessed Value",
                          "Lot Size",
                          "Living Space",
                          "Estimated Start",
                          "Estimated End",
                        ]}
                        tableDataObject={addressObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Addresses Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Phone Numbers ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.phoneNumbers
                    ? pulledReport.phoneNumbers.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.phoneNumbers}
          tooltipContent="Phone numbers may be identified as Landline, Mobile, Unlisted, VOIP (Voiceover Internet Protocol)"
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              lineItemSubHeaderLine2
              numberToLoad={5}
            />
          ) : pulledReport &&
            pulledReport.phoneNumbers &&
            pulledReport.phoneNumbers[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.phoneNumbers.map((phoneNumber, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    brandInfo.acronym !== "pf" ? (
                      <ReportLink
                        inputValue={phoneNumber.number}
                        text={formatPhoneNumber(phoneNumber.number)}
                        handleClick={pullPhoneReport}
                      />
                    ) : (
                      <Text>{formatPhoneNumber(phoneNumber.number)}</Text>
                    )
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value:
                          phoneNumber.phoneType !== "Unknown"
                            ? phoneNumber.phoneType
                            : null,
                        label: "",
                      },
                      {
                        value: generateList(
                          [
                            {
                              value: phoneNumber.city ? phoneNumber.city : null,
                              label: "",
                            },
                            {
                              value: phoneNumber.state
                                ? phoneNumber.state
                                : null,
                              label: "",
                            },
                          ],
                          ", ",
                        ),
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  lineItemSubHeaderLine2={phoneNumber.carrier}
                  lineItemSubheaderNoWrap
                  isOpen={printReady ? true : false}
                  key={index}
                />
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Phone Numbers Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Email Addresses ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.emailAddresses
                    ? pulledReport.emailAddresses.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.emailAddresses}
        >
          {loading ? (
            <LoadingContainerLineItem lineItemHeader numberToLoad={5} />
          ) : pulledReport &&
            pulledReport.emailAddresses &&
            pulledReport.emailAddresses[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.emailAddresses.map((email, index) => (
                <ContainerLineItem
                  lineItemHeader={email.emailAddress}
                  lineItemHeaderWordBreak
                  key={index}
                />
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Email Addresses Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
      </>
    </>
  );
};

export default ContactInformation;
