export const formatPhoneNumber = (
  phoneNumberString: string | number,
): string => {
  // Convert the number to a string, if it isn't already
  const numStr = phoneNumberString.toString();

  // Use a regular expression to format the number
  const formatted = numStr.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  return formatted;
};
