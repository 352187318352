import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  generateList,
  renderArrayOfObjectsPrep,
  parseDate,
} from "../../../utils";

// types
import {
  Mortgage,
  PropertyOwnershipHistory,
} from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Padding,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  LineItemSummary,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

const PropertySales: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";

  function getFinanceInfo(sale: PropertyOwnershipHistory) {
    if (sale.propertyOwnerships && sale.propertyOwnerships[0]) {
      if (sale.propertyOwnerships[0].mortgages?.length) {
        if (sale.propertyOwnerships[0].mortgages?.length === 1) {
          return (
            sale.propertyOwnerships[0].mortgages?.length + " Financing Event"
          );
        } else {
          return (
            sale.propertyOwnerships[0].mortgages?.length + " Financing Events"
          );
        }
      } else {
        return null;
      }
    }
  }

  function getFirstOwnership(sale: PropertyOwnershipHistory) {
    if (sale.propertyOwnerships && sale.propertyOwnerships[0]) {
      let obj = { ...sale.propertyOwnerships[0] };
      if (sale.saleDate) {
        obj.saleDate = sale.saleDate;
      }
      return obj;
    }
  }

  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Property Sales ${
          loading
            ? ""
            : `(${
                pulledReport?.propertyOwnershipHistory
                  ? pulledReport.propertyOwnershipHistory.length
                  : 0
              })`
        }`}
        containerId={containerId}
        newInfo={reportDifferences?.propertyTransactions}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : pulledReport?.propertyOwnershipHistory &&
          pulledReport.propertyOwnershipHistory[0] ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport.propertyOwnershipHistory.map((sale, index) => (
              <ContainerLineItem
                lineItemHeader={`SALE ${
                  sale.saleAmount ? sale.saleAmount : ""
                }`}
                lineItemSubHeader={generateList(
                  [
                    {
                      value: sale.saleDate
                        ? parseDate(sale.saleDate, "month-year")
                        : null,
                      label: "",
                    },
                    {
                      value: getFinanceInfo(sale) ? getFinanceInfo(sale) : null,
                      label: "",
                    },
                  ],
                  " | ",
                )}
                isOpen={printReady ? true : false}
                key={index}
              >
                <LineItemSummary
                  summaryDataObject={
                    renderArrayOfObjectsPrep(
                      [getFirstOwnership(sale)],
                      [
                        "businessName",
                        "firstName",
                        "lastName",
                        "reportToken",
                        "transactionType",
                        "financialSummary",
                        "mortgageRecordingDate",
                        "mortgageRecordingYear",
                        "mortgageAmount",
                        "mortgageTerm",
                        "mortgageInterestRateType",
                        "mortgageLoanType",
                        "mortgageDeedType",
                        "mortgageDocumentNumber",
                        "cashPurchase",
                        "dateFirstSeenAsOwner",
                        "dateLastSeenAsOwner",
                        "middleName",
                      ],
                      [
                        {
                          key: ["sellerTransactionSummary"],
                          keyFormat: () => {
                            return "Seller";
                          },
                        },
                        {
                          key: ["ownerTransactionSummary"],
                          keyFormat: () => {
                            return "Owner";
                          },
                        },
                        {
                          key: ["ownerOwnershipRights"],
                          keyFormat: () => {
                            return "Ownership Rights";
                          },
                        },
                      ],
                    )[0]
                  }
                  orderOfProperties={["Sale Date", "Sale Code", "Seller"]}
                />
                {sale.propertyOwnerships && sale.propertyOwnerships.length > 0
                  ? sale.propertyOwnerships[0].mortgages?.map(
                      (mortgage: Mortgage, mortIndex) => {
                        /* generate sales Object */
                        const salesObjects = renderArrayOfObjectsPrep(
                          sale.propertyOwnerships![0].mortgages || [],
                          ["financialSummary"],
                          [
                            {
                              key: ["lastName"],
                              keyFormat: () => {
                                return "Lender";
                              },
                            },
                            {
                              key: ["mortgageRecordingYear"],
                              valueFormat: value => {
                                return value ? (
                                  <Text size="sm" fontWeight="bold">
                                    {value.toString()}
                                  </Text>
                                ) : null;
                              },
                            },
                            {
                              key: ["mortgageDocumentNumber"],
                              keyFormat: () => {
                                return "Document Number";
                              },
                            },
                          ],
                        );
                        return salesObjects[mortIndex] ? (
                          <Padding space="md" bottom="none" key={mortIndex}>
                            <Table tableDataObject={salesObjects[mortIndex]} />
                          </Padding>
                        ) : (
                          <Padding space="md" key={mortIndex}>
                            <Text>
                              No Additional Financial Details Available
                            </Text>
                          </Padding>
                        );
                      },
                    )
                  : null}
              </ContainerLineItem>
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No results found for Sales
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default PropertySales;
