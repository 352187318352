import { stateAbbreviationDict, StateAbbreviation } from "./stateConstants";

export const convertStateAbbreviation = (stateAbbreviation: string): string => {
  if (stateAbbreviation.length !== 2) {
    let abbreviation;

    for (let stateAbbrv in stateAbbreviationDict) {
      if (stateAbbreviation.includes(stateAbbrv)) {
        abbreviation = stateAbbrv;
        break;
      }
    }

    return (
      stateAbbreviationDict[abbreviation as StateAbbreviation] || "Unknown"
    );
  }

  return (
    stateAbbreviationDict[stateAbbreviation as StateAbbreviation] || "Unknown"
  );
};

export const convertStateToAbbreviation = (state: string): string => {
  const stateName = state.toLowerCase();
  const stateAbbreviation = Object.keys(stateAbbreviationDict).find(
    key =>
      stateAbbreviationDict[key as StateAbbreviation].toLowerCase() ===
      stateName,
  );

  return stateAbbreviation || "Unknown";
};
