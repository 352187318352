import React, { useEffect } from "react";
import { verifyCookie } from "../../features/authentication/authenticationAPI";
import { logoutNoRedirect } from "../../features/authentication/authenticationSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { useLocation } from "react-router-dom";
import { sendMemberEventAsync } from "../../features/admin/adminSlice";

export interface DeepLinkingProps {
  logMemberEvent?: boolean;
  pageName: string;
}

const deepLinkRedirect = <P extends {}>(Component: React.ComponentType<P>) => {
  const DeepLinkRedirect: React.FC<P & DeepLinkingProps> = props => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const searchParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(searchParams.entries());
    const redirectPath = location.pathname + location.search;

    const handleLogout = () => {
      const baseRedirectUrl = `/login?redirect=${encodeURIComponent(redirectPath)}`;

      const urlWithParams = Object.entries(queryParams).reduce(
        (url, [key, value]) => {
          if (key !== "redirect") {
            return (
              url + `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            );
          }
          return url;
        },
        baseRedirectUrl,
      );

      dispatch(logoutNoRedirect());
      window.location.href = urlWithParams;
    };

    useEffect(() => {
      if (props.logMemberEvent) {
        sendMemberEvent();
      }
      verifyCookie()
        .then((res: any) => {
          if (!res || res.success === false) {
            handleLogout();
          }
        })
        .catch(error => {
          handleLogout();
          console.log("Error verifying Authentication:", error);
        });
    });

    const sendMemberEvent = () => {
      dispatch(
        sendMemberEventAsync({
          EventCategory: props.pageName,
          EventAction: "page",
        }),
      );
    };

    return <Component {...props} />;
  };

  return DeepLinkRedirect;
};

export default deepLinkRedirect;
