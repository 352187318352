import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  sendResetPasswordEmail,
  newPassword,
  setCookie,
  createCustomerRedisToken,
  addIdProtect,
  getIdProtectSsoKey,
  rateLimit,
  sendMemberEvent,
  sendEmailVerification,
  verifyEmailApi,
} from "./adminAPI";
import {
  ResetPasswordParams,
  NewPasswordParams,
  SetCookieParams,
  CreateCustomerRedisTokenParams,
  AddIdProtectParams,
  GetSsoKeyParams,
  RateLimitParams,
  MemberEvent,
  SendEmailVerificationParams,
  VerifyEmailParams,
} from "./adminTypes";

import {
  pullPeopleReportAsync,
  pullPhoneReportAsync,
  pullAutoReportAsync,
  pullPropertyReportAsync,
  searchSocialAsync,
} from "../reports/reportsSlice";

export interface AdminState {
  status: string;
  emailSent: boolean;
  passwordReset: boolean;
  emailVerificationRequired: boolean;
}

const initialState: AdminState = {
  status: "idle",
  emailSent: false,
  passwordReset: false,
  emailVerificationRequired: false,
};

export const sendResetPasswordEmailAsync = createAsyncThunk<
  any,
  ResetPasswordParams,
  {}
>(
  "resetPassword/sendResetPasswordEmail",
  async (params: ResetPasswordParams) => {
    return sendResetPasswordEmail(params);
  },
);

export const savePasswordAsync = createAsyncThunk<any, NewPasswordParams, {}>(
  "newPassword/newPassword",
  async (params: NewPasswordParams) => {
    return newPassword(params);
  },
);

export const setCookieAsync = createAsyncThunk<any, SetCookieParams, {}>(
  "setCookie/UUID",
  async (params: SetCookieParams) => {
    return setCookie(params);
  },
);

export const rateLimitAsync = createAsyncThunk<any, RateLimitParams, {}>(
  "rateLimit/Notify",
  async (params: RateLimitParams) => {
    return rateLimit(params);
  },
);

export const createCustomerRedisTokenAsync = createAsyncThunk<
  any,
  CreateCustomerRedisTokenParams,
  {}
>(
  "createCustomerRedisToken",
  async (params: CreateCustomerRedisTokenParams) => {
    return createCustomerRedisToken(params);
  },
);
export const addIdProtectAsync = createAsyncThunk<any, AddIdProtectParams, {}>(
  "admin/addIdProtect",
  async (params: AddIdProtectParams) => {
    return addIdProtect(params);
  },
);

export const getIdProtectSsoKeyAsync = createAsyncThunk<
  any,
  GetSsoKeyParams,
  {}
>("admin/getIdProtectSsoKey", async (params: GetSsoKeyParams) => {
  return getIdProtectSsoKey(params);
});

export const sendMemberEventAsync = createAsyncThunk<any, MemberEvent, {}>(
  "admin/sendMemberEvent",
  async (params: MemberEvent) => {
    return sendMemberEvent(params);
  },
);

export const sendEmailVerificationAsync = createAsyncThunk<
  any,
  SendEmailVerificationParams,
  {}
>(
  "admin/sendEmailVerification",
  async (params: SendEmailVerificationParams) => {
    return sendEmailVerification(params);
  },
);

export const verifyEmailAsync = createAsyncThunk<any, VerifyEmailParams, {}>(
  "admin/verifyEmail",
  async (params: VerifyEmailParams) => {
    return verifyEmailApi(params);
  },
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setEmailVerificationRequired: (state, action) => {
      state.emailVerificationRequired = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sendResetPasswordEmailAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(sendResetPasswordEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.emailSent = true;
      })
      .addCase(sendResetPasswordEmailAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(savePasswordAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(savePasswordAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.passwordReset = true;
      })
      .addCase(savePasswordAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(setCookieAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(setCookieAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(setCookieAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(createCustomerRedisTokenAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(createCustomerRedisTokenAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(createCustomerRedisTokenAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(addIdProtectAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(addIdProtectAsync.fulfilled, state => {
        state.status = "idle";
      })
      .addCase(addIdProtectAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(getIdProtectSsoKeyAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getIdProtectSsoKeyAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(getIdProtectSsoKeyAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(sendMemberEventAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(sendMemberEventAsync.fulfilled, state => {
        state.status = "idle";
      })
      .addCase(sendMemberEventAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(sendEmailVerificationAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(sendEmailVerificationAsync.fulfilled, state => {
        state.status = "idle";
      })
      .addCase(sendEmailVerificationAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(verifyEmailAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(verifyEmailAsync.fulfilled, state => {
        state.status = "idle";
      })
      .addCase(verifyEmailAsync.rejected, state => {
        state.status = "failed";
      })
      // Fulfilled added to handle use case where user verifies their email on a different device
      .addCase(pullPeopleReportAsync.fulfilled, state => {
        state.emailVerificationRequired = false;
      })
      .addCase(pullPeopleReportAsync.rejected, (state, action) => {
        if (
          action.error &&
          action.error.message === "Customer verification required"
        ) {
          state.emailVerificationRequired = true;
        }
      })
      // Fulfilled added to handle use case where user verifies their email on a different device
      .addCase(pullPropertyReportAsync.fulfilled, state => {
        state.emailVerificationRequired = false;
      })
      .addCase(pullPropertyReportAsync.rejected, (state, action) => {
        if (
          action.error &&
          action.error.message === "Customer verification required"
        ) {
          state.emailVerificationRequired = true;
        }
      })
      // Fulfilled added to handle use case where user verifies their email on a different device
      .addCase(pullAutoReportAsync.fulfilled, state => {
        state.emailVerificationRequired = false;
      })
      .addCase(pullAutoReportAsync.rejected, (state, action) => {
        if (
          action.error &&
          action.error.message === "Customer verification required"
        ) {
          state.emailVerificationRequired = true;
        }
      })
      // Fulfilled added to handle use case where user verifies their email on a different device
      .addCase(pullPhoneReportAsync.fulfilled, state => {
        state.emailVerificationRequired = false;
      })
      .addCase(pullPhoneReportAsync.rejected, (state, action) => {
        if (
          action.error &&
          action.error.message === "Customer verification required"
        ) {
          state.emailVerificationRequired = true;
        }
      })
      // Fulfilled added to handle use case where user verifies their email on a different device
      .addCase(searchSocialAsync.fulfilled, state => {
        state.emailVerificationRequired = false;
      })
      .addCase(searchSocialAsync.rejected, (state, action) => {
        if (
          action.error &&
          action.error.message === "Customer verification required"
        ) {
          state.emailVerificationRequired = true;
        }
      });
  },
});

/* actions */
export const { setEmailVerificationRequired } = adminSlice.actions;

/* Getters */

export const selectAdminStatus = (state: RootState) => state.admin.status;
export const selectResetPasswordEmailSent = (state: RootState) =>
  state.admin.emailSent;
export const selectPasswordReset = (state: RootState) =>
  state.admin.passwordReset;
export const selectEmailVerificationRequired = (state: RootState) =>
  state.admin.emailVerificationRequired;

export default adminSlice.reducer;
