import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
  fullCapsText,
} from "../../../utils";

// types
import { Foreclosure } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Padding,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

// pull report hook
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

const Foreclosures: React.FC<{ containerId: string; printReady?: boolean }> = ({
  containerId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);

  const loading = propertyReportStatus === "loading";
  const pullPeopleReport = usePeopleReportDispatch();
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  /* generate liens Object */
  const foreclosureObjects = renderArrayOfObjectsPrep(
    pulledReport?.foreclosures || [],
    [],
    [
      {
        key: ["defendantsDetails"],
        keyFormat: () => {
          return "Defendants";
        },
        valueFormat: (value, currentObject: Foreclosure) => {
          const jsxElements = [];

          if (currentObject && currentObject.defendantsDetails) {
            for (let i = 0; i < currentObject.defendantsDetails.length; i++) {
              const defendant = currentObject.defendantsDetails[i];

              jsxElements.push(
                defendant.reportToken && defendant.fullName ? (
                  <ReportLink<PullPeopleReportParams>
                    key={`defendant-${i}`}
                    inputValue={defendant.reportToken}
                    handleClick={pullPeopleReport}
                    text={defendant.fullName}
                    smallText
                  />
                ) : (
                  <Text
                    passThroughClassess="sc-table-row__detail"
                    key={`defendant-${i}`}
                  >
                    {defendant.fullName}
                  </Text>
                ),
              );
            }
          }

          return <>{jsxElements}</>;
        },
      },
      {
        key: ["plaintiffsList"],
        valueFormat: (value, currentObject: Foreclosure) => {
          const jsxElements = [];

          if (currentObject && currentObject.plaintiffsList) {
            for (let i = 0; i < currentObject.plaintiffsList.length; i++) {
              const plaintiff = currentObject.plaintiffsList[i];

              jsxElements.push(
                <Text
                  passThroughClassess="sc-table-row__detail"
                  key={`plaintiff-${i}`}
                >
                  {plaintiff}
                </Text>,
              );
            }
          }

          return <>{jsxElements}</>;
        },
      },
    ],
  );
  return (
    <>
      {pulledReport?.foreclosures && pulledReport.foreclosures[0] ? (
        <Padding
          left="md"
          right="md"
          breakpointStyles={{
            desktop: {
              space: "none",
            },
          }}
        >
          <Container
            containerTitle={`Foreclosures at Address ${
              loading
                ? ""
                : `(${
                    pulledReport.foreclosures
                      ? pulledReport.foreclosures.length
                      : 0
                  })`
            }`}
            containerId={containerId}
            tooltipContent="Foreclosures are legal procedures in which lenders reclaim and sell properties from borrowers who have defaulted on mortgage payments. Because lenders in foreclosure usually want to sell the property fast to minimize losses, property values may be impacted."
            newInfo={reportDifferences?.foreclosures}
          >
            {loading ? (
              <LoadingContainerLineItem
                lineItemHeader
                lineItemSubHeader
                numberToLoad={5}
                children
              />
            ) : (
              <CollapsableItemList numberToShow={5} isOpen={printReady}>
                {pulledReport?.foreclosures.map((foreclosure, index) => (
                  <ContainerLineItem
                    lineItemHeader={`FORECLOSURE - ${
                      foreclosure.deedCategory
                        ? fullCapsText(foreclosure.deedCategory)
                        : null
                    }`}
                    lineItemSubHeader={generateList(
                      [
                        {
                          value: foreclosure.recordingDate
                            ? parseDate(foreclosure.recordingDate, "year")
                            : null,
                          label: "",
                        },
                        {
                          value: foreclosure.plaintiffsList
                            ? foreclosure.plaintiffsList
                            : null,
                          label: "",
                        },
                      ],
                      " | ",
                    )}
                    isOpen={printReady ? true : false}
                    key={index}
                  >
                    {foreclosureObjects[index] ? (
                      <Padding space="md">
                        <Table
                          orderOfProperties={[]}
                          tableDataObject={foreclosureObjects[index]}
                        />
                      </Padding>
                    ) : null}
                  </ContainerLineItem>
                ))}
              </CollapsableItemList>
            )}
          </Container>
        </Padding>
      ) : null}
    </>
  );
};

export default Foreclosures;
