import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  Button,
} from "@kidslivesafe/sc-react";
import { useAppSelector, useHandlePageNavigate } from "../../../hooks/hooks";

import {
  selectDownsellDetails,
  selectPrimarySubscription,
} from "../../../features/subscriptions/subscriptionSlice";

import "./offer-confirmation.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

const OfferConfirmation: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();
  const subscriptionInfo = useAppSelector(selectPrimarySubscription);
  const downsellData = useAppSelector(selectDownsellDetails);

  const currentBreakpoint = useCurrentBreakpoint();

  return (
    <>
      <ReportTitle reportTitle="Order Confirmed" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            <Text>
              Great choice! Your billing plan is now switched to $
              {downsellData?.downSellPrice} every{" "}
              {subscriptionInfo?.billingInterval} days.
            </Text>
            <Text>
              Now let’s go set up monitoring or run some new searches.
            </Text>

            <Button
              buttonText="Return to Dashboard"
              handleClick={() => pageNavigate("/")}
              size="md"
            />
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default OfferConfirmation;
