import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import { generateList } from "../../../utils";

// components
import {
  Container,
  Layout,
  Padding,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  Icon,
  IconList,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";

import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

const LotBuildingDetails: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const brandInfo = useAppSelector(selectBrandInfo);

  const loading = propertyReportStatus === "loading";

  /* generate lotObject */
  const lotObjects = [
    {
      ...(pulledReport?.propertyReportSummary?.universalLandUse && {
        "Land Use": pulledReport?.propertyReportSummary?.universalLandUse,
      }),
      ...(pulledReport?.propertyDetails?.apn && {
        "Assessors Parcel Number": pulledReport?.propertyDetails?.apn,
      }),
      ...(pulledReport?.propertyDetails?.acres &&
        pulledReport?.propertyDetails?.landSquareFootage && {
          "Lot Size": `${pulledReport?.propertyDetails?.acres} acres (${pulledReport?.propertyDetails?.landSquareFootage} sq ft)`,
        }),
      ...(pulledReport?.propertyDetails?.frontFootage &&
        pulledReport?.propertyDetails?.depthFootage && {
          "Recorded Lot Dimensions": `${pulledReport?.propertyDetails?.frontFootage} ft (front) / ${pulledReport?.propertyDetails?.depthFootage} ft (depth)`,
        }),
    },
  ];

  /* generate buildingObject */
  const buildingObjects = [
    {
      ...(pulledReport?.propertyDetails?.buildingSquareFeet && {
        "Total Square Footage": `${pulledReport?.propertyDetails?.buildingSquareFeet} sq ft`,
      }),
      ...(pulledReport?.propertyDetails?.bedrooms && {
        Bedrooms: `${pulledReport?.propertyDetails?.bedrooms} bd`,
      }),
      ...(pulledReport?.propertyDetails?.totalBaths && {
        Bathrooms: `${pulledReport?.propertyDetails?.totalBaths} ba`,
      }),
      ...(pulledReport?.propertyDetails?.totalRooms && {
        "Total Rooms": pulledReport?.propertyDetails?.totalRooms,
      }),
      ...(pulledReport?.propertyDetails?.parkingSpaces && {
        Parking: `${pulledReport?.propertyDetails?.parkingSpaces} spaces`,
      }),
      ...(pulledReport?.propertyDetails?.parkingTypeCode &&
        pulledReport?.propertyDetails?.parkingTypeCode !== "Type Unknown" && {
          "Parking Type": pulledReport?.propertyDetails?.parkingTypeCode,
        }),
      ...(pulledReport?.propertyDetails?.qualityCode && {
        Condition: pulledReport?.propertyDetails?.qualityCode,
      }),
      ...(pulledReport?.propertyDetails?.yearBuilt && {
        "Year Built": pulledReport?.propertyDetails?.yearBuilt,
      }),
      ...(pulledReport?.propertyDetails?.stories && {
        Stories: parseInt(pulledReport?.propertyDetails?.stories).toString(),
      }),
    },
  ];

  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Lot & Building Details ${
          loading
            ? ""
            : `(${
                pulledReport?.propertyReportSummary?.lotAndBuildingDetailsCount
                  ? pulledReport.propertyReportSummary
                      ?.lotAndBuildingDetailsCount
                  : 0
              })`
        }`}
        containerId={containerId}
      >
        {loading ? (
          <>
            <Container altColor="grey">
              <Layout type="flex" flexProps={{ direction: "column" }}>
                <Padding space="md">
                  <Padding bottom="xs">
                    <LoadingPlaceholder width={"10rem"} height={"20px"} />
                  </Padding>
                  <Padding bottom="xs">
                    <LoadingPlaceholder width={"10rem"} height={"20px"} />
                  </Padding>
                  <Padding bottom="xs">
                    <LoadingPlaceholder width={"10rem"} height={"20px"} />
                  </Padding>
                </Padding>
              </Layout>
            </Container>
            <LoadingContainerLineItem
              lineItemHeader
              numberToLoad={2}
              children
            />
          </>
        ) : pulledReport?.propertyReportSummary?.lotAndBuildingDetailsCount &&
          pulledReport.propertyReportSummary?.lotAndBuildingDetailsCount > 0 ? (
          <>
            <Container altColor="grey">
              <Layout type="flex" flexProps={{ direction: "column" }}>
                <Padding space="md">
                  {pulledReport.propertyReportSummary?.residenceOverview ? (
                    <Padding bottom="xs">
                      <Layout
                        type="flex"
                        flexProps={{ direction: "row", gap: "xxs" }}
                      >
                        <Text
                          color={brandInfo.acronym === "pf" ? "base" : "accent"}
                        >
                          <Icon size="16" name={IconList.IconHouse} />
                        </Text>
                        <Text size="sm">
                          {
                            pulledReport.propertyReportSummary
                              ?.residenceOverview
                          }
                        </Text>
                      </Layout>
                    </Padding>
                  ) : null}
                  {pulledReport.propertyReportSummary
                    ?.residentialLotOverview ? (
                    <Padding bottom="xs">
                      <Layout
                        type="flex"
                        flexProps={{ direction: "row", gap: "xxs" }}
                      >
                        <Text
                          color={brandInfo.acronym === "pf" ? "base" : "accent"}
                        >
                          <Icon size="16" name={IconList.IconRuler} />
                        </Text>

                        <Text size="sm">
                          {
                            pulledReport.propertyReportSummary
                              ?.residentialLotOverview
                          }
                        </Text>
                      </Layout>
                    </Padding>
                  ) : null}
                  {pulledReport.propertyReportSummary?.bedBathroomOverview ? (
                    <Padding bottom="xs">
                      <Layout
                        type="flex"
                        flexProps={{ direction: "row", gap: "xxs" }}
                      >
                        <Text
                          color={brandInfo.acronym === "pf" ? "base" : "accent"}
                        >
                          <Icon size="16" name={IconList.IconMag} />
                        </Text>

                        <Text size="sm">
                          {
                            pulledReport.propertyReportSummary
                              ?.bedBathroomOverview
                          }
                        </Text>
                      </Layout>
                    </Padding>
                  ) : null}
                  {pulledReport.propertyDetails?.parkingTypeCode ||
                  pulledReport.propertyDetails?.parkingSpaces ? (
                    <Padding bottom="xs">
                      <Layout
                        type="flex"
                        flexProps={{ direction: "row", gap: "xxs" }}
                      >
                        <Text
                          color={brandInfo.acronym === "pf" ? "base" : "accent"}
                        >
                          <Icon size="16" name={IconList.IconParking} />
                        </Text>
                        <Text size="sm">
                          {generateList(
                            [
                              {
                                value: pulledReport.propertyDetails
                                  ?.parkingTypeCode
                                  ? pulledReport.propertyDetails
                                      ?.parkingTypeCode
                                  : null,
                                label: "",
                              },
                              {
                                value: pulledReport.propertyDetails
                                  ?.parkingSpaces
                                  ? `${pulledReport.propertyDetails?.parkingSpaces} spaces`
                                  : null,
                                label: "",
                              },
                            ],
                            " | ",
                          )}
                        </Text>
                      </Layout>
                    </Padding>
                  ) : null}
                  {pulledReport.propertyDetails?.apn ? (
                    <Layout
                      type="flex"
                      flexProps={{ direction: "row", gap: "xxs" }}
                    >
                      <Text
                        color={brandInfo.acronym === "pf" ? "base" : "accent"}
                      >
                        <Icon size="16" name={IconList.IconFolder} />
                      </Text>
                      <Text size="sm">
                        Assessors Parcel Number:{" "}
                        {pulledReport.propertyDetails?.apn ?? null}
                      </Text>
                    </Layout>
                  ) : null}
                </Padding>
              </Layout>
            </Container>

            <CollapsableItemList numberToShow={5} isOpen={printReady}>
              {Object.keys(lotObjects).length > 0
                ? lotObjects.map((obj, index) => {
                    return (
                      <ContainerLineItem
                        lineItemHeader={`Lot Details`}
                        key={`${obj["Assessors Parcel Number"]}-${index}`}
                        isOpen={printReady ? true : false}
                      >
                        <Padding space="md">
                          <Table
                            orderOfProperties={[]}
                            tableDataObject={lotObjects[index]}
                          />
                        </Padding>
                      </ContainerLineItem>
                    );
                  })
                : null}
              {Object.keys(buildingObjects).length > 0
                ? buildingObjects.map((obj, index) => {
                    return (
                      <ContainerLineItem
                        lineItemHeader={`Building Details`}
                        key={`${obj["Total Square Footage"]}-${index}`}
                        isOpen={printReady ? true : false}
                      >
                        <Padding space="md">
                          <Table
                            orderOfProperties={[]}
                            tableDataObject={buildingObjects[index]}
                          />
                        </Padding>
                      </ContainerLineItem>
                    );
                  })
                : null}
            </CollapsableItemList>
          </>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No results found for Lot & Building Details
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default LotBuildingDetails;
