import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  Container,
  ContainerLineItem,
  Padding,
  Table,
  Text,
} from "@kidslivesafe/sc-react";

import {
  AutoReportType,
  VehicleDetails,
} from "../../../features/reports/reportTypes";
import { renderArrayOfObjectsPrep } from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

const VehicleSpecs: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);

  // pulledReport = testData as AutoReportType; //TODO: remove this line

  const loading = autoReportStatus === "loading";

  const vehicleSpecsSummary = renderArrayOfObjectsPrep(
    [pulledReport?.vehicleDetails || []],
    [],
    [
      {
        key: ["interiorTrim"],
        valueFormat: (value, currentObject: VehicleDetails) => {
          if (
            !currentObject.interiorTrim ||
            currentObject.interiorTrim.every(trim => trim === "")
          ) {
            return null;
          }
          const jsxElements = currentObject.interiorTrim.map((trim, i) => (
            <Text key={`${trim}-${i}`} tag="b" size="sm">
              {trim}
            </Text>
          ));
          return <>{jsxElements}</>;
        },
      },
      {
        key: ["exteriorColor"],
        valueFormat: (value, currentObject: VehicleDetails) => {
          if (
            !currentObject.exteriorColor ||
            currentObject.exteriorColor.every(color => color === "")
          ) {
            return null;
          }
          const jsxElements = currentObject.exteriorColor.map((color, i) => (
            <Text key={`${color}-${i}`} tag="b" size="sm">
              {color}
            </Text>
          ));
          return <>{jsxElements}</>;
        },
      },
    ],
  );

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Vehicle Specs ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.vehicleDetails
                  ? Object.keys(vehicleSpecsSummary[0]).length
                  : 0
              })`
        }`}
      >
        {loading ? (
          <LoadingContainerLineItem lineItemHeader children />
        ) : (
          <ContainerLineItem
            lineItemHeader="Full Vehicle Specs"
            isOpen={printReady ? true : false}
          >
            {vehicleSpecsSummary[0] ? (
              <Table tableDataObject={vehicleSpecsSummary[0]} />
            ) : null}
          </ContainerLineItem>
        )}
      </Container>
    </Padding>
  );
};

export default VehicleSpecs;
