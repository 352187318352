import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  Button,
  AnchorLink,
  Icon,
  IconList,
} from "@kidslivesafe/sc-react";
import { useAppSelector, useHandlePageNavigate } from "../../../../hooks/hooks";
import { selectBrandInfo } from "../../../../features/brand/brandSlice";

import "./cancel-online.css";
import {
  selectIDPSubscription,
  selectPrimarySubscription,
} from "../../../../features/subscriptions/subscriptionSlice";
import { selectMemberData } from "../../../../features/authentication/authenticationSlice";
import { formatPhoneNumber, parseDate } from "../../../../utils";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

const CancelOnline: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();

  const brandInfo = useAppSelector(selectBrandInfo);
  const subscriptionInfo = useAppSelector(selectPrimarySubscription);
  const memberInfo = useAppSelector(selectMemberData);
  const idpSubscription = useAppSelector(selectIDPSubscription);

  const currentBreakpoint = useCurrentBreakpoint();

  const renderIDPSubscription = () => {
    if (idpSubscription?.active) {
      return (
        <div>
          <Text tag="h3">ID Protect Plan Details</Text>

          <Layout type="flex" flexProps={{ gap: "xxxs", alignItems: "center" }}>
            <Text color="success">
              <Icon name={IconList.IconCircleCheck} />
            </Text>
            <Text color="success" fontWeight="bold">
              ACTIVE
            </Text>
          </Layout>

          <Text fontWeight="semibold">
            ${idpSubscription?.price} every 30 days.
          </Text>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderMembershipStatus = () => {
    if (subscriptionInfo?.cancelDate) {
      return (
        <>
          <Text>
            <Text tag="span" color="error">
              CANCELED
            </Text>
            . You have access to all of your membership benefits until{" "}
            {memberInfo.expirationDate
              ? parseDate(memberInfo.expirationDate, "MM/DD/YYYY")
              : null}{" "}
            after which your membership will no longer be active. There will be
            no new charges to your credit card.
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text fontWeight="semibold">
            <Text tag="span" color="success">
              ACTIVE
            </Text>
            . This gives you access to all of your membership benefits until{" "}
            {memberInfo.expirationDate
              ? parseDate(memberInfo.expirationDate, "MM/DD/YYYY")
              : null}
            .
          </Text>
          <div>
            <Text tag="h3">{brandInfo.name}</Text>
            <Layout
              type="flex"
              flexProps={{ gap: "xxxs", alignItems: "center" }}
            >
              <Text color="success">
                <Icon name={IconList.IconCircleCheck} />
              </Text>
              <Text color="success" fontWeight="bold">
                ACTIVE
              </Text>
            </Layout>
            <Text fontWeight="semibold">
              ${subscriptionInfo?.price} every{" "}
              {subscriptionInfo?.billingInterval} days.
            </Text>
          </div>
          {renderIDPSubscription()}
          <Text fontWeight="bold">
            To cancel, please call{" "}
            <AnchorLink
              inline
              phoneNumber
              hoverUnderline
              fontWeight="bold"
              color="base"
              href={brandInfo.phoneNumber}
            >
              {formatPhoneNumber(brandInfo.phoneNumber)}
            </AnchorLink>{" "}
            or switch plans{" "}
            <AnchorLink
              color="gray"
              fontWeight="bold"
              hoverUnderline
              inline
              role="button"
              handleClick={() => {
                pageNavigate("/cancel-feedback");
              }}
            >
              here
            </AnchorLink>
            .
          </Text>
        </>
      );
    }
  };

  const listItems = [];

  if (subscriptionInfo) {
    if (subscriptionInfo.propertyReportThreshold > 0) {
      listItems.push(
        `Access to ${subscriptionInfo.propertyReportThreshold} Property Reports per month`,
      );
    }
    if (subscriptionInfo.peopleReportThreshold > 0) {
      listItems.push(
        `Access to ${subscriptionInfo.peopleReportThreshold} People Reports per month`,
      );
    }
    if (subscriptionInfo.phoneReportThreshold > 0) {
      listItems.push(
        `Access to ${subscriptionInfo.phoneReportThreshold} Phone Reports per month`,
      );
    }
    if (subscriptionInfo.socialReportThreshold > 0) {
      listItems.push(
        `Access to ${subscriptionInfo.socialReportThreshold} Social Reports per month`,
      );
    }
    if (subscriptionInfo.autoReportThreshold > 0) {
      listItems.push(
        `Access to ${subscriptionInfo.autoReportThreshold} Auto Reports per month`,
      );
    }
  }

  return (
    <>
      <ReportTitle reportTitle="Membership Information" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h2">Do you need Member Support assistance?</Text>
              <Text fontWeight="bold">
                Our helpful support team is a convenient phone call away 7-days
                a week. For personal assistance, please call us at{" "}
                <AnchorLink
                  inline
                  phoneNumber
                  hoverUnderline
                  color="base"
                  fontWeight="bold"
                  href={brandInfo.phoneNumber}
                >
                  {formatPhoneNumber(brandInfo.phoneNumber)}
                </AnchorLink>
                .
              </Text>
            </Layout>
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h3">Your Account Includes:</Text>
              <ul className="page-cancel-online__ul">
                {brandInfo.acronym === "pf" ? (
                  <>
                    {listItems.map((item, index) => (
                      <Text key={index} tag="li">
                        {item}
                      </Text>
                    ))}
                    <Text tag="li">
                      Active monitoring of up to 10 reports with real-time
                      alerts
                    </Text>
                    <Text tag="li">
                      Accurate, up-to-date information from reliable sources
                    </Text>
                    <Text tag="li">
                      Complete access to all available property data
                    </Text>
                    <Text tag="li">
                      Detailed insights into resident and owner information
                    </Text>
                  </>
                ) : (
                  <>
                    <Text tag="li">Name searches</Text>
                    <Text tag="li">Comprehensive people reports</Text>
                    <Text tag="li">
                      Reports contain address and contact info, arrest and
                      warrants, financial issues, and more
                    </Text>
                    <Text tag="li">
                      Active people monitoring with email alerts when info
                      changes
                    </Text>
                    <Text tag="li">Social media information</Text>
                  </>
                )}
              </ul>
            </Layout>
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h3">Current Membership Status</Text>
              {renderMembershipStatus()}
            </Layout>
            <Layout
              type="flex"
              flexProps={{ direction: "column" }}
              breakpointStyles={{
                desktop: {
                  type: "flex",
                  flexProps: {
                    direction: "column",
                    alignItems: "center",
                  },
                },
              }}
            >
              <Button
                buttonText="Return to Dashboard"
                handleClick={() => pageNavigate("/")}
              />
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default CancelOnline;
