import React from "react";

const TestError: React.FC = () => {

  const ErrorTest = () => {
    let test = new Array(-1);
    console.log(test[1]);
  }

  return(
    <>
    <h3>Unknown ERROR</h3>
    <button onClick={ErrorTest}>TRY ME!</button>
    </>
  );
};

export default TestError;