import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";

// redux getters
import {
  getRentDataAsync,
  selectPropertyReport,
  selectPropertyReportStatus,
  selectRentDataStatus,
  selectRentData,
  selectPropertyReportMonitoringDifferences,
} from "../../../features/reports/reportsSlice";

// utils
import { formatNumberCommas } from "../../../utils";

// components
import {
  Container,
  Layout,
  Padding,
  Text,
  ContainerLineItem,
  IconList,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";

import ConfidenceRating from "../../../components/UIComponents/ConfidenceRating/ConfidenceRating";

import MarketValuesGraph from "../../../components/UIComponents/MarketValuesGraph/MarketValuesGraph";

const MarketValues: React.FC<{ containerId: string; printReady?: boolean }> = ({
  containerId,
  printReady,
}) => {
  const [rentDataCalled, setRentDataCalled] = useState(false);

  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  const dispatch = useAppDispatch();
  const rentDataStatus = useAppSelector(selectRentDataStatus);
  const rentData = useAppSelector(selectRentData);

  const loading = propertyReportStatus === "loading";

  const rating = (r: number) => {
    if (r >= 86) {
      return 5;
    } else if (r <= 85 && r >= 67) {
      return 4;
    } else if (r <= 66 && r >= 40) {
      return 3;
    } else if (r <= 39 && r >= 20) {
      return 2;
    } else if (r <= 19 && r >= 2) {
      return 1;
    } else {
      return 0;
    }
  };
  const handleRentClick = () => {
    if (rentDataCalled) return;
    if (rentDataStatus === "loading") return;
    dispatch(
      getRentDataAsync({
        AddressLine1: pulledReport?.propertyDetails?.fullAddress,
        State: pulledReport?.propertyDetails?.state,
        City: pulledReport?.propertyDetails?.city,
        Zip: pulledReport?.propertyDetails?.postalCode,
      }),
    ).then(action => {
      if (getRentDataAsync.fulfilled.match(action)) {
        setRentDataCalled(true);
      }
    });
  };

  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Market Values ${
          loading
            ? ""
            : `(${
                pulledReport?.propertyReportSummary
                  ? pulledReport.propertyReportSummary.marketValueCount
                  : 0
              })`
        }`}
        containerId={containerId}
        tooltipContent="Market Values indicate the likely selling price of a property based on recent sales of similar properties, current market conditions, and other relevant factors. Estimated Market Value is often determined by professional appraisers or real estate agents."
        newInfo={reportDifferences?.marketValues}
      >
        {loading ? (
          <LoadingPlaceholder width={"300px"} height={"300px"} />
        ) : pulledReport?.propertyValuation &&
          pulledReport?.propertyReportSummary?.marketValueCount !== 0 ? (
          <>
            <Padding space="md" bottom="none">
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {pulledReport?.propertyValuation?.latest ? (
                  <>
                    <Text size="base">Estimated Market Value</Text>
                    <Text
                      size="xl"
                      fontWeight="bold"
                    >{`$${pulledReport?.propertyValuation?.latest?.valuationAmount?.marketValue ? formatNumberCommas(pulledReport?.propertyValuation?.latest?.valuationAmount?.marketValue) : null} `}</Text>
                    <Padding top="md">
                      <Text size="xs" textAlign="center">
                        {`Estimated sales range: $${pulledReport?.propertyValuation?.latest?.valuationAmount?.rangeLow ? formatNumberCommas(pulledReport?.propertyValuation?.latest?.valuationAmount?.rangeLow) : null} - $${pulledReport?.propertyValuation?.latest?.valuationAmount?.rangeHigh ? formatNumberCommas(pulledReport?.propertyValuation?.latest?.valuationAmount?.rangeHigh) : null}`}
                      </Text>
                    </Padding>
                  </>
                ) : null}

                {pulledReport?.propertyValuation?.historical &&
                pulledReport.propertyValuation.historical.length > 0 ? (
                  <MarketValuesGraph
                    propValHistoryArray={
                      pulledReport.propertyValuation.historical
                    }
                  />
                ) : null}
                <Padding bottom="sm">
                  <ConfidenceRating
                    numberOfColored={rating(
                      pulledReport?.propertyValuation?.latest?.valuationAmount
                        ?.confidence
                        ? pulledReport.propertyValuation?.latest
                            ?.valuationAmount?.confidence
                        : 0,
                    )}
                    maxScore={
                      pulledReport?.propertyValuation?.latest?.valuationAmount
                        ?.confidence
                        ? pulledReport.propertyValuation?.latest
                            ?.valuationAmount?.confidence
                        : 0
                    }
                    toolTipContent="Represents the reliability associated with the estimated market value. A higher confidence rating indicates a greater level of certainty."
                    iconType={IconList.IconHelp}
                  />
                </Padding>
                <hr
                  style={{
                    borderTop: "1px solid rgb(201, 203, 212)",
                    height: "1px",
                    width: "100%",
                    borderRight: "none",
                    borderLeft: "none",
                    borderBottom: "none",
                  }}
                />
              </Layout>
            </Padding>

            <Padding bottom="sm">
              <ContainerLineItem
                lineItemHeader="Rent Estimate"
                isOpen={printReady ? true : false}
                handleExpandClick={() => {
                  handleRentClick();
                }}
              >
                <Padding space="md">
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", alignItems: "center" }}
                  >
                    {rentDataStatus === "loading" ? (
                      <Padding bottom="md">
                        <LoadingPlaceholder height={"16px"} width={"10rem"} />
                      </Padding>
                    ) : rentDataCalled &&
                      rentData?.estimatedRentalValue &&
                      rentData?.estimatedMinRentalValue &&
                      rentData?.estimatedMaxRentalValue ? (
                      <>
                        <Padding bottom="md">
                          <Text size="xl" fontWeight="bold">
                            $
                            {formatNumberCommas(rentData?.estimatedRentalValue)}{" "}
                            per month
                          </Text>
                        </Padding>
                        <Layout
                          type="flex"
                          flexProps={{ justifyContent: "center" }}
                        >
                          <Text size="xs">Estimated rent range:</Text>
                          <Padding left="xxs">
                            <Text size="xs">
                              $
                              {formatNumberCommas(
                                rentData?.estimatedMinRentalValue,
                              )}{" "}
                              - $
                              {formatNumberCommas(
                                rentData?.estimatedMaxRentalValue,
                              )}
                            </Text>
                          </Padding>
                        </Layout>
                      </>
                    ) : (
                      <Text size="xxl" fontWeight="bold">
                        No Rent Estimate Available
                      </Text>
                    )}
                  </Layout>
                </Padding>
              </ContainerLineItem>
            </Padding>
          </>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No results found for Market Values
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default MarketValues;
