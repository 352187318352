import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";

import "./print.css"; // used for print styling .page-break could be added
import "./property-report.css";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Container,
  Padding,
  Text,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
  Modal,
  Icon,
  IconList,
  Input,
  Button,
  AnchorLink,
  Tag,
} from "@kidslivesafe/sc-react";

import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

import {
  generateListNotFound,
  parseDate,
  generatePropertyReportHighlights,
  formatAddress,
} from "../../../utils";

import { selectBrandInfo } from "../../../features/brand/brandSlice";

// Property Report Sections
import LotBuildingDetails from "./LotBuildingDetails";
import PropertyTimeline from "./PropertyTimeline";
import OwnershipHistory from "./OwnershipHistory";
import ResidentHistory from "./ResidentHistory";
import PropertySales from "./PropertySales";
import MarketValues from "./MarketValues";
import ValueTaxes from "./ValueTaxes";
import NeighborhoodOverview from "./NeighborhoodOverview";
import Evictions from "./Evictions";
import Liens from "./Liens";
import BusinessDetails from "./BusinessDetails";
import ProfessionalLicenses from "./ProfessionalLicenses";
import UCCFilings from "./UCCFilings";
import Foreclosures from "./Foreclosures";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";

import { selectPropertySearchParams } from "../../../features/multisearch/multisearchSlice";

import { useTogglePropertyMonitoringDispatch } from "../../../hooks/monitoringHook";
import useSiteJabberDispatch from "../../../hooks/siteJabberHook";

import NoResults from "../../../components/UIComponents/NoResults/NoResults";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";
import StreetView from "@kidslivesafe/sc-react/lib/atoms/StreetView";
import { PropertyMonitoringParamsInterface } from "../../../features/monitoring/monitoringAPI";
import {
  checkCurrentlyMonitoredProperty,
  selectMaxMonitoringReached,
  selectPropertyMonitoring,
  selectPropertyMonitoringStatus,
  updatePropertyMonitoringAsync,
} from "../../../features/monitoring/monitoringSlice";
import { selectMemberExperienceData } from "../../../features/authentication/authenticationSlice";
import TimedMonitoringModal from "../../../components/UIComponents/TimedMonitoringModal/TimedMonitoringModal";

const PropertyReport: React.FC = () => {
  const propertySearch = useAppSelector(selectPropertySearchParams);
  // local state
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);
  const [openMonitoringModal, setOpenMonitoringModal] =
    useState<boolean>(false);
  const [nickname, setNickname] = useState<string>("");
  const [nicknameError, setNickNameError] = useState<boolean>(false);
  const [printReady, setPrintReady] = useState<boolean>(false);
  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const dispatch = useAppDispatch();

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  const monitoredProperties = useAppSelector(selectPropertyMonitoring);
  const monitoringLimitReached = useAppSelector(selectMaxMonitoringReached);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  const memberExperienceData = useAppSelector(selectMemberExperienceData);

  useEffect(() => {
    // closes monitoring modal when max number of monitoring areas is hit
    if (monitoringLimitReached === true) {
      setOpenMonitoringModal(false);
    }
  }, [monitoringLimitReached]);

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  const { togglePropertyMonitoring, monitoringSuccess } =
    useTogglePropertyMonitoringDispatch();

  // store state
  const pulledReport = useAppSelector(selectPropertyReport);
  const currentlyMonitoring = useAppSelector(state =>
    checkCurrentlyMonitoredProperty(
      state,
      pulledReport && pulledReport.propertyReportSummary
        ? {
            addressLine1: pulledReport.propertyReportSummary.addressLine1,
            addressLine2: pulledReport.propertyReportSummary.addressLine2,
            city: pulledReport.propertyReportSummary.city,
            state: pulledReport.propertyReportSummary.state,
            zip: pulledReport.propertyReportSummary.zip,
          }
        : undefined,
    ),
  );
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const brandInfo = useAppSelector(selectBrandInfo);
  const runSiteJabber = useSiteJabberDispatch();
  const otherSearches = generateListNotFound(
    [
      {
        value:
          pulledReport && pulledReport.businesses
            ? pulledReport.businesses.length > 0
              ? null
              : "Businesses"
            : "Businesses",
        label: "",
      },
      {
        value:
          pulledReport && pulledReport.evictions
            ? pulledReport.evictions.length > 0
              ? null
              : "Evictions"
            : "Evictions",
        label: "",
      },
      {
        value:
          pulledReport && pulledReport.foreclosures
            ? pulledReport.foreclosures.length > 0
              ? null
              : "Foreclosures"
            : "Foreclosures",
        label: "",
      },
      {
        value:
          pulledReport && pulledReport.liens
            ? pulledReport.liens.length > 0
              ? null
              : "Liens"
            : "Liens",
        label: "",
      },

      {
        value:
          pulledReport && pulledReport.professionalLicenses
            ? pulledReport.professionalLicenses.length > 0
              ? null
              : "Professional Licenses"
            : "Professional Licenses",
        label: "",
      },
      {
        value:
          pulledReport && pulledReport.uccFilings
            ? pulledReport.uccFilings.length > 0
              ? null
              : "UCC Filings"
            : "UCC Filings",
        label: "",
      },
    ],
    ", ",
  );

  const loading = propertyReportStatus === "loading";
  const monitoringLoading =
    useAppSelector(selectPropertyMonitoringStatus) === "loading";

  // constants
  const lotBuildingDetailsId = "lot-building-details";
  const propertyTimelineId = "property-timeline";
  const ownershipHistoryId = "ownership-history";
  const residentHistoryId = "resident-history";
  const propertySalesId = "property-sales";
  const marketValuesId = "market-values";
  const valueTaxesId = "values-taxes";
  const neighborhoodId = "neighborhood-overview";
  const evictionsId = "evictions";
  const liensId = "liens";
  const businessesId = "businesses";
  const professionalLicensesId = "professional-licenses";
  const UccLicensesId = "ucc-licenses";
  const foreclosuresId = "foreclosures";

  // effects
  /* generate Navigation Props */
  useEffect(() => {
    if (pulledReport) {
      setNavigateProps([
        {
          navigateToSectionId: lotBuildingDetailsId,
          navigateToSectionText: "Lot & Building Details",
          itemCount:
            pulledReport.propertyReportSummary?.lotAndBuildingDetailsCount ?? 0,
          newInfo: false,
          loading: loading,
        },
        {
          navigateToSectionId: propertyTimelineId,
          navigateToSectionText: "Property Timeline",
          itemCount:
            pulledReport.propertyReportSummary
              ?.propertyTransactionsDisplayCount ?? 0,
          newInfo: false,
          loading: loading,
        },
        {
          navigateToSectionId: ownershipHistoryId,
          navigateToSectionText: "Ownership History",
          itemCount:
            pulledReport.propertyReportSummary?.ownershipHistoryCount ?? 0,
          newInfo: reportDifferences?.ownershipHistories,
          loading: loading,
        },
        {
          navigateToSectionId: residentHistoryId,
          navigateToSectionText: "Resident History",
          itemCount: pulledReport.propertyReportSummary?.residentsCount ?? 0,
          newInfo: reportDifferences?.residents,
          loading: loading,
        },
        {
          navigateToSectionId: propertySalesId,
          navigateToSectionText: "Property Sales",
          itemCount: pulledReport.propertyOwnershipHistory
            ? pulledReport.propertyOwnershipHistory.length
            : 0,
          newInfo: reportDifferences?.propertyTransactions,
          loading: loading,
        },
        {
          navigateToSectionId: marketValuesId,
          navigateToSectionText: "Market Values",
          itemCount: pulledReport.propertyReportSummary?.marketValueCount ?? 0,
          newInfo: reportDifferences?.marketValues,
          loading: loading,
        },
        {
          navigateToSectionId: valueTaxesId,
          navigateToSectionText: "Assessed Value & Taxes",
          itemCount: pulledReport.propertyAssessments
            ? pulledReport.propertyAssessments.length
            : 0,
          newInfo: reportDifferences?.propertyAssessments,
          loading: loading,
        },
        {
          navigateToSectionId: "neighborhood-overview",
          navigateToSectionText:
            ["prr"].includes(brandInfo.acronym) &&
            memberExperienceData &&
            memberExperienceData.variant &&
            [
              "m31_prr_/property-report_v1",
              "m31_prr_/property-report_v2",
            ].includes(memberExperienceData.variant) ? (
              <Layout
                type="flex"
                flexProps={{ gap: "xs", alignItems: "center" }}
              >
                <Text>Neighborhood Overview</Text>
                <Tag
                  type="ai"
                  size="small"
                  tagText={
                    <Layout
                      type="flex"
                      flexProps={{ alignItems: "center", gap: "xxxs" }}
                    >
                      <Icon block="inline" name={IconList.IconAi} size="12" />
                      AI
                    </Layout>
                  }
                />
              </Layout>
            ) : (
              "Neighborhood Overview"
            ),
          itemCount:
            (pulledReport.propertyReportSummary?.neighborsCount ?? 0) +
            (pulledReport.neighborhoodSchools?.length ?? 0),
          newInfo: reportDifferences?.neighbors,
          loading: loading,
        },
        {
          navigateToSectionId: businessesId,
          navigateToSectionText: "Businesses at Address",
          itemCount: pulledReport.businesses
            ? pulledReport.businesses.length
            : 0,
          newInfo: reportDifferences?.businesses,
          displayIfZero: false,
          loading: loading,
        },
        {
          navigateToSectionId: evictionsId,
          navigateToSectionText: "Evictions at Address",
          itemCount: pulledReport.evictions ? pulledReport.evictions.length : 0,
          newInfo: reportDifferences?.evictions,
          displayIfZero: false,
          loading: loading,
        },
        {
          navigateToSectionId: foreclosuresId,
          navigateToSectionText: "Foreclosures at Address",
          itemCount: pulledReport.foreclosures
            ? pulledReport.foreclosures.length
            : 0,
          newInfo: reportDifferences?.foreclosures,
          displayIfZero: false,
          loading: loading,
        },
        {
          navigateToSectionId: liensId,
          navigateToSectionText: "Liens at Address",
          itemCount: pulledReport.liens ? pulledReport.liens.length : 0,
          newInfo: reportDifferences?.liens,
          displayIfZero: false,
          loading: loading,
        },
        {
          navigateToSectionId: professionalLicensesId,
          navigateToSectionText: "Professional Licenses at Address",
          itemCount: pulledReport.professionalLicenses
            ? pulledReport.professionalLicenses.length
            : 0,
          newInfo: reportDifferences?.professionalLicenses,
          displayIfZero: false,
          loading: loading,
        },
        {
          navigateToSectionId: UccLicensesId,
          navigateToSectionText: "UCC Filings at Address",
          itemCount: pulledReport.uccFilings
            ? pulledReport.uccFilings.length
            : 0,
          newInfo: reportDifferences?.uccFilings,
          displayIfZero: false,
          loading: loading,
        },
      ]);
    }
  }, [
    pulledReport,
    loading,
    reportDifferences,
    brandInfo,
    memberExperienceData,
  ]);

  useEffect(() => {
    // Show SiteJabber prompt only for select brands
    if (
      !loading &&
      ["pf", "sf", "qpr", "pis", "prr", "pdc"].includes(brandInfo.acronym)
    ) {
      runSiteJabber();
    }
  }, [loading, brandInfo]);

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Property Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport && pulledReport.propertyDetails?.fullAddress
              ? formatAddress(pulledReport.propertyDetails)
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport && pulledReport.propertyDetails
            ? `${originalTitle} - ${pulledReport.propertyDetails.fullAddress}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-container-line-item__content--closed",
          );
          const showMoreElements = document.querySelectorAll(
            ".sc-show-more__icon--closed",
          );
          if (elements.length === 0 && showMoreElements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  const handleSubmitNickName = (nickname: string) => {
    if (nickname.length > 15 || nickname.length === 0) {
      setNickNameError(true);
    } else {
      setNickNameError(false);
    }
  };

  return (
    <>
      {pulledReport || loading ? (
        <PrintableComponent
          ref={contentToPrint}
          printReady={printReady}
          reportType="property"
          header={
            pulledReport && pulledReport.propertyDetails
              ? formatAddress(pulledReport.propertyDetails, "with-postalCode")
              : undefined
          }
          subtitle={
            pulledReport && pulledReport.propertyReportSummary
              ? generatePropertyReportHighlights(pulledReport)
              : undefined
          }
          date={new Date().toLocaleDateString()}
        >
          <ReportTitle
            reportTitle="Property Report"
            reportType="property"
            handleDownload={
              loading
                ? () => {
                    console.log("Wait for loading");
                  }
                : () => {
                    sendMemberEvent();
                    handlePrint(null, () => null);
                  }
            }
          />
          <Sheet>
            <Padding
              space="none"
              bottom="md"
              breakpointStyles={{
                desktop: {
                  space: "md",
                  top: "none",
                },
              }}
            >
              <Layout
                type="grid"
                gridProps={{ gap: "md", templateColumns: "1-column" }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { templateColumns: componentFormat, gap: "md" },
                  },
                }}
                shrinkChildren
              >
                <>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "md", direction: "column" }}
                    passThroughClassess="page-property-report__left-column"
                  >
                    <Sticky
                      space="sm"
                      turnOffAtBreakPoint={["mobile"]}
                      direction="header-offset"
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <div>
                          <ReportSummary<PropertyMonitoringParamsInterface>
                            reportDate={
                              loading ? (
                                <LoadingPlaceholder
                                  height={"16px"}
                                  width={"10rem"}
                                />
                              ) : (
                                `REPORT DATE ${parseDate(
                                  new Date().toLocaleDateString(), // change to pulled report date
                                  "MM/DD/YYYY",
                                )}`
                              )
                            }
                            reportTitle={
                              loading ? (
                                <LoadingPlaceholder
                                  height={"25px"}
                                  width={"15rem"}
                                />
                              ) : pulledReport &&
                                pulledReport.propertyDetails?.fullAddress ? (
                                <>
                                  <Text size="xl" textAlign="center" tag="span">
                                    {pulledReport.propertyDetails.fullAddress}
                                  </Text>
                                  <br />
                                  <Text size="xl" textAlign="center" tag="span">
                                    {pulledReport.propertyDetails.city},{" "}
                                    {pulledReport.propertyDetails.state}{" "}
                                    {pulledReport.propertyDetails.postalCode}
                                  </Text>
                                </>
                              ) : (
                                ""
                              )
                            }
                            reportSubtitle={
                              loading ? (
                                <LoadingPlaceholder
                                  height={"14px"}
                                  width={"10rem"}
                                />
                              ) : pulledReport &&
                                pulledReport.propertyReportSummary
                                  ?.residenceOverview ? (
                                pulledReport.propertyReportSummary
                                  .residenceOverview
                              ) : (
                                ""
                              )
                            }
                            reportHighlights={
                              loading ? (
                                <LoadingPlaceholder
                                  height={"18px"}
                                  width={"12rem"}
                                />
                              ) : pulledReport ? (
                                generatePropertyReportHighlights(pulledReport)
                              ) : null
                            }
                            newInfo={false} // TODO: update with report difference for property
                            monitoringInput={
                              pulledReport && pulledReport.propertyReportSummary
                                ? {
                                    addressLine1:
                                      pulledReport.propertyReportSummary
                                        .addressLine1,
                                    addressLine2:
                                      pulledReport.propertyReportSummary
                                        .addressLine2,
                                    city: pulledReport.propertyReportSummary
                                      .city,
                                    nickname: `Property ${monitoredProperties.length + 1}`,
                                    state:
                                      pulledReport.propertyReportSummary.state,
                                    zip: pulledReport.propertyReportSummary.zip,
                                    version:
                                      pulledReport.propertyReportSummary
                                        .version,
                                    bedrooms:
                                      pulledReport.propertyDetails?.bedrooms,
                                    totalBaths:
                                      pulledReport.propertyDetails?.totalBaths,
                                    sqft: pulledReport.propertyDetails
                                      ?.buildingSquareFeet,
                                    lotAcres:
                                      pulledReport.propertyDetails?.acres,
                                  }
                                : undefined
                            }
                            monitoringInitialState={
                              loading ? false : currentlyMonitoring
                            }
                            handleMonitoring={address => {
                              if (loading) {
                                console.log("Wait for loading");
                              } else {
                                if (currentlyMonitoring) {
                                  togglePropertyMonitoring(address);
                                } else {
                                  togglePropertyMonitoring(address);
                                  setOpenMonitoringModal(true);
                                }
                              }
                            }}
                            monitoringSuccess={monitoringSuccess}
                            monitoringWiggle
                          />
                          {loading ? (
                            <LoadingPlaceholder width={"100%"} height={250} />
                          ) : (
                            <StreetView
                              lat={pulledReport?.propertyDetails?.latitude}
                              long={pulledReport?.propertyDetails?.longitude}
                              size={{ width: 374, height: 250 }}
                            ></StreetView>
                          )}
                        </div>
                        <Padding
                          left="md"
                          right="md"
                          breakpointStyles={{
                            desktop: {
                              space: "none",
                            },
                          }}
                        >
                          <NavigateToSection
                            items={navigateProps}
                            tooltipContent={`The ${brandInfo.name} Property Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                          />
                        </Padding>
                      </Layout>
                    </Sticky>
                  </Layout>
                  <Layout type="flex" flexProps={{ direction: "column" }}>
                    <Padding space="none" top="md">
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        {/* property report sections */}
                        <LotBuildingDetails
                          containerId={lotBuildingDetailsId}
                          printReady={printReady}
                        />
                        <PropertyTimeline
                          containerId={propertyTimelineId}
                          printReady={printReady}
                        />
                        <OwnershipHistory
                          containerId={ownershipHistoryId}
                          printReady={printReady}
                        />
                        <ResidentHistory
                          containerId={residentHistoryId}
                          printReady={printReady}
                        />
                        <PropertySales
                          containerId={propertySalesId}
                          printReady={printReady}
                        />
                        <MarketValues
                          containerId={marketValuesId}
                          printReady={printReady}
                        />
                        <ValueTaxes
                          containerId={valueTaxesId}
                          printReady={printReady}
                        />
                        <NeighborhoodOverview
                          containerId={neighborhoodId}
                          printReady={printReady}
                        />
                        <BusinessDetails
                          containerId={businessesId}
                          printReady={printReady}
                        />
                        <Evictions
                          containerId={evictionsId}
                          printReady={printReady}
                        />
                        <Foreclosures
                          containerId={foreclosuresId}
                          printReady={printReady}
                        />
                        <Liens containerId={liensId} printReady={printReady} />

                        <ProfessionalLicenses
                          containerId={professionalLicensesId}
                          printReady={printReady}
                        />
                        <UCCFilings
                          containerId={UccLicensesId}
                          printReady={printReady}
                        />

                        {/* end of property report sections */}
                        {otherSearches && (
                          <Padding
                            left="md"
                            right="md"
                            breakpointStyles={{
                              desktop: {
                                space: "none",
                              },
                            }}
                          >
                            <Container>
                              <Padding space="md">
                                <Container
                                  customClassName="complaints__container"
                                  bodyBorderRadius
                                  v-if="false"
                                >
                                  <Padding
                                    top="lg"
                                    bottom="lg"
                                    left="xs"
                                    right="xs"
                                  >
                                    <Layout
                                      type="flex"
                                      flexProps={{
                                        alignItems: "center",
                                        direction: "column",
                                      }}
                                    >
                                      <Padding bottom="sm">
                                        <Text
                                          size="xxl"
                                          fontWeight="bold"
                                          headerFont
                                        >
                                          Other Searches Conducted
                                        </Text>
                                      </Padding>
                                      <Text
                                        fontWeight="semibold"
                                        textAlign="center"
                                      >
                                        No {otherSearches} found at address
                                      </Text>
                                    </Layout>
                                  </Padding>
                                </Container>
                              </Padding>
                            </Container>
                          </Padding>
                        )}
                      </Layout>
                    </Padding>
                  </Layout>
                </>
              </Layout>
            </Padding>
          </Sheet>
        </PrintableComponent>
      ) : (
        <>
          <ReportTitle reportTitle="Property Report" reportType="property" />
          <NoResults
            searchType="property"
            header={
              <Text textAlign="center" fontWeight="bold" tag="h2">
                No results for
              </Text>
            }
            subHeader={
              <Text textAlign="center">
                We’ve run searches and found no data for this input. In some
                cases this may be due to privacy preferences or regulations.
              </Text>
            }
            noResultText={
              <Text tag="h2" fontWeight="bold" textAlign="center">
                {propertySearch?.addressLine1}{" "}
                {propertySearch?.addressLine2
                  ? propertySearch.addressLine2
                  : null}{" "}
                <br /> {propertySearch?.city ? propertySearch.city : null},{" "}
                {propertySearch?.state ? propertySearch.state : null}{" "}
                {propertySearch?.zip ? propertySearch.zip : null}
              </Text>
            }
            soloTab={["pf"].includes(brandInfo.acronym) ? true : false}
          />
        </>
      )}
      <Modal
        open={openMonitoringModal}
        setOpen={() => {
          setOpenMonitoringModal(!openMonitoringModal);
          setNickname("");
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding top="md" bottom="md">
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "lg", alignItems: "center" }}
          >
            <Text tag="h2">Property Monitoring Set</Text>
            <Text textAlign="center">
              If we detect changes, we'll notify you with a{" "}
              <Text tag="span" color="monitoring">
                <Icon name={IconList.IconMonitoring} size={20} block="inline" />
              </Text>{" "}
              and send you an alert.
            </Text>
            <Layout
              type="flex"
              fillHorizontal
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Layout
                type="flex"
                fillHorizontal
                flexProps={{
                  gap: "xxs",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <Text size="sm">Set a name for your report (optional)</Text>
                <Text size="xxs" tag="i">
                  Please use 15 characters maximum
                </Text>
              </Layout>
              <Layout
                type="flex"
                fillHorizontal
                flexProps={{
                  gap: "xs",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <Input
                  id="neighborhood-report__monitoring-modal"
                  value={nickname}
                  handleChange={input => {
                    setNickname(input);
                    handleSubmitNickName(input);
                  }}
                  type="text"
                  placeholder="Property 1 (optional)"
                  fill="fill"
                  error={nicknameError}
                />
                <Button
                  buttonText="Set Name"
                  fill="fill"
                  disabled={monitoringLoading || nicknameError}
                  handleClick={() => {
                    if (
                      currentlyMonitoring &&
                      pulledReport &&
                      pulledReport.propertyReportSummary
                    ) {
                      if (nickname.length > 0) {
                        dispatch(
                          updatePropertyMonitoringAsync({
                            addressLine1:
                              pulledReport.propertyReportSummary.addressLine1,
                            addressLine2:
                              pulledReport.propertyReportSummary.addressLine2,
                            city: pulledReport.propertyReportSummary.city,
                            state: pulledReport.propertyReportSummary.state,
                            zip: pulledReport.propertyReportSummary.zip,
                            nickname: nickname,
                          }),
                        );

                        setOpenMonitoringModal(false);
                        setNickname("");
                      } else {
                        setNickNameError(true);
                      }
                    }
                  }}
                />
                <AnchorLink
                  role="button"
                  size="xs"
                  handleClick={() => {
                    setOpenMonitoringModal(false);
                    setNickname("");
                  }}
                >
                  No Thanks, Exit
                </AnchorLink>
              </Layout>
            </Layout>
          </Layout>
        </Padding>
      </Modal>
      {pulledReport && pulledReport.propertyReportSummary && (
        <TimedMonitoringModal<PropertyMonitoringParamsInterface>
          reportTitle={
            pulledReport.propertyDetails?.fullAddress ? (
              <>
                <Text size="xl" textAlign="center" tag="span">
                  {pulledReport.propertyDetails.fullAddress}
                </Text>
                <br />
                <Text size="xl" textAlign="center" tag="span">
                  {pulledReport.propertyDetails.city},{" "}
                  {pulledReport.propertyDetails.state}{" "}
                  {pulledReport.propertyDetails.postalCode}
                </Text>
              </>
            ) : (
              ""
            )
          }
          toggleMonitoring={togglePropertyMonitoring}
          monitoringInput={{
            addressLine1: pulledReport.propertyReportSummary.addressLine1,
            addressLine2: pulledReport.propertyReportSummary.addressLine2,
            city: pulledReport.propertyReportSummary.city,
            nickname: `Property ${monitoredProperties.length + 1}`,
            state: pulledReport.propertyReportSummary.state,
            zip: pulledReport.propertyReportSummary.zip,
            version: pulledReport.propertyReportSummary.version,
            bedrooms: pulledReport.propertyDetails?.bedrooms,
            totalBaths: pulledReport.propertyDetails?.totalBaths,
            sqft: pulledReport.propertyDetails?.buildingSquareFeet,
            lotAcres: pulledReport.propertyDetails?.acres,
          }}
          currentlyMonitoring={currentlyMonitoring}
          monitoringSuccess={monitoringSuccess}
          loading={loading}
        />
      )}
      <div className="stjr-instant-feedback"></div>
    </>
  );
};

export default PropertyReport;
