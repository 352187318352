import React, { useEffect, useState } from "react";

// utils
import { formatNumberCommas } from "../../../utils";

import { Chart, ChartWrapperOptions } from "react-google-charts";

interface MarketValuesGraphProps {
  propValHistoryArray: ValuationData[];
}

interface ValuationData {
  valuationDate?: string;
  valuationAmount?: {
    confidence?: number;
    marketValue?: number;
    rangeHigh?: number;
    rangeLow?: number;
    fsd?: number;
  };
}
interface AxisTick {
  v: number;
  f: string;
}

type DataPoint =
  | {
      type: "number" | "string" | "date";
      label?: string;
      id?: string;
      role?: string;
      p?: Ptype;
    }
  | number
  | string
  | Date;

type Ptype = { html?: boolean };

const MarketValuesGraph: React.FC<MarketValuesGraphProps> = ({
  propValHistoryArray = [],
}) => {
  function formatNumberForAxis(value: number) {
    if (value >= 1000000) {
      return "$" + (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return "$" + (value / 1000).toFixed(1) + "K";
    } else {
      return "$" + value;
    }
  }

  function generateTooltipContent(
    date: string,
    val1: string,
    val2: string,
    val3: string,
  ) {
    return (
      "<div>Date: <strong>" +
      date +
      "</strong><br>Low: <strong>" +
      val1 +
      "</strong><br>High: <strong>" +
      val2 +
      "</strong><br>Market Value: <strong>" +
      val3 +
      "</strong></div>"
    );
  }

  //local state
  const [data, setData] = useState<DataPoint[][]>([]);
  const [yAxisTicks, setYAxisTicks] = useState<AxisTick[]>([]);

  const options = {
    hAxis: {
      titleTextStyle: { color: "#333" },
      format: "yyyy",
    },
    vAxis: {
      minValue: 0,
      ticks: yAxisTicks, // Use custom formatted ticks
      gridlines: {},
    },
    pointSize: 3,
    pointShape: "diamond",
    interval: {
      i1: { style: "area", color: "#bbd1e3", lineWidth: 0 },
    },
    series: {
      0: { type: "line", color: "#44A54D" },
    },
    isStacked: true,
    crosshair: { trigger: "both" },
    tooltip: { isHtml: true },
    legend: { position: "none" },
    chartArea: {
      width: "90%",
      left: "10%",
      top: 60,
    },
  };

  //custom y-axis ticks
  useEffect(() => {
    let maxValue = 0;
    let tickArray = [];
    propValHistoryArray.forEach(item => {
      if (
        item.valuationAmount &&
        item.valuationAmount.rangeHigh &&
        item.valuationAmount.marketValue
      ) {
        maxValue = Math.max(
          maxValue,
          item.valuationAmount.rangeHigh,
          item.valuationAmount?.marketValue,
        );
      }
    });
    if (maxValue > 0) {
      for (let i = 0; i <= maxValue; i += maxValue / 5) {
        // Adjust the step as needed
        tickArray.push({ v: i, f: formatNumberForAxis(i) });
      }
    }
    setYAxisTicks(tickArray);
  }, [propValHistoryArray]);

  //graph Data function
  useEffect(() => {
    let graphData: DataPoint[][] = [
      [
        { type: "date", label: "x" },
        { type: "number", label: "values" },
        { type: "string", role: "tooltip", p: { html: true } },
        { id: "i1", type: "number", role: "interval" },
        { id: "i1", type: "number", role: "interval" },
      ],
    ];

    propValHistoryArray.forEach((data: ValuationData) => {
      if (
        data.valuationAmount &&
        data.valuationDate &&
        data.valuationAmount.marketValue &&
        data.valuationAmount.rangeHigh &&
        data.valuationAmount.rangeLow
      ) {
        //create tooltip
        let tooltip = generateTooltipContent(
          data.valuationDate,
          "$" + formatNumberCommas(data.valuationAmount.rangeLow),
          "$" + formatNumberCommas(data.valuationAmount.rangeHigh),
          "$" + formatNumberCommas(data.valuationAmount.marketValue),
        );

        //formatting x-axis
        let dateParts = data.valuationDate.split("/");
        let date = new Date(
          +dateParts[2],
          parseInt(dateParts[0]) - 1,
          +dateParts[1],
        );

        graphData.push([
          date,
          data.valuationAmount.marketValue,
          tooltip,
          data.valuationAmount.rangeLow,
          data.valuationAmount.rangeHigh,
        ]);
      }
    });
    setData(graphData);
  }, [propValHistoryArray]);

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="300px"
      data={data}
      options={options as unknown as ChartWrapperOptions}
    />
  );
};

export default MarketValuesGraph;
