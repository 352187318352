import React from "react";

import { useAppSelector, useHandlePageNavigate } from "../../hooks/hooks";
import {
  selectAllReportHistory,
  selectReportHistoryStatus,
} from "../../features/reports/reportsSlice";

import {
  AnchorLink,
  CollapsableItemList,
  Container,
  Layout,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";
import ActivityHistoryLineItem from "../../components/UIComponents/ActivityHistory/ActivityHistoryLineItem";

const ActivityHistoryContainer: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();

  const handleClick = () => {
    pageNavigate("/activity-history");
  };

  const reportHistory = useAppSelector(selectAllReportHistory);
  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);

  const loading = reportHistoryStatus === "loading";

  return (
    <Padding left="md" right="md">
      <Container
        containerTitle="Activity History"
        containerId="dashboard__activity-history__container"
      >
        <CollapsableItemList>
          {loading ? (
            Array(5)
              .fill(null)
              .map((_, index) => (
                <ActivityHistoryLineItem
                  report={undefined}
                  loading={loading}
                  loadingKey={index}
                  key={index}
                />
              ))
          ) : reportHistory && reportHistory.length > 0 ? (
            reportHistory
              .slice(0, 5)
              .map(report =>
                report ? (
                  <ActivityHistoryLineItem
                    report={report}
                    loadingKey={report.score}
                    key={report.score}
                  />
                ) : null,
              )
          ) : (
            <Padding space="md">
              <Text>No Activity History</Text>
            </Padding>
          )}
        </CollapsableItemList>
        <Padding space="md" top="none">
          <Layout type="flex" flexProps={{ justifyContent: "center" }}>
            <AnchorLink
              handleClick={handleClick}
              role="button"
              hoverUnderline
              size="xs"
              fontWeight="semibold"
            >
              View All
            </AnchorLink>
          </Layout>
        </Padding>
      </Container>
    </Padding>
  );
};

export default ActivityHistoryContainer;
