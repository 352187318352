export const formatNumberCommas = (
  amount: string | number,
  dollarSign = false,
): string => {
  // Convert amount to a string
  const strAmount = amount.toString();

  // Split the string into parts before and after the decimal point
  const parts = strAmount.split(".");

  // Add commas to the thousands part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Join the parts back together with a decimal point if necessary
  const formattedAmount = parts.join(".");

  // Return with or without dollar sign based on dollarSign
  return dollarSign && formattedAmount[0] !== "$"
    ? `$${formattedAmount}`
    : formattedAmount;
};
