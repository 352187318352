export function scoreGenerator(): number {
  const dateObj = new Date();

  let year = dateObj.getUTCFullYear();
  let month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  let day = String(dateObj.getUTCDate()).padStart(2, "0");
  let hours = String(dateObj.getUTCHours()).padStart(2, "0");
  let minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  let seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

  return Number(`${year}${month}${day}${hours}${minutes}${seconds}`);
}
