import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import { generateList, parseDate, formatName } from "../../../utils";

// types
import { PropertyName } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Tag,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  Padding,
  ReportLink,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

interface Owners {
  owners?: PropertyName[];
  dateFirstSeenAsOwner?: string;
  dateLastSeenAsOwner?: string;
}

const OwnershipHistory: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";

  const [owners, setOwners] = useState<Owners[]>([]);

  useEffect(() => {
    let owners: Owners[] = [];

    if (pulledReport?.propertyOwnershipHistory) {
      pulledReport.propertyOwnershipHistory.forEach(transac => {
        if (transac.propertyOwnerships) {
          transac.propertyOwnerships.forEach(ownership => {
            owners.push({
              owners: ownership.owners,
              dateFirstSeenAsOwner: ownership.dateFirstSeenAsOwner,
              dateLastSeenAsOwner: ownership.dateLastSeenAsOwner,
            });
          });
        }
      });
    }

    setOwners(owners);
  }, [pulledReport]);
  const pullPeopleReport = usePeopleReportDispatch();
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Ownership History ${
          loading
            ? ""
            : `(${pulledReport && pulledReport.propertyReportSummary?.ownershipHistoryCount ? pulledReport.propertyReportSummary.ownershipHistoryCount : 0})`
        }`}
        containerId={containerId}
        newInfo={reportDifferences?.ownershipHistories}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={3}
          />
        ) : pulledReport && owners && owners[0] ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {owners.map((owner, index) => (
              <ContainerLineItem
                lineItemHeader={
                  <>
                    {owner.owners
                      ? owner.owners.map((individualOwner, ownerIndex) => {
                          const ownerName = individualOwner.firstName
                            ? formatName(individualOwner)
                            : individualOwner.businessName
                              ? individualOwner.businessName
                              : null;
                          return individualOwner.reportToken ? (
                            <div
                              key={`${individualOwner["firstName"]}-${ownerIndex}`}
                            >
                              <ReportLink<PullPeopleReportParams>
                                text={formatName(individualOwner)}
                                inputValue={individualOwner.reportToken}
                                handleClick={pullPeopleReport}
                                reportTag={
                                  index === 0 ? (
                                    <Tag
                                      type="primary"
                                      tagText="current owner"
                                    />
                                  ) : null
                                }
                              />
                            </div>
                          ) : (
                            <div
                              key={`${individualOwner["firstName"]}-${ownerIndex}`}
                            >
                              <Text>
                                {ownerName}{" "}
                                {index === 0 ? (
                                  <Tag type="primary" tagText="current owner" />
                                ) : null}
                              </Text>
                            </div>
                          );
                        })
                      : ""}
                  </>
                }
                lineItemSubHeader={generateList(
                  [
                    {
                      value: owner.dateFirstSeenAsOwner
                        ? parseDate(owner.dateFirstSeenAsOwner, "year")
                        : null,
                      label: "",
                    },
                    {
                      value: owner.dateLastSeenAsOwner
                        ? owner.dateLastSeenAsOwner
                        : null,
                      label: "",
                    },
                  ],
                  " - ",
                )}
                key={`${owner["dateFirstSeenAsOwner"]}-${index}`}
                isOpen={printReady ? true : false}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No results found for Ownership History
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default OwnershipHistory;
