import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";
import {
  getReportDifferenceAsync,
  pullPeopleReportAsync,
  searchPeopleAsync,
  selectPeopleSearchStatus,
  selectReportStatus,
} from "../features/reports/reportsSlice";
import { useLocation } from "react-router-dom";
import { PeopleReport } from "../features/reports/reportTypes";
import { selectMonitoring } from "../features/monitoring/monitoringSlice";
import {
  setPeopleSearch,
  toggleReinitialize,
} from "../features/multisearch/multisearchSlice";

export type PullPeopleReportParams = string;

type UsePeopleReportHook = () => (token: PullPeopleReportParams) => void;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/* Pull People Report */
const usePeopleReportDispatch: UsePeopleReportHook = () => {
  const dispatch = useAppDispatch();
  const reportStatus = useAppSelector(selectReportStatus);
  const monitoredReports = useAppSelector(selectMonitoring);
  const query = useQuery();

  const version = query.get("version");

  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const pullPeopleReport = (token: PullPeopleReportParams) => {
    if (reportStatus === "loading") return;
    if (location.pathname !== "/people-report") {
      pageNavigate("/people-report");
    } else {
      window.scrollTo(0, 0);
    }
    dispatch(pullPeopleReportAsync({ reportToken: token }))
      .unwrap()
      .then(result => {
        if (result.success) {
          const report = result.report.result as PeopleReport;
          const monitoredReport = monitoredReports.find(
            report => report.reportToken === token,
          );

          if (version) {
            dispatch(
              getReportDifferenceAsync({
                reportToken: token,
                versionA: report.reportSummary.version!,
                versionB: version,
              }),
            );
          }

          if (
            !version &&
            monitoredReport &&
            monitoredReport.versionZ !== report.reportSummary.version &&
            monitoredReport.versionZ &&
            report.reportSummary.version
          ) {
            dispatch(
              getReportDifferenceAsync({
                reportToken: token,
                versionA: report.reportSummary.version!,
                versionB: monitoredReport.versionZ!,
              }),
            );
          }
        }
      })
      .catch(error => {
        console.log(error.message);
        pageNavigate("/");
      });
  };

  return pullPeopleReport;
};

/* People Search */
export type SearchPeopleParams = {
  firstName: string;
  lastName: string;
  state: string;
  city: string;
  minAge: number | string;
  maxAge: number | string;
};

type UsePeopleSearchHook = () => (params: SearchPeopleParams) => void;

const usePeopleSearchDispatch: UsePeopleSearchHook = () => {
  const dispatch = useAppDispatch();
  const searchStatus = useAppSelector(selectPeopleSearchStatus);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const searchPeople = async (params: SearchPeopleParams) => {
    if (searchStatus === "loading") return;

    dispatch(searchPeopleAsync(params));
    dispatch(setPeopleSearch(params));
    dispatch(toggleReinitialize());

    if (location.pathname !== "/people-search") {
      pageNavigate("/people-search");
    } else {
      window.scrollTo(0, 0);
    }
  };

  return searchPeople;
};

export { usePeopleReportDispatch, usePeopleSearchDispatch };
