import React, { useEffect } from "react";
import {
  Button,
  Container,
  Input,
  Layout,
  Padding,
  PropertyPlaceProps,
  Text,
} from "@kidslivesafe/sc-react";
import {
  useNeighborhoodReportDispatch,
  useNeighborhoodSearchDispatch,
} from "../../../hooks/neighborhoodReportHook";
import { useAppSelector } from "../../../hooks/hooks";
import { selectNeighborhoodNameSearchParams } from "../../../features/multisearch/multisearchSlice";

import "./neighborhood-watch-search.css";

interface NeighborhoodWatchSearchProps {
  showNeighborhoodSearchName?: boolean;
  showNeighborhoodSearchLocation?: boolean;
  secondary?: boolean;
  prefix?: string;
  prefill?: boolean;
}

const NeighborhoodWatchSearch: React.FC<NeighborhoodWatchSearchProps> = ({
  showNeighborhoodSearchLocation,
  showNeighborhoodSearchName,
  secondary = false,
  prefix = "",
  prefill = false,
}) => {
  // local state
  const [neighborhoodSearchName, setNeighborhoodSearchName] =
    React.useState<string>("");
  const [neighborhoodSearchError, setNeighborhoodSearchError] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const [neighborhoodSearchLocation, setNeighborhoodSearchLocation] =
    React.useState<PropertyPlaceProps>({});
  const [neighborhoodSearchLocationError, setNeighborhoodSearchLocationError] =
    React.useState<boolean>(false);

  const [searchType, setSearchType] = React.useState<string>("location"); // State to handle form switching

  const neighborhoodSearchParams = useAppSelector(
    selectNeighborhoodNameSearchParams,
  );

  const handleSearchChange = (neighborhoodSearchName: string) => {
    setNeighborhoodSearchName(neighborhoodSearchName);
    setNeighborhoodSearchError(false);
  };

  const handleLocationChange = (
    neighborhoodSearchLocation: PropertyPlaceProps,
  ) => {
    setNeighborhoodSearchLocation(neighborhoodSearchLocation);
    setNeighborhoodSearchLocationError(false);
  };

  const pullNeighborhoodSearch = useNeighborhoodSearchDispatch();
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();

  useEffect(() => {
    if (neighborhoodSearchParams && prefill) {
      setNeighborhoodSearchName(
        `${neighborhoodSearchParams.firstName} ${neighborhoodSearchParams.lastName}`,
      );
    }
  }, [neighborhoodSearchParams, prefill]);

  const handleNeighborhoodSearch = () => {
    if (!neighborhoodSearchName) {
      setNeighborhoodSearchError(true);
      setErrorMessage("Please enter a name to search.");
      return;
    }

    const nameArray = neighborhoodSearchName.split(" ");
    if (nameArray.length < 2) {
      setNeighborhoodSearchError(true);
      setErrorMessage("Please enter a first and last name to search.");
      return;
    }

    let firstName = nameArray.shift() || "";
    let lastName = nameArray.join(" ");

    if (firstName.length < 2 || lastName.length < 2) {
      setNeighborhoodSearchError(true);
      setErrorMessage("Please enter a first and last name to search.");
      return;
    }

    setNeighborhoodSearchError(false);
    pullNeighborhoodSearch({
      firstName,
      lastName,
    });
  };

  const handleNeighborhoodReport = () => {
    if (!neighborhoodSearchLocation.formatted_address) {
      setNeighborhoodSearchLocationError(true);
      setErrorMessage("Please enter a location to search.");
      return;
    }

    pullNeighborhoodReport({
      addressSearch: {
        address: [
          neighborhoodSearchLocation.streetNumber,
          neighborhoodSearchLocation.streetName,
        ].join(" "),
        city: neighborhoodSearchLocation.city,
        state: neighborhoodSearchLocation.state,
        zipCode: neighborhoodSearchLocation.zip,
        addressLine2: neighborhoodSearchLocation.subPremise,
      },
      searchMethod: "address",
    });
  };

  return (
    <Container>
      <Padding space="md" top="none">
        {showNeighborhoodSearchLocation && showNeighborhoodSearchName ? (
          <Padding bottom="sm">
            <Layout
              type="flex"
              flexProps={{
                gap: "md",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text size="xs">Search By:</Text>
              <Layout
                type="flex"
                flexProps={{ gap: "xxs", alignItems: "center" }}
              >
                <input
                  type="radio"
                  id="searchByLocation"
                  name="searchType"
                  value="location"
                  checked={searchType === "location"}
                  onChange={() => setSearchType("location")}
                  className="neighborhood-inputs__search-type__radio-button"
                />
                <label
                  htmlFor="searchByLocation"
                  className="neighborhood-inputs__search-type__radio-button-label"
                >
                  <Text size="sm" color="primary" fontWeight="semibold">
                    Address
                  </Text>
                </label>
              </Layout>
              <Layout
                type="flex"
                flexProps={{ gap: "xxs", alignItems: "center" }}
                passThroughClassess="neighborhood-inputs__search-type__radio-button-container"
              >
                <input
                  type="radio"
                  id="searchByName"
                  name="searchType"
                  value="name"
                  checked={searchType === "name"}
                  onChange={() => setSearchType("name")}
                  className="neighborhood-inputs__search-type__radio-button"
                />
                <label
                  htmlFor="searchByName"
                  className="neighborhood-inputs__search-type__radio-button-label"
                >
                  <Text size="sm" color="primary" fontWeight="semibold">
                    Name
                  </Text>
                </label>
              </Layout>
            </Layout>
          </Padding>
        ) : null}
        <Layout
          type="flex"
          flexProps={{ gap: "md", direction: "column" }}
          breakpointStyles={{
            desktop: {
              type: "flex",
              flexProps: {
                direction: "row",
                justifyContent: "space-between",
                gap: "xs",
              },
            },
          }}
          passThroughClassess="neighborhood-inputs__search-container"
        >
          {searchType === "location" && showNeighborhoodSearchLocation ? (
            <>
              <Input
                id={`${prefix}_neighborhood-search-location`}
                value={neighborhoodSearchLocation}
                placeholder="Address"
                handleChange={handleLocationChange}
                type="property"
                error={neighborhoodSearchLocationError}
                errorText={errorMessage}
              />
              <Button
                buttonText="New Search"
                handleClick={handleNeighborhoodReport}
              />
            </>
          ) : null}

          {searchType === "name" && showNeighborhoodSearchName ? (
            <>
              <Input
                id={`${prefix}_neighborhood-search-name`}
                value={neighborhoodSearchName}
                placeholder="Name"
                handleChange={handleSearchChange}
                type="text"
                error={neighborhoodSearchError}
                errorText={errorMessage}
              />
              <Button
                buttonText={secondary ? "Refine Search" : "New Search"}
                handleClick={handleNeighborhoodSearch}
              />
            </>
          ) : null}
        </Layout>
      </Padding>
    </Container>
  );
};

export default NeighborhoodWatchSearch;
