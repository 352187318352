export function generateList(optionsArr: any[], dilineator: string = " | ") {
  let returnArr = [];
  for (let i = 0; i < optionsArr.length; i++) {
    if (optionsArr[i].value) {
      if (optionsArr[i].order === "reverse") {
        returnArr.push(`${optionsArr[i].value}${optionsArr[i].label}`);
      } else {
        returnArr.push(`${optionsArr[i].label}${optionsArr[i].value}`);
      }
    }
  }

  return returnArr.join(dilineator);
}

export function generateListNotFound(
  optionsArr: any[],
  dilineator: string = " | ",
) {
  let returnArr = [];
  let returnStr = "";
  const filteredArr = optionsArr.filter(obj => obj.value !== null);

  for (let i = 0; i < filteredArr.length - 1; i++) {
    if (filteredArr[i].value && filteredArr[i].value != null) {
      returnArr.push(`${filteredArr[i].label}${filteredArr[i].value}`);
    }
  }

  if (filteredArr.length > 1) {
    returnStr =
      returnArr.join(dilineator) +
      " or " +
      `${filteredArr[filteredArr.length - 1].value}`;
  } else if (filteredArr.length === 1) {
    returnStr =
      returnArr.join(dilineator) +
      `${filteredArr[filteredArr.length - 1].value}`;
  } else {
    return null;
  }

  return returnStr;
}
