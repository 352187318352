import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";
import {
  pullNeighborhoodReportByLocationAsync,
  pullNeighborhoodSearchAsync,
  selectNeighborhoodReportStatus,
  selectNeighborhoodSearchStatus,
} from "../features/reports/reportsSlice";
import { useLocation } from "react-router-dom";

import {
  PullNeighborhoodReportParams,
  PullNeighborhoodSearchParams,
} from "../features/reports/reportTypes";
import {
  setNeighborhoodNameSearch,
  setNeighborhoodReportSearch,
} from "../features/multisearch/multisearchSlice";

type UseNeighborhoodReportHook = () => ({
  addressSearch,
  searchMethod,
}: PullNeighborhoodReportParams) => void;

/* Pull Neighborhood Report */
const useNeighborhoodReportDispatch: UseNeighborhoodReportHook = () => {
  const dispatch = useAppDispatch();
  const reportStatus = useAppSelector(selectNeighborhoodReportStatus);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const pullNeighborhoodReport = ({
    addressSearch,
    searchMethod,
  }: PullNeighborhoodReportParams) => {
    if (reportStatus === "loading") return;
    dispatch(
      pullNeighborhoodReportByLocationAsync({ addressSearch, searchMethod }),
    );
    dispatch(setNeighborhoodReportSearch({ addressSearch, searchMethod }));
    if (location.pathname !== "/neighborhood-report") {
      pageNavigate("/neighborhood-report");
    } else {
      window.scroll(0, 0);
    }
  };
  return pullNeighborhoodReport;
};

type UseNeighborhoodSearchHook = () => ({
  firstName,
  lastName,
}: PullNeighborhoodSearchParams) => void;

/* Pull Neighborhood Search */
const useNeighborhoodSearchDispatch: UseNeighborhoodSearchHook = () => {
  const dispatch = useAppDispatch();
  const reportStatus = useAppSelector(selectNeighborhoodSearchStatus);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const pullNeighborhoodReport = ({
    firstName,
    lastName,
  }: PullNeighborhoodSearchParams) => {
    if (reportStatus === "loading") return;
    dispatch(pullNeighborhoodSearchAsync({ firstName, lastName }));
    dispatch(setNeighborhoodNameSearch({ firstName, lastName }));
    if (location.pathname !== "/neighborhood-search") {
      pageNavigate("/neighborhood-search");
    } else {
      window.scroll(0, 0);
    }
  };
  return pullNeighborhoodReport;
};

export { useNeighborhoodReportDispatch, useNeighborhoodSearchDispatch };
