import {
  SocialReportHistory,
  SocialReportSummary,
} from "../features/reports/reportTypes";

export const renderSocialReportType = (
  reportSummary: SocialReportSummary | SocialReportHistory,
) => {
  if (reportSummary.twitter) return "twitter";
  if (reportSummary.email) return "email";
  if (reportSummary.phone) return "phone";
  return undefined;
};
