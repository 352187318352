import {
  Container,
  Icon,
  IconList,
  Layout,
  Padding,
  Spotlight,
  Tab,
  Text,
} from "@kidslivesafe/sc-react";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  setTab,
  toggleOpen,
} from "../../features/multisearch/multisearchSlice";
import { selectAccountType } from "../../features/authentication/authenticationSlice";

const SpacerMultiSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const accountType = useAppSelector(selectAccountType);

  const handleSpotlightClick = (tab: Tab) => {
    dispatch(setTab(tab));
    dispatch(toggleOpen());
  };

  const handleNeighborhoodSpotlightClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        mobile: { space: "none" },
      }}
    >
      <Container containerId="dashboard__spacer-multi-search__container">
        <Padding space="md">
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            <Text size="sm" textAlign="left">
              Explore billions of data points across millions of records with
              these premium account search options.
            </Text>
            <Layout
              type="flex"
              flexProps={{ direction: "column", gap: "md" }}
              breakpointStyles={{
                desktop: {
                  type: "grid",
                  gridProps: { templateColumns: "2-column", gap: "md" },
                },
              }}
            >
              <Spotlight
                onClick={() => {
                  handleSpotlightClick("people");
                }}
              >
                <Padding
                  space="md"
                  className="page-dashboard_multisearch-spacer_spotlight-content"
                >
                  <Layout
                    type="flex"
                    flexProps={{ gap: "lg", alignItems: "center" }}
                    passThroughClassess="page-dashboard_multisearch-spacer_spotlight-content_layout"
                  >
                    <div className="page-dashboard__membership-information__icon">
                      <Icon name={IconList.IconPeople} size={32} />
                    </div>
                    <Layout type="flex" flexProps={{ direction: "column" }}>
                      <Text tag="h3" color="primary">
                        People Search
                      </Text>
                      <Text size="xs" tag="i">
                        Easily reconnect with individuals and find answers using
                        our robust database.
                      </Text>
                    </Layout>
                  </Layout>
                </Padding>
              </Spotlight>
              <Spotlight
                onClick={() => {
                  handleSpotlightClick("property");
                }}
              >
                <Padding
                  space="md"
                  className="page-dashboard_multisearch-spacer_spotlight-content"
                >
                  <Layout
                    type="flex"
                    flexProps={{ gap: "lg", alignItems: "center" }}
                    passThroughClassess="page-dashboard_multisearch-spacer_spotlight-content_layout"
                  >
                    <div className="page-dashboard__membership-information__icon">
                      <Icon name={IconList.IconProperty} size={32} />
                    </div>
                    <Layout type="flex" flexProps={{ direction: "column" }}>
                      <Text tag="h3" color="primary">
                        Property Search
                      </Text>
                      <Text size="xs" tag="i">
                        Discover property owners, deeds, values, and
                        transactions effortlessly.
                      </Text>
                    </Layout>
                  </Layout>
                </Padding>
              </Spotlight>
              <Spotlight
                onClick={() => {
                  handleSpotlightClick("phone");
                }}
              >
                <Padding
                  space="md"
                  className="page-dashboard_multisearch-spacer_spotlight-content"
                >
                  <Layout
                    type="flex"
                    flexProps={{ gap: "lg", alignItems: "center" }}
                    passThroughClassess="page-dashboard_multisearch-spacer_spotlight-content_layout"
                  >
                    <div className="page-dashboard__membership-information__icon">
                      <Icon name={IconList.IconPhone} size={32} />
                    </div>
                    <Layout type="flex" flexProps={{ direction: "column" }}>
                      <Text tag="h3" color="primary">
                        Phone Search
                      </Text>
                      <Text size="xs" tag="i">
                        Identify the source of unknown or unwanted calls and
                        texts.
                      </Text>
                    </Layout>
                  </Layout>
                </Padding>
              </Spotlight>

              {accountType === "neighborhood" ? (
                <Spotlight
                  onClick={() => {
                    handleNeighborhoodSpotlightClick();
                  }}
                >
                  <Padding
                    space="md"
                    className="page-dashboard_multisearch-spacer_spotlight-content"
                  >
                    <Layout
                      type="flex"
                      flexProps={{ gap: "lg", alignItems: "center" }}
                      passThroughClassess="page-dashboard_multisearch-spacer_spotlight-content_layout"
                    >
                      <div className="page-dashboard__membership-information__icon">
                        <Icon name={IconList.IconNeighborhoodWatch} size={32} />
                      </div>
                      <Layout type="flex" flexProps={{ direction: "column" }}>
                        <Text tag="h3" color="primary">
                          Neighborhood Search
                        </Text>
                        <Text size="xs" tag="i">
                          Search for and monitor registered offenders in your
                          area.
                        </Text>
                      </Layout>
                    </Layout>
                  </Padding>
                </Spotlight>
              ) : (
                <Spotlight
                  onClick={() => {
                    handleSpotlightClick("social");
                  }}
                >
                  <Padding
                    space="md"
                    className="page-dashboard_multisearch-spacer_spotlight-content"
                  >
                    <Layout
                      type="flex"
                      flexProps={{ gap: "lg", alignItems: "center" }}
                      passThroughClassess="page-dashboard_multisearch-spacer_spotlight-content_layout"
                    >
                      <div className="page-dashboard__membership-information__icon">
                        <Icon name={IconList.IconSocial} size={32} />
                      </div>
                      <Layout type="flex" flexProps={{ direction: "column" }}>
                        <Text tag="h3" color="primary">
                          Social Search
                        </Text>
                        <Text size="xs" tag="i">
                          Get to know people beyond their public record. We
                          scour social media to find publicly available
                          profiles.
                        </Text>
                      </Layout>
                    </Layout>
                  </Padding>
                </Spotlight>
              )}
            </Layout>
          </Layout>
        </Padding>
      </Container>
    </Padding>
  );
};

export default SpacerMultiSearch;
