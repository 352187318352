import React from "react";

import {
  Layout,
  Padding,
  Text,
  Sheet,
  Container,
} from "@kidslivesafe/sc-react";

import "./neighborhood-name-report.css";
import "./print.css";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectNeighborhoodSearch,
  selectNeighborhoodSearchStatus,
} from "../../../features/reports/reportsSlice";
import { selectNeighborhoodNameSearchParams } from "../../../features/multisearch/multisearchSlice";
import MapOfNames from "./MapOfNames";
import ListOfOffenderNames from "./ListOfOffenderNames";
import NeighborhoodWatchSearch from "../../../components/UIComponents/NeighborhoodWatchSearch/NeighborhoodWatchSearch";

import "./neighborhood-search.css";

const NeighborhoodSearch: React.FC = () => {
  // local state
  const neighborhoodSearch = useAppSelector(selectNeighborhoodNameSearchParams);

  const loading = useAppSelector(selectNeighborhoodSearchStatus) === "loading";
  const pulledReport = useAppSelector(selectNeighborhoodSearch);

  return (
    <>
      {(pulledReport && pulledReport.offendersCount > 0) || loading ? (
        <>
          <Container customClassName="page-neighborhood-search_input-container">
            <Sheet>
              <Padding breakpointStyles={{ desktop: { bottom: "lg" } }}>
                <NeighborhoodWatchSearch
                  showNeighborhoodSearchName
                  prefix="search"
                  secondary
                  prefill
                />
              </Padding>
            </Sheet>
          </Container>
          <Sheet>
            <Padding
              space="md"
              top="none"
              breakpointStyles={{
                mobile: {
                  space: "none",
                  bottom: "md",
                },
              }}
            >
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "xs" }}
                shrinkChildren
              >
                <>
                  <Padding
                    space="none"
                    breakpointStyles={{
                      mobile: {
                        left: "md",
                        right: "md",
                        top: "none",
                      },
                      tablet: {
                        top: "none",
                      },
                    }}
                  >
                    <Layout
                      type="flex"
                      flexProps={{ direction: "column", gap: "md" }}
                    >
                      <MapOfNames />
                      <ListOfOffenderNames />
                    </Layout>
                  </Padding>
                </>
              </Layout>
            </Padding>
          </Sheet>
        </>
      ) : (
        <>
          <Sheet>
            <Container>
              <NeighborhoodWatchSearch
                showNeighborhoodSearchName
                prefix="no-results"
                secondary
              />
            </Container>
          </Sheet>

          <Sheet>
            <Padding
              top="xl"
              breakpointStyles={{
                mobile: {
                  top: "xl",
                  left: "md",
                  right: "md",
                },
              }}
            >
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "lg" }}
              >
                <Text tag="h1" fontWeight="bold" textAlign="center">
                  0 matches found
                </Text>
                <Text size="lg" textAlign="center">
                  Sorry, we were unable to find any results for{" "}
                  <Text tag="span" size="lg">
                    {neighborhoodSearch?.firstName}{" "}
                    {neighborhoodSearch?.lastName}
                  </Text>
                  . in our public records database. Try again using these tips:
                </Text>
                <ol className="page-people-search__ordered-list">
                  <Text tag="li">Check spelling. Any mistakes?</Text>
                  <Text tag="li">
                    Try using a full legal name like "William" instead of
                    "Bill".
                  </Text>
                  <Text tag="li">
                    If searching for a married individual who has changed their
                    last name then try using their previous last name.
                  </Text>
                  <Text tag="li">
                    Try a different state or broaden the search to include all
                    states.
                  </Text>
                  <Text tag="li">
                    In some cases "0 Matches Found" may be due to privacy
                    preferences or regulations.
                  </Text>
                </ol>
              </Layout>
            </Padding>
          </Sheet>
        </>
      )}
    </>
  );
};

export default NeighborhoodSearch;
