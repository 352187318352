import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  CollapsableItemList,
  ContainerLineItem,
  Tag,
  Layout,
} from "@kidslivesafe/sc-react";

import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

import { generateList, parseDate, formatAddress } from "../../../utils";

import {
  selectPhoneReport,
  selectPhoneReportStatus,
} from "../../../features/reports/reportsSlice";

const PossibleBusiness: React.FC<{
  sectionId: string;
  printReady?: boolean;
}> = ({ sectionId, printReady }) => {
  //store state
  const pulledReport = useAppSelector(selectPhoneReport);
  const phoneReportStatus = useAppSelector(selectPhoneReportStatus);

  const loading = phoneReportStatus === "loading";

  const isDateWithinLastYear = (date: any) => {
    const [month, day, year] = date.split("/");
    const inputDate = new Date(year, month - 1, day);

    if (isNaN(inputDate.getTime())) {
      return false;
    }

    const currentDate = new Date();

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return inputDate >= oneYearAgo && inputDate <= currentDate;
  };
  return (
    <>
      {(pulledReport && pulledReport.results.businesses.length !== 0) ||
      loading ? (
        <Container
          containerTitle={
            loading
              ? "Possible Business Results"
              : `Possible Business Results (${
                  pulledReport && pulledReport.results.businesses.length > 0
                    ? pulledReport.results.businesses.length
                    : 0
                })`
          }
          tooltipContent="Phone numbers can be linked to businesses or people. Business records are the main focus, with additional information about individuals connected to the business or phone number."
          containerId={sectionId}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
            />
          ) : pulledReport && pulledReport.results.businesses.length > 0 ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.results.businesses.map((business, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    <>
                      <Layout type="block">
                        <div>
                          {business.name}{" "}
                          {business.dateLastSeen &&
                          isDateWithinLastYear(business.dateLastSeen) ? (
                            <Tag type="primary" tagText="recently seen" />
                          ) : null}
                        </div>
                      </Layout>
                    </>
                  }
                  lineItemSubHeader={generateList([
                    {
                      value: business.dateLastSeen
                        ? parseDate(business.dateLastSeen, "year")
                        : null,
                      label: "",
                    },
                    {
                      value: business.address
                        ? formatAddress(business.address, "with-postalCode")
                        : null,
                      label: "",
                    },
                  ])}
                  key={index}
                  isOpen={printReady ? true : false}
                />
              ))}
            </CollapsableItemList>
          ) : null}
        </Container>
      ) : null}
    </>
  );
};

export default PossibleBusiness;
