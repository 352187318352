import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  AnchorLink,
} from "@kidslivesafe/sc-react";
import { useAppSelector, useHandlePageNavigate } from "../../../../hooks/hooks";
import { selectBrandInfo } from "../../../../features/brand/brandSlice";

import "./cancel-member.css";
import {
  brandPossessive,
  useCurrentBreakpoint,
} from "@kidslivesafe/sc-foundation";
import { formatPhoneNumber } from "../../../../utils";

const CancelMember: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();

  const brandInfo = useAppSelector(selectBrandInfo);
  const currentBreakpoint = useCurrentBreakpoint();

  return (
    <>
      <ReportTitle reportTitle="How to Cancel" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h2">
                How to Cancel Your {brandInfo.name} Membership
              </Text>
              <Layout
                type="flex"
                flexProps={{ gap: "md", direction: "column" }}
              >
                <Text fontWeight="bold">
                  In order for you to cancel your {brandInfo.name} account you
                  will need to call our member support department at{" "}
                  <AnchorLink
                    inline
                    color="base"
                    fontWeight="bold"
                    phoneNumber
                    hoverUnderline
                    href={brandInfo.phoneNumber}
                  >
                    {formatPhoneNumber(brandInfo.phoneNumber)}
                  </AnchorLink>
                  .
                </Text>
                {brandInfo.acronym === "pf" ? (
                  <Text>
                    {brandInfo.name} was founded with the mission of making it
                    safe and easy to search property records. All our members
                    receive full access to the platform with no restrictions on
                    details or extra charges for additional insights; everything
                    is included upfront, giving you complete access at all
                    times. We are always striving to improve our offering with
                    regular feature updates and new data sources. We are sorry
                    to see you cancel your account and miss out on our best in
                    class services. 
                  </Text>
                ) : (
                  <Text>
                    {brandInfo.name} was founded with the mission of making it
                    safe and easy for people to find and learn the truth about
                    each other. We hate to see you cancel your account and miss
                    out on our best in class services.
                  </Text>
                )}
              </Layout>
            </Layout>
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h3">Accurate and up-to-date information</Text>
              {brandInfo.acronym === "pf" ? (
                <Text>
                  {brandInfo.name} provides you with easy and secure access to
                  detailed property and owner information. We partner with
                  trusted data providers to ensure accurate and up-to-date
                  reports. By sourcing data from both public records and
                  third-party providers, we offer comprehensive information that
                  users can use to make informed real estate decisions, assess
                  properties of interest, and uncover ownership details—all
                  while ensuring their searches remain private.
                </Text>
              ) : (
                <Text>
                  {brandInfo.name} provides you with accurate and up-to-date
                  information that you can rely on. Our data not only includes a
                  comprehensive criminal check but also current address and
                  phone number, address history, professional licenses and much
                  more.
                </Text>
              )}
            </Layout>
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              {brandInfo.acronym === "pf" ? (
                <>
                  <Text tag="h3">Fast and Accurate Searches</Text>
                  <Text>
                    Our service offers quick access to detailed information on
                    residential, commercial, and industrial properties. Users
                    can explore property history, ownership records, sales
                    transactions, mortgages, and assessments with ease.
                  </Text>
                </>
              ) : (
                <>
                  <Text tag="h3">Comprehensive Searches and Reports</Text>
                  <Text>
                    Our service provides members with quick access to
                    information on individuals, properties and phone numbers.
                  </Text>
                </>
              )}
            </Layout>
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h3">Proactive Monitoring and Email Alerts</Text>
              {brandInfo.acronym === "pf" ? (
                <Text>
                  Set up monitoring on specific properties and receive email
                  alerts whenever public records change. We continuously track
                  updates, ensuring you stay informed without the need for
                  manual searches.
                </Text>
              ) : (
                <Text>
                  We provide you automatic updates on up to 4 individuals if
                  public data on them changes. You will receive an automatic
                  email alerting you that something in their profile has
                  changed. This way, you don’t need to log into the website to
                  run reports on them every month.
                </Text>
              )}
            </Layout>
            {brandInfo.acronym === "pf" ? (
              <Layout
                type="flex"
                flexProps={{ gap: "sm", direction: "column" }}
              >
                <Text tag="h3">World-Class Member Support</Text>
                <Text>
                  Property Focus is proud to offer exceptional customer service.
                  Our knowledgeable and friendly support team is available 363
                  days a year to assist with any inquiries, ensuring 100% member
                  satisfaction.
                </Text>
                <Text>
                  If you would still like to cancel your account please call us
                  at{" "}
                  <AnchorLink
                    inline
                    color="base"
                    fontWeight="bold"
                    phoneNumber
                    hoverUnderline
                    href={brandInfo.phoneNumber}
                  >
                    {formatPhoneNumber(brandInfo.phoneNumber)}
                  </AnchorLink>
                  . Or,{" "}
                  <AnchorLink
                    fontWeight="bold"
                    role="button"
                    handleClick={() => {
                      pageNavigate("/cancel-online");
                    }}
                    hoverUnderline
                    inline
                  >
                    click here
                  </AnchorLink>
                  .
                </Text>
              </Layout>
            ) : (
              <>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text tag="h3">Property Search & Reports</Text>
                  <Text>
                    Our members have access to over 160 million property
                    records. Lookup any property's lot and building details, or
                    research its history such as sales transactions and
                    valuations. Property reports connect to People reports, with
                    deeper insights into the property’s owners and residents.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text tag="h3">Reverse Phone Search & Reports</Text>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "md", direction: "column" }}
                  >
                    <Text>
                      Use {brandPossessive(brandInfo.name)} Reverse Phone Search
                      to uncover an unknown phone number’s registered location
                      and name. Phone reports connect to People reports, telling
                      you more about the person or business behind unwanted
                      calls and texts.
                    </Text>
                    <Text>
                      If you would still like to cancel your account please call
                      us at{" "}
                      <AnchorLink
                        inline
                        color="base"
                        fontWeight="bold"
                        phoneNumber
                        hoverUnderline
                        href={brandInfo.phoneNumber}
                      >
                        {formatPhoneNumber(brandInfo.phoneNumber)}
                      </AnchorLink>
                      . Or,{" "}
                      <AnchorLink
                        fontWeight="bold"
                        role="button"
                        handleClick={() => {
                          pageNavigate("/cancel-online");
                        }}
                        hoverUnderline
                        inline
                      >
                        click here
                      </AnchorLink>
                      .
                    </Text>
                  </Layout>
                </Layout>
              </>
            )}
            <div className="page-cancel-member_divider" />
            <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
              <Text tag="h3">Unsubscribe Your Email Address</Text>
              <Text>
                Please use the link below to unsubscribe from {brandInfo.name}{" "}
                email marketing. If you are an active {brandInfo.name} member
                you will continue to receive email alerts related to your public
                records reports and account.
              </Text>
              <AnchorLink
                fontWeight="bold"
                href={brandInfo.unsubscribeUrl}
                hoverUnderline
              >
                Click here to unsubscribe
              </AnchorLink>
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default CancelMember;
