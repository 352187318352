import {
  HistoryValuation,
  PropertyReport,
} from "../features/reports/reportTypes";

export const setValuationSummary = (
  report: PropertyReport,
):
  | {
      currentValue?: HistoryValuation;
      historicalValue?: HistoryValuation;
    }
  | undefined => {
  if (report.propertyValuation != null) {
    // Parse the latest valuation date
    const currentDate = report.propertyValuation.latest?.valuationDate
      ? new Date(report.propertyValuation.latest?.valuationDate)
      : null;

    // Create a list of historical values with their parsed dates
    const historicalValues = report.propertyValuation.historical
      ?.map(h => {
        const parsedDate = h.valuationDate ? new Date(h.valuationDate) : null;
        return {
          marketValue: h.valuationAmount?.marketValue,
          date: parsedDate,
        };
      })
      .filter(h => h.date !== null) // Ensure we have valid dates
      .sort((a, b) => (a.date?.getTime() || 0) - (b.date?.getTime() || 0)); // Sort by date

    if (!historicalValues || !currentDate) {
      return undefined;
    }

    // Find the historical value within 5 years of the current date
    const fiveYearsAgo = new Date(currentDate);
    fiveYearsAgo.setFullYear(currentDate.getFullYear() - 5);

    let tempHistorical = historicalValues.find(
      h => h.date && h.date >= fiveYearsAgo,
    );

    // If not found, get the last historical value before 5 years ago
    if (!tempHistorical) {
      tempHistorical = historicalValues
        .reverse()
        .find(h => h.date && h.date < fiveYearsAgo);
    }

    const historicalValue = tempHistorical
      ? {
          marketValue: tempHistorical.marketValue,
          date: tempHistorical.date?.toLocaleDateString("en-US"),
        }
      : undefined;

    const valuationSummary = {
      currentValue: report.propertyValuation?.latest
        ? {
            marketValue:
              report.propertyValuation.latest.valuationAmount?.marketValue,
            date: report.propertyValuation.latest.valuationDate,
          }
        : undefined,
      historicalValue,
    };

    return valuationSummary;
  }

  return undefined;
};
