import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  generateList,
  renderArrayOfObjectsPrep,
  formatNumberCommas,
} from "../../../utils";

// types
import { PropertyAssessment } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Layout,
  Padding,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  Table,
} from "@kidslivesafe/sc-react";

import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

const ValueTaxes: React.FC<{ containerId: string; printReady?: boolean }> = ({
  containerId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);

  const loading = propertyReportStatus === "loading";

  /* generate uccFiling Object */
  const valueObjects = renderArrayOfObjectsPrep(
    pulledReport?.propertyAssessments || [],
    ["totalBaths", "totalRooms"],
    [
      {
        key: ["bedrooms"],
        keyFormat: () => {
          return "Total Bedrooms/Bathrooms";
        },
        valueFormat: (value, currentObject: PropertyAssessment) => {
          const bedBathInfo =
            currentObject.bedrooms && currentObject.totalBaths
              ? `${currentObject.bedrooms}bd/${currentObject.totalBaths}ba`
              : null;

          return (
            <>
              <Text size="sm" fontWeight="bold">
                {bedBathInfo}
              </Text>
            </>
          );
        },
      },
      {
        key: ["landSquareFootage"],
        valueFormat: (value, currentObject: PropertyAssessment) => {
          const sqFootage =
            currentObject.acres && currentObject.landSquareFootage
              ? `${currentObject.acres} acres (${currentObject.landSquareFootage} sq ft)`
              : null;
          return (
            <>
              <Text size="sm" fontWeight="bold">
                {sqFootage}
              </Text>
            </>
          );
        },
      },
      {
        key: ["stories"],
        valueFormat: (value, currentObject: PropertyAssessment) => {
          return (
            <>
              <Text size="sm" fontWeight="bold">
                {parseInt(currentObject.stories!)}
              </Text>
            </>
          );
        },
      },
      {
        key: ["taxAmount"],
        valueFormat: (value, currentObject: PropertyAssessment) => {
          return (
            <>
              <Text size="sm" fontWeight="bold">
                ${currentObject.taxAmount}
              </Text>
            </>
          );
        },
      },
      {
        key: ["buildingSquareFeet"],
        valueFormat: (value, currentObject: PropertyAssessment) => {
          return (
            <>
              <Text size="sm" fontWeight="bold">
                {currentObject.buildingSquareFeet} sq ft
              </Text>
            </>
          );
        },
      },
      {
        key: ["taxAmount"],
        keyFormat: () => {
          return "Property Tax";
        },
      },
      {
        key: ["hasPricing"],
        valueFormat: () => {
          return "true";
        },
      },
      {
        key: ["acres"],
        valueFormat: (value, currentObject: PropertyAssessment) => {
          return (
            <>
              <Text size="sm" fontWeight="bold">
                {currentObject.acres?.toString()}
              </Text>
            </>
          );
        },
      },
      {
        key: ["parkingSpaces"],
        valueFormat: (value, currentObject: PropertyAssessment) => {
          return (
            <>
              <Text size="sm" fontWeight="bold">
                {currentObject.parkingSpaces?.toString()}
              </Text>
            </>
          );
        },
      },
    ],
  );
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Assessed Value & Taxes ${
          loading
            ? ""
            : `(${
                pulledReport?.propertyAssessments
                  ? pulledReport.propertyAssessments.length
                  : 0
              })`
        }`}
        containerId={containerId}
        tooltipContent="Assessed value is the County Assessor's estimate of a property's worth determined by factors like location, size, condition, and comparable properties nearby. It is used to determine property tax rates, and may not reflect the current market value of the property."
      >
        {" "}
        {pulledReport?.propertyDetails?.mostRecentTaxYear ||
        (pulledReport?.propertyDetails?.mostRecentAssessedImprovementValue &&
          pulledReport.propertyDetails.mostRecentAssessedLandValue &&
          pulledReport.propertyDetails.mostRecentAssessedTotalValue) ? (
          <Container altColor="grey">
            <Padding space="md">
              <Layout
                type="flex"
                flexProps={{
                  direction: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", justifyContent: "center" }}
                >
                  <Text size="sm">Land Value</Text>
                  <Text textAlign="center" size="sm" fontWeight="bold">
                    {pulledReport.propertyDetails.mostRecentAssessedLandValue
                      ? pulledReport.propertyDetails.mostRecentAssessedLandValue
                      : "$0"}
                  </Text>
                </Layout>
                <Text>+</Text>
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", justifyContent: "center" }}
                >
                  <Text size="sm">Improvements</Text>
                  <Text textAlign="center" size="sm" fontWeight="bold">
                    {pulledReport.propertyDetails
                      .mostRecentAssessedImprovementValue
                      ? pulledReport.propertyDetails
                          .mostRecentAssessedImprovementValue
                      : "$0"}
                  </Text>
                </Layout>
                <Text>=</Text>
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", justifyContent: "center" }}
                >
                  <Text size="sm">Total Value</Text>
                  <Text textAlign="center" size="sm" fontWeight="bold">
                    {pulledReport.propertyDetails.mostRecentAssessedTotalValue}
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Container>
        ) : null}
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : pulledReport?.propertyAssessments &&
          pulledReport.propertyAssessments[0] ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport.propertyAssessments.map((assessment, index) => (
              <ContainerLineItem
                lineItemHeader={`${
                  assessment.taxAmount
                    ? formatNumberCommas(assessment.taxAmount, true)
                    : ""
                } Property Tax`}
                lineItemSubHeader={generateList(
                  [
                    {
                      value: assessment.taxYear ? assessment.taxYear : null,
                      label: "",
                    },
                    {
                      value: assessment.assessedTotalValue
                        ? assessment.assessedTotalValue +
                          " Total Assessed Value"
                        : null,
                      label: "",
                    },
                  ],
                  " | ",
                )}
                isOpen={printReady ? true : false}
                key={index}
              >
                {valueObjects[index] ? (
                  <Padding space="md">
                    <Table
                      orderOfProperties={["taxYear"]}
                      tableDataObject={valueObjects[index]}
                    />
                  </Padding>
                ) : null}
              </ContainerLineItem>
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No results found for Assessed Value and Taxes
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default ValueTaxes;
