import { FooterProps } from "@kidslivesafe/sc-react/lib/organisms/Footer/Footer";
import { selectBrandInfo } from "../features/brand/brandSlice";
import { useAppSelector } from "./hooks";
import { useMemo } from "react";

export const useFooterProps = (): FooterProps => {
  const brandInfo = useAppSelector(selectBrandInfo);

  return useMemo(() => {
    switch (brandInfo.acronym) {
      // Add specific cases here as needed
      case "pf":
        return {
          column_one: {
            title: "Quick Links",
            links: [
              {
                text: "Help Center",
                link: `${brandInfo.homeUrl}/help-center/help-center`,
                target: "_blank",
              },
              {
                text: "FAQs",
                link: `${brandInfo.homeUrl}/help-center/faqs`,
                target: "_blank",
              },
              {
                text: `Why ${brandInfo.name}`,
                link: `${brandInfo.homeUrl}/help-center/why`,
                target: "_blank",
              },
              {
                text: `Property Report Guide`,
                link: `${brandInfo.homeUrl}/help-center/property-report-guide`,
                target: "_blank",
              },
              {
                text: `Reviews`,
                link: `${brandInfo.homeUrl}/help-center/reviews`,
                target: "_blank",
              },
              {
                text: `About Us`,
                link: `${brandInfo.homeUrl}/help-center/faqs#what-is-Property-Focus`,
                target: "_blank",
              },
            ],
          },
          column_two: {
            title: "Legal",
            links: [
              {
                text: "Terms of Use",
                link: `${brandInfo.homeUrl}/help-center/terms`,
                target: "_blank",
              },
              {
                text: "Privacy Policy",
                link: `${brandInfo.homeUrl}/help-center/privacy`,
                target: "_blank",
              },
              {
                text: "Dos and Don'ts",
                link: `${brandInfo.homeUrl}/help-center/dos-and-donts`,
                target: "_blank",
              },
              {
                text: "Fair Credit Reporting Act",
                link: `${brandInfo.homeUrl}/help-center/fcra`,
                target: "_blank",
              },
            ],
          },
          column_three: {
            title: "Support",
            links: [
              {
                text: "Contact Us",
                link: `${brandInfo.homeUrl}/help-center/contact`,
                target: "_blank",
              },
              {
                text: "Reset Password",
                link: `${brandInfo.homeUrl}/help-center/lost-password`,
                target: "_blank",
              },
              {
                text: "Do Not Sell My Personal Information",
                link: `${brandInfo.homeUrl}/help-center/privacy-requests`,
                target: "_blank",
              },
              {
                text: "Unsubscribe My Email",
                link: `${brandInfo.homeUrl}/help-center/unsubscribe`,
                target: "_blank",
              },
            ],
          },
          column_details: {
            brand: brandInfo.name,
            address: {
              addressLine1: brandInfo.address.line1,
              addressLine2: brandInfo.address.line2,
            },
            llcName: `${brandInfo.llcName}, LLC`,
          },
          socialMediaLinks: [],
          footerTermsLink: `${brandInfo.homeUrl}/help-center/terms`,
          footerDosDontsLink: `${brandInfo.homeUrl}/help-center/dos-and-donts`,
          footerFCRALink: `${brandInfo.homeUrl}/help-center/fcra`,
        };

      default:
        return {
          column_one: {
            title: "Quick Links",
            links: [
              {
                text: "Help Center",
                link: `${brandInfo.homeUrl}/help-center/help-center`,
                target: "_blank",
              },
              {
                text: "FAQs",
                link: `${brandInfo.homeUrl}/help-center/faqs`,
                target: "_blank",
              },
              {
                text: `Why ${brandInfo.name}`,
                link: `${brandInfo.homeUrl}/help-center/why`,
                target: "_blank",
              },
            ],
          },
          column_two: {
            title: "Legalese",
            links: [
              {
                text: "Terms of Use",
                link: `${brandInfo.homeUrl}/help-center/terms`,
                target: "_blank",
              },
              {
                text: "Privacy Policy",
                link: `${brandInfo.homeUrl}/help-center/privacy`,
                target: "_blank",
              },
              {
                text: "Fair Credit Reporting Act",
                link: `${brandInfo.homeUrl}/help-center/fcra`,
                target: "_blank",
              },
            ],
          },
          column_three: {
            title: "Customer Support",
            links: [
              {
                text: "Contact Us",
                link: `${brandInfo.homeUrl}/help-center/contact`,
                target: "_blank",
              },
              {
                text: "Reset Password",
                link: `${brandInfo.homeUrl}/help-center/lost-password`,
                target: "_blank",
              },
              {
                text: "Unsubscribe Your Email Address",
                link: `${brandInfo.homeUrl}/help-center/unsubscribe`,
                target: "_blank",
              },
              {
                text: "Do Not Sell My Personal Information",
                link: `${brandInfo.homeUrl}/help-center/privacy-requests`,
                target: "_blank",
              },
            ],
          },
          column_details: {
            brand: brandInfo.name,
            address: {
              addressLine1: brandInfo.address.line1,
              addressLine2: brandInfo.address.line2,
            },
            llcName: `${brandInfo.llcName}, LLC`,
          },
          socialMediaLinks: [],
          footerTermsLink: `${brandInfo.homeUrl}/help-center/terms`,
          footerDosDontsLink: `${brandInfo.homeUrl}/help-center/dos-and-donts`,
          footerFCRALink: `${brandInfo.homeUrl}/help-center/fcra`,
        };
    }
  }, [brandInfo]);
};
