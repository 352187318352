import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Container,
  Icon,
  IconList,
  Layout,
  LoadingPlaceholder,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";

import "./most-recent-property-focus.css";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  selectBrandInfo,
  selectMapboxKey,
} from "../../../../features/brand/brandSlice";
import {
  HistoryValuation,
  PropertyReportHistory,
} from "../../../../features/reports/reportTypes";
import { usePropertySearchDispatch } from "../../../../hooks/propertyReportHook";
import { selectPropertyReportHistory } from "../../../../features/reports/reportsSlice";
import {
  formatAddress,
  formatNumberCommas,
  generatePropertyReportHighlights,
  parseDate,
} from "../../../../utils";
import StreetView from "@kidslivesafe/sc-react/lib/atoms/StreetView";

interface MostRecentPropertyFocusProps {}

const MostRecentPropertyFocus: React.FC<MostRecentPropertyFocusProps> = () => {
  const mapBoxKey = useAppSelector(selectMapboxKey);
  const [reportContent, setReportContent] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const brandInfo = useAppSelector(selectBrandInfo);
  const mostRecentPropertyReport = useAppSelector(selectPropertyReportHistory);
  const pullPropertyReport = usePropertySearchDispatch();

  const calculatePercentageIncreaseWithYears = (valuationSummary: {
    currentValue?: HistoryValuation;
    historicalValue?: HistoryValuation;
  }): string | null => {
    if (
      !valuationSummary ||
      !valuationSummary.currentValue ||
      !valuationSummary.historicalValue
    ) {
      return null;
    }

    const { currentValue, historicalValue } = valuationSummary;

    if (
      !currentValue.marketValue ||
      !historicalValue?.marketValue ||
      !currentValue.date ||
      !historicalValue.date
    ) {
      return null;
    }

    const historicalDate = new Date(
      parseDate(historicalValue.date, "MM/DD/YYYY"),
    );

    const percentageIncrease =
      ((currentValue.marketValue - historicalValue.marketValue) /
        historicalValue.marketValue) *
      100;

    const roundedPercentage = percentageIncrease.toFixed(2);

    return `(${roundedPercentage}% increase from ${historicalDate.getFullYear()})`;
  };

  const fetchReportContent = useCallback(
    async (report: PropertyReportHistory | undefined) => {
      if (!report) {
        setReportContent(<Text tag="h3">No most recent report available</Text>);
        setLoading(false);
        return;
      }

      const generateMap = (marker: string, coordinates: string) => {
        let imageSize = "450x289@2x";
        let zoom = 13;

        return `https://api.mapbox.com/styles/v1/scalable/cj5e6w98q14a22rs639t1y12i/static/${marker}/${coordinates},${zoom}/${imageSize}?access_token=${mapBoxKey}`;
      };

      switch (report.type) {
        case "property":
          let mapUrl: string | undefined;
          let longitude = report.longitude;
          let latitude = report.latitude;

          const marker = `pin-s+${brandInfo.markerColor}(${longitude},${latitude})`;
          const coordinates = `${longitude},${latitude}`;
          mapUrl = generateMap(marker, coordinates);

          setReportContent(
            <Layout
              type="flex"
              flexProps={{ direction: "column", gap: "lg" }}
              passThroughClassess="pf-most-recent-report__inner-container"
            >
              <div className="pf-most-recent-report__content-container">
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "lg" }}
                >
                  <div className="pf-most-recent-report__header">
                    <Text tag="h3">{formatAddress(report, "with-comma")}</Text>
                    <Text size="xs">
                      {generatePropertyReportHighlights(report)}
                    </Text>
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "lg" }}
                    breakpointStyles={{
                      desktop: {
                        type: "grid",
                        gridProps: { templateColumns: "2-column", gap: "lg" },
                      },
                    }}
                  >
                    <div className="pf-most-recent-report__content">
                      <Padding left="md" right="md">
                        <img
                          className="pf-most-recent-report__content__image"
                          src={mapUrl}
                          alt="map"
                        />
                      </Padding>
                      {report.valuationSummary && (
                        <>
                          <Layout
                            type="flex"
                            flexProps={{ direction: "column", gap: "xxxs" }}
                            passThroughClassess="pf-most-recent-report__content__container"
                          >
                            {report.valuationSummary.currentValue &&
                              report.valuationSummary.currentValue
                                .marketValue && (
                                <div className="pf-most-recent-report__content__data-container pf-most-recent-report__content__data-container--left">
                                  <Padding space="xs">
                                    <Text size="xxs">MOST RECENT VALUE</Text>
                                    <Text size="xs" fontWeight="semibold">
                                      {formatNumberCommas(
                                        report.valuationSummary.currentValue
                                          .marketValue,
                                        true,
                                      )}
                                    </Text>
                                    <Text size="xxxs">
                                      {report.valuationSummary.currentValue.date
                                        ? `(${parseDate(
                                            report.valuationSummary.currentValue
                                              .date,
                                            "year",
                                          )})`
                                        : undefined}
                                    </Text>
                                  </Padding>
                                </div>
                              )}
                            {report.valuationSummary.historicalValue &&
                              report.valuationSummary.currentValue &&
                              report.valuationSummary.currentValue
                                .marketValue &&
                              report.valuationSummary.historicalValue
                                .marketValue && (
                                <div className="pf-most-recent-report__content__data-container pf-most-recent-report__content__data-container--left">
                                  <Padding space="xs">
                                    <Text size="xxs">
                                      PROPERTY APPRECIATION
                                    </Text>
                                    <Text size="xs" fontWeight="semibold">
                                      {formatNumberCommas(
                                        report.valuationSummary.currentValue
                                          .marketValue -
                                          report.valuationSummary
                                            .historicalValue.marketValue,
                                        true,
                                      )}
                                    </Text>
                                    <Text size="xxxs">
                                      {calculatePercentageIncreaseWithYears(
                                        report.valuationSummary,
                                      )}
                                    </Text>
                                  </Padding>
                                </div>
                              )}
                          </Layout>
                          <div className="pf-most-recent-report__content__icon-container">
                            <Padding space="xs" top="xxxs" bottom="xxxs">
                              <Icon
                                name={IconList.IconLineGoUp}
                                size={24}
                                iconColor="#7ED9E6"
                              />
                            </Padding>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="pf-most-recent-report__content">
                      <Padding left="md" right="md">
                        <StreetView
                          lat={report.latitude}
                          long={report.longitude}
                          size={{ height: 193, width: 300 }}
                          passThroughClassess="pf-most-recent-report__content__image"
                        />
                      </Padding>
                      {report.currentOwners &&
                        report.currentOwners.owners &&
                        report.currentOwners.owners.length > 0 &&
                        report.currentOwners.owners[0].fullName.length > 0 && (
                          <Layout
                            type="flex"
                            flexProps={{ direction: "column", gap: "xxxs" }}
                            passThroughClassess="pf-most-recent-report__content__container pf-most-recent-report__content__container--right"
                          >
                            <div className="pf-most-recent-report__content__data-container pf-most-recent-report__content-data-container--pill pf-most-recent-report__content__data-container--right">
                              <Padding space="xs">
                                <Layout
                                  type="flex"
                                  flexProps={{
                                    gap: "xs",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Icon
                                    name={IconList.IconPeople}
                                    size={16}
                                    iconColor="#E85B5E"
                                  />
                                  <Text size="xs" fontWeight="semibold">
                                    Ownership
                                  </Text>
                                </Layout>
                              </Padding>
                            </div>

                            {report.currentOwners.owners && (
                              <div className="pf-most-recent-report__content__data-container pf-most-recent-report__content__data-container--right">
                                <Padding space="xs">
                                  <Layout
                                    type="flex"
                                    flexProps={{
                                      gap: "xs",
                                      alignItems: "flex-end",
                                      direction: "column",
                                    }}
                                  >
                                    <div
                                      id="pf-most-recent-report__content__data-container--owners"
                                      className="pf-most-recent-report__content__data-container__data-item"
                                    >
                                      <Text size="xxs" textAlign="right">
                                        OWNER
                                      </Text>
                                      <Text
                                        size="xs"
                                        textAlign="right"
                                        fontWeight="semibold"
                                      >
                                        {report.currentOwners?.owners?.length >
                                          0 &&
                                          report.currentOwners.owners.flatMap(
                                            (owner, index) => {
                                              if (
                                                index ===
                                                report.currentOwners!.owners!
                                                  .length -
                                                  1
                                              ) {
                                                return owner.fullName;
                                              } else {
                                                return [
                                                  owner.fullName,
                                                  " & ",
                                                  <br key={index} />,
                                                ];
                                              }
                                            },
                                          )}
                                      </Text>
                                    </div>
                                    {report.currentOwners.saleDate && (
                                      <div className="pf-most-recent-report__content__data-container__data-item">
                                        <Text size="xxs" textAlign="right">
                                          SALE DATE
                                        </Text>
                                        <Text
                                          size="xs"
                                          textAlign="right"
                                          fontWeight="semibold"
                                        >
                                          {report.currentOwners.saleDate}
                                        </Text>
                                      </div>
                                    )}
                                  </Layout>
                                </Padding>
                              </div>
                            )}
                          </Layout>
                        )}
                    </div>
                  </Layout>
                </Layout>
              </div>
              <Button
                buttonText="View Report"
                handleClick={() => pullPropertyReport(report)}
                passThroughClassess="pf-most-recent-report__button"
              />
              <Icon
                name={IconList.IconDots}
                size={64}
                className="pf-most-recent-report__background-icon pf-most-recent-report__background-icon--top"
              />
              <Icon
                name={IconList.IconDots}
                size={64}
                className="pf-most-recent-report__background-icon pf-most-recent-report__background-icon--bottom"
              />
            </Layout>,
          );
          break;
        default:
          // Handle any unspecified report types
          setReportContent(
            <Text tag="h3">No report type specified or not supported yet</Text>,
          );
      }
      setLoading(false);
    },
    [brandInfo, mapBoxKey, pullPropertyReport],
  );

  useEffect(() => {
    setLoading(true);
    if (mostRecentPropertyReport && mostRecentPropertyReport[0]) {
      fetchReportContent(mostRecentPropertyReport[0]).catch(console.error);
    } else {
      setReportContent(<Text tag="h3">No most recent report available</Text>);
      setLoading(false);
    }
  }, [mostRecentPropertyReport]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Padding left="md" right="md">
      <Container customClassName="pf-most-recent-report__container">
        <Padding space="lg">
          {loading ? (
            <Layout
              type="flex"
              flexProps={{ direction: "column", gap: "lg" }}
              passThroughClassess="pf-most-recent-report__inner-container"
            >
              <div className="pf-most-recent-report__content-container">
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "lg" }}
                >
                  <div className="pf-most-recent-report__header">
                    <LoadingPlaceholder height={24} width={"75%"} />

                    <LoadingPlaceholder height={16} width={"50%"} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "lg" }}
                    breakpointStyles={{
                      desktop: {
                        type: "grid",
                        gridProps: { templateColumns: "2-column", gap: "lg" },
                      },
                    }}
                  >
                    <div className="pf-most-recent-report__content">
                      <Padding left="md" right="md">
                        <div className="pf-most-recent-report__content__image--placeholder">
                          <LoadingPlaceholder height={241} width={"100%"} />
                        </div>
                      </Padding>
                    </div>
                    <div className="pf-most-recent-report__content">
                      <Padding left="md" right="md">
                        <div className="pf-most-recent-report__content__image--placeholder">
                          <LoadingPlaceholder height={241} width={"100%"} />
                        </div>
                      </Padding>
                    </div>
                  </Layout>
                </Layout>
              </div>
              <Button
                buttonText="View Report"
                disabled
                handleClick={() => {}}
                passThroughClassess="pf-most-recent-report__button"
              />
              <Icon
                name={IconList.IconDots}
                size={64}
                className="pf-most-recent-report__background-icon pf-most-recent-report__background-icon--top"
              />
              <Icon
                name={IconList.IconDots}
                size={64}
                className="pf-most-recent-report__background-icon pf-most-recent-report__background-icon--bottom"
              />
            </Layout>
          ) : (
            reportContent || (
              <Text tag="h3">No most recent report available</Text>
            )
          )}
        </Padding>
      </Container>
    </Padding>
  );
};

export default MostRecentPropertyFocus;
