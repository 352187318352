import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Padding,
  Text,
  SectionTitle,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  LineItemSummary,
} from "@kidslivesafe/sc-react";

import {
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  Arrest,
  Crime,
  Suspect,
  Warrant,
} from "../../../features/reports/reportTypes";
import {
  convertStateAbbreviation,
  formatName,
  formatNumberCommas,
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
} from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

interface SuspectWithWarrant extends Warrant {
  suspect?: Suspect;
}

interface SuspectWithArrest extends Arrest {
  suspect?: Suspect;
}

interface SuspectWithCrime extends Crime {
  suspect?: Suspect;
}

const CriminalRecords: React.FC<{
  sectionId: string;
  printReady?: boolean;
}> = ({ sectionId, printReady }) => {
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );

  const loading = peopleReportStatus === "loading";

  const [warrants, setWarrants] = useState<SuspectWithWarrant[]>([]);
  const [arrests, setArrests] = useState<SuspectWithArrest[]>([]);
  const [charges, setCharges] = useState<SuspectWithCrime[]>([]);

  /* extracts warrants, charges and crimes from criminal records */
  useEffect(() => {
    if (pulledReport && pulledReport.criminalRecords) {
      let warrants: SuspectWithWarrant[] = [];
      let arrests: SuspectWithArrest[] = [];
      let charges: SuspectWithCrime[] = [];

      for (let i = 0; i < pulledReport.criminalRecords.length; i++) {
        let record = pulledReport.criminalRecords[i];

        record.warrants?.forEach(warrant => {
          let modifiedWarrant: SuspectWithWarrant = {
            ...warrant,
            suspect: record.suspect,
          };

          warrants.push(modifiedWarrant);
        });

        record.arrests?.forEach(arrest => {
          let modifiedArrest: SuspectWithArrest = {
            ...arrest,
            suspect: record.suspect,
          };

          arrests.push(modifiedArrest);
        });

        record.crimes?.forEach(crime => {
          let modifiedCrime: SuspectWithCrime = {
            ...crime,
            suspect: record.suspect,
          };

          charges.push(modifiedCrime);
        });
      }

      // Set state directly without checking the lengths of the arrays
      setWarrants(warrants);
      setArrests(arrests);
      setCharges(charges);
    }
  }, [pulledReport]);
  /* generate warrant Object */
  const warrantObjectArray = renderArrayOfObjectsPrep(
    warrants ? warrants : [],
    ["suspect"],
    [],
  );

  /* generate arrest Object */
  const arrestObjectArray = renderArrayOfObjectsPrep(
    arrests ? arrests : [],
    ["pictureUrl", "suspect"],
    [
      {
        key: ["bond"],
        valueFormat: (value, currentObject) => {
          return `$${value}`;
        },
      },
    ],
  );

  /* generate charge Object */
  const chargeObjectArray = renderArrayOfObjectsPrep(
    charges ? charges : [],
    ["suspect"],
    [
      {
        key: ["offenseDescription"],
        valueFormat: (value, currentObject) => {
          if (value && value.trim().length > 0) {
            return value;
          } else {
            return "NOT SPECIFIED";
          }
        },
      },
      {
        key: ["courtCosts"],
        valueFormat: (value, currentObject) => {
          const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
          if (isNaN(numericValue)) {
            return value;
          }

          return formatNumberCommas(value, true);
        },
      },
    ],
  );

  return (
    <>
      <>
        <SectionTitle
          sectionTitle="Criminal Records"
          tooltipContent="A Warrant is a legal document authorizing specific actions, an Arrest is the physical act of taking someone into custody, and criminal Charges are formal accusations brought against an individual alleging their involvement in a crime. There may be overlap between these records."
          headingLevel={2}
          sectionId={sectionId}
        />
        <Container
          containerTitle={`Possible Warrants ${
            loading ? "" : `(${warrants ? warrants.length : 0})`
          }`}
          newInfo={peopleReportMonitoringDifferences?.criminalRecords}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : warrants.length > 0 ? (
            <CollapsableItemList isOpen={printReady}>
              {warrants.map((warrant, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    warrant.offenseInformation
                      ? warrant.offenseInformation
                      : "NOT SPECIFIED"
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: warrant.offenseDate
                          ? parseDate(warrant.offenseDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: warrant.sourceState
                          ? convertStateAbbreviation(warrant.sourceState)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  <LineItemSummary
                    summaryDataObject={
                      renderArrayOfObjectsPrep([warrant], [], [])[0]
                    }
                  />
                  {warrantObjectArray[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={warrantObjectArray[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Warrants Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Arrests ${
            loading ? "" : `(${arrests ? arrests.length : 0})`
          }`}
          newInfo={peopleReportMonitoringDifferences?.criminalRecords}
        >
          {loading ? (
            <LoadingContainerLineItem
              pictureUrl
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : arrests.length > 0 ? (
            <CollapsableItemList isOpen={printReady}>
              {arrests.map((arrest, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    arrest.charges ? arrest.charges : "NOT SPECIFIED"
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: arrest.arrestDate
                          ? parseDate(arrest.arrestDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: arrest.sourceState
                          ? convertStateAbbreviation(arrest.sourceState)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  pictureUrl={arrest.suspect?.pictureUrl}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  <LineItemSummary
                    summaryDataObject={
                      renderArrayOfObjectsPrep(
                        [arrest],
                        [
                          "arrestDate",
                          "arrestLocation",
                          "bookingDate",
                          "bookingNumber",
                          "releaseDate",
                          "caseNumber",
                          "chargeClass",
                          "charges",
                          "court",
                          "bond",
                          "currentStatus",
                          "remarks",
                          "sourceState",
                          "agencyIdLabel",
                          "agencyIdValue",
                          "fullName",
                          "pictureUrl",
                          "state",
                        ],
                        [
                          {
                            key: ["dateOfBirth"],
                            keyFormat: () => {
                              return "Date of Birth";
                            },
                            valueFormat: (value, currentObject) => {
                              return parseDate(value, "month-day-year");
                            },
                          },
                          {
                            key: ["fullAddress"],
                            keyFormat() {
                              return "Subject's Address";
                            },
                            valueFormat: (value, currentObject) => {
                              return value.split("-")[0];
                            },
                          },
                        ],
                      )[0]
                    }
                    orderOfProperties={[
                      "Subject's Address",
                      "Date of Birth",
                      "Current Age",
                      "Gender",
                      "Hair Color",
                      "Build",
                      "Height",
                      "Weight",
                      "Ethnicity",
                      "Eye Color",
                      "Employer",
                      "Occupation",
                    ]}
                  />
                  {arrestObjectArray[index] ? (
                    <Padding top="md">
                      <Table
                        tableDataObject={arrestObjectArray[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Arrests Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        <Container
          containerTitle={`Possible Charges ${
            loading ? "" : `(${charges ? charges.length : 0})`
          }`}
          newInfo={peopleReportMonitoringDifferences?.criminalRecords}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : charges.length > 0 ? (
            <CollapsableItemList isOpen={printReady}>
              {charges.map((charge, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    charge.offenseDescription &&
                    charge.offenseDescription.trim().length > 0
                      ? charge.offenseDescription
                      : "NOT SPECIFIED"
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: charge.chargesFiledDate
                          ? parseDate(charge.chargesFiledDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: charge.sourceState
                          ? convertStateAbbreviation(charge.sourceState)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  <LineItemSummary
                    summaryDataObject={
                      renderArrayOfObjectsPrep(
                        [charge],
                        [
                          "caseNumber",
                          "charges",
                          "classification",
                          "crimeCounty",
                          "offenseCode",
                          "offenseDate",
                          "offenseDescription",
                          "chargesFiledDate",
                          "court",
                          "disposition",
                          "dispositionDate",
                          "isSexOffender",
                          "sourceState",
                          "agencyIdLabel",
                          "agencyIdValue",
                          "fullName",
                          "pictureUrl",
                          "counts",
                          "extraInfo",
                          "arrestDate",
                          "sentence",
                          "crimeType",
                          "caseType",
                          "plea",
                          "courtCosts",
                          "fines",
                          "state",
                        ],
                        [
                          {
                            key: ["fullAddress"],
                            keyFormat: () => {
                              return "Subject's Address";
                            },
                          },
                          {
                            key: ["dateOfBirth"],
                            keyFormat: () => {
                              return "Date of Birth";
                            },
                            valueFormat: (value, currentObject) => {
                              return parseDate(value, "month-day-year");
                            },
                          },
                        ],
                      )[0]
                    }
                    orderOfProperties={[
                      "Subject's Address",
                      "Date of Birth",
                      "Current Age",
                      "Gender",
                      "Ethnicity",
                    ]}
                  />
                  {chargeObjectArray[index] ? (
                    <Padding top="md">
                      <Table
                        tableDataObject={chargeObjectArray[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Charges Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
      </>
    </>
  );
};

export default CriminalRecords;
