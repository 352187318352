import { useAppDispatch, useHandlePageNavigate } from "./hooks";
import { getSubscriptionDetailsAsync } from "../features/subscriptions/subscriptionSlice";
import { clearExpired } from "../features/authentication/authenticationSlice";

type UseReactivateCheckHook = () => () => void;

/* Pull People Report */
const useReactivateCheckHook: UseReactivateCheckHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useHandlePageNavigate();
  const reactivateCheck = async () => {
    const getSubscriptionDetailsResponse = await dispatch(
      getSubscriptionDetailsAsync(),
    );
    if (
      getSubscriptionDetailsAsync.fulfilled.match(
        getSubscriptionDetailsResponse,
      )
    ) {
      const { accessExpirationDate } =
        getSubscriptionDetailsResponse.payload.subscriptionDetails;
      if (
        accessExpirationDate &&
        Date.parse(accessExpirationDate) > Date.now()
      ) {
        dispatch(clearExpired());
        navigate("/");
      }
    }
  };
  return reactivateCheck;
};

export { useReactivateCheckHook };
