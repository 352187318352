import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  NeighborhoodMonitoringInterface,
  NeighborhoodSaveMonitoringInterface,
  PeopleMonitoringInterface,
  PropertyMonitoringInterface,
  PropertyMonitoringParamsInterface,
  deleteNeighborhoodWatchMonitoringArea,
  getNeighborhoodWatchMonitoringAreas,
  getPropertyMonitoring,
  getReportMonitoring,
  saveNeighborhoodWatchMonitoringArea,
  toggleMonitoring,
  togglePropertyMonitoring,
  updateNeighborhoodWatchMonitoringArea,
  updatePropertyMonitoring,
} from "./monitoringAPI";
import { logoutAsync } from "../authentication/authenticationSlice";

type MonitoringReport = PeopleMonitoringInterface;

export interface MonitoringState {
  monitoredReports: MonitoringReport[];
  neighborhoodMonitoringAreas: NeighborhoodMonitoringInterface[];
  propertyMonitoredReports: PropertyMonitoringInterface[];
  totalAllowedMonitoredReports: number;
  peopleMonitoringStatus: "idle" | "loading" | "failed";
  neighborhoodMonitoringStatus: "idle" | "loading" | "failed";
  propertyMonitoringStatus: "idle" | "loading" | "failed";
  renameReport?:
    | NeighborhoodMonitoringInterface
    | PropertyMonitoringInterface
    | MonitoringReport;
  toggleMonitoringModal: boolean;
  maxMonitoringReached: boolean;
}

const initialState: MonitoringState = {
  monitoredReports: [],
  neighborhoodMonitoringAreas: [],
  propertyMonitoredReports: [],
  totalAllowedMonitoredReports: 10,
  peopleMonitoringStatus: "idle",
  neighborhoodMonitoringStatus: "idle",
  propertyMonitoringStatus: "idle",
  toggleMonitoringModal: false,
  maxMonitoringReached: false,
};

/* People Monitoring */
export const getMonitoringAsync = createAsyncThunk<any, void, {}>(
  "monitoring/getMonitoring",
  async () => {
    return getReportMonitoring();
  },
);

export const toggleMonitoringAsync = createAsyncThunk<
  any,
  { reportToken: string; version: string },
  {}
>("monitoring/toggleMonitoring", async ({ reportToken, version }) => {
  return toggleMonitoring(reportToken, version);
});

/* Neighborhood Monitoring */
export const getNeighborhoodWatchMonitoringAreasAsync = createAsyncThunk<
  any,
  void,
  {}
>("monitoring/getNeighborhoodWatchMonitoringAreasAsync", async () => {
  return getNeighborhoodWatchMonitoringAreas();
});

export const saveNeighborhoodWatchMonitoringAreaAsync = createAsyncThunk<
  any,
  NeighborhoodSaveMonitoringInterface,
  {}
>("monitoring/saveNeighborhoodWatchMonitoringAreaAsync", async area => {
  return saveNeighborhoodWatchMonitoringArea(area);
});

export const updateNeighborhoodWatchMonitoringAreaAsync = createAsyncThunk<
  any,
  NeighborhoodSaveMonitoringInterface,
  {}
>("monitoring/updateNeighborhoodWatchMonitoringAreaAsync", async area => {
  return updateNeighborhoodWatchMonitoringArea(area);
});

export const deleteNeighborhoodWatchMonitoringAreaAsync = createAsyncThunk<
  any,
  { id: number },
  {}
>("monitoring/deleteNeighborhoodWatchMonitoringAreaAsync", async ({ id }) => {
  return deleteNeighborhoodWatchMonitoringArea(id);
});

/* Property Monitoring */
export const getPropertyMonitoringAsync = createAsyncThunk<any, void, {}>(
  "monitoring/getPropertyMonitoringAsync",
  async () => {
    return getPropertyMonitoring();
  },
);

export const togglePropertyMonitoringAsync = createAsyncThunk<
  any,
  PropertyMonitoringParamsInterface,
  {}
>("monitoring/togglePropertyMonitoringAsync", async address => {
  return togglePropertyMonitoring(address);
});

export const updatePropertyMonitoringAsync = createAsyncThunk<
  any,
  PropertyMonitoringParamsInterface,
  {}
>("monitoring/updatePropertyMonitoringAsync", async address => {
  return updatePropertyMonitoring(address);
});

/* Slice initialization */
export const monitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    setRenameReport: (state, action) => {
      state.renameReport = action.payload;
    },
    setToggleMonitoringModal: (state, action) => {
      state.toggleMonitoringModal = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      /* getMonitoringAsync */
      .addCase(getMonitoringAsync.pending, state => {
        state.peopleMonitoringStatus = "loading";
      })
      .addCase(getMonitoringAsync.fulfilled, (state, action) => {
        state.peopleMonitoringStatus = "idle";
        state.monitoredReports = action.payload;
      })
      .addCase(getMonitoringAsync.rejected, state => {
        state.peopleMonitoringStatus = "failed";
      })
      /* getNeighborhoodWatchMonitoringAreasAsync */
      .addCase(getNeighborhoodWatchMonitoringAreasAsync.pending, state => {
        state.neighborhoodMonitoringStatus = "loading";
      })
      .addCase(
        getNeighborhoodWatchMonitoringAreasAsync.fulfilled,
        (state, action) => {
          state.neighborhoodMonitoringAreas = [];
          state.neighborhoodMonitoringStatus = "idle";
          state.neighborhoodMonitoringAreas = action.payload;
        },
      )
      .addCase(getNeighborhoodWatchMonitoringAreasAsync.rejected, state => {
        state.neighborhoodMonitoringStatus = "failed";
      })
      /* toggleMonitoringAsync */
      .addCase(toggleMonitoringAsync.pending, state => {})
      .addCase(toggleMonitoringAsync.fulfilled, (state, action) => {
        if (action.payload.toggleState === false) {
          state.monitoredReports = state.monitoredReports.filter(
            report => report.reportToken !== action.payload.reportToken,
          );
        }
      })
      .addCase(toggleMonitoringAsync.rejected, state => {})
      /* saveNeighborhoodWatchMonitoringAreaAsync */
      .addCase(saveNeighborhoodWatchMonitoringAreaAsync.pending, state => {
        state.neighborhoodMonitoringStatus = "loading";
      })
      .addCase(
        saveNeighborhoodWatchMonitoringAreaAsync.fulfilled,
        (state, action) => {
          state.neighborhoodMonitoringStatus = "idle";
          state.neighborhoodMonitoringAreas = [
            ...state.neighborhoodMonitoringAreas,
            action.payload.monitoringArea,
          ];
        },
      )
      .addCase(
        saveNeighborhoodWatchMonitoringAreaAsync.rejected,
        (state, action) => {
          state.neighborhoodMonitoringStatus = "failed";
          if (
            action.error.message ===
            "The max number of monitors has been reached."
          ) {
            state.maxMonitoringReached = true;
          }
        },
      )
      /* updateNeighborhoodWatchMonitoringAreaAsync */
      .addCase(updateNeighborhoodWatchMonitoringAreaAsync.pending, state => {
        state.neighborhoodMonitoringStatus = "loading";
      })
      .addCase(
        updateNeighborhoodWatchMonitoringAreaAsync.fulfilled,
        (state, action) => {
          state.neighborhoodMonitoringStatus = "idle";
          state.renameReport = undefined;
          state.neighborhoodMonitoringAreas =
            state.neighborhoodMonitoringAreas.map(area => {
              if (area.id === action.payload.monitoringArea.id) {
                return action.payload.monitoringArea;
              }
              return area;
            });
        },
      )
      .addCase(updateNeighborhoodWatchMonitoringAreaAsync.rejected, state => {
        state.neighborhoodMonitoringStatus = "failed";
      })
      /* deleteNeighborhoodWatchMonitoringArea */
      .addCase(deleteNeighborhoodWatchMonitoringAreaAsync.pending, state => {
        state.neighborhoodMonitoringStatus = "loading";
      })
      .addCase(
        deleteNeighborhoodWatchMonitoringAreaAsync.fulfilled,
        (state, action) => {
          state.neighborhoodMonitoringStatus = "idle";
          state.neighborhoodMonitoringAreas =
            state.neighborhoodMonitoringAreas.filter(
              area => area.id !== action.payload,
            );
        },
      )
      .addCase(deleteNeighborhoodWatchMonitoringAreaAsync.rejected, state => {
        state.neighborhoodMonitoringStatus = "failed";
      })
      /* getPropertyMonitoringAsync */
      .addCase(getPropertyMonitoringAsync.pending, state => {
        state.propertyMonitoringStatus = "loading";
      })
      .addCase(getPropertyMonitoringAsync.fulfilled, (state, action) => {
        state.propertyMonitoringStatus = "idle";
        state.propertyMonitoredReports = action.payload;
      })
      .addCase(getPropertyMonitoringAsync.rejected, state => {
        state.propertyMonitoringStatus = "failed";
      })
      /* togglePropertyMonitoringAsync */
      .addCase(togglePropertyMonitoringAsync.pending, state => {
        state.propertyMonitoringStatus = "loading";
      })
      .addCase(togglePropertyMonitoringAsync.fulfilled, (state, action) => {
        state.propertyMonitoringStatus = "idle";
        if (action.payload.toggleState === false) {
          state.propertyMonitoredReports =
            state.propertyMonitoredReports.filter(
              report => report.addressLine1 !== action.meta.arg.addressLine1,
            );
        }
      })
      .addCase(togglePropertyMonitoringAsync.rejected, (state, action) => {
        state.propertyMonitoringStatus = "failed";
        if (
          action.error.message ===
          "The max number of monitors has been reached."
        ) {
          state.maxMonitoringReached = true;
        }
      })
      /* updatePropertyMonitoringAsync */
      .addCase(updatePropertyMonitoringAsync.pending, state => {
        state.propertyMonitoringStatus = "loading";
      })
      .addCase(updatePropertyMonitoringAsync.fulfilled, (state, action) => {
        state.propertyMonitoringStatus = "idle";
        state.renameReport = undefined;
        state.propertyMonitoredReports = state.propertyMonitoredReports.map(
          report => {
            if (
              report.addressLine1 === action.meta.arg.addressLine1 &&
              report.addressLine2 === action.meta.arg.addressLine2
            ) {
              report.nickname = action.meta.arg.nickname;
            }
            return report;
          },
        );
      })
      .addCase(updatePropertyMonitoringAsync.rejected, state => {
        state.propertyMonitoringStatus = "failed";
      })
      /* handle logout */
      .addCase(logoutAsync.fulfilled, state => {
        state.monitoredReports = [];
        state.neighborhoodMonitoringAreas = [];
        state.propertyMonitoredReports = [];
        state.totalAllowedMonitoredReports = 10;
        state.peopleMonitoringStatus = "idle";
        state.neighborhoodMonitoringStatus = "idle";
        state.propertyMonitoringStatus = "idle";
      });
  },
});

export const { setToggleMonitoringModal, setRenameReport } =
  monitoringSlice.actions;

/* Getters */
export const selectMonitoring = (state: RootState) =>
  state.monitoring.monitoredReports;

export const selectNeighborhoodMonitoringAreas = (state: RootState) =>
  state.monitoring.neighborhoodMonitoringAreas;

export const selectPropertyMonitoring = (state: RootState) =>
  state.monitoring.propertyMonitoredReports;

export const selectMonitoringStatus = (state: RootState) =>
  state.monitoring.peopleMonitoringStatus;

export const selectNeighborhoodMonitoringStatus = (state: RootState) =>
  state.monitoring.neighborhoodMonitoringStatus;

export const selectPropertyMonitoringStatus = (state: RootState) =>
  state.monitoring.propertyMonitoringStatus;

export const selectTotalAllowedMonitoredReports = (state: RootState) =>
  state.monitoring.totalAllowedMonitoredReports;

export const selectNumberOfMonitoredReports = (state: RootState) =>
  state.monitoring.monitoredReports.length +
  state.monitoring.propertyMonitoredReports.length +
  state.monitoring.neighborhoodMonitoringAreas.length;

export const selectRenameReport = (state: RootState) =>
  state.monitoring.renameReport;

export const selectToggleMonitoringModal = (state: RootState) =>
  state.monitoring.toggleMonitoringModal;

export const selectMaxMonitoringReached = (state: RootState) =>
  state.monitoring.maxMonitoringReached;

export const checkCurrentlyMonitoring = (
  state: RootState,
  reportToken: string | undefined,
) => {
  const monitoring = state.monitoring.monitoredReports.find(
    report => report.reportToken === reportToken,
  );
  return monitoring ? true : false;
};

export const checkCurrentlyMonitoringNeighborhood = (
  state: RootState,
  addressObject:
    | {
        address: string;
        addressLine2?: string;
        city: string;
        state: string;
        zipCode: string;
        zip?: string;
      }
    | undefined,
) => {
  if (!addressObject) {
    return false;
  }
  const monitoring = state.monitoring.neighborhoodMonitoringAreas.find(area =>
    area.address === addressObject.address &&
    area.city === addressObject.city &&
    area.state === addressObject.state &&
    // this squashed bug where zipCode was being passed as zip on first neighborhood report pull after funnel
    area.zipCode === addressObject.zipCode
      ? addressObject.zipCode
      : addressObject.zip,
  );
  return monitoring ? monitoring.id : false;
};

export const selectCurrentlyMonitoredNeighborhood = (
  state: RootState,
  addressObject:
    | {
        address: string;
        addressLine2?: string;
        city: string;
        state: string;
        zipCode: string;
      }
    | undefined,
) => {
  if (!addressObject) {
    return false;
  }
  const monitoring = state.monitoring.neighborhoodMonitoringAreas.find(
    area =>
      area.address === addressObject.address &&
      area.city === addressObject.city &&
      area.state === addressObject.state &&
      area.zipCode === addressObject.zipCode,
  );
  return monitoring;
};

export const checkCurrentlyMonitoredProperty = (
  state: RootState,
  addressObject:
    | {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        state?: string;
        zip?: string;
      }
    | undefined,
) => {
  if (!addressObject) {
    return false;
  }
  const monitoring = state.monitoring.propertyMonitoredReports.find(
    area =>
      area.addressLine1 === addressObject.addressLine1 &&
      area.addressLine2 === addressObject.addressLine2 &&
      area.city === addressObject.city &&
      area.state === addressObject.state &&
      area.zip === addressObject.zip,
  );

  return monitoring ? !!monitoring.addressLine1 : false;
};

export default monitoringSlice.reducer;
