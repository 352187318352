import { generateList } from "./generateList";

export interface NameObject {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
}

export const formatName = (
  name: NameObject,
  method: "default" | "abbreviated" = "default",
): string => {
  switch (method) {
    case "abbreviated":
      return generateList(
        [
          {
            value: name.firstName ? name.firstName[0] : null,
            label: " ",
          },
          {
            value: name.lastName ? name.lastName : null,
            label: " ",
          },
        ],
        " ",
      );
    case "default":
    default:
      return generateList(
        [
          {
            value: name.firstName ? name.firstName : null,
            label: " ",
          },
          {
            value: name.middleName ? name.middleName : null,
            label: " ",
          },
          {
            value: name.lastName ? name.lastName : null,
            label: " ",
          },
          {
            value: name.nameSuffix ? name.nameSuffix : null,
            label: " ",
          },
        ],
        " ",
      );
  }
};
