import {
  PeopleReport,
  PeopleReportHistory,
  SocialReportType,
  SocialReportHistory,
  PhoneReport,
  PhoneReportHistory,
  PropertyReport,
  PropertyReportHistory,
  PropertyDetails,
} from "../features/reports/reportTypes";

import { formatGender, generateList, parseDate, generateCarrierLogo } from ".";

import { Layout } from "@kidslivesafe/sc-react";
import {
  NeighborhoodReport,
  NeighborhoodReportHistory,
} from "../features/reports/reportTypes/neighborhoodReportTypes";

export const generatePeopleReportHighlights = (
  report: PeopleReport | PeopleReportHistory,
) => {
  const highlightText = generateList(
    [
      {
        value: report.gender ? formatGender(report.gender) : null,
        label: "",
      },
      {
        value: report.age && !report.dateOfDeath ? report.age : null,
        label: "Age ",
      },
      {
        value: generateList(
          [
            {
              value: report.dateOfBirth
                ? parseDate(report.dateOfBirth, "month-year")
                : null,
              label: `${report.dateOfDeath ? "" : "Born "}`,
            },
            {
              value: report.dateOfDeath
                ? parseDate(report.dateOfDeath, "month-year")
                : null,
              label: "",
            },
          ],
          " - ",
        ),
        label: "",
      },
    ],
    " | ",
  );

  return highlightText;
};

export const generateSocialReportHighlights = (
  report: SocialReportType | SocialReportHistory,
  type: "email" | "phone" | "twitter" | undefined,
) => {
  return generateList(
    [
      {
        value: report.fullName ? report.fullName : null,
        label: "",
      },
      {
        value: report.gender ? formatGender(report.gender) : null,
        label: "",
      },
      {
        value: type ? type.charAt(0).toUpperCase() + type.slice(1) : null,
        label: "",
      },
    ],
    " | ",
  );
};

export const generatePhoneReportHighlights = (
  report: PhoneReport | PhoneReportHistory,
  logo: boolean,
) => {
  let carrierLogo;
  if (logo && "phoneReportSummary" in report) {
    carrierLogo = generateCarrierLogo(report.phoneReportSummary.carrier);
    return (
      <Layout type="flex" flexProps={{ alignItems: "center", gap: "xxs" }}>
        {report.phoneReportSummary.phoneType ? (
          <span>{report.phoneReportSummary.phoneType} </span>
        ) : null}

        {carrierLogo ? (
          <>
            {report.phoneReportSummary.phoneType ? <span>|</span> : null}
            <img
              width="78"
              src={carrierLogo ? carrierLogo : undefined}
              alt=""
            ></img>
          </>
        ) : null}
      </Layout>
    );
  } else if ("carrier" in report || "phoneType" in report) {
    return generateList(
      [
        {
          value: report.phoneType ? report.phoneType : null,
          label: "",
        },
        {
          value: report.carrier
            ? generateCarrierLogo(report.carrier, false)
            : null,
          label: "",
        },
      ],
      " | ",
    );
  } else if ("phoneReportSummary" in report) {
    return generateList(
      [
        {
          value: report.phoneReportSummary.phoneType
            ? report.phoneReportSummary.phoneType
            : null,
          label: "",
        },
        {
          value: report.phoneReportSummary.carrier
            ? generateCarrierLogo(report.phoneReportSummary.carrier, false)
            : null,
          label: "",
        },
      ],
      " | ",
    );
  }
};

export const generatePropertyReportHighlights = (
  report: PropertyReport | PropertyReportHistory,
) => {
  let reportDetails = report.propertyDetails
    ? (report.propertyDetails as PropertyDetails)
    : (report as PropertyReportHistory);

  return generateList(
    [
      {
        value:
          reportDetails.bedrooms && reportDetails.totalBaths
            ? reportDetails.bedrooms + "bd/" + reportDetails.totalBaths + "ba"
            : null,
        label: "",
      },
      {
        value: reportDetails?.buildingSquareFeet
          ? reportDetails.buildingSquareFeet + " sq ft"
          : null,
        label: "",
      },
      {
        value: reportDetails?.acres ? reportDetails.acres + " acre lot" : null,
        label: "",
      },
    ],
    " | ",
  );
};

export const generateNeighborhoodReportHighlights = (
  report: NeighborhoodReport | NeighborhoodReportHistory,
) => {
  return generateList(
    [
      {
        value: report?.offendersCount
          ? report.offendersCount + " Offenders Nearby"
          : null,
        label: "",
      },
      {
        value: "5 Mile Radius",
        label: "",
      },
    ],
    " | ",
  );
};
