import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";
import {
  pullPhoneReportAsync,
  selectPhoneReportStatus,
} from "../features/reports/reportsSlice";
import { useLocation } from "react-router-dom";

import { PullPhoneReportParams } from "../features/reports/reportTypes";
import { setPhoneSearch } from "../features/multisearch/multisearchSlice";

type UsePhoneReportHook = () => (phoneNumber: PullPhoneReportParams) => void;

/* Pull Phone Report */
const usePhoneReportDispatch: UsePhoneReportHook = () => {
  const dispatch = useAppDispatch();
  const reportStatus = useAppSelector(selectPhoneReportStatus);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const pullPhoneReport = (phoneNumber: PullPhoneReportParams) => {
    if (reportStatus === "loading") return;
    if (location.pathname !== "/phone-report") {
      pageNavigate("/phone-report");
    } else {
      window.scroll(0, 0);
    }
    dispatch(pullPhoneReportAsync(phoneNumber))
      .unwrap()
      .then(result => {
        if (result.success) {
          dispatch(setPhoneSearch(phoneNumber));
        }
      })
      .catch(error => {
        console.log(error.message);
        pageNavigate("/");
      });
  };
  return pullPhoneReport;
};

export { usePhoneReportDispatch };
