import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  Padding,
  Table,
  Text,
} from "@kidslivesafe/sc-react";

import {
  AutoReportType,
  JunkAndSalvageInformation,
  InsuranceInformation,
} from "../../../features/reports/reportTypes";
import {
  capFirstLetter,
  formatPhoneNumber,
  parseDate,
  renderArrayOfObjectsPrep,
} from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { generateList } from "@kidslivesafe/sc-foundation";

const JunkAndSalvageRecords: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const [junkRecords, setJunkRecords] = React.useState<
    JunkAndSalvageInformation[]
  >([]);
  const [insuranceRecords, setInsuranceRecords] = React.useState<
    InsuranceInformation[]
  >([]);

  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);

  // pulledReport = testData as AutoReportType; //TODO: remove this line

  const loading = autoReportStatus === "loading";

  const junkAndSalvageSummary = renderArrayOfObjectsPrep(
    pulledReport?.vehicleTitle?.junkAndSalvageInformation || [],
    [],
    [
      {
        key: ["vehicleObtainedDate"],
        valueFormat: (value: string) => parseDate(value, "utc:MM/DD/YYYY"),
      },
      {
        key: ["reportingEntityCategoryText"],
        keyFormat: () => "Reporting Entity",
      },
      {
        key: ["locationCityName"],
        keyFormat: () => "City",
      },
      {
        key: ["locationStateUSPostalServiceCode"],
        keyFormat: () => "State",
      },
      {
        key: ["telephoneNumberFullId"],
        keyFormat: () => "Phone Number",
        valueFormat: (value: string) => formatPhoneNumber(value),
      },
      {
        key: ["contactEmailId"],
        keyFormat: () => "Email",
      },
    ],
  );

  const insuranceSummary = renderArrayOfObjectsPrep(
    pulledReport?.vehicleTitle?.insuranceInformation || [],
    [],
    [
      {
        key: ["vehicleObtainedDate"],
        valueFormat: (value: string) => parseDate(value, "utc:MM/DD/YYYY"),
      },
      {
        key: ["reportingEntityCategoryText"],
        keyFormat: () => "Reporting Entity",
      },
      {
        key: ["locationCityName"],
        keyFormat: () => "City",
      },
      {
        key: ["locationStateUSPostalServiceCode"],
        keyFormat: () => "State",
      },
      {
        key: ["telephoneNumberFullId"],
        keyFormat: () => "Phone Number",
        valueFormat: (value: string) => formatPhoneNumber(value),
      },
      {
        key: ["contactEmailId"],
        keyFormat: () => "Email",
      },
    ],
  );

  const renderJunkAndSalvageTitle = (record: JunkAndSalvageInformation) => {
    if (record.vehicleDisposition !== "TO BE DETERMINED") {
      return `Junk and Salvage - ${record.vehicleDisposition}`;
    } else {
      return "Junk and Salvage";
    }
  };

  useEffect(() => {
    if (pulledReport && pulledReport.vehicleTitle) {
      setJunkRecords(
        pulledReport.vehicleTitle?.junkAndSalvageInformation || [],
      );
      setInsuranceRecords(
        pulledReport.vehicleTitle?.insuranceInformation || [],
      );
    }
  }, [pulledReport]);

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Junk & Salvage Records ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.vehicleTitle
                  ? pulledReport &&
                    pulledReport.vehicleSummary &&
                    (pulledReport.vehicleSummary.junkAndSalvageCount ||
                      pulledReport.vehicleSummary.junkAndSalvageCount === 0)
                    ? pulledReport.vehicleSummary.junkAndSalvageCount +
                      (pulledReport.vehicleSummary.insuranceCount ||
                      pulledReport.vehicleSummary.insuranceCount === 0
                        ? pulledReport.vehicleSummary.insuranceCount
                        : 0)
                    : 0
                  : 0
              })`
        }`}
      >
        <Padding space="md" bottom="none">
          <Text size="xs">Source: NMVTIS</Text>
        </Padding>
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            lineItemSubHeaderLine2
            numberToLoad={3}
            children
          />
        ) : (
          <>
            {(junkRecords && junkRecords.length > 0) ||
            (insuranceRecords && insuranceRecords.length > 0) ? (
              <CollapsableItemList numberToShow={5}>
                {junkRecords &&
                  junkRecords.length > 0 &&
                  junkRecords.map((record, index) => (
                    <ContainerLineItem
                      lineItemHeader={
                        <Layout
                          type="flex"
                          flexProps={{ gap: "xxs", alignItems: "center" }}
                        >
                          <Text>{renderJunkAndSalvageTitle(record)}</Text>
                          <Text color="error" tag="div">
                            <Icon name={IconList.IconWarning} size={18} />
                          </Text>
                        </Layout>
                      }
                      lineItemSubHeader={generateList(
                        [
                          {
                            value: record.vehicleObtainedDate
                              ? parseDate(record.vehicleObtainedDate, "year")
                              : null,
                            label: "",
                          },
                          {
                            value: record.reportingEntityAbstract
                              ? generateList(
                                  [
                                    {
                                      value:
                                        record.reportingEntityAbstract
                                          .locationCityName || null,
                                      label: "",
                                    },
                                    {
                                      value:
                                        record.reportingEntityAbstract
                                          .locationStateUSPostalServiceCode ||
                                        null,
                                      label: "",
                                    },
                                  ],
                                  ", ",
                                )
                              : null,
                            label: "",
                          },
                        ],
                        " | ",
                      )}
                      lineItemSubHeaderLine2={generateList(
                        [
                          {
                            value:
                              record.reportingEntityAbstract?.entityName ||
                              null,
                            label: "REPORTING ENTITY: ",
                          },
                        ],
                        "",
                      )}
                      isOpen={printReady ? true : false}
                      key={`${record.reportingEntityAbstract}_${record.vehicleDisposition}_${index}`}
                      lineItemSubheaderNoWrap
                    >
                      {junkAndSalvageSummary[index] ? (
                        <Table tableDataObject={junkAndSalvageSummary[index]} />
                      ) : null}
                    </ContainerLineItem>
                  ))}
                {insuranceRecords &&
                  insuranceRecords.length > 0 &&
                  insuranceRecords.map((record, index) => (
                    <ContainerLineItem
                      lineItemHeader={
                        <Layout
                          type="flex"
                          flexProps={{ gap: "xxs", alignItems: "center" }}
                        >
                          <Text>
                            {capFirstLetter(record.vehicleDisposition)}
                          </Text>
                          <Text color="error" tag="div">
                            <Icon name={IconList.IconWarning} size={18} />
                          </Text>
                        </Layout>
                      }
                      lineItemSubHeader={generateList(
                        [
                          {
                            value: record.vehicleObtainedDate
                              ? parseDate(record.vehicleObtainedDate, "year")
                              : null,
                            label: "",
                          },
                          {
                            value: record.reportingEntityAbstract
                              ? generateList(
                                  [
                                    {
                                      value:
                                        record.reportingEntityAbstract
                                          .locationCityName || null,
                                      label: "",
                                    },
                                    {
                                      value:
                                        record.reportingEntityAbstract
                                          .locationStateUSPostalServiceCode ||
                                        null,
                                      label: "",
                                    },
                                  ],
                                  ", ",
                                )
                              : null,
                            label: "",
                          },
                        ],
                        " | ",
                      )}
                      lineItemSubHeaderLine2={generateList(
                        [
                          {
                            value:
                              record.reportingEntityAbstract?.entityName ||
                              null,
                            label: "REPORTING ENTITY: ",
                          },
                        ],
                        "",
                      )}
                      isOpen={printReady ? true : false}
                      key={`${record.reportingEntityAbstract}_${record.vehicleDisposition}_${index}`}
                      lineItemSubheaderNoWrap
                    >
                      {insuranceSummary[index] ? (
                        <Table tableDataObject={insuranceSummary[index]} />
                      ) : null}
                    </ContainerLineItem>
                  ))}
              </CollapsableItemList>
            ) : (
              <ContainerLineItem
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    <Text color="success" tag="div">
                      <Icon name={IconList.IconCircleCheck} size={18} />
                    </Text>
                    <Text>0 Junk or Salvage Records Found</Text>
                  </Layout>
                }
              />
            )}
          </>
        )}
        <Container altColor="dark-grey">
          <Padding space="md">
            <Text size="xxs" tag="i" fontWeight="bold">
              Explanatory Note:
            </Text>
            <Text size="xxs">
              If this VIN has a record in the{" "}
              <Text tag="b" size="xxs">
                Junk/Salvage or Insurance information
              </Text>{" "}
              then the business that submitted the VIN to NMVTIS deemed the
              vehicle to be either a{" "}
              <Text tag="i" size="xxs">
                junk, salvage, or in the case of an insurer, a total loss
              </Text>
              . The information in the{" "}
              <Text tag="b" size="xxs">
                DISPOSITION
              </Text>{" "}
              field in the Junk/Salvage section denotes what has happened to the
              VIN (i.e., vehicle) since it came into the possession of the
              business.
            </Text>
          </Padding>
        </Container>
      </Container>
    </Padding>
  );
};

export default JunkAndSalvageRecords;
