import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useAutoReportDispatch } from "../../hooks/autoReportHook";

interface TESTPullAutoReportProps {}

const TestPullAutoReport: React.FC<TESTPullAutoReportProps> = () => {
  const pullAutoReport = useAutoReportDispatch();
  const { vin } = useParams();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    if (vin && !hasFetchedReport.current) {
      pullAutoReport({ vin: vin });
      hasFetchedReport.current = true;
    }
  }, [vin, pullAutoReport]);

  return <div>Pulling Report</div>;
};

export default TestPullAutoReport;
