import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
  fullCapsText,
} from "../../../utils";

// types
import { PropertyUccFiling } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Padding,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

// pull report hook
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

const UCCFilings: React.FC<{ containerId: string; printReady?: boolean }> = ({
  containerId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";
  const pullPeopleReport = usePeopleReportDispatch();

  /* generate uccFiling Object */
  const uccFilingObjects = renderArrayOfObjectsPrep(
    pulledReport?.uccFilings || [],
    [],
    [
      {
        key: ["debtorsDetails"],
        keyFormat: () => {
          return "Debtors";
        },
        valueFormat: (value, currentObject: PropertyUccFiling) => {
          const jsxElements = [];

          for (let i = 0; i < currentObject.debtorsDetails.length; i++) {
            const debtor = currentObject.debtorsDetails[i];
            jsxElements.push(
              debtor.reportToken && debtor.fullName ? (
                <ReportLink<PullPeopleReportParams>
                  key={`debtor-${i}`}
                  inputValue={debtor.reportToken}
                  handleClick={pullPeopleReport}
                  text={debtor.fullName}
                  smallText
                />
              ) : debtor.fullName ? (
                <Text
                  passThroughClassess="sc-table-row__detail"
                  key={`debtor-${i}`}
                  fontWeight="bold"
                  size="sm"
                >
                  {debtor.fullName}
                </Text>
              ) : debtor.businessName ? (
                <Text
                  passThroughClassess="sc-table-row__detail"
                  key={`debtor-${i}`}
                  fontWeight="bold"
                  size="sm"
                >
                  {debtor.businessName}
                </Text>
              ) : null,
            );
          }

          return <>{jsxElements}</>;
        },
      },
      {
        key: ["securedPartiesAddress"],
        valueFormat: (value, currentObject: PropertyUccFiling) => {
          const secureAddress =
            currentObject.securedPartiesAddress &&
            currentObject.securedPartiesAddress.length > 0
              ? currentObject.securedPartiesAddress[0]
              : null;

          return (
            <>
              <Text fontWeight="bold" size="sm">
                {secureAddress}
              </Text>
            </>
          );
        },
      },
      {
        key: ["securedPartiesBusinessName"],
        valueFormat: (value, currentObject: PropertyUccFiling) => {
          const secureAddress =
            currentObject.securedPartiesBusinessName &&
            currentObject.securedPartiesBusinessName.length > 0
              ? currentObject.securedPartiesBusinessName[0]
              : null;

          return (
            <>
              <Text fontWeight="bold" size="sm">
                {secureAddress}
              </Text>
            </>
          );
        },
      },
      {
        key: ["securedPartiesName"],
        valueFormat: (value, currentObject: PropertyUccFiling) => {
          const secureAddress =
            currentObject.securedPartiesName &&
            currentObject.securedPartiesName.length > 0
              ? currentObject.securedPartiesName[0]
              : null;

          return (
            <>
              <Text fontWeight="bold" size="sm">
                {secureAddress}
              </Text>
            </>
          );
        },
      },
    ],
  );
  return (
    <>
      {pulledReport?.uccFilings && pulledReport.uccFilings[0] ? (
        <Padding
          left="md"
          right="md"
          breakpointStyles={{
            desktop: {
              space: "none",
            },
          }}
        >
          <Container
            containerTitle={`UCC Filings at Address ${
              loading
                ? ""
                : `(${
                    pulledReport.uccFilings ? pulledReport.uccFilings.length : 0
                  })`
            }`}
            containerId={containerId}
            tooltipContent="UCC Filings are legal documents submitted to state authorities to secure interest in personal property as collateral for a debt. Secured parties, debtors, and collateral details related to this property may be listed in this section."
            newInfo={reportDifferences?.uccFilings}
          >
            {loading ? (
              <LoadingContainerLineItem
                lineItemHeader
                lineItemSubHeader
                numberToLoad={5}
                children
              />
            ) : (
              <CollapsableItemList numberToShow={5} isOpen={printReady}>
                {pulledReport.uccFilings.map((uccFile, index) => (
                  <ContainerLineItem
                    lineItemHeader={`UCC FILING - ${
                      uccFile.filingType
                        ? fullCapsText(uccFile.filingType)
                        : null
                    }`}
                    lineItemSubHeader={generateList(
                      [
                        {
                          value: uccFile.filingDate
                            ? parseDate(uccFile.filingDate, "year")
                            : null,
                          label: "",
                        },
                        {
                          value: uccFile.securedPartiesName
                            ? uccFile.securedPartiesName
                            : null,
                          label: "",
                        },
                      ],
                      " | ",
                    )}
                    isOpen={printReady ? true : false}
                    key={index}
                  >
                    {uccFilingObjects[index] ? (
                      <Padding space="md">
                        <Table
                          orderOfProperties={[]}
                          tableDataObject={uccFilingObjects[index]}
                        />
                      </Padding>
                    ) : null}
                  </ContainerLineItem>
                ))}
              </CollapsableItemList>
            )}
          </Container>
        </Padding>
      ) : null}
    </>
  );
};

export default UCCFilings;
