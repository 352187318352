import React, { useEffect, memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

// components
import {
  Container,
  Layout,
  Text,
  Map,
  LoadingPlaceholder,
  Icon,
  IconList,
  Padding,
  Button,
} from "@kidslivesafe/sc-react";

// redux getters
import {
  selectNeighborhoodReport,
  selectNeighborhoodReportStatus,
  setNeighborhoodMapModal,
  setNeighborhoodMapModalContent,
} from "../../../features/reports/reportsSlice";
import { selectMapboxKey } from "../../../features/brand/brandSlice";

// utils

// types
import {
  NeighborhoodReport,
  Offender,
} from "../../../features/reports/reportTypes";
import { formatAddress } from "../../../utils";

interface PinData {
  latitude: number;
  longitude: number;
  popup: React.ReactNode;
  element?: React.ReactNode;
}

const MapOfNeighborhood: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectNeighborhoodReport);
  const neighborhoodWatchReport = useAppSelector(
    selectNeighborhoodReportStatus,
  );
  const [mapPins, setMapPins] = React.useState<PinData[]>([]);
  React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const loading = neighborhoodWatchReport === "loading";

  const mapboxToken = useAppSelector(selectMapboxKey);

  useEffect(() => {
    /* Map Prep */
    const prepareAddressPinData = (report: NeighborhoodReport) => {
      const pinData = [
        {
          latitude: Number(report.searchLatitude),
          longitude: Number(report.searchLongitude),
          popup: (
            <Layout type="flex" flexProps={{ direction: "column" }}>
              <Text fontWeight="bold" size="sm">
                {report.addressSearch}
              </Text>
            </Layout>
          ),
          element: (
            <Text tag="div" color="accent">
              <Icon name={IconList.IconPropertyPin} size={21} />
            </Text>
          ),
        },
      ];
      return pinData;
    };

    const prepareOffenderPinData = (offenders: Offender[]) => {
      const pinData = offenders
        .map((offender, index) => {
          const latitude = Number(offender.latitude);
          const longitude = Number(offender.longitude);

          // Check if the coordinates are valid within the US, and if so, return the pin data
          if (
            !isNaN(latitude) &&
            !isNaN(longitude) &&
            latitude !== 0 &&
            longitude !== 0 &&
            latitude >= 24.396308 &&
            latitude <= 49.384358 &&
            longitude >= -125.0 &&
            longitude <= -66.93457
          ) {
            return {
              latitude: latitude,
              longitude: longitude,
              popup: (
                <Layout type="flex" flexProps={{ direction: "row", gap: "xs" }}>
                  <img
                    src={`https://photo.familywatchdog.us/OffenderPhoto/OffenderPhoto.aspx?id=${offender.offenderID}`}
                    alt={`picture_${offender.name}`}
                    height={77}
                    width={61.8}
                  />
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      alignItems: "flex-start",
                    }}
                    passThroughClassess="page-neighborhood-report__popup-container"
                  >
                    <Text fontWeight="bold" size="sm">
                      {offender.name.split(", ").reverse().join(" ")}
                    </Text>
                    <Text
                      size="xxs"
                      passThroughClassess="page-neighborhood-report__popup-overflow"
                    >
                      {offender.city}, {offender.state} {offender.zipCode}
                    </Text>
                    <Text
                      size="xxs"
                      passThroughClassess="page-neighborhood-report__popup-overflow"
                    >
                      {offender.offense}
                    </Text>
                    <Padding top="xxs">
                      <Button
                        buttonText={"View Details"}
                        handleClick={() => {
                          dispatch(
                            setNeighborhoodMapModalContent({ offender, index }),
                          );
                          dispatch(setNeighborhoodMapModal(true));
                        }}
                        color="secondary"
                        size="xs"
                      />
                    </Padding>
                  </Layout>
                </Layout>
              ),
            };
          } else {
            // Skip this entry if the coordinates are invalid
            return null;
          }
        })
        // Remove null entries
        .filter((pin): pin is Exclude<typeof pin, null> => pin !== null);
      return pinData;
    };
    if (pulledReport) {
      if (pulledReport.addressSearch) {
        setMapPins([
          ...prepareAddressPinData(pulledReport),
          ...prepareOffenderPinData(pulledReport.offenders),
        ]);
      }
    }
  }, [pulledReport, dispatch]);

  return (
    <>
      <>
        <Container
          containerTitle={`Map of Neighborhood ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.offenders
                    ? pulledReport.offenders.length
                    : 0
                })`
          }`}
          containerId={containerId}
          tooltipContent="This interactive map displays the location of each registered offender. Click the pins to see complete offender details including offenses, mugshots and physical characteristics."
        >
          <Padding top="md" bottom="md" left="sm" right="sm">
            {" "}
            <Layout type="flex" flexProps={{ direction: "column", gap: "xxs" }}>
              <Layout
                type="flex"
                flexProps={{ gap: "xs", alignItems: "center" }}
              >
                <Text color="accent">
                  <Icon name={IconList.IconPropertyPin} size={27}></Icon>
                </Text>
                <Text size="base" fontWeight="bold">
                  {pulledReport && pulledReport.addressObject.address
                    ? pulledReport.addressObject.address
                    : formatAddress({
                        city: pulledReport?.addressObject.city,
                        state: pulledReport?.addressObject.state,
                        zip: pulledReport?.addressObject.zipCode,
                      })}
                </Text>
              </Layout>
              {pulledReport && pulledReport.offendersCount ? (
                <Layout
                  type="flex"
                  flexProps={{ justifyContent: "space-between" }}
                >
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xs", alignItems: "center" }}
                  >
                    <Text color="primary">
                      <Icon name={IconList.IconPin} size={27}></Icon>
                    </Text>
                    <Text size="sm">{`${pulledReport.offendersCount} offenders found within a 5 mile radius`}</Text>
                  </Layout>
                </Layout>
              ) : null}
            </Layout>
          </Padding>
          {loading ? (
            <LoadingPlaceholder width="100%" height="500px" />
          ) : (
            pulledReport &&
            pulledReport.offenders &&
            pulledReport.offenders.length > 0 &&
            !printReady && (
              <Map
                mapboxToken={mapboxToken}
                markerData={pulledReport.offenders ? mapPins : undefined}
                center={
                  pulledReport.offenders &&
                  !isNaN(Number(pulledReport.offenders[0].latitude)) &&
                  !isNaN(Number(pulledReport.offenders[0].longitude)) &&
                  Number(pulledReport.offenders[0].latitude) !== 0 &&
                  Number(pulledReport.offenders[0].longitude) !== 0
                    ? [
                        Number(pulledReport.offenders[0].longitude),
                        Number(pulledReport.offenders[0].latitude),
                      ]
                    : undefined
                }
              />
            )
          )}
        </Container>
      </>
    </>
  );
};

export default memo(MapOfNeighborhood);
