import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useNeighborhoodReportDispatch } from "../../hooks/neighborhoodReportHook";

interface TESTPullNeighborhoodReportProps {}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TestPullNeighborhoodReport: React.FC<
  TESTPullNeighborhoodReportProps
> = () => {
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();
  const query = useQuery();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    const addressSearch = {
      address: query.get("addressLine1")!,
      addressLine2: query.get("addressLine2"),
      state: query.get("state"),
      city: query.get("city"),
      zipCode: query.get("zip"),
    };

    if (addressSearch && !hasFetchedReport.current) {
      pullNeighborhoodReport({
        addressSearch: addressSearch,
        searchMethod: "address",
      });
      hasFetchedReport.current = true;
    }
  }, [query, pullNeighborhoodReport]);

  return <div>Pulling Report</div>;
};

export default TestPullNeighborhoodReport;
