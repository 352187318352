import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";
import {
  searchSocialAsync,
  selectSocialSearchStatus,
} from "../features/reports/reportsSlice";
import { setSocialSearch } from "../features/multisearch/multisearchSlice";

export type SearchSocialParams = string;

type UseSocialSearchHook = () => (search: SearchSocialParams) => void;

/* Social Search */
const useSocialSearchDispatch: UseSocialSearchHook = () => {
  const dispatch = useAppDispatch();
  const socialSearchStatus = useAppSelector(selectSocialSearchStatus);
  const pageNavigate = useHandlePageNavigate();

  const searchSocial = (search: SearchSocialParams) => {
    if (socialSearchStatus === "loading") return;
    pageNavigate("/social-report");
    dispatch(searchSocialAsync({ search: search }))
      .unwrap()
      .then(result => {
        if (result.success) {
          dispatch(setSocialSearch(search));
        }
      })
      .catch(error => {
        console.log(error.message);
        pageNavigate("/");
      });
  };

  return searchSocial;
};

export { useSocialSearchDispatch };
