import React, { useState, useEffect, useRef, useMemo } from "react";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";

import "./print.css"; // used for print styling .page-break could be added
import "./auto-report.css";

import nmvtisLogo from "../../../assets/global/images/auto-report_nmvtis_logo.png";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Padding,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
  Container,
  Text,
  AnchorLink,
} from "@kidslivesafe/sc-react";

import VehicleImages from "./VehicleImages";
import TitleRecordHistory from "./TitleRecordHistory";
import TitleBrandsReported from "./TitleBrandsReported";
import JunkAndSalvageRecords from "./JunkAndSalvageRecords";
import Warranties from "./Warranties";
import Overview from "./Overview";

import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";

import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";
import { parseDate, renderArrayOfObjectsPrep } from "../../../utils";
import {
  AutoReportType,
  VehicleDetails,
} from "../../../features/reports/reportTypes";
import VehicleSpecs from "./VehicleSpecs";
import { generateList } from "@kidslivesafe/sc-foundation";
import { selectAutoSearchParams } from "../../../features/multisearch/multisearchSlice";
import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";
import NoResults from "../../../components/UIComponents/NoResults/NoResults";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";

const AutoReport: React.FC = () => {
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);

  const [printReady, setPrintReady] = useState<boolean>(false);

  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const dispatch = useAppDispatch();

  const multiSearchProps = useMultiSearchProps(
    {
      footer: false,
      resetInput: true,
      buttonText: "NEW SEARCH",
      tabs: ["auto"],
    },
    "auto-report",
  );

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  // pulledReport = testData as AutoReportType; //TODO: remove this line

  const autoReportStatus = useAppSelector(selectAutoReportStatus);
  const autoSearch = useAppSelector(selectAutoSearchParams);
  const brandInfo = useAppSelector(selectBrandInfo);
  const loading = autoReportStatus === "loading";

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Auto Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport &&
            pulledReport.vehicleSummary &&
            pulledReport.vehicleSummary.vehicleDescription
              ? pulledReport.vehicleSummary.vehicleDescription.toUpperCase()
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport &&
          pulledReport.vehicleSummary &&
          pulledReport.vehicleSummary.vehicleDescription
            ? `${originalTitle} - ${pulledReport.vehicleSummary.vehicleDescription}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-container-line-item__content--closed",
          );
          if (elements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  // constants
  const overviewId = "auto-overview";
  const vehicleImagesId = "vehicle-images";
  const titleHistoryId = "title-history";
  const titleBrandsReportedId = "title-brands-reported";
  const junkAndSalvageId = "junk-and-salvage";
  const warrantiesId = "warranties";
  const vehicleSpecsId = "vehicle-specs";

  // memoize the vehicleSpecsSummary
  const vehicleSpecsSummary = useMemo(
    () =>
      renderArrayOfObjectsPrep(
        [pulledReport?.vehicleDetails || []],
        [],
        [
          {
            key: ["interiorTrim"],
            valueFormat: (value, currentObject: VehicleDetails) => {
              if (
                !currentObject.interiorTrim ||
                currentObject.interiorTrim.every(trim => trim === "")
              ) {
                return null;
              }
              const jsxElements = currentObject.interiorTrim.map((trim, i) => (
                <Text key={`${trim}-${i}`} tag="b" size="sm">
                  {trim}
                </Text>
              ));
              return <>{jsxElements}</>;
            },
          },
          {
            key: ["exteriorColor"],
            valueFormat: (value, currentObject: VehicleDetails) => {
              if (
                !currentObject.exteriorColor ||
                currentObject.exteriorColor.every(color => color === "")
              ) {
                return null;
              }
              const jsxElements = currentObject.exteriorColor.map(
                (color, i) => (
                  <Text key={`${color}-${i}`} tag="b" size="sm">
                    {color}
                  </Text>
                ),
              );
              return <>{jsxElements}</>;
            },
          },
        ],
      ),
    [pulledReport],
  );

  // effects
  useEffect(() => {
    setNavigateProps([
      {
        navigateToSectionId: vehicleImagesId,
        navigateToSectionText: "Vehicle Images",
        itemCount:
          pulledReport && pulledReport.images ? pulledReport.images?.length : 0,
        loading: loading,
      },
      {
        navigateToSectionId: titleHistoryId,
        navigateToSectionText: "Title History",
        itemCount:
          pulledReport &&
          pulledReport.vehicleSummary &&
          pulledReport.vehicleSummary.titleCount
            ? pulledReport.vehicleSummary.titleCount
            : 0,
        loading: loading,
      },
      {
        navigateToSectionId: titleBrandsReportedId,
        navigateToSectionText: "Title Brands Reported",
        itemCount:
          pulledReport &&
          pulledReport.vehicleSummary &&
          pulledReport.vehicleSummary.titleBrandCount
            ? pulledReport.vehicleSummary.titleBrandCount
            : 0,
        loading: loading,
      },
      {
        navigateToSectionId: junkAndSalvageId,
        navigateToSectionText: "Junk and Salvage",
        itemCount:
          pulledReport &&
          pulledReport.vehicleSummary &&
          (pulledReport.vehicleSummary.junkAndSalvageCount ||
            pulledReport.vehicleSummary.junkAndSalvageCount === 0)
            ? pulledReport.vehicleSummary.junkAndSalvageCount +
              (pulledReport.vehicleSummary.insuranceCount ||
              pulledReport.vehicleSummary.insuranceCount === 0
                ? pulledReport.vehicleSummary.insuranceCount
                : 0)
            : 0,
        loading: loading,
      },
      {
        navigateToSectionId: warrantiesId,
        navigateToSectionText: "Warranties",
        itemCount:
          pulledReport &&
          pulledReport.vehicleSummary &&
          pulledReport.vehicleSummary.warrantyCount
            ? pulledReport.vehicleSummary.warrantyCount
            : 0,
        loading: loading,
      },
      {
        navigateToSectionId: vehicleSpecsId,
        navigateToSectionText: "Vehicle Specs",
        itemCount:
          pulledReport && vehicleSpecsSummary[0]
            ? Object.keys(vehicleSpecsSummary[0]).length
            : 0,
        loading: loading,
      },
    ]);
  }, [pulledReport, loading, vehicleSpecsSummary]);

  return (
    <>
      {pulledReport || loading ? (
        <PrintableComponent
          ref={contentToPrint}
          printReady={printReady}
          reportType="auto"
          header={
            pulledReport &&
            pulledReport.vehicleSummary.vehicleDescription &&
            pulledReport.vehicleSummary.vehicleDescription.toUpperCase()
          }
          subtitle={generateList(
            [
              {
                value:
                  pulledReport &&
                  pulledReport.vehicleSummary &&
                  pulledReport.vehicleSummary.vin
                    ? pulledReport.vehicleSummary.vin
                    : undefined,
                label: "",
              },
              {
                value:
                  pulledReport &&
                  pulledReport.vehicleSummary &&
                  pulledReport.vehicleSummary.titleCount
                    ? `${pulledReport.vehicleSummary.titleCount} Title Record${
                        pulledReport.vehicleSummary.titleCount === 1 ? "" : "s"
                      }`
                    : undefined,
                label: "",
              },
            ],
            " | ",
          )}
          date={
            pulledReport &&
            pulledReport.vehicleSummary &&
            pulledReport.vehicleSummary.score
          }
        >
          <ReportTitle
            reportTitle="Auto Report"
            reportType="auto"
            handleDownload={
              loading
                ? () => {
                    console.log("Wait for loading");
                  }
                : () => {
                    sendMemberEvent();
                    handlePrint(null, () => null);
                  }
            }
          />
          <Sheet>
            <Padding
              space="md"
              top="none"
              breakpointStyles={{
                mobile: {
                  space: "none",
                  bottom: "md",
                },
              }}
            >
              <Layout
                type="grid"
                gridProps={{ gap: "md", templateColumns: "1-column" }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { templateColumns: componentFormat, gap: "md" },
                  },
                }}
                shrinkChildren
              >
                <>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "md", direction: "column" }}
                    passThroughClassess="page-people-report__left-column"
                  >
                    <Sticky
                      space="sm"
                      turnOffAtBreakPoint={["mobile"]}
                      direction="header-offset"
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <ReportSummary
                          reportDate={
                            loading ? (
                              <LoadingPlaceholder
                                height={"16px"}
                                width={"10rem"}
                              />
                            ) : pulledReport ? (
                              `Report Date ${parseDate(
                                pulledReport.vehicleSummary.score,
                                "MM/DD/YYYY",
                              )}`
                            ) : (
                              ""
                            )
                          }
                          reportTitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"25px"}
                                width={"15rem"}
                              />
                            ) : pulledReport &&
                              pulledReport.vehicleSummary &&
                              pulledReport.vehicleSummary.vehicleDescription ? (
                              pulledReport.vehicleSummary.vehicleDescription.toUpperCase()
                            ) : (
                              ""
                            )
                          }
                          reportSubtitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"14px"}
                                width={"10rem"}
                              />
                            ) : pulledReport && pulledReport.vehicleSummary ? (
                              pulledReport.vehicleSummary.vin
                            ) : (
                              ""
                            )
                          }
                          reportHighlights={
                            loading ? (
                              <LoadingPlaceholder
                                height={"18px"}
                                width={"12rem"}
                              />
                            ) : pulledReport ? (
                              generateList(
                                [
                                  {
                                    value: pulledReport.vehicleSummary
                                      .titleCount
                                      ? `${
                                          pulledReport.vehicleSummary.titleCount
                                        } Title Record${
                                          pulledReport.vehicleSummary
                                            .titleCount === 1
                                            ? ""
                                            : "s"
                                        }`
                                      : undefined,
                                    label: "",
                                  },
                                  {
                                    value: pulledReport.vehicleSummary.msrp
                                      ? pulledReport.vehicleSummary.msrp
                                      : undefined,
                                    label: "",
                                  },
                                ],
                                " | ",
                              )
                            ) : null
                          }
                          reportDetails={
                            loading ? (
                              <LoadingPlaceholder
                                height={"24px"}
                                width={"10rem"}
                              />
                            ) : pulledReport && pulledReport.vehicleSummary ? (
                              pulledReport.vehicleSummary.lastKnownMileage
                            ) : null
                          }
                        />
                        <Padding
                          space="none"
                          breakpointStyles={{
                            mobile: {
                              left: "md",
                              right: "md",
                            },
                          }}
                        >
                          <NavigateToSection
                            items={navigateProps}
                            tooltipContent={`The ${brandInfo.name} Auto Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                          />
                        </Padding>
                        <Container altColor="dark-grey">
                          <Padding space="md">
                            <Text size="xxs">
                              This vehicle history report is based on
                              information supplied to us and available as of 
                              {pulledReport && pulledReport.vehicleSummary
                                ? parseDate(
                                    pulledReport.vehicleSummary.score,
                                    "MM/DD/YYYY at HH:mm",
                                  )
                                : null}
                              . Other information about this vehicle, such as
                              issues relating to title, theft, damage or
                              otherwise may not have been reported to us, so you
                              should strongly consider a vehicle inspection,
                              test drive and formal title search to make a more
                              informed decision concerning this vehicle before
                              proceeding.
                            </Text>
                          </Padding>
                        </Container>
                      </Layout>
                    </Sticky>
                  </Layout>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    {/* auto report sections */}
                    <Overview
                      containerId={overviewId}
                      printReady={printReady}
                    />

                    <VehicleImages
                      containerId={vehicleImagesId}
                      printReady={printReady}
                    />

                    <TitleRecordHistory
                      containerId={titleHistoryId}
                      printReady={printReady}
                    />

                    <TitleBrandsReported
                      containerId={titleBrandsReportedId}
                      printReady={printReady}
                    />

                    <JunkAndSalvageRecords
                      containerId={junkAndSalvageId}
                      printReady={printReady}
                    />

                    <Warranties
                      containerId={warrantiesId}
                      printReady={printReady}
                    />

                    <VehicleSpecs
                      containerId={vehicleSpecsId}
                      printReady={printReady}
                    />

                    {/* end of auto report sections */}
                  </Layout>
                </>
              </Layout>
            </Padding>

            <Padding
              space="md"
              top="none"
              breakpointStyles={{
                mobile: {
                  space: "none",
                  bottom: "md",
                },
              }}
            >
              <Container>
                <Padding space="md">
                  <Layout
                    type="flex"
                    flexProps={{ gap: "sm", direction: "column" }}
                  >
                    <Text fontWeight="bold">Report Disclaimer</Text>
                    <Layout
                      type="flex"
                      flexProps={{ gap: "md", direction: "column" }}
                    >
                      <Text size="xs">
                        This report does not include comprehensive details on
                        the history of vehicles or data on all motor vehicles in
                        the United States, as some states have not yet
                        contributed their data to the system. The frequency of
                        data updates varies by state, with some providing
                        information in real-time as title transactions occur,
                        while others do so less frequently.
                      </Text>

                      <Text size="xs">
                        Not all significant previous vehicle damages may be
                        recorded in the system, particularly if a vehicle has
                        not been declared a "total loss" by an insurance company
                        or branded by a state titling agency.
                      </Text>

                      <Text size="xs">
                        Please note that a vehicle history report is not a
                        replacement for an independent vehicle inspection. We
                        strongly recommend that potential buyers obtain such an
                        inspection to discover any potential hidden damages
                        before purchasing a vehicle.
                      </Text>

                      <Text size="xs">
                        The report may not include information on commercial
                        vehicles if they are not part of a state's primary
                        database for title records. In some states, these
                        vehicles are managed by separate state agencies, but
                        such records might be included in future updates. The
                        definitions and types of brands vary from state to state
                        and can offer insights into a vehicle's condition or
                        previous usage.
                      </Text>

                      <Text size="xs">
                        The data in this report is collected from various
                        governmental agencies, non-profit organizations, and
                        industry sources. The accuracy and reliability of this
                        information depend largely on these sources, and all
                        parties involved in creating this report disclaim any
                        liability for possible errors or omissions.
                      </Text>
                    </Layout>
                  </Layout>
                </Padding>
                <Padding space="md" top="none">
                  <Layout
                    type="flex"
                    flexProps={{ gap: "sm", direction: "column" }}
                  >
                    <img src={nmvtisLogo} alt="nmvtis logo" width={119} />
                    <Text fontWeight="bold">NMVTIS Disclaimer</Text>
                    <Layout
                      type="flex"
                      flexProps={{ gap: "md", direction: "column" }}
                    >
                      <Text size="xs">
                        The National Motor Vehicle Title Information System
                        (NMVTIS) is an electronic system that contains
                        information on certain automobiles titled in the United
                        States. NMVTIS is intended to serve as a reliable source
                        of title and{" "}
                        <AnchorLink
                          inline
                          size="xs"
                          href="https://vehiclehistory.bja.ojp.gov/nmvtis_glossary"
                        >
                          brand
                        </AnchorLink>{" "}
                        history for automobiles, but it does not contain
                        detailed information regarding a vehicle's repair
                        history.
                      </Text>

                      <Text size="xs">
                        All states, insurance companies, and junk and salvage
                        yards are required by federal law to regularly report
                        information to NMVTIS. However, NMVTIS does not contain
                        information on all motor vehicles in the United States
                        because{" "}
                        <AnchorLink
                          inline
                          href="https://vehiclehistory.bja.ojp.gov/nmvtis_states"
                          size="xs"
                        >
                          some states
                        </AnchorLink>{" "}
                        are not yet providing their vehicle data to the system.
                        Currently, the data provided to NMVTIS by states is
                        provided in a variety of time frames; while some states
                        report and update NMVTIS data in "real-time" (as title
                        transactions occur), other states send updates less
                        frequently, such as once every 24 hours or within a
                        period of days.
                      </Text>

                      <Text size="xs">
                        Information on previous, significant vehicle damage may
                        not be included in the system if the vehicle was never
                        determined by an insurance company (or other appropriate
                        entity) to be a "total loss" or branded by a state
                        titling agency. Conversely, an insurance carrier may be
                        required to report a "total loss" even if the vehicle's
                        titling-state has not determined the vehicle to be
                        "salvage" or "junk."
                      </Text>

                      <Text size="xs">
                        A vehicle history report is{" "}
                        <Text tag="u" size="xs">
                          NOT
                        </Text>{" "}
                        a substitute for an independent vehicle inspection.
                        Before making a decision to purchase a vehicle,
                        consumers are 
                        <Text tag="b" size="xs">
                          strongly encouraged to also obtain an independent
                          vehicle inspection
                        </Text>
                         to ensure the vehicle does not have hidden damage. The{" "}
                        <AnchorLink
                          href="https://vehiclehistory.bja.ojp.gov/nmvtis_vehiclehistory"
                          inline
                          size="xs"
                        >
                          Approved NMVTIS Data Providers
                        </AnchorLink>{" "}
                        (look for the NMVTIS logo) can include vehicle condition
                        data from sources other than NMVTIS.
                      </Text>

                      <Text size="xs">Consumer Access Product Disclaimer</Text>
                      <Text size="xs" tag="div">
                        NMVTIS data 
                        <Text tag="u" fontWeight="bold" size="xs">
                          INCLUDES
                        </Text>{" "}
                        (as available by those entities required to report to
                        the System):
                        <ul>
                          <li>
                            Information from{" "}
                            <AnchorLink
                              href="https://vehiclehistory.bja.ojp.gov/nmvtis_states"
                              inline
                              size="xs"
                            >
                              participating
                            </AnchorLink>{" "}
                            state motor vehicle titling agencies.
                          </li>
                          <li>
                            Information on automobiles, buses, trucks,
                            motorcycles, recreational vehicles, motor homes, and
                            tractors. NMVTIS may not currently include
                            commercial vehicles if those vehicles are not
                            included in a state's primary database for title
                            records (in some states, those vehicles are managed
                            by a separate state agency), although these records
                            may be added at a later time.
                          </li>
                          <li>
                            Information on "brands" applied to vehicles provided
                            by participating state motor vehicle titling
                            agencies. Brand types and definitions vary by state,
                            but may provide useful information about the
                            condition or prior use of the vehicle.
                          </li>
                          <li>
                            Most recent odometer reading in the state's title
                            record.
                          </li>
                          <li>
                            Information from insurance companies, and auto
                            recyclers, including junk and salvage yards, that is
                            required by law to be reported to the system,
                            beginning March 31, 2009. This information will
                            include if the vehicle was determined to be a "total
                            loss" by an insurance carrier.
                          </li>
                          <li>
                            Information from junk and salvage yards receiving a
                            "cash for clunker" vehicle traded-in under the
                            Consumer Assistance to Recycle and Save Act of 2009
                            (CARS) Program.
                          </li>
                        </ul>
                      </Text>
                      <Text size="xs">
                        Consumers are advised to visit{" "}
                        <AnchorLink
                          inline
                          size="xs"
                          href="https://vehiclehistory.bja.ojp.gov/"
                        >
                          vehiclehistory.bja.ojp.gov
                        </AnchorLink>{" "}
                        for details on how to interpret the information in the
                        system and understand the meaning of various labels
                        applied to vehicles by the participating state motor
                        vehicle titling agencies.
                      </Text>
                    </Layout>
                  </Layout>
                </Padding>
              </Container>
            </Padding>
          </Sheet>
        </PrintableComponent>
      ) : (
        <>
          <ReportTitle reportTitle="Auto Report" reportType="auto" />
          <NoResults
            searchType="auto"
            header={
              <>
                {" "}
                <Text textAlign="center" fontWeight="bold" size="xl">
                  We are unable to find information on the VIN
                  {autoSearch?.vin ? " - " + autoSearch.vin : ""}
                  {"."}
                  <br></br>
                  Please try another search.
                </Text>
              </>
            }
            subHeader={
              <Text textAlign="center">
                We’ve run searches and found no data for this input. In some
                cases this may be due to privacy preferences or regulations.
              </Text>
            }
            showMultiSearch
            multiSearchProps={multiSearchProps}
          />
        </>
      )}
    </>
  );
};

export default AutoReport;
