import React from "react";

import {
  Color,
  Padding,
  Layout,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Icon,
  IconList,
  Text,
} from "@kidslivesafe/sc-react";

interface ConfidenceRatingProps {
  numberOfColored: number;
  mapArray?: number[];
  maxScore: number;
  toolTipContent: string;
  iconType: IconList;
}

const ConfidenceRating: React.FC<ConfidenceRatingProps> = ({
  numberOfColored,
  mapArray = [1, 2, 3, 4, 5],
  maxScore,
  toolTipContent,
  iconType,
}) => {
  return (
    <>
      {numberOfColored > 0 ? (
        <Padding top="xs">
          <Layout
            type="flex"
            flexProps={{
              gap: "xxs",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Layout
              type="flex"
              flexProps={{
                gap: "xxs",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text size="xs">Confidence:</Text>
              <Tooltip padding={8}>
                <TooltipTrigger className="sc-tooltip__trigger">
                  <Icon name={iconType} iconColor="#1B5299" size={11} />
                </TooltipTrigger>
                <TooltipContent className="sc-tooltip__content">
                  {toolTipContent}
                </TooltipContent>
              </Tooltip>
            </Layout>
            <Layout
              type="flex"
              flexProps={{
                gap: "xxxs",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Layout
                type="flex"
                flexProps={{
                  gap: "xs",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text size="xs">{`${maxScore}%`}</Text>
                <div className="score">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {mapArray.map(i => (
                      <Color
                        key={i}
                        width="md"
                        height="xs"
                        hexCode={i <= numberOfColored ? "#44A54D" : "#DFDEDC"}
                      />
                    ))}
                  </Layout>
                </div>
              </Layout>
            </Layout>
          </Layout>
        </Padding>
      ) : null}
    </>
  );
};

export default ConfidenceRating;
