import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  Button,
  AnchorLink,
} from "@kidslivesafe/sc-react";
import { useAppSelector, useHandlePageNavigate } from "../../../hooks/hooks";

import "./cancel-confirmation.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { selectMemberData } from "../../../features/authentication/authenticationSlice";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { formatPhoneNumber, parseDate } from "../../../utils";

const CancelConfirmation: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();
  const brandInfo = useAppSelector(selectBrandInfo);
  const memberInfo = useAppSelector(selectMemberData);

  const currentBreakpoint = useCurrentBreakpoint();

  return (
    <>
      <ReportTitle reportTitle="Cancellation Confirmed" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            <Text>{memberInfo.firstname},</Text>
            <Text>Your account is now canceled.</Text>
            <Text>
              We encourage you to fully use {brandInfo.name} for the remainder
              of your current active membership.
            </Text>
            <Text>
              Your membership will stop on{" "}
              {parseDate(memberInfo.expirationDate!, "MM/DD/YYYY")}. No further
              charge will occur after that date.
            </Text>
            <Text>
              You will receive an email confirmation of your cancellation.
              Please check your junk folder if you do not see the confirmation
              email within the next 30 minutes.
            </Text>
            <Text>
              Please call our helpful customer support at{" "}
              <AnchorLink
                inline
                phoneNumber
                hoverUnderline
                href={brandInfo.phoneNumber}
                color="base"
              >
                {formatPhoneNumber(brandInfo.phoneNumber)}
              </AnchorLink>{" "}
              for further assistance.
            </Text>
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "xxxs",
              }}
            >
              <Text>Sincerely,</Text>
              <Text>{brandInfo.name}</Text>
            </Layout>

            <Button
              buttonText="Return to Dashboard"
              handleClick={() => pageNavigate("/")}
              size="md"
            />
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default CancelConfirmation;
