import React from "react";
import {
  PeopleReportHistory,
  PhoneReportHistory,
  PropertyReportHistory,
  SocialReportHistory,
  PullPhoneReportParams,
  AutoReportHistory,
  NeighborhoodReportHistory,
} from "../../../features/reports/reportTypes";
import {
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  LoadingPlaceholder,
  ReportLink,
  Tag,
  Text,
} from "@kidslivesafe/sc-react";
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import { usePhoneReportDispatch } from "../../../hooks/phoneReportHook";

import {
  PullPropertyReportParams,
  usePropertySearchDispatch,
} from "../../../hooks/propertyReportHook";

import {
  formatAddress,
  formatName,
  formatPhoneNumber,
  fullCapsText,
  generatePeopleReportHighlights,
  parseDate,
  generatePhoneReportHighlights,
  generateSocialReportHighlights,
  renderSocialReportType,
  generatePropertyReportHighlights,
  generateNeighborhoodReportHighlights,
} from "../../../utils";
import {
  generateList,
  useCurrentBreakpoint,
} from "@kidslivesafe/sc-foundation";
import { useSocialSearchDispatch } from "../../../hooks/socialSearchHook";
import { useAutoReportDispatch } from "../../../hooks/autoReportHook";
import { useNeighborhoodReportDispatch } from "../../../hooks/neighborhoodReportHook";
import { useAppSelector } from "../../../hooks/hooks";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

interface ActivityHistoryLineItemProps {
  report:
    | PeopleReportHistory
    | PhoneReportHistory
    | PropertyReportHistory
    | SocialReportHistory
    | AutoReportHistory
    | NeighborhoodReportHistory
    | undefined;
  loading?: boolean;
  loadingKey?: any;
}

const ActivityHistoryLineItem: React.FC<ActivityHistoryLineItemProps> = ({
  report,
  loading = false,
  loadingKey,
}) => {
  const pullPeopleReport = usePeopleReportDispatch();
  const searchSocial = useSocialSearchDispatch();
  const pullPhoneReport = usePhoneReportDispatch();
  const pullPropertyReport = usePropertySearchDispatch();
  const pullAutoReport = useAutoReportDispatch();
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();
  const brandInfo = useAppSelector(selectBrandInfo);

  const currentBreakpoint = useCurrentBreakpoint();

  const detailsSpacing = currentBreakpoint === "desktop" ? "md" : "xxs";
  // helper functions.

  // render history based on report type
  const renderHistory = (
    report:
      | PeopleReportHistory
      | PhoneReportHistory
      | PropertyReportHistory
      | SocialReportHistory
      | AutoReportHistory
      | NeighborhoodReportHistory
      | undefined,
  ) => {
    if (!report) return null;
    switch (report.type) {
      case "people":
        return (
          <ContainerLineItem
            key={report.score}
            lineItemHeader={
              <Layout
                type="flex"
                flexProps={{ gap: "xxs", alignItems: "center" }}
              >
                <ReportLink<PullPeopleReportParams>
                  noWrap
                  handleClick={pullPeopleReport}
                  inputValue={report.reportToken}
                  text={formatName(report)}
                />
                {report.dateOfDeath ? (
                  <Tag type="deceased" tagText="deceased" />
                ) : null}
              </Layout>
            }
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconPeople} />
                </Text>
                <div className="activity-history__line-item-details">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      direction: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {parseDate(report.score, "down-to-the-minute")
                      .split(",")
                      .map((date, index) => {
                        return (
                          <Text size="xxs" key={index}>
                            {date}
                          </Text>
                        );
                      })}
                  </Layout>
                </div>
              </Layout>
            }
            lineItemSubHeader={
              report.schema === "v2"
                ? generatePeopleReportHighlights(report)
                : "null"
            }
          />
        );
      case "property":
        return (
          <ContainerLineItem
            key={report.score}
            lineItemHeader={
              <ReportLink<PullPropertyReportParams>
                noWrap
                text={fullCapsText(formatAddress(report, "with-comma"))}
                inputValue={{
                  addressLine1: report.addressLine1,
                  addressLine2: report.addressLine2,
                  state: report.state,
                  city: report.city,
                  zip: report.zip,
                  lat: report.latitude,
                  long: report.longitude,
                  nearbySchoolRadius: 5,
                }}
                handleClick={pullPropertyReport}
              />
            }
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconProperty} />
                </Text>
                <div className="activity-history__line-item-details">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      direction: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {parseDate(report.score, "down-to-the-minute")
                      .split(",")
                      .map((date, index) => {
                        return (
                          <Text size="xxs" key={index}>
                            {date}
                          </Text>
                        );
                      })}
                  </Layout>
                </div>
              </Layout>
            }
            lineItemSubHeader={
              report.schema === "v2"
                ? generatePropertyReportHighlights(report)
                : null
            }
          />
        );
      case "social":
        const socialText = report.email
          ? report.email
          : report.twitter
            ? report.twitter
            : report.phone
              ? formatPhoneNumber(report.phone)
              : "";

        return (
          <ContainerLineItem
            key={report.score}
            lineItemHeader={
              <ReportLink
                noWrap
                inputValue={socialText}
                text={fullCapsText(socialText)}
                handleClick={searchSocial}
              />
            }
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconSocial} />
                </Text>
                <div className="activity-history__line-item-details">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      direction: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {parseDate(report.score, "down-to-the-minute")
                      .split(",")
                      .map((date, index) => {
                        return (
                          <Text size="xxs" key={index}>
                            {date}
                          </Text>
                        );
                      })}
                  </Layout>
                </div>
              </Layout>
            }
            lineItemSubHeader={
              report.schema === "v2"
                ? `${generateSocialReportHighlights(
                    report,
                    renderSocialReportType(report),
                  )}`
                : null
            }
          />
        );
      case "phone":
        return (
          <ContainerLineItem
            key={report.score}
            lineItemHeader={
              <ReportLink<PullPhoneReportParams>
                noWrap
                inputValue={report.phone}
                text={formatPhoneNumber(report.phone)}
                handleClick={pullPhoneReport}
              />
            }
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconPhone} />
                </Text>
                <div className="activity-history__line-item-details">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      direction: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {parseDate(report.score, "down-to-the-minute")
                      .split(",")
                      .map((date, index) => {
                        return (
                          <Text size="xxs" key={index}>
                            {date}
                          </Text>
                        );
                      })}
                  </Layout>
                </div>
              </Layout>
            }
            lineItemSubHeader={
              report.schema === "v2"
                ? generatePhoneReportHighlights(report, false)
                : null
            }
          />
        );
      case "auto":
        return (
          <ContainerLineItem
            key={report.score}
            lineItemHeader={
              <ReportLink
                noWrap
                inputValue={{ vin: report.vin }}
                text={
                  report.vehicleDescription
                    ? report.vehicleDescription.toUpperCase()
                    : report.vin
                }
                handleClick={pullAutoReport}
              />
            }
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconAuto} />
                </Text>
                <div className="activity-history__line-item-details">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      direction: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {parseDate(report.score, "down-to-the-minute")
                      .split(",")
                      .map((date, index) => {
                        return (
                          <Text size="xxs" key={index}>
                            {date}
                          </Text>
                        );
                      })}
                  </Layout>
                </div>
              </Layout>
            }
            lineItemSubHeader={generateList(
              [
                {
                  value: report.vehicleDescription ? report.vin : "",
                  label: "",
                },
                {
                  value: report.titleCount
                    ? `${report.titleCount} Title Record${
                        report.titleCount === 1 ? "" : "s"
                      }`
                    : null,
                  label: "",
                },
              ],
              " | ",
            )}
          />
        );

      case "neighborhood":
        return (
          <ContainerLineItem
            key={report.score}
            lineItemHeader={
              <ReportLink
                noWrap
                inputValue={{
                  addressSearch: {
                    address: report.addressLine1,
                    addressLine2: report.addressLine2,
                    city: report.city,
                    state: report.state,
                    zipCode: report.zip,
                  },
                  searchMethod: "address",
                }}
                text={fullCapsText(formatAddress(report))}
                handleClick={pullNeighborhoodReport}
              />
            }
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconNeighborhoodWatch} />
                </Text>
                <div className="activity-history__line-item-details">
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      direction: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {parseDate(report.score, "down-to-the-minute")
                      .split(",")
                      .map((date, index) => {
                        return (
                          <Text size="xxs" key={index}>
                            {date}
                          </Text>
                        );
                      })}
                  </Layout>
                </div>
              </Layout>
            }
            lineItemSubHeader={
              report.schema === "v2"
                ? generateNeighborhoodReportHighlights(report)
                : null
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        <ContainerLineItem
          key={loadingKey}
          lineItemHeader={
            <LoadingPlaceholder width={"10rem"} height={"20px"} />
          }
          lineItemDetails={
            <Layout
              type="flex"
              flexProps={{ gap: detailsSpacing, alignItems: "center" }}
            >
              <LoadingPlaceholder width={"32px"} height={"32px"} />
              <div className="activity-history__line-item-details">
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    direction: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <LoadingPlaceholder width={"55px"} height={"13px"} />
                  <LoadingPlaceholder width={"50px"} height={"13px"} />
                </Layout>
              </div>
            </Layout>
          }
          lineItemSubHeader={<LoadingPlaceholder width={"12rem"} />}
        />
      ) : (
        renderHistory(report)
      )}
    </>
  );
};

export default ActivityHistoryLineItem;
