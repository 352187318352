import React from "react";
import { selectBrandInfo } from "../../features/brand/brandSlice";

import { useAppSelector } from "../../hooks/hooks";

export interface brandedLoginLayoutProps {
  children: React.ReactNode;
  pageName: string;
  documentTitle?: string;
  header?: boolean;
  footerType?: "default" | "no-footer" | "min-footer";
  minFooterOptions?: {
    color?: "base" | "white";
  };
  noFooter?: boolean;
  displayButtons?: boolean;
}

const brandedLoginLayout = <P extends {}>(
  Component: React.ComponentType<P>,
) => {
  const LoginLayout: React.FC<P & brandedLoginLayoutProps> = props => {
    const brandInfo = useAppSelector(selectBrandInfo);
    if (["qpr", "pis", "spr", "pf"].includes(brandInfo.acronym)) {
      props = {
        ...props,
        footerType: "min-footer",
        minFooterOptions: { color: "base" },
      };
    } else {
      props = {
        ...props,
        footerType: "min-footer",
        minFooterOptions: { color: "white" },
      };
    }

    return <Component {...props} />;
  };

  return LoginLayout;
};

export default brandedLoginLayout;
