import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { usePropertySearchDispatch } from "../../hooks/propertyReportHook";

interface TESTPullPropertyReportProps {}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TestPullPropertyReport: React.FC<TESTPullPropertyReportProps> = () => {
  const pullPropertyReport = usePropertySearchDispatch();
  const query = useQuery();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    const dto = {
      addressLine1: query.get("addressLine1"),
      addressLine2: query.get("addressLine2"),
      state: query.get("state"),
      city: query.get("city"),
      zip: Number(query.get("zip")),
      lat: Number(query.get("lat")),
      long: Number(query.get("long")),
    };

    if (dto && !hasFetchedReport.current) {
      pullPropertyReport(dto);
      hasFetchedReport.current = true;
    }
  }, [query, pullPropertyReport]);

  return <div>Pulling Report</div>;
};

export default TestPullPropertyReport;
