import React from "react";
import {
  Container,
  Padding,
  Layout,
  Text,
  Spotlight,
  Icon,
  IconList,
  Tab,
} from "@kidslivesafe/sc-react";
import {
  useAppDispatch,
  useAppSelector,
  useHandlePageNavigate,
} from "../../../hooks/hooks";
import {
  setTab,
  toggleOpen,
} from "../../../features/multisearch/multisearchSlice";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

const PlanBenefits: React.FC = () => {
  const navigate = useHandlePageNavigate();
  const dispatch = useAppDispatch();

  const brandInfo = useAppSelector(selectBrandInfo);

  const handleSpotlightClick = (tab: Tab) => {
    dispatch(setTab(tab));
    dispatch(toggleOpen());
  };

  const renderSpotlights = () => {
    switch (brandInfo.acronym) {
      case "pf":
        return (
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "md" }}
            breakpointStyles={{
              desktop: {
                type: "grid",
                gridProps: {
                  templateColumns: "2-column",
                  gap: "md",
                  alignItems: "stretch",
                },
              },
            }}
          >
            <Spotlight
              onClick={() => {
                handleSpotlightClick("property");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconProperty} size={27} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="clickable">
                      In-Depth Property Details
                    </Text>
                    <Text fontWeight="normal">
                      Access comprehensive property data including ownership
                      history, financial transactions, and building attributes.
                      View estimated market values, neighborhood insights, and
                      set up monitoring alerts for changes. Explore timelines of
                      sales, mortgages, and refinancing for analysis.
                    </Text>
                    <Text
                      color="clickable"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Search Property Details Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
            <Spotlight
              onClick={() => {
                handleSpotlightClick("property");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconPeople} size={27} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="clickable">
                      Owner & Resident Info
                    </Text>
                    <Text fontWeight="normal">
                      People reports provide detailed insights into a property's
                      history, including ownership timelines, residents, and
                      neighborhood trends. Discover connections between
                      individuals and properties to make informed
                      decisions—ideal for buyers, homeowners, or investors
                      seeking clarity on property history.
                    </Text>
                    <Text
                      color="clickable"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Find Owner & Resident Info Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
            <Spotlight
              onClick={() => {
                handleSpotlightClick("property");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconNeighborhoodWatch} size={27} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="clickable">
                      Neighborhood Insights
                    </Text>
                    <Text fontWeight="normal">
                      Property Focus provides insights into neighborhoods,
                      including rental rates, school ratings, and nearby
                      property values. Compare your property with others in the
                      area and explore details on local residents. Access
                      in-depth data to better understand the surrounding real
                      estate landscape.
                    </Text>
                    <Text
                      color="clickable"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Get Neighborhood Insights Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
            <Spotlight
              onClick={() => {
                navigate("/#dashboard__monitoring");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconMonitoring} size={27} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="clickable">
                      Monitoring Reports
                    </Text>
                    <Text fontWeight="normal">
                      You can set up monitoring on up to 10 reports, and we will
                      automatically send you alerts when we detect new
                      information or updates. You can change which reports you
                      monitor as often as you would like with just a few simple
                      clicks, ensuring you're always informed.
                    </Text>
                    <Text
                      color="clickable"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Start Monitoring Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
          </Layout>
        );

      default:
        return (
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "md" }}
            breakpointStyles={{
              desktop: {
                type: "grid",
                gridProps: {
                  templateColumns: "2-column",
                  gap: "md",
                  alignItems: "stretch",
                },
              },
            }}
          >
            <Spotlight
              onClick={() => {
                navigate("/#dashboard__monitoring");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconMonitoring} size={27} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="primary">
                      Monitoring Reports
                    </Text>
                    <Text fontWeight="normal">
                      You can set up monitoring on up to 4 people and we will
                      automatically send you alerts when we detect new
                      information. You can change who you monitor as often as
                      you would like with a few simple clicks.
                    </Text>
                    <Text
                      color="primary"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Start Monitoring Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
            <Spotlight
              onClick={() => {
                handleSpotlightClick("people");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconPeople} size={32} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="primary">
                      People Search
                    </Text>
                    <Text fontWeight="normal">
                      People reports can help you get a better understanding of
                      the people in your life with a fast, easy, and reliable
                      way to find public record information. There are millions
                      of names in our national database and your searches are
                      confidential.
                    </Text>
                    <Text
                      color="primary"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Try a People Search Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
            <Spotlight
              onClick={() => {
                handleSpotlightClick("property");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconProperty} size={32} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="primary">
                      Property Search
                    </Text>
                    <Text fontWeight="normal">
                      Our property search has information on millions of
                      properties in our nationwide database. Research home
                      values, ownership history, and property details. Be sure
                      to checkout all of the information and run a search today.
                    </Text>
                    <Text
                      color="primary"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Try a Property Search Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
            <Spotlight
              onClick={() => {
                handleSpotlightClick("phone");
              }}
            >
              <Padding top="xl" left="lg" right="lg" bottom="xl">
                <Layout type="flex" flexProps={{ gap: "lg" }}>
                  <div className="page-my-account__membership-information__icon">
                    <Icon name={IconList.IconPhone} size={32} />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    <Text tag="h3" color="primary">
                      Phone Search
                    </Text>
                    <Text fontWeight="normal">
                      Phone search allows you to learn about millions of phone
                      numbers in our national database. Use phone search to
                      reconnect with old friends, verify information before a
                      blind date, or protect yourself from scammers. Always stay
                      informed by knowing who is on the other end of the call.
                    </Text>
                    <Text
                      color="primary"
                      size="sm"
                      passThroughClassess="page-my-account__spotlight-link"
                    >
                      Try a Phone Search Now
                    </Text>
                  </Layout>
                </Layout>
              </Padding>
            </Spotlight>
          </Layout>
        );
    }
  };

  return (
    <Container customClassName="page-my-account__membership-information">
      <Padding top="lg" left="md" right="md" bottom="lg">
        <Layout type="flex" flexProps={{ direction: "column", gap: "lg" }}>
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            <Text tag="h2" fontWeight="bold">
              Membership Information
            </Text>
            <Layout type="flex" flexProps={{ gap: "md" }}>
              <Layout type="flex" flexProps={{ direction: "column" }}>
                <Text tag="h3">Plan Benefits</Text>
                <Text size="sm" fontWeight="normal" tag="i">
                  Take advantage of all of the features included in your
                  membership
                </Text>
              </Layout>
            </Layout>
            {renderSpotlights()}
          </Layout>
        </Layout>
      </Padding>
    </Container>
  );
};

export default PlanBenefits;
