import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

import {
  createCustomerRedisTokenAsync,
  selectAdminStatus,
} from "../../../features/admin/adminSlice";

import { selectMemberData } from "../../../features/authentication/authenticationSlice";
import { selectPrimarySubscription } from "../../../features/subscriptions/subscriptionSlice";

import { capFirstLetter, generateCreditCardLogo } from "../../../utils";

import "./UpdateCreditCard.css";

import {
  Padding,
  Layout,
  Button,
  ReportTitle,
  Text,
  Sheet,
} from "@kidslivesafe/sc-react";

const UpdateCreditCard: React.FC = () => {
  const subscriptionDetails = useAppSelector(selectPrimarySubscription);
  const memberData = useAppSelector(selectMemberData);
  const brandInfo = useAppSelector(selectBrandInfo);
  const { customerId } = useAppSelector(selectMemberData);
  const adminStatus = useAppSelector(selectAdminStatus);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (adminStatus === "loading") {
      setLoading(true);
    }
  }, [adminStatus]);

  const handleExternalNavigation = async () => {
    const createCustomerRedisTokenAsyncResponse = await dispatch(
      createCustomerRedisTokenAsync({ customerId }),
    );
    if (
      createCustomerRedisTokenAsync.fulfilled.match(
        createCustomerRedisTokenAsyncResponse,
      )
    ) {
      const uuid = createCustomerRedisTokenAsyncResponse.payload.token;
      window.location.href =
        brandInfo.homeUrl + "/update-credit-card?uuid=" + uuid + "&beta=true";
    }
  };

  const creditCardLogo = generateCreditCardLogo(memberData.cardType);

  return (
    <>
      <ReportTitle reportTitle="Billing Information" />
      <Sheet>
        <Padding top="lg" bottom="lg" right="md" left="md">
          <Layout type="flex" flexProps={{ direction: "column" }}>
            <Text fontWeight="bold" size="lg">
              Status:{" "}
              <Text
                fontWeight="normal"
                tag="span"
                size="lg"
                color={subscriptionDetails?.cancelDate ? "error" : "base"}
              >
                {subscriptionDetails?.cancelDate
                  ? "Canceled"
                  : capFirstLetter(
                      memberData.subscriptionStatus
                        ? memberData.subscriptionStatus
                        : "",
                    )}{" "}
              </Text>
            </Text>
            <Text fontWeight="bold" size="lg">
              Next Bill Date:{" "}
              <Text fontWeight="normal" tag="span" size="lg">
                {memberData.billingDate}
              </Text>
            </Text>
            <Layout type="flex" flexProps={{ direction: "row" }}>
              <Text fontWeight="bold" size="lg">
                Card Ending:
              </Text>
              <Padding left="xxs">
                <Layout type="flex" flexProps={{ alignContent: "center" }}>
                  <Text fontWeight="normal" tag="span" size="lg">
                    {memberData.lastFour}
                  </Text>
                  {creditCardLogo ? (
                    <Padding left="xxs">
                      <img
                        width={45}
                        src={creditCardLogo}
                        alt="credit card logo"
                      />
                    </Padding>
                  ) : null}
                </Layout>
              </Padding>
            </Layout>
            <Text fontWeight="bold" size="lg">
              Order ID:{" "}
              <Text fontWeight="normal" tag="span" size="lg">
                {memberData.orderId}
              </Text>
            </Text>
            <Padding top="lg" bottom="lg">
              <Text>To update your credit card please click below.</Text>
            </Padding>
            <Layout type="flex" flexProps={{ justifyContent: "center" }}>
              <Button
                buttonText="Update Credit Card"
                handleClick={handleExternalNavigation}
                size="md"
                loading={loading}
                clicked={loading}
              />
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default UpdateCreditCard;
