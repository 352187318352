import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";

import {
  pullAutoReportAsync,
  selectAutoReportStatus,
} from "../features/reports/reportsSlice";

import { useLocation } from "react-router-dom";
import { PullAutoReportParams } from "../features/reports/reportTypes";

import {
  setAutoReportSearch,
  toggleReinitialize,
} from "../features/multisearch/multisearchSlice";

type UseAutoReportHook = () => (data: PullAutoReportParams) => void;

/* Pull Auto Report */
const useAutoReportDispatch: UseAutoReportHook = () => {
  const dispatch = useAppDispatch();
  const searchStatus = useAppSelector(selectAutoReportStatus);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const pullAutoReport = (data: PullAutoReportParams) => {
    if (searchStatus === "loading") return;

    dispatch(pullAutoReportAsync(data));
    dispatch(setAutoReportSearch(data));
    dispatch(toggleReinitialize());

    if (location.pathname !== "/auto-report") {
      pageNavigate("/auto-report");
    } else {
      window.scrollTo(0, 0);
    }
  };

  return pullAutoReport;
};

export { useAutoReportDispatch };
