import React, { useEffect, useState } from "react";
import hacker from "../../../assets/global/images/id-protect_hacker.png";
import shield from "@assets/images/id-protect_shield.png";
import IdProtectDoubleIcon from "../../../components/UIComponents/IdProtectDoubleIcon/IdProtectDoubleIcon";
import "./IdProtect.css";

import {
  Container,
  ReportTitle,
  Layout,
  Text,
  Padding,
  Sheet,
  Button,
  Icon,
  IconList,
  Modal,
  AnchorLink,
  Margin,
} from "@kidslivesafe/sc-react";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  addIdProtectAsync,
  getIdProtectSsoKeyAsync,
  selectAdminStatus,
} from "../../../features/admin/adminSlice";
import {
  selectCustomerId,
  selectIdProtectData,
} from "../../../features/authentication/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { formatPhoneNumber } from "../../../utils";

const IdProtect: React.FC = () => {
  const currentBreakpoint = useCurrentBreakpoint();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerId = useAppSelector(selectCustomerId);
  const idProtectData = useAppSelector(selectIdProtectData);
  const { name, idProtectPrice, phoneNumber, idProtecturl, acronym } =
    useAppSelector(selectBrandInfo);
  const [successfulPurchase, setSuccessfulPurchase] = useState<boolean>(false);
  const [failedPurchase, setFailedPurchase] = useState<boolean>(false);
  const [isMember, setIsMember] = useState<boolean>(false);

  const status = useAppSelector(selectAdminStatus);

  const loading = status === "loading";

  useEffect(() => {
    if (idProtectData.idProtectMember) {
      setIsMember(true);
    }
  }, [idProtectData.idProtectMember]);

  const handleAddIdProtect = async () => {
    if (idProtectData.isIdProtectAvailable && !loading) {
      if (customerId) {
        const saveIdProtectAsyncResponse = await dispatch(
          addIdProtectAsync({ customerId }),
        );
        if (addIdProtectAsync.fulfilled.match(saveIdProtectAsyncResponse)) {
          setSuccessfulPurchase(true);
        } else if (
          addIdProtectAsync.rejected.match(saveIdProtectAsyncResponse)
        ) {
          setFailedPurchase(true);
        }
      }
    }
  };

  const handleNotInterested = () => {
    navigate("/");
  };

  const handleWelcomeBack = async () => {
    if (customerId && !loading) {
      const getIdProtectSsoKeyAsyncResponse = await dispatch(
        getIdProtectSsoKeyAsync({ customerId }),
      );
      if (
        getIdProtectSsoKeyAsync.fulfilled.match(getIdProtectSsoKeyAsyncResponse)
      ) {
        if (getIdProtectSsoKeyAsyncResponse.payload.success) {
          window.open(
            `${idProtecturl}?ocx=${getIdProtectSsoKeyAsyncResponse.payload.res.result}`,
            "_blank",
          );
        } else {
          window.open(`${idProtecturl}`, "_blank");
        }
      }
    }
  };

  const handleStayOnSite = () => {
    navigate("/");
  };

  return (
    <>
      <ReportTitle
        altStyle
        reportTitle="177 MILLION AMERICAN IDENTITIES WERE EXPOSED LAST YEAR"
      />
      <Container>
        <Sheet>
          <Padding space="md">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                width={currentBreakpoint === "desktop" ? "495" : "284"}
                src={hacker}
                alt=""
              ></img>
              <Text
                size="xl"
                headerFont
                fontWeight="bold"
                textAlign="center"
                passThroughClassess={`page-id-protect-large-text--${currentBreakpoint}`}
              >
                Safeguard your{" "}
                <Text
                  size="xl"
                  headerFont
                  passThroughClassess={`page-id-protect-large-text--${currentBreakpoint} page-id-protect-accent1-text`}
                  tag="span"
                >
                  identity
                </Text>{" "}
                and{" "}
                <Text
                  size="xl"
                  headerFont
                  passThroughClassess={`page-id-protect-large-text--${currentBreakpoint} page-id-protect-accent1-text`}
                  tag="span"
                >
                  financial security
                </Text>{" "}
                with{" "}
                <Text
                  size="xl"
                  headerFont
                  passThroughClassess={`page-id-protect-large-text--${currentBreakpoint} page-id-protect-accent1-text`}
                  tag="span"
                >
                  ID PROTECT
                </Text>
              </Text>
            </Layout>
          </Padding>
        </Sheet>
      </Container>
      <Container altColor="id-protect">
        <Sheet>
          <Padding space="md" top="xl" bottom="xl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                alignItems: "center",
                gap: "lg",
              }}
            >
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  alignItems: "center",
                  gap: "xs",
                }}
                passThroughClassess="page-id-protect__underline"
              >
                <Text
                  unresponsive
                  headerFont
                  size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                  color="white"
                  passThroughClassess={`page-id-protect-small-text--${currentBreakpoint}`}
                >
                  Exclusive Offer for {name} Subscribers
                </Text>
                <Padding bottom="md">
                  <Text
                    unresponsive
                    textAlign="center"
                    headerFont
                    size={currentBreakpoint === "desktop" ? "xxxl" : "base"}
                    color="white"
                    fontWeight="semibold"
                  >
                    Get{" "}
                    <Text
                      size="xl"
                      headerFont
                      fontWeight="bold"
                      passThroughClassess={`page-id-protect-large-text--${currentBreakpoint} page-id-protect-accent2-text`}
                      tag="span"
                    >
                      ID PROTECT
                    </Text>{" "}
                    for only
                    <Text
                      passThroughClassess={`page-id-protect-large-text--${currentBreakpoint}`}
                      tag="span"
                      fontWeight="bold"
                      headerFont
                      size="xl"
                    >
                      {" "}
                      ${idProtectPrice} per month
                    </Text>
                  </Text>
                </Padding>
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  alignItems: "center",
                  gap: "xs",
                }}
              >
                <Text
                  size="xxl"
                  passThroughClassess={`page-id-protect-large-text--${currentBreakpoint}`}
                  fontWeight="black"
                  headerFont
                  color="white"
                >
                  80% SAVINGS
                </Text>
                <Text
                  size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                  headerFont
                  color="white"
                  passThroughClassess={`page-id-protect-small-text--${currentBreakpoint}`}
                >
                  compared to the competition
                </Text>
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "sm",
                }}
                breakpointStyles={{
                  desktop: {
                    type: "flex",
                    flexProps: { gap: "md", direction: "column" },
                  },
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "xs",
                    alignItems: "center",
                  }}
                >
                  <div className="id-protect-icon-color">
                    <Icon
                      size={currentBreakpoint === "desktop" ? 32 : 18}
                      name={IconList.IconCheck}
                    ></Icon>
                  </div>
                  <Text
                    size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                    fontWeight="semibold"
                    color="white"
                    headerFont
                  >
                    Identify Monitoring and Alerts
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "xs",
                    alignItems: "center",
                  }}
                >
                  <div className="id-protect-icon-color">
                    <Icon
                      size={currentBreakpoint === "desktop" ? 32 : 18}
                      name={IconList.IconCheck}
                    ></Icon>
                  </div>
                  <Text
                    size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                    fontWeight="semibold"
                    color="white"
                    headerFont
                  >
                    $1 Million Identity Theft Insurance
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "xs",
                    alignItems: "center",
                  }}
                >
                  <div className="id-protect-icon-color">
                    <Icon
                      size={currentBreakpoint === "desktop" ? 32 : 18}
                      name={IconList.IconCheck}
                    ></Icon>
                  </div>
                  <Text
                    size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                    fontWeight="semibold"
                    color="white"
                    headerFont
                  >
                    Comprehensive Restoration Services
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "xs",
                    alignItems: "center",
                  }}
                >
                  <div className="id-protect-icon-color">
                    <Icon
                      size={currentBreakpoint === "desktop" ? 32 : 18}
                      name={IconList.IconCheck}
                    ></Icon>
                  </div>
                  <Text
                    size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                    fontWeight="semibold"
                    color="white"
                    headerFont
                  >
                    Computer Protection Software
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "xs",
                    alignItems: "center",
                  }}
                >
                  <div className="id-protect-icon-color">
                    <Icon
                      size={currentBreakpoint === "desktop" ? 32 : 18}
                      name={IconList.IconCheck}
                    ></Icon>
                  </div>
                  <Text
                    size={currentBreakpoint === "desktop" ? "xl" : "sm"}
                    fontWeight="semibold"
                    color="white"
                    headerFont
                  >
                    Compare the LifeLock <sup>&trade;</sup> Standard
                  </Text>
                </Layout>
              </Layout>
              <Text
                textAlign="center"
                headerFont
                size="base"
                color="subtle"
                tag={acronym === "qpr" ? "i" : undefined}
              >
                A ${idProtectPrice} monthly fee will appear separately from{" "}
                {name} membership on your credit card statement.
              </Text>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "xs",
                }}
              >
                <Button
                  color="id-protect-primary"
                  handleClick={handleAddIdProtect}
                  buttonText="Yes, Add ID Protect"
                  passThroughClassess={`page-id-protect-select-button--${currentBreakpoint}`}
                  loading={loading}
                  clicked={loading}
                />
                <Button
                  color="id-protect-secondary"
                  handleClick={handleNotInterested}
                  buttonText="I'm Not Concerned About Identity Theft"
                  passThroughClassess={`page-id-protect-select-button--${currentBreakpoint}`}
                />
              </Layout>
            </Layout>
          </Padding>
        </Sheet>
      </Container>
      <Container>
        <Sheet>
          <Padding space="lg">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                alignItems: "center",
                gap: "xl",
              }}
            >
              <img
                width={currentBreakpoint === "desktop" ? "182" : "123"}
                src={shield}
                alt=""
              ></img>

              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  alignItems: "center",
                  gap: "md",
                }}
                passThroughClassess={`page-id-protect_policy--${currentBreakpoint}`}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "lg",
                  }}
                  breakpointStyles={{
                    desktop: { type: "flex", flexProps: { gap: "xl" } },
                  }}
                >
                  <IdProtectDoubleIcon type="wallet"></IdProtectDoubleIcon>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <Text
                      unresponsive
                      headerFont
                      size={currentBreakpoint === "desktop" ? "xl" : "lg"}
                      fontWeight="bold"
                    >
                      $1 Million Insurance Policy
                    </Text>
                    <Text
                      size={currentBreakpoint === "desktop" ? "base" : "sm"}
                      headerFont
                      color={acronym === "qpr" ? "accent" : "base"}
                    >
                      Members are covered for qualified losses by a $1 million
                      insurance policy, underwritten by an A.M. Best A-Rated
                      carrier. Protect yourself against lost wages, attorney's
                      fees, and other costs associated with identity theft.
                    </Text>
                  </Layout>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "lg",
                  }}
                  breakpointStyles={{
                    desktop: { type: "flex", flexProps: { gap: "xl" } },
                  }}
                >
                  <IdProtectDoubleIcon type="building"></IdProtectDoubleIcon>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <Text
                      unresponsive
                      headerFont
                      size={currentBreakpoint === "desktop" ? "xl" : "lg"}
                      fontWeight="bold"
                    >
                      Social Security Monitoring and Alerts
                    </Text>
                    <Text
                      size={currentBreakpoint === "desktop" ? "base" : "sm"}
                      headerFont
                      color={acronym === "qpr" ? "accent" : "base"}
                    >
                      Social Security Monitoring detects when a name becomes
                      associated with your social security number. You will
                      receive an alert any time your Social Security Number is
                      used.
                    </Text>
                  </Layout>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "lg",
                  }}
                  breakpointStyles={{
                    desktop: { type: "flex", flexProps: { gap: "xl" } },
                  }}
                >
                  <IdProtectDoubleIcon type="home"></IdProtectDoubleIcon>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <Text
                      unresponsive
                      headerFont
                      size={currentBreakpoint === "desktop" ? "xl" : "lg"}
                      fontWeight="bold"
                    >
                      Change of Address Alerts
                    </Text>
                    <Text
                      size={currentBreakpoint === "desktop" ? "base" : "sm"}
                      headerFont
                      color={acronym === "qpr" ? "accent" : "base"}
                    >
                      Our service monitors requests to change your address
                      through the United States Postal Service. You will be
                      notified if someone attempts to send your mail to a new
                      address.
                    </Text>
                  </Layout>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "lg",
                  }}
                  breakpointStyles={{
                    desktop: { type: "flex", flexProps: { gap: "xl" } },
                  }}
                >
                  <IdProtectDoubleIcon type="person"></IdProtectDoubleIcon>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <Text
                      unresponsive
                      headerFont
                      size={currentBreakpoint === "desktop" ? "xl" : "lg"}
                      fontWeight="bold"
                    >
                      Full-Service Identity Restoration
                    </Text>
                    <Text
                      size={currentBreakpoint === "desktop" ? "base" : "sm"}
                      headerFont
                      color={acronym === "qpr" ? "accent" : "base"}
                    >
                      Trained, certified agents help customers restore their
                      identity to pre-theft status. We will work to identify the
                      cause of the security leak. We will assist you in shutting
                      down access to the problem. We can also help you repair
                      any damage done because of identity theft.
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
          </Padding>
        </Sheet>
      </Container>
      <Container altColor="id-protect-bottom">
        <div className="page-id-protect-sub-footer">
          <Sheet>
            <Padding space="lg" left="md" right="md">
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "lg",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    unresponsive
                    fontWeight="bold"
                    headerFont
                    size={currentBreakpoint === "desktop" ? "xxxl" : "xxl"}
                    passThroughClassess="page-id-protect-testimonial-text"
                    tag="i"
                  >
                    PROTECT. DETECT. RESTORE.
                  </Text>
                  <img
                    width={currentBreakpoint === "desktop" ? "127" : "107"}
                    src={shield}
                    alt=""
                  ></img>
                </Layout>
                <Text
                  fontWeight="semibold"
                  size={currentBreakpoint === "desktop" ? "base" : "sm"}
                  headerFont
                  passThroughClassess="page-id-protect-testimonial-text"
                  tag="i"
                >
                  "Hackers and identity thieves continue to evolve. They are
                  very soPhisticated, extremely creative and dogged in their
                  pursuit of what is ours...Therefore, monitoring and damage
                  control become even more important than ever before."
                </Text>
                <Text
                  unresponsive
                  size={currentBreakpoint === "desktop" ? "base" : "sm"}
                  headerFont
                  passThroughClassess="page-idprotect-text-subtle-id-protect"
                  textAlign="right"
                >
                  Adam Levin, Chairman and Founder of CyberScout
                </Text>
              </Layout>
            </Padding>
          </Sheet>
        </div>
      </Container>
      <Modal
        open={isMember}
        setOpen={handleStayOnSite}
        size="fit-content"
        xClose
      >
        <Padding space="md">
          <Margin top="xl" bottom="xl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "lg",
                alignItems: "center",
              }}
            >
              <Text textAlign="center" tag="h2">
                Welcome back ID Protect Member!
              </Text>
              <Text size="sm" textAlign="center">
                Click continue below to go to our dedicated {name} ID Protect
                website. Your username and password are the same as your {name}{" "}
                login.
              </Text>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "xs",
                }}
              >
                <Button
                  buttonText="Continue to ID Protect"
                  handleClick={handleWelcomeBack}
                  loading={loading}
                  clicked={loading}
                  fill="fill"
                />
                <Layout type="flex" flexProps={{ alignSelf: "center" }}>
                  <AnchorLink
                    size="xs"
                    handleClick={handleStayOnSite}
                    role="button"
                  >
                    Remain on {name}
                  </AnchorLink>
                </Layout>
              </Layout>
            </Layout>
          </Margin>
        </Padding>
      </Modal>
      <Modal
        open={successfulPurchase}
        setOpen={handleStayOnSite}
        size="fit-content"
        xClose
      >
        <Margin top="lg" bottom="lg">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
              alignItems: "center",
            }}
          >
            <Text textAlign="center" tag="h2">
              Thank you for your order
            </Text>
            <Text size="sm" textAlign="center">
              Please check your email inbox for a message regarding your {name}{" "}
              ID Protect Membership. A separate charge of ${idProtectPrice} will
              appear on your credit card statement each month.
            </Text>
            <Text size="sm" textAlign="center">
              You may now proceed to our dedicated {name} ID Protect website.
              Your username and password are the same as your {name} login,
              unless you were required to change them during registration.
            </Text>
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "xs",
              }}
            >
              <Button
                buttonText="Continue to ID Protect"
                handleClick={handleWelcomeBack}
                loading={loading}
                clicked={loading}
              />
              <Layout type="flex" flexProps={{ alignSelf: "center" }}>
                <AnchorLink
                  size="xs"
                  handleClick={handleStayOnSite}
                  role="button"
                >
                  Remain on {name}
                </AnchorLink>
              </Layout>
            </Layout>
          </Layout>
        </Margin>
      </Modal>
      <Modal
        open={failedPurchase}
        setOpen={handleStayOnSite}
        size="base"
        xClose
      >
        <Padding space="md">
          <Margin top="xxl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "lg",
                alignItems: "center",
              }}
            >
              <Text textAlign="center" tag="h2">
                Sorry, something went wrong when trying to purchase ID Protect
              </Text>
              <Text size="sm" textAlign="center">
                Please contact member support at{" "}
                <AnchorLink
                  inline
                  phoneNumber
                  hoverUnderline
                  href={phoneNumber}
                >
                  {formatPhoneNumber(phoneNumber)}
                </AnchorLink>
                .
              </Text>
            </Layout>
          </Margin>
        </Padding>
      </Modal>
    </>
  );
};

export default IdProtect;
