import { Relative } from "../features/reports/reportTypes";

export interface FormattedRelative extends Relative {
  generation: string;
}

export const assignGenerationsAndCombine = (
  relatives: Relative[],
  generation: number = 1,
): FormattedRelative[] => {
  return relatives.reduce((acc: FormattedRelative[], relative) => {
    const genLabel = `${generation}${
      generation === 1 ? "st" : generation === 2 ? "nd" : "rd"
    }`;
    const newRelative = { ...relative, generation: genLabel };
    acc.push(newRelative);

    if (relative.relatives) {
      acc = acc.concat(
        assignGenerationsAndCombine(relative.relatives, generation + 1),
      );
    }

    return acc;
  }, []);
};

export const sortedAssignGenerationsAndCombine = (
  relatives: Relative[],
  generation: number = 1,
): FormattedRelative[] => {
  const appliedTag = assignGenerationsAndCombine(relatives, generation);

  appliedTag.sort((a, b) => {
    const genA = parseInt(a.generation.charAt(0));
    const genB = parseInt(b.generation.charAt(0));
    return genA - genB;
  });

  return appliedTag;
};
