import { useEffect, useState, useCallback } from "react";
import {
  Container,
  LoadingPlaceholder,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";
import ReportTeaser from "../../components/UIComponents/ReportTeaser/ReportTeaser";
import {
  formatAddress,
  formatName,
  formatPhoneNumber,
  generateNeighborhoodReportHighlights,
  generatePeopleReportHighlights,
  generatePhoneReportHighlights,
  generatePropertyReportHighlights,
  generateSocialReportHighlights,
  renderSocialReportType,
} from "../../utils";
import {
  AutoReportHistory,
  NeighborhoodReportHistory,
  PeopleReportHistory,
  PhoneAddress,
  PhoneReportHistory,
  PropertyReportHistory,
  SocialReportHistory,
} from "../../features/reports/reportTypes";

import { useAppSelector } from "../../hooks/hooks";
import {
  selectBrandInfo,
  selectMapboxKey,
} from "../../features/brand/brandSlice";

import { selectMostRecentReport } from "../../features/reports/reportsSlice";

import { usePeopleReportDispatch } from "../../hooks/peopleReportHook";
import { getCoordinates } from "../../features/reports/reportsAPI";
import { useSocialSearchDispatch } from "../../hooks/socialSearchHook";
import { usePropertySearchDispatch } from "../../hooks/propertyReportHook";
import { usePhoneReportDispatch } from "../../hooks/phoneReportHook";
import { useAutoReportDispatch } from "../../hooks/autoReportHook";
import { generateList } from "@kidslivesafe/sc-foundation";
import { useNeighborhoodReportDispatch } from "../../hooks/neighborhoodReportHook";

// TODO: set up case for neighborhood
const MostRecentReport = () => {
  const mostRecentReport = useAppSelector(selectMostRecentReport);
  const brandInfo = useAppSelector(selectBrandInfo);
  const mapBoxKey = useAppSelector(selectMapboxKey);
  const [reportContent, setReportContent] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const pullPeopleReport = usePeopleReportDispatch();
  const pullSocialReport = useSocialSearchDispatch();
  const pullPropertyReport = usePropertySearchDispatch();
  const pullPhoneReport = usePhoneReportDispatch();
  const pullAutoReport = useAutoReportDispatch();
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();

  const fetchReportContent = useCallback(
    async (
      report:
        | PeopleReportHistory
        | PhoneReportHistory
        | PropertyReportHistory
        | SocialReportHistory
        | AutoReportHistory
        | NeighborhoodReportHistory
        | undefined,
    ) => {
      if (!report) {
        setReportContent(<Text tag="h3">No most recent report available</Text>);
        setLoading(false);
        return;
      }

      const generateMap = (marker: string, coordinates: string) => {
        let imageSize = "601x247@2x";
        let zoom = 13;

        return `https://api.mapbox.com/styles/v1/scalable/cj5e6w98q14a22rs639t1y12i/static/${marker}/${coordinates},${zoom}/${imageSize}?access_token=${mapBoxKey}`;
      };

      switch (report.type) {
        case "people":
          try {
            let mapUrl: string | undefined;
            const coordinateData: any = await getCoordinates([
              {
                fullAddress: report.fullAddress || "",
                city: report.city || "",
                state: report.state,
                postalCode: report.zip,
                latitude: 0,
                longitude: 0,
              },
            ]);

            if (coordinateData[0].error) {
              mapUrl = "";
            } else {
              const { lat: latitude, lon: longitude } = coordinateData[0];
              const marker = `pin-s+${brandInfo.markerColor}(${longitude},${latitude})`;
              const coordinates = `${longitude},${latitude}`;
              mapUrl = generateMap(marker, coordinates);
            }

            setReportContent(
              <ReportTeaser
                reportType="people"
                reportTitle={formatName(report).toUpperCase()}
                reportHighlights={generatePeopleReportHighlights(report)}
                reportDetails={
                  <>
                    <Text tag="span" size="sm">
                      {formatAddress({
                        addressLine1: report.fullAddress,
                      }).toUpperCase()}
                    </Text>
                    <br></br>
                    <Text tag="span" size="sm">
                      {formatAddress(
                        {
                          city: report.city,
                          state: report.state,
                          zip: report.zip,
                        },
                        "with-comma",
                      ).toUpperCase()}
                    </Text>
                  </>
                }
                handleClick={() => {
                  pullPeopleReport(report.reportToken);
                }}
                inputValue={report.reportToken}
                imgUrl={mapUrl}
              />,
            );
          } catch (error) {
            console.error(error);
            setReportContent(<Text tag="h3">An error has occurred</Text>);
          }
          break;
        case "property":
          // Handle property reports
          let mapUrl: string | undefined;
          let longitude = report.longitude;
          let latitude = report.latitude;

          const marker = `pin-s+${brandInfo.markerColor}(${longitude},${latitude})`;
          const coordinates = `${longitude},${latitude}`;
          mapUrl = generateMap(marker, coordinates);

          setReportContent(
            <ReportTeaser
              reportType="property"
              reportTitle={
                <>
                  <span>
                    {formatAddress({
                      addressLine1: report.addressLine1,
                    }).toUpperCase()}
                  </span>
                  <br></br>
                  <span>
                    {formatAddress(
                      {
                        city: report.city,
                        state: report.state,
                        zip: report.zip,
                      },
                      "with-comma",
                    ).toUpperCase()}
                  </span>
                </>
              }
              reportHighlights={generatePropertyReportHighlights(report)}
              handleClick={() => pullPropertyReport(report)}
              inputValue={report}
              imgUrl={mapUrl}
            />,
          );
          break;
        case "phone":
          // Handle phone reports
          try {
            let mapUrl: string | undefined;
            let address: PhoneAddress | undefined;
            // if person address
            if (
              report.personCity &&
              report.personFullAddress &&
              report.personState &&
              report.personZip
            ) {
              address = {
                fullAddress: report.personFullAddress || "",
                city: report.personCity || "",
                state: report.personState,
                postalCode: report.personZip,
                latitude: 0,
                longitude: 0,
              };
              const coordinateData: any = await getCoordinates([address]);

              if (coordinateData[0].error) {
                mapUrl = "";
              } else {
                const { lat: latitude, lon: longitude } = coordinateData[0];
                const marker = `pin-s+${brandInfo.markerColor}(${longitude},${latitude})`;
                const coordinates = `${longitude},${latitude}`;
                mapUrl = generateMap(marker, coordinates);
              }
            } else if (
              report.businessCity &&
              report.businessFullAddress &&
              report.businessState &&
              report.businessZip
            ) {
              address = {
                fullAddress: report.businessFullAddress || "",
                city: report.businessCity || "",
                state: report.businessState,
                postalCode: report.businessZip,
                latitude: 0,
                longitude: 0,
              };
              const coordinateData: any = await getCoordinates([address]);

              if (coordinateData[0].error) {
                mapUrl = "";
              } else {
                const { lat: latitude, lon: longitude } = coordinateData[0];
                const marker = `pin-s+${brandInfo.markerColor}(${longitude},${latitude})`;
                const coordinates = `${longitude},${latitude}`;
                mapUrl = generateMap(marker, coordinates);
              }
            } else {
              mapUrl = "";
            }
            setReportContent(
              <ReportTeaser
                reportType="phone"
                reportTitle={formatPhoneNumber(report.phone)}
                reportHighlights={generatePhoneReportHighlights(report, true)}
                handleClick={() => pullPhoneReport(report.phone)}
                reportDetails={
                  address ? (
                    <>
                      <Text tag="span" size="sm">
                        {formatAddress({
                          addressLine1: address.fullAddress.split(",")[0],
                        }).toUpperCase()}
                      </Text>
                      <br></br>
                      <Text tag="span" size="sm">
                        {formatAddress(
                          {
                            city: address.city,
                            state: address.state,
                            zip: address.postalCode,
                          },
                          "with-comma",
                        ).toUpperCase()}
                      </Text>
                    </>
                  ) : undefined
                }
                inputValue={report}
                imgUrl={mapUrl}
              />,
            );
          } catch (error) {
            console.error(error);
            setReportContent(<Text tag="h3">An error has occurred</Text>);
          }
          break;
        case "social":
          // Handle social reports
          const reportType = renderSocialReportType(report);

          if (reportType) {
            setReportContent(
              <ReportTeaser
                reportType="social"
                reportTitle={report[reportType]?.toUpperCase()}
                reportHighlights={generateSocialReportHighlights(
                  report,
                  reportType,
                )}
                reportDetails={report.location}
                handleClick={() => pullSocialReport(report[reportType]!)}
                inputValue={report[reportType]}
                imgUrl=""
              />,
            );
          } else {
            setReportContent(<Text tag="h3">An error has occurred</Text>);
          }

          break;
        case "auto":
          // Handle auto reports
          setReportContent(
            <ReportTeaser
              reportType="auto"
              reportTitle={
                report.vehicleDescription
                  ? report.vehicleDescription.toUpperCase()
                  : "Vehicle Report"
              }
              reportHighlights={generateList(
                [
                  {
                    value: report.vin,
                    label: "",
                  },
                  {
                    value: report.titleCount
                      ? `${report.titleCount} Title Record${
                          report.titleCount === 1 ? "" : "s"
                        }`
                      : null,
                    label: "",
                  },
                ],
                " | ",
              )}
              handleClick={() => pullAutoReport(report)}
              inputValue={report}
              imgUrl=""
            />,
          );
          break;
        case "neighborhood":
          // Handle neighborhood reports
          let neighborhoodMapUrl: string | undefined;
          const coordinateData: any = await getCoordinates([
            {
              fullAddress: report.addressLine1 || "",
              city: report.city || "",
              state: report.state,
              postalCode: report.zip,
              latitude: 0,
              longitude: 0,
            },
          ]);

          if (coordinateData[0].error) {
            neighborhoodMapUrl = "";
          } else {
            const { lat: latitude, lon: longitude } = coordinateData[0];
            const marker = `pin-s+${brandInfo.markerColor}(${longitude},${latitude})`;
            const coordinates = `${longitude},${latitude}`;
            neighborhoodMapUrl = generateMap(marker, coordinates);
          }

          setReportContent(
            <ReportTeaser
              reportType="neighborhood"
              reportTitle={report && formatAddress(report, "with-comma")}
              reportHighlights={generateNeighborhoodReportHighlights(report)}
              handleClick={() => {
                pullNeighborhoodReport({
                  searchMethod: "address",
                  addressSearch: { ...report, address: report.addressLine1 },
                });
              }}
              inputValue={report}
              imgUrl={neighborhoodMapUrl}
            />,
          );
          break;
        default:
          // Handle any unspecified report types
          setReportContent(
            <Text tag="h3">No report type specified or not supported yet</Text>,
          );
      }
      setLoading(false);
    },
    [
      brandInfo,
      mapBoxKey,
      pullPeopleReport,
      pullSocialReport,
      pullPropertyReport,
      pullPhoneReport,
      pullAutoReport,
      pullNeighborhoodReport,
    ],
  );

  useEffect(() => {
    setLoading(true);
    if (mostRecentReport) {
      fetchReportContent(mostRecentReport).catch(console.error);
    } else {
      setReportContent(<Text tag="h3">No most recent report available</Text>);
      setLoading(false);
    }
  }, [mostRecentReport]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Padding left="md" right="md">
      <Container
        containerTitle="Most Recent Report"
        containerId="dashboard__most-recent-report__container"
      >
        <Padding
          space="md"
          breakpointStyles={{
            tablet: { space: "lg" },
            desktop: { space: "lg" },
          }}
        >
          {loading ? (
            <ReportTeaser
              reportType="loading"
              reportTitle={
                <LoadingPlaceholder width={"12.5rem"} height={"1.5rem"} />
              }
              reportHighlights={
                <LoadingPlaceholder width={"15rem"} height={"1.125rem"} />
              }
              reportDetails={
                <LoadingPlaceholder width={"14rem"} height={"1rem"} />
              }
              handleClick={() => {}}
              inputValue={"loading"}
              loading={true}
            />
          ) : (
            reportContent
          )}
        </Padding>
      </Container>
    </Padding>
  );
};

export default MostRecentReport;
