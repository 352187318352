import {
  ResetPasswordParams,
  NewPasswordParams,
  SetCookieParams,
  CreateCustomerRedisTokenParams,
  AddIdProtectParams,
  GetSsoKeyParams,
  RateLimitParams,
  MemberEvent,
  SendEmailVerificationParams,
  VerifyEmailParams,
} from "./adminTypes";

export function sendResetPasswordEmail(params: ResetPasswordParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/resetPasswordEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data.result.success);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function newPassword(params: NewPasswordParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/newPassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data.success);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function setCookie(params: SetCookieParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/setCookie", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function createCustomerRedisToken(
  params: CreateCustomerRedisTokenParams,
) {
  return new Promise((resolve, reject) => {
    fetch("/api/createCustomerRedisToken", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function addIdProtect(params: AddIdProtectParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/addIdProtect", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getIdProtectSsoKey(params: GetSsoKeyParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/getIdProtectSsoKey", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function rateLimit(params: RateLimitParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/rateLimit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function sendMemberEvent(params: MemberEvent) {
  return new Promise((resolve, reject) => {
    fetch("/api/sendMemberEvent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function sendEmailVerification(params: SendEmailVerificationParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/sendEmailVerification", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function verifyEmailApi(params: VerifyEmailParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/verifyEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
