import { convertStateToAbbreviation } from "./convertStateAbbreviation";
import { capFirstLetter } from "./fullCapsText";
import { generateList } from "./generateList";

export interface AddressObject {
  addressLine1?: string;
  addressLine2?: string;
  fullStreetAddress?: string;
  fullAddress?: string; // for peopleReport
  city?: string;
  state?: string;
  zip?: string;
  postalCode?: string;
}

export type Format = "with-comma" | "with-postalCode" | "shortened" | "";

export const formatAddress = (
  address: AddressObject,
  format: Format = "",
): string => {
  switch (format) {
    case "with-postalCode":
      return generateList(
        [
          {
            value: address.fullStreetAddress
              ? address.fullStreetAddress
              : address.addressLine1
                ? address.addressLine1
                : address.fullAddress
                  ? address.fullAddress
                  : null,
            label: " ",
          },
          {
            value: address.addressLine2
              ? capFirstLetter(address.addressLine2)
              : null,
            label: " ",
          },
          {
            value: generateList(
              [
                {
                  value: address.city ? address.city : null,
                  label: " ",
                },
                {
                  value: generateList(
                    [
                      {
                        value: address.state ? address.state : null,
                        label: " ",
                      },
                      {
                        value: address.postalCode
                          ? address.postalCode.split("-")[0]
                          : null,
                        label: " ",
                      },
                    ],
                    " ",
                  ),
                  label: " ",
                },
              ],
              ",",
            ),
            label: "",
          },
        ],
        ",",
      );
    case "with-comma":
      return generateList(
        [
          {
            value: address.fullStreetAddress
              ? address.fullStreetAddress
              : address.addressLine1
                ? address.addressLine1
                : address.fullAddress
                  ? address.fullAddress
                  : null,
            label: " ",
          },
          {
            value: address.addressLine2
              ? capFirstLetter(address.addressLine2)
              : null,
            label: " ",
          },
          {
            value: address.city ? address.city : null,
            label: " ",
          },
          {
            value: generateList(
              [
                {
                  value: address.state ? address.state : null,
                  label: " ",
                },
                {
                  value: address.zip ? address.zip.split("-")[0] : null,
                  label: " ",
                },
              ],
              "",
            ),
            label: "",
          },
        ],
        ", ",
      );
    case "shortened":
      return generateList(
        [
          {
            value: address.fullStreetAddress
              ? address.fullStreetAddress
              : address.addressLine1
                ? address.addressLine1
                : address.fullAddress
                  ? address.fullAddress
                  : null,
            label: " ",
          },
          {
            value: address.addressLine2
              ? capFirstLetter(address.addressLine2)
              : null,
            label: " ",
          },
          {
            value: generateList(
              [
                {
                  value: address.city ? address.city : null,
                  label: " ",
                },
                {
                  value: generateList(
                    [
                      {
                        value: address.state
                          ? convertStateToAbbreviation(address.state)
                          : null,
                        label: " ",
                      },
                      {
                        value: address.zip ? address.zip.split("-")[0] : null,
                        label: " ",
                      },
                    ],
                    " ",
                  ),
                  label: " ",
                },
              ],
              ",",
            ),
            label: "",
          },
        ],
        "",
      );

    default:
      return generateList(
        [
          {
            value: address.fullStreetAddress
              ? address.fullStreetAddress
              : address.addressLine1
                ? address.addressLine1
                : address.fullAddress
                  ? address.fullAddress
                  : null,
            label: " ",
          },
          {
            value: address.addressLine2
              ? capFirstLetter(address.addressLine2)
              : null,
            label: " ",
          },
          {
            value: address.city ? address.city : null,
            label: " ",
          },
          {
            value: generateList(
              [
                {
                  value: address.state ? address.state : null,
                  label: " ",
                },
                {
                  value: address.zip ? address.zip : null,
                  label: " ",
                },
              ],
              ", ",
            ),
            label: "",
          },
        ],
        " ",
      );
  }
};
