import React from "react";
import { camelCaseToTitle } from "./";

export interface KeyAndValueFormat {
  key: string[]; // what keys to format
  keyFormat?: (key: string) => string; // how to format the key
  valueFormat?: (
    value: string,
    object: any,
  ) => string | React.ReactNode | undefined; // how to format the value
}

export const renderObjectPrep = (
  object: any,
  blacklist: string[] | undefined,
  keyAndValueFormatting: KeyAndValueFormat[] | undefined,
) => {
  const renderObject: any = {};

  Object.keys(object).forEach(originalKey => {
    if (blacklist?.includes(originalKey) || object[originalKey] === "") {
      return;
    }

    let key = originalKey;
    let value = object[originalKey];
    let formatted = false;

    if ((Array.isArray(value) && value.length === 0) || value === "") {
      return;
    }

    if (Array.isArray(value) && value.every(item => item === "")) {
      return;
    }

    if (keyAndValueFormatting) {
      keyAndValueFormatting
        .filter(format => format.key.includes(originalKey))
        .forEach(format => {
          if (format.keyFormat) {
            key = format.keyFormat(key);
            formatted = true;
          }
          if (format.valueFormat) {
            const formattedValue = format.valueFormat(value, object);
            // Skip if valueFormat returns null or undefined
            if (formattedValue === undefined || formattedValue === null) {
              return;
            }
            value = formattedValue;
          }
        });
    }

    if (!formatted) {
      key = camelCaseToTitle(key);
    }

    // Check if value is an object and not a React node
    if (
      value !== null &&
      typeof value === "object" &&
      !React.isValidElement(value)
    ) {
      if (Array.isArray(value)) {
        // Process array of objects
        value.forEach((item, index) => {
          if (typeof item === "object" && item !== null) {
            const nestedObject = renderObjectPrep(
              item,
              blacklist,
              keyAndValueFormatting,
            );
            // Append each property from the nested object
            Object.keys(nestedObject).forEach(nestedKey => {
              renderObject[`${key}_${index}_${nestedKey}`] =
                nestedObject[nestedKey];
            });
          } else {
            renderObject[`${key}_${index}`] = item;
          }
        });
      } else {
        // Process single object
        const nestedObject = renderObjectPrep(
          value,
          blacklist,
          keyAndValueFormatting,
        );
        // Append each property from the nested object
        Object.keys(nestedObject).forEach(nestedKey => {
          renderObject[`${key}_${nestedKey}`] = nestedObject[nestedKey];
        });
      }
    } else {
      renderObject[key] = value;
    }
  });

  return renderObject;
};

export const renderArrayOfObjectsPrep = (
  array: any[],
  blacklist: string[] | undefined,
  keyAndValueFormatting: KeyAndValueFormat[] | undefined,
) => {
  return array
    .map(object => renderObjectPrep(object, blacklist, keyAndValueFormatting))
    .filter(object => Object.keys(object).length > 0);
};
