import { useAppDispatch, useAppSelector } from "./hooks";

import {
  getMonitoringAsync,
  getPropertyMonitoringAsync,
  selectMonitoringStatus,
  toggleMonitoringAsync,
  togglePropertyMonitoringAsync,
} from "../features/monitoring/monitoringSlice";
import { useState } from "react";
import { PropertyMonitoringParamsInterface } from "../features/monitoring/monitoringAPI";

export interface MonitoringInterface {
  reportToken: string;
  version: string;
}

export interface UseMonitoringHookReturn {
  toggleMonitoring: (monitoringReport: MonitoringInterface) => void;
  monitoringSuccess: "success" | "failed" | "";
}

export interface UsePropertyMonitoringHookReturn {
  togglePropertyMonitoring: (
    monitoringReport: PropertyMonitoringParamsInterface,
  ) => void;
  monitoringSuccess: "success" | "failed" | "";
}

const useToggleMonitoringDispatch = (): UseMonitoringHookReturn => {
  const dispatch = useAppDispatch();
  const monitoringStatus = useAppSelector(selectMonitoringStatus);
  const [monitoringSuccess, setMonitoringSuccess] = useState<
    "success" | "failed" | ""
  >("");

  const toggleMonitoring = (monitoringReport: MonitoringInterface) => {
    if (monitoringStatus === "loading") return;
    dispatch(toggleMonitoringAsync(monitoringReport)).then(action => {
      if (toggleMonitoringAsync.fulfilled.match(action)) {
        if (action.payload.toggleState) {
          setMonitoringSuccess("success");
          dispatch(getMonitoringAsync());
        } else {
          setMonitoringSuccess("");
        }
      } else {
        setMonitoringSuccess("failed");
      }
    });
  };

  return { toggleMonitoring, monitoringSuccess };
};

const useTogglePropertyMonitoringDispatch =
  (): UsePropertyMonitoringHookReturn => {
    const dispatch = useAppDispatch();
    const monitoringStatus = useAppSelector(selectMonitoringStatus);
    const [monitoringSuccess, setMonitoringSuccess] = useState<
      "success" | "failed" | ""
    >("");

    const togglePropertyMonitoring = (
      monitoringReport: PropertyMonitoringParamsInterface,
    ) => {
      if (monitoringStatus === "loading") return;
      dispatch(togglePropertyMonitoringAsync(monitoringReport)).then(action => {
        if (togglePropertyMonitoringAsync.fulfilled.match(action)) {
          if (action.payload.toggleState) {
            setMonitoringSuccess("success");
            dispatch(getPropertyMonitoringAsync());
          } else {
            setMonitoringSuccess("");
          }
        } else {
          setMonitoringSuccess("failed");
        }
      });
    };

    return { togglePropertyMonitoring, monitoringSuccess };
  };

export { useToggleMonitoringDispatch, useTogglePropertyMonitoringDispatch };
