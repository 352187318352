export interface LoginParams {
  email: string;
  password: string;
}

export interface SetMemberExperienceParams {
  key: string;
  value: any
}

export function login({ email, password }: LoginParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
      credentials: "include",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    fetch("/api/logout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function verifyCookie() {
  return new Promise((resolve, reject) => {
    fetch("/api/verifyCookie", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getMemberExperienceData() {
  return new Promise((resolve, reject) => {
    fetch("/api/MemberExperience", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function setMemberExperienceData(params: SetMemberExperienceParams,) {
  return new Promise((resolve, reject) => {
    fetch("/api/setMemberExperience", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
