export const sumArraysWithinObjectsInArray = (
  array: Object[],
  keys: string[],
) => {
  let count = 0;
  array.forEach((object: any) => {
    keys.forEach(key => {
      count += object[key] ? object[key].length : 0;
    });
  });
  return count;
};
