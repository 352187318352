import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Text,
  Map,
  LoadingPlaceholder,
  Icon,
  IconList,
} from "@kidslivesafe/sc-react";

import { selectMapboxKey } from "../../../features/brand/brandSlice";

import { selectPhoneReportStatus } from "../../../features/reports/reportsSlice";

import { PhoneAddress } from "../../../features/reports/reportTypes";

const PossibleLocations: React.FC<{
  sectionId: string;
  addresses: PhoneAddress[];
  printReady?: boolean;
}> = ({ sectionId, addresses, printReady }) => {
  const mapBoxKey = useAppSelector(selectMapboxKey);
  const peopleReportStatus = useAppSelector(selectPhoneReportStatus);
  const loading = peopleReportStatus === "loading";

  /* Map Prep */
  const preparePinData = (addresses: PhoneAddress[]) => {
    const groupedAddresses: { [key: string]: PhoneAddress[] } = {};

    addresses.forEach(address => {
      const key = `${address.latitude},${address.longitude}`;
      if (!groupedAddresses[key]) {
        groupedAddresses[key] = [];
      }
      groupedAddresses[key].push(address);
    });
    const pinData = Object.keys(groupedAddresses).map(key => {
      const group = groupedAddresses[key];
      const { latitude, longitude } = group[0];

      return {
        latitude,
        longitude,
        popup: (
          <>
            {group.map((address, index) => (
              <div key={index}>
                <Text fontWeight="bold" size="sm">
                  {address.name}
                </Text>
              </div>
            ))}
            <Text size="xxs">
              {group[0].fullAddress}, {group[0].city}, {group[0].state}{" "}
              {group[0].postalCode}
            </Text>
          </>
        ),
        element: (
          <Text tag="div" color="primary">
            <Icon name={IconList.IconPin} size={21} />
          </Text>
        ),
      };
    });

    return pinData;
  };

  return (
    <>
      {((addresses && addresses.length !== 0) || loading) && !printReady ? (
        <Container
          containerTitle={
            loading
              ? "Possible Locations"
              : `Possible Locations (${
                  addresses.length > 0 ? addresses.length : 0
                })`
          }
          tooltipContent="Locations are included for the registration of the phone itself as well as any persons or businesses found to be associated with the phone record.  See more detail within the Business and People results sections below."
          containerId={sectionId}
        >
          {loading ? (
            <LoadingPlaceholder width="100%" height="400px" />
          ) : (
            <Map
              mapboxToken={mapBoxKey}
              markerData={
                addresses.length > 0 ? preparePinData(addresses) : undefined
              }
              center={
                addresses.length > 0 && addresses[0].latitude !== 0
                  ? [addresses[0].longitude, addresses[0].latitude]
                  : undefined
              }
            />
          )}
        </Container>
      ) : null}
    </>
  );
};

export default PossibleLocations;
