import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  LoadingPlaceholder,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

import { AutoReportType } from "../../../features/reports/reportTypes";
import { generateList } from "@kidslivesafe/sc-foundation";
import { capFirstLetter } from "../../../utils";

const Overview: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);

  // pulledReport = testData as AutoReportType; //TODO: remove this line

  const loading = autoReportStatus === "loading";

  const totalJunkAndSalvage = () => {
    const junkAndSalvageCount =
      pulledReport.vehicleSummary.junkAndSalvageCount ?? 0;
    const insuranceCount = pulledReport.vehicleSummary.insuranceCount ?? 0;
    return junkAndSalvageCount + insuranceCount;
  };

  return (
    <Padding
      space="none"
      top="md"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container containerId={containerId} containerTitle="Overview">
        <Container altColor="grey">
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "xs", direction: "column" }}>
              {loading ||
              (pulledReport &&
                pulledReport.vehicleSummary.vehicleDescription) ? (
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="accent">
                    <Icon name={IconList.IconCar} size={20} />
                  </Text>
                  {loading ? (
                    <LoadingPlaceholder width="50%" height="20px" noGrow />
                  ) : (
                    <Text size="sm">
                      {pulledReport.vehicleSummary.vehicleDescription}
                    </Text>
                  )}
                </Layout>
              ) : null}
              {loading ||
              (pulledReport &&
                (pulledReport.vehicleSummary.transmissionOverview ||
                  pulledReport.vehicleSummary.engineOverview)) ? (
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="accent">
                    <Icon name={IconList.IconGears} size={20} />
                  </Text>
                  {loading ? (
                    <LoadingPlaceholder width="50%" height="20px" noGrow />
                  ) : (
                    <Text size="sm">
                      {generateList(
                        [
                          {
                            value:
                              pulledReport.vehicleSummary.transmissionOverview,
                            label: "",
                          },
                          {
                            value: pulledReport.vehicleSummary.engineOverview,
                            label: "",
                          },
                        ],
                        " | ",
                      )}
                    </Text>
                  )}
                </Layout>
              ) : null}
              {loading ||
              (pulledReport && pulledReport.vehicleSummary.styleOverview) ? (
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="accent">
                    <Icon name={IconList.IconSteering} size={20} />
                  </Text>
                  {loading ? (
                    <LoadingPlaceholder width="50%" height="20px" noGrow />
                  ) : (
                    <Text size="sm">
                      {capFirstLetter(
                        pulledReport.vehicleSummary.styleOverview,
                      )}
                    </Text>
                  )}
                </Layout>
              ) : null}
              {loading ||
              (pulledReport &&
                pulledReport.vehicleSummary.drivetrainOverview) ? (
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="accent">
                    <Icon name={IconList.IconWheel} size={20} />
                  </Text>
                  {loading ? (
                    <LoadingPlaceholder width="50%" height="20px" noGrow />
                  ) : (
                    <Text size="sm">
                      {pulledReport.vehicleSummary.drivetrainOverview}
                    </Text>
                  )}
                </Layout>
              ) : null}
              {loading ||
              (pulledReport && pulledReport.vehicleSummary.mileageOverview) ? (
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="accent">
                    <Icon name={IconList.IconGas} size={20} />
                  </Text>
                  {loading ? (
                    <LoadingPlaceholder width="50%" height="20px" noGrow />
                  ) : (
                    <Text size="sm">
                      {generateList(
                        [
                          {
                            value: pulledReport.vehicleSummary.mileageOverview,
                            label: "",
                          },
                        ],
                        " | ",
                      )}
                    </Text>
                  )}
                </Layout>
              ) : null}
              {loading || (pulledReport && pulledReport.vehicleSummary.msrp) ? (
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="accent">
                    <Icon name={IconList.IconMoney} size={20} />
                  </Text>
                  {loading ? (
                    <LoadingPlaceholder width="50%" height="20px" noGrow />
                  ) : (
                    <Text size="sm">{pulledReport.vehicleSummary.msrp}</Text>
                  )}
                </Layout>
              ) : null}
            </Layout>
          </Padding>
        </Container>
        <Padding space="md" bottom="none">
          <Text size="xs">Source: NMVTIS</Text>
        </Padding>
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemDetails
            numberToLoad={3}
          />
        ) : (
          pulledReport && (
            <CollapsableItemList>
              <ContainerLineItem
                lineItemHeader={`${
                  pulledReport.vehicleSummary.titleCount
                    ? pulledReport.vehicleSummary.titleCount
                    : 0
                } Title Record${
                  pulledReport.vehicleSummary.titleCount === 1 ? "" : "s"
                } Found`}
                lineItemDetails={
                  <Text
                    tag="div"
                    color={
                      !pulledReport.vehicleSummary.titleCount ||
                      pulledReport.vehicleSummary.titleCount === 0
                        ? "error"
                        : "success"
                    }
                  >
                    <Icon
                      name={
                        !pulledReport.vehicleSummary.titleCount ||
                        pulledReport.vehicleSummary.titleCount === 0
                          ? IconList.IconWarning
                          : IconList.IconCircleCheck
                      }
                      size={20}
                    />
                  </Text>
                }
              />
              <ContainerLineItem
                lineItemHeader={
                  pulledReport.vehicleSummary.titleBrandCount
                    ? `${pulledReport.vehicleSummary.titleBrandCount} Title Brands Reported`
                    : `0 Title Brands Reported`
                }
                lineItemDetails={
                  <Text
                    tag="div"
                    color={
                      !pulledReport.vehicleSummary.titleBrandCount ||
                      pulledReport.vehicleSummary.titleBrandCount === 0
                        ? "success"
                        : "error"
                    }
                  >
                    <Icon
                      name={
                        !pulledReport.vehicleSummary.titleBrandCount ||
                        pulledReport.vehicleSummary.titleBrandCount === 0
                          ? IconList.IconCircleCheck
                          : IconList.IconWarning
                      }
                      size={20}
                    />
                  </Text>
                }
              />
              <ContainerLineItem
                lineItemHeader={
                  totalJunkAndSalvage() > 0
                    ? `${totalJunkAndSalvage()} Junk and Salvage Records Found`
                    : ` 0 Junk and Salvage Records Found`
                }
                lineItemDetails={
                  <Text
                    tag="div"
                    color={totalJunkAndSalvage() > 0 ? "error" : "success"}
                  >
                    <Icon
                      name={
                        totalJunkAndSalvage() > 0
                          ? IconList.IconWarning
                          : IconList.IconCircleCheck
                      }
                      size={20}
                    />
                  </Text>
                }
              />
            </CollapsableItemList>
          )
        )}
      </Container>
    </Padding>
  );
};

export default Overview;
