import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useSocialSearchDispatch } from "../../hooks/socialSearchHook";

interface TESTPullSocialReportProps {}

const TestPullSocialReport: React.FC<TESTPullSocialReportProps> = () => {
  const pullPeopleReport = useSocialSearchDispatch();
  const { social } = useParams();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    if (social && !hasFetchedReport.current) {
      pullPeopleReport(social);
      hasFetchedReport.current = true;
    }
  }, [social, pullPeopleReport]);

  return <div>Pulling Report</div>;
};

export default TestPullSocialReport;
