import { useParams } from "react-router-dom";
import { usePeopleReportDispatch } from "../../hooks/peopleReportHook";
import { useEffect, useRef } from "react";

interface TESTPullPeopleReportProps {}

const TestPullPeopleReport: React.FC<TESTPullPeopleReportProps> = () => {
  const pullPeopleReport = usePeopleReportDispatch();
  const { reportToken } = useParams();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    if (reportToken && !hasFetchedReport.current) {
      pullPeopleReport(reportToken);
      hasFetchedReport.current = true;
    }
  }, [reportToken, pullPeopleReport]);

  return <div>Pulling Report</div>;
};

export default TestPullPeopleReport;
