import React from "react";
import { selectCustomerId } from "../../features/authentication/authenticationSlice";

import { useAppSelector } from "../../hooks/hooks";

export interface FlexAccessLayouProps {
  children: React.ReactNode;
  pageName: string;
  documentTitle?: string;
  header?: boolean;
  footerType?: "default" | "no-footer" | "min-footer";
  minFooterOptions?: {
    color?: "base" | "white";
  };
  noFooter?: boolean;
  displayButtons?: boolean;
}

const flexAccessLayout = <P extends {}>(Component: React.ComponentType<P>) => {
  const LoginOrLogoutLayout: React.FC<P & FlexAccessLayouProps> = props => {
    const customerId = useAppSelector(selectCustomerId);
    if (!customerId) {
      props = {
        ...props,
        displayButtons: false,
        footerType: "min-footer",
      };
    }

    return <Component {...props} />;
  };

  return LoginOrLogoutLayout;
};

export default flexAccessLayout;
