import {
  GetCoordinatesParams,
  GetReportDifferenceParams,
  PullPeopleReportParams,
  SearchPeopleReportParams,
  PullPropertyReportParams,
  SocialSearch,
  PullPhoneReportParams,
  GetRentDataParams,
  SubmitComplaintParams,
  PropertyReportDifferenceParams,
  GetPropertyPOIsParams,
} from "./reportTypes/";

import { store } from "../../store/index";
import { logoutWithRedirect } from "../authentication/authenticationSlice";
import { rateLimitAsync } from "../admin/adminSlice";
import { PullAutoReportParams } from "./reportTypes/autoReportTypes";
import {
  PullNeighborhoodReportParams,
  PullNeighborhoodSearchParams,
} from "./reportTypes/neighborhoodReportTypes";

export function getReportHistory() {
  return new Promise((resolve, reject) => {
    fetch("/api/getReportHistory", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function pullPeopleReport({ reportToken }: PullPeopleReportParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/pullPeopleReport", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ reportToken }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "pullPeopleReport" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          if (data.message === "Plan Exceeded") {
            //TODO: HANDLE USER NOTIFICATION (Should this happen in a component?)
            alert(
              "Your membership has exceeded normal usage for the given billing period.  Please contact member support to resolve this issue.",
            );
            console.log("People:", data.message);
          }
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getReportDifference({
  reportToken,
  versionA,
  versionB,
}: GetReportDifferenceParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/getReportDifference", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ reportToken, versionA, versionB }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function searchPeople({
  firstName,
  lastName,
  minAge,
  maxAge,
  city,
  state,
}: SearchPeopleReportParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/searchPeople", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        firstName,
        lastName,
        minAge,
        maxAge,
        city,
        state,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "searchPeople" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getCoordinates(addressArray: GetCoordinatesParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/getCoordinates", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ addresses: addressArray }),
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
}

export function pullPropertyReport({
  addressLine1,
  addressLine2,
  state,
  city,
  zip,
  latitude,
  longitude,
  nearbySchoolRadius,
}: PullPropertyReportParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/pullPropertyReport", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        addressLine1,
        addressLine2,
        state,
        city,
        zip,
        latitude,
        longitude,
        nearbySchoolRadius,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "pullPropertyReport" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          if (data.message === "Plan Exceeded") {
            //TODO: HANDLE USER NOTIFICATION (Should this happen in a component?)
            alert(
              "Your membership has exceeded normal usage for the given billing period.  Please contact member support to resolve this issue.",
            );
            console.log("Property:", data.message);
          }
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function propertyReportDifference({
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  versionA,
  versionB,
}: PropertyReportDifferenceParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/propertyReportDifference", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        versionA,
        versionB,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.success);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getNeighborhoodPOIs({
  zip,
  zip4,
  variant,
}: GetPropertyPOIsParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/getNeighborhoodPOIs", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ zip, zip4, variant }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.result);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function socialSearch({ search }: SocialSearch) {
  return new Promise((resolve, reject) => {
    fetch("/api/socialProfileSearch", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ search }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "socialSearch" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          if (data.message === "Plan Exceeded") {
            //TODO: HANDLE USER NOTIFICATION (Should this happen in a component?)
            alert(
              "Your membership has exceeded normal usage for the given billing period.  Please contact member support to resolve this issue.",
            );
            console.log("Social:", data.message);
          }
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function pullPhoneReport(phoneNumber: PullPhoneReportParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/phoneSearch", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ phoneNumber }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "pullPhoneReport" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          if (data.message === "Plan Exceeded") {
            //TODO: HANDLE USER NOTIFICATION (Should this happen in a component?)
            alert(
              "Your membership has exceeded normal usage for the given billing period.  Please contact member support to resolve this issue.",
            );
            console.log("Phone:", data.message);
          }
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function submitComplaint({
  isCall,
  MultipleFrequency,
  RecordedMessage,
  phoneNumber,
}: SubmitComplaintParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/submitPhoneComplaint", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        isCall,
        MultipleFrequency,
        RecordedMessage,
        phoneNumber,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "submitPhoneComplaint" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getRentData({
  AddressLine1,
  State,
  City,
  Zip,
}: GetRentDataParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/getRentData", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        AddressLine1,
        State,
        City,
        Zip,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store.dispatch(rateLimitAsync({ action: "getRentData" })).then(() => {
            store.dispatch(logoutWithRedirect());
            reject({ message: "Rate Limit, authorization denied" });
          });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function pullAutoReport({
  vin,
  fields,
  state,
  plate,
}: PullAutoReportParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/pullAutoReport", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ vin, fields, state, plate }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "pullAutoReport" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data);
        } else {
          if (data.message === "Plan Exceeded") {
            //TODO: HANDLE USER NOTIFICATION (Should this happen in a component?)
            alert(
              "Your membership has exceeded normal usage for the given billing period.  Please contact member support to resolve this issue.",
            );
            console.log("Auto:", data.message);
          }
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function pullNeighborhoodReportByLocation({
  addressSearch,
  searchMethod,
}: PullNeighborhoodReportParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/searchByAddress", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ addressSearch, searchMethod }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "neighborhoodSearchByAddress" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.report);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function pullNeighborhoodSearch({
  firstName,
  lastName,
}: PullNeighborhoodSearchParams) {
  return new Promise((resolve, reject) => {
    fetch("/api/searchByName", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ firstName, lastName }),
    })
      .then(response => {
        if (response.status === 401) {
          store.dispatch(logoutWithRedirect());
          reject({ message: "No token, authorization denied" });
        } else if (response.status === 429) {
          store
            .dispatch(rateLimitAsync({ action: "neighborhoodSearchByName" }))
            .then(() => {
              store.dispatch(logoutWithRedirect());
              reject({ message: "Rate Limit, authorization denied" });
            });
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data.success) {
          resolve(data.search);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
