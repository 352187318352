import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

type SocialUrls =
  | "facebook"
  | "twitter"
  | "instagram"
  | "linkedin"
  | "youtube"
  | "tiktok";

export interface BrandDetails {
  acronym: string;
  name: string;
  phoneNumber: string;
  email: string;
  address: {
    line1: string;
    line2: string;
  };
  homeUrl: string;
  footerSocials: SocialUrls[];
  socialUrls: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    linkedin?: string;
    youtube?: string;
    tiktok?: string;
  };
  idProtectUrl: string;
  idProtectPrice: string;
  markerColor: string;
  reviewsUrl?: string; // TODO: check if SF is the only one that has reviews?
  captchaSiteKey: string;
  unsubscribeUrl: string;
  mapboxKey: string;
  downsellPrice?: string; // TODO: check if PIS doesnt have downsell?
  llcName: string;
  siteJabberId: string;
  rollbarId: string; // This is client side post access token
  gtmId: string;
}

const sc_brand = process.env.REACT_APP_SC_BRAND || "agnostic";
const node_env = process.env.REACT_APP_NODE_ENV;

let initialState: BrandDetails;

// TODO: Finish differentiation between production and default
// prr captcha site key 6LdeIwQlAAAAAN4ZC0M7vwlysnb4DO9JGbT1_beb

switch (sc_brand) {
  case "kls":
    initialState = {
      acronym: "kls",
      name: "Kids Live Safe",
      phoneNumber: "8003015905",
      email: "support@kidslivesafe.com",
      address: {
        line1: "3905 State Street, Suite 7-228",
        line2: "Santa Barbara, CA 93105",
      },
      homeUrl: "https://staging.kidslivesafe.com",
      footerSocials: ["facebook", "instagram", "youtube"],
      socialUrls: {
        facebook: "https://www.facebook.com/KidsLiveSafe",
        instagram: "https://www.instagram.com/kidslivesafereviews/",
        youtube: "https://www.youtube.com/user/KidsLiveSafe",
      },
      idProtectUrl: "https://idprotect.kidslivesafe.com/",
      idProtectPrice: "1.93",
      markerColor: "3e7dc6",
      captchaSiteKey: "6Leee38UAAAAAB5rJO6VlKNdC2zFuUCZmulukb8Z",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-f91-8b9fdf3c0c61db5f0051141ef41f24c4",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2pwZWcxenY4MTVjNTNrbnJ6OWx6a2cwbSJ9.6ovFmwbr5DYuBsjPw68PIQ",
      downsellPrice: "2.96",
      llcName: "Scalable Commerce",
      siteJabberId: "5310037e10987",
      rollbarId: "069dd50e57e84ccda39cb98b275f9033",
      gtmId: "",
    };
    break;
  case "sf":
    initialState = {
      acronym: "sf",
      name: "SpyFly",
      phoneNumber: "8008319235",
      email: "support@spyfly.com",
      address: {
        line1: "1804 Garnet Ave, Suite 409",
        line2: "San Diego, CA 92109",
      },
      homeUrl: "https://staging.spyfly.com",
      footerSocials: ["facebook", "linkedin", "tiktok", "youtube"],
      socialUrls: {
        facebook: "https://www.facebook.com/MySpyFly/",
        twitter: "https://twitter.com/MySpyFly",
        youtube: "https://www.youtube.com/channel/UCffBDs5LhGLNYQ2rfNu1gRQ",
        tiktok: "https://www.tiktok.com/@getspyfly",
        linkedin: "https://www.linkedin.com/company/spyfly",
      },
      idProtectUrl: "https://idprotect.spyfly.com/",
      idProtectPrice: "1.96",
      markerColor: "1B5299",
      captchaSiteKey: "6LduNkYUAAAAAIL_oIsd8Bw4yKTZhoMyNlVDAU3T",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-g42-87b7d9ecfd495f6c49a71248cd64eae9",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2prMnk1b2RnMGUzdTNxbzBxbW5od2Y1YiJ9.ZA5Bugx-0AEMhiiVhdrIgw",
      downsellPrice: "2.97",
      llcName: "Digital Safety Products",
      siteJabberId: "53862a3b9fa1c",
      rollbarId: "45e91a6992d14ec69cb7c5e5d3d2ff06",
      gtmId: "566Z6GPP",
    };
    break;
  case "pis":
    initialState = {
      acronym: "pis",
      name: "Public Information Services",
      phoneNumber: "8006173169",
      email: "support@publicinfoservices.com",
      address: {
        line1: "7127 Hollister Ave., Suite 25 A-314",
        line2: "Goleta, CA 93117",
      },
      homeUrl: "https://staging.publicinfoservices.com",
      footerSocials: ["facebook", "twitter", "instagram"],
      socialUrls: {
        facebook: "https://www.facebook.com/publicinformationservices",
        twitter: "https://twitter.com/publicinfoserv",
        instagram: "https://www.instagram.com/publicinformationservices/",
      },
      idProtectUrl: "https://idprotect.publicinfoservices.com/",
      idProtectPrice: "1.97",
      markerColor: "285A98",
      captchaSiteKey: "6LfFLkYUAAAAANNTQgvCdXMzko9D3qQwBzLBQmxW",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-c01-3489d12f6a5f414318ee08cb30b8d531",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2prMzRodXRrMGprMTNrcXFkcmRjMWgzdSJ9.5x2yrPBMtjzdP_alLpxGgQ",
      llcName: "Information Data Resources",
      siteJabberId: "5ada252c85f9a",
      rollbarId: "263c6db0193e4729b2cad9ed1cfcda03",
      gtmId: "KZ6WZD4K",
    };
    break;
  case "pdc":
    initialState = {
      acronym: "pdc",
      name: "Public Data Check",
      phoneNumber: "8009500953",
      email: "support@publicdatacheck.com",
      address: {
        line1: "5662 Calle Real, Suite 107",
        line2: "Goleta, CA 93117",
      },
      homeUrl: "https://staging.publicdatacheck.com",
      footerSocials: ["facebook", "twitter", "instagram"],
      socialUrls: {
        facebook: "https://www.facebook.com/publicdatacheck/",
        twitter: "https://twitter.com/publicdatacheck?lang=en",
        tiktok: "https://www.tiktok.com/@publicdatacheck?lang=en",
        instagram: "https://www.instagram.com/publicdatacheck",
      },
      idProtectUrl: "https://idprotect.publicdatacheck.com/",
      idProtectPrice: "1.94",
      markerColor: "003c76",
      captchaSiteKey: "6Le0NkYUAAAAAHpbeo689sEq-SiJqXaSF78QxpJa",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-d41-2f59519660c4036a5a71813bda6c88b4",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2prMnk2Ymc4MHdwbTNxbnRmOHc3NWdrdyJ9.nlVwPaZudVc8j-Xug5kTsA",
      downsellPrice: "2.96",
      llcName: "National Data Analytics",
      siteJabberId: "5cafb135a5a72",
      rollbarId: "552afd90c378468d8e6c76ef94175c0a",
      gtmId: "58G5CGCL",
    };
    break;
  case "spr":
    initialState = {
      acronym: "spr",
      name: "Search Public Records",
      phoneNumber: "8007192498",
      email: "support@searchpublicrecords.com",
      address: {
        line1: "315 Meigs Rd Suite A292",
        line2: "Santa Barbara, CA, 93109",
      },
      homeUrl: "https://staging.searchpublicrecords.com",
      footerSocials: ["facebook", "twitter", "instagram"],
      socialUrls: {
        facebook: "https://www.facebook.com/searchpublicrecords",
        twitter: "https://twitter.com/srchpubrecords",
        instagram: "https://www.instagram.com/searchpublicrecords/",
      },
      idProtectUrl: "https://idprotect.searchpublicrecords.com/",
      idProtectPrice: "1.98",
      markerColor: "D11414",
      captchaSiteKey: "6Ld-uJcUAAAAAB0YwV95VUkWCGgDyJ7so3FaDbRa",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-f12-a8997af92a3484e5e405e6bb4a810190",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY20ydjB6ZzVsMDZ4NzJrcTFidXR2NzdxeiJ9.Wt-IJNs2dorVinLrubF36Q",
      downsellPrice: "2.98",
      llcName: "Civil Data Research",
      siteJabberId: "5cafb157e0bb7",
      rollbarId: "c60b1efde9dc43b1a252a4d2bab040bf",
      gtmId: "MK9V7ZLB",
    };
    break;
  case "prr":
    initialState = {
      acronym: "prr",
      name: "Public Record Reports",
      phoneNumber: "8003262283",
      email: "support@publicrecordreports.com",
      address: {
        line1: "1804 Garnet Ave, Suite 409",
        line2: "San Diego, CA 92109",
      },
      homeUrl: "https://staging.publicrecordreports.com",
      footerSocials: ["facebook", "instagram", "youtube"],
      socialUrls: {
        facebook: "https://www.facebook.com/profile.php?id=100089746244008",
        instagram: "https://www.instagram.com/publicrecordreports/",
        youtube: "https://www.youtube.com/channel/UCSw2lcgqIwmpIpCtwNdRrIQ",
      },
      idProtectUrl: "https://idprotect.publicrecordreports.com/",
      idProtectPrice: "1.95",
      markerColor: "009AA4",
      captchaSiteKey: "6LdeIwQlAAAAAN4ZC0M7vwlysnb4DO9JGbT1_beb",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-m54-f411bec6af8bcc9773a110e1cd8963ac",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2xmaWxpaG54MGg2ODNxcWdlcjlkOTAxNiJ9.z5FJdketNS5fal9S2eZt9w",
      downsellPrice: "2.95",
      llcName: "Digital Safety Products",
      siteJabberId: "642cbb28d7a94",
      rollbarId: "1565645ff41244258c39b4c1ae212dbb",
      gtmId: "WTR5HJVD",
    };
    break;
  case "qpr":
    initialState = {
      acronym: "qpr",
      name: "Quick Public Records",
      phoneNumber: "8332022626",
      email: "support@quickpublicrecords.com",
      address: {
        line1: "3905 State Street, Suite 7-228",
        line2: "Santa Barbara, CA 93105",
      },
      homeUrl: "https://staging.quickpublicrecords.com",
      footerSocials: ["facebook", "instagram", "youtube"],
      socialUrls: {
        facebook: "https://www.facebook.com/profile.php?id=61565708351464",
        youtube: "https://www.youtube.com/channel/UC03Ufn7ZNVsLiD6eelAB4pA",
        instagram: "https://www.instagram.com/quickpublicrecords/",
      },
      idProtectUrl: "https://idprotect.quickpublicrecords.com/", //TO DO
      idProtectPrice: "1.92",
      markerColor: "5A61EC",
      captchaSiteKey: "6Lfv6wYqAAAAAKCkRclE7EF2Jjjbqd-oJQJ-6HFn",
      unsubscribeUrl:
        "https://www.effecthillzinc.com/o-zqmn-q06-c4e0684a06d169a7057494c0ee153e50",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2x5NTBzeTg1MDhnMjJwcTN0M296bDQ4eCJ9.TpBU1kXnelA9SFBNClcFew",
      downsellPrice: "2.92",
      llcName: "Scalable Commerce",
      siteJabberId: "66aaad2d10460",
      rollbarId: "08c0b243d1d24ac5a1fa88d0cbcf358d",
      gtmId: "M4B3NFG3",
    };
    break;
  case "pf":
    initialState = {
      acronym: "pf",
      name: "Property Focus",
      phoneNumber: "8003104347",
      email: "support@propertyfocus.com",
      address: {
        line1: "548 Market St  Suite 57458",
        line2: "San Francisco, CA, 94104",
      },
      homeUrl: "https://staging.propertyfocus.com",
      footerSocials: ["facebook", "instagram", "tiktok", "youtube"], // TODO: update this to pf value
      socialUrls: {
        facebook: "http://www.propertyfocus.com/Facebook", // TODO: update this to pf value
        youtube: "http://www.propertyfocus.com/Youtube", // TODO: update this to pf value
        instagram: "http://www.propertyfocus.com/Instagram", // TODO: update this to pf value
        tiktok: "http://www.propertyfocus.com/TikTok", // TODO: update this to pf value
      },
      idProtectUrl: "https://idprotect.propertyfocus.com/",
      idProtectPrice: "",
      markerColor: "181819",
      captchaSiteKey: "6LeHGlcqAAAAAFABx7RnPhlERv3IZGupzDrHlk3M",
      unsubscribeUrl:
        " https://www.batcrownplate.com/o-kqzp-r56-26a3e6184b48177ca16eda8d08e839fd",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY20xdHNqbzdiMDV5ZTJwcHdlYWZyaGlpNCJ9.rYLy24SkPdaLAhTf0e-hWw",
      downsellPrice: "2.99",
      llcName: "Property Focus LLC",
      siteJabberId: "66aaad54dde55",
      rollbarId: "c3ad26cf11d94cc1a8b85a17676202fc",
      gtmId: "MWN3K222",
    };
    break;
  default: // uses spyfly keys as default
    initialState = {
      acronym: "agnostic",
      name: "Agnostic",
      phoneNumber: "8005555555",
      email: "support@spyfly.com",
      address: {
        line1: "1640 Riverside Drive",
        line2: "Hill Valley, CA, 90382",
      },
      homeUrl: "https://staging.spyfly.com",
      footerSocials: ["facebook", "twitter", "youtube", "linkedin", "tiktok"],
      socialUrls: {
        facebook: "https://www.facebook.com/MySpyFly/",
        twitter: "https://twitter.com/MySpyFly",
        youtube: "https://www.youtube.com/channel/UCffBDs5LhGLNYQ2rfNu1gRQ",
        tiktok: "https://www.tiktok.com/@getspyfly",
        linkedin: "https://www.linkedin.com/company/spyfly",
      },
      idProtectUrl: "https://idprotect.spyfly.com/",
      idProtectPrice: "1.96",
      markerColor: "009AA4",
      captchaSiteKey: "6LduNkYUAAAAAIL_oIsd8Bw4yKTZhoMyNlVDAU3T",
      unsubscribeUrl:
        "https://effecthillzinc.com/o-zqmn-g42-87b7d9ecfd495f6c49a71248cd64eae9",
      mapboxKey:
        "pk.eyJ1Ijoic2NhbGFibGUiLCJhIjoiY2prMnk1b2RnMGUzdTNxbzBxbW5od2Y1YiJ9.ZA5Bugx-0AEMhiiVhdrIgw",
      downsellPrice: "2.97",
      llcName: "Digital Safety Products",
      siteJabberId: "53862a3b9fa1c",
      rollbarId: "45e91a6992d14ec69cb7c5e5d3d2ff06",
      gtmId: "566Z6GPP",
    };
}

if (node_env === "production") {
  switch (sc_brand) {
    case "kls":
      initialState = {
        ...initialState,
        homeUrl: "https://www.kidslivesafe.com",
      };
      break;
    case "sf":
      initialState = {
        ...initialState,
        homeUrl: "https://www.spyfly.com",
      };
      break;
    case "pis":
      initialState = {
        ...initialState,
        homeUrl: "https://www.publicinfoservices.com",
      };
      break;
    case "pdc":
      initialState = {
        ...initialState,
        homeUrl: "https://www.publicdatacheck.com",
      };
      break;
    case "spr":
      initialState = {
        ...initialState,
        homeUrl: "https://www.searchpublicrecords.com",
      };
      break;
    case "prr":
      initialState = {
        ...initialState,
        homeUrl: "https://www.publicrecordreports.com",
      };
      break;
    case "qpr":
      initialState = {
        ...initialState,
        homeUrl: "https://www.quickpublicrecords.com",
      };
      break;
    case "pf":
      initialState = {
        ...initialState,
        homeUrl: "https://www.propertyfocus.com",
      };
      break;
    default: // uses spyfly keys as default
      initialState = {
        ...initialState,
        homeUrl: "https://www.spyfly.com",
      };
  }
}

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
});

/* Getters */
export const selectBrand = (state: RootState) => state.brand;

export const selectBrandInfo = createSelector([selectBrand], brand => {
  return {
    acronym: brand.acronym,
    name: brand.name,
    phoneNumber: brand.phoneNumber,
    email: brand.email,
    address: brand.address,
    homeUrl: brand.homeUrl,
    footerSocials: brand.footerSocials,
    socialUrls: brand.socialUrls,
    llcName: brand.llcName,
    unsubscribeUrl: brand.unsubscribeUrl,
    markerColor: brand.markerColor,
    idProtectPrice: brand.idProtectPrice,
    idProtecturl: brand.idProtectUrl,
    rollbarId: brand.rollbarId,
    gtmId: brand.gtmId,
    siteJabberId: brand.siteJabberId,
  };
});

export const selectMapboxKey = (state: RootState) => state.brand.mapboxKey;
export const selectCaptchaSiteKey = (state: RootState) =>
  state.brand.captchaSiteKey;

export default brandSlice.reducer;
