import React, { useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  AnchorLink,
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Layout,
  Margin,
  Modal,
  Padding,
  Tag,
  Text,
} from "@kidslivesafe/sc-react";

import { AutoReportType } from "../../../features/reports/reportTypes";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import {
  convertStateAbbreviation,
  formatNumberCommas,
  parseDate,
  renderStateAutoContent,
  StateAbbreviation,
} from "../../../utils";

const TitleRecordHistory: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);
  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  // pulledReport = testData as AutoReportType; //TODO: remove this line

  const loading = autoReportStatus === "loading";

  const renderMilage = (milage: string, units?: string): string => {
    if (milage === "EXEMPT") {
      return "Exempt";
    }

    const milageNumber = parseInt(milage);

    var displayUnits = units && units === 'K' ? 'KMs' : 'Miles'
    return formatNumberCommas(milageNumber) + " " + displayUnits;
  };

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Title Record History ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.vehicleSummary
                  ? pulledReport.vehicleSummary.titleCount
                  : 0
              })`
        }`}
      >
        <Padding space="md" bottom="none">
          <Text size="xs">Source: NMVTIS</Text>
        </Padding>
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            lineItemSubHeaderLine2
            lineItemDetails
            numberToLoad={5}
          />
        ) : pulledReport &&
          pulledReport.vehicleTitle &&
          (pulledReport.vehicleTitle.currentTitleInformation ||
            pulledReport.vehicleTitle.currentTitleInformation) ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport?.vehicleTitle?.currentTitleInformation && 
            pulledReport?.vehicleTitle?.currentTitleInformation.map(
              (currentItem, index) => (
              <ContainerLineItem
                key={index}
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    <Text size="base">
                      {currentItem.vehicleOdometerReadingMeasure === "UNKNOWN"
                        ? "Unknown"
                        : renderMilage(
                            currentItem.vehicleOdometerReadingMeasure,
                            currentItem.vehicleOdometerReadingUnitCode
                          )}
                    </Text>
                    {index === 0 ? (
                      <Tag tagText="current" type="primary" />
                    ) : (
                      <Tag tagText="historical" type="secondary" />
                    )}
                  </Layout>
                }
                lineItemSubHeader={
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "row",
                      alignItems: "flex-start",
                      gap: "xxs",
                    }}
                  >
                    <Text size="xs" fontWeight="semibold">
                      Title Issue Date:
                    </Text>
                    <Text size="xs" fontWeight="semibold">
                      {parseDate(
                        currentItem.titleIssueDate.date,
                        "utc:month-day-year",
                      )}
                    </Text>
                  </Layout>
                }
                lineItemSubHeaderLine2={
                  currentItem.vehicleIdentification.identificationID
                }
                subContent={
                  <AnchorLink
                    role="button"
                    handleClick={() => {
                      setModalContent(
                        renderStateAutoContent(
                          currentItem.titleIssuingAuthorityName as StateAbbreviation,
                        ),
                      );
                      setOpenPdfModal(true);
                    }}
                    size="xs"
                    hoverUnderline
                  >
                    {convertStateAbbreviation(
                      currentItem.titleIssuingAuthorityName,
                    )}
                  </AnchorLink>
                }
                isOpen={printReady ? true : false}
              ></ContainerLineItem>
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" fontWeight="bold">
              No results found for Title History
            </Text>
          </Padding>
        )}
      </Container>
      <Modal
        open={openPdfModal}
        setOpen={() => {
          setOpenPdfModal(!openPdfModal);
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Margin top="xxl" bottom="xl">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
            }}
          >
            {modalContent}
          </Layout>
        </Margin>
      </Modal>
    </Padding>
  );
};

export default TitleRecordHistory;
