import React from "react";
import building from "@assets/images/id-protect_building.png";
import wallet from "@assets/images/id-protect_wallet.png";
import home from "@assets/images/id-protect_home.png";
import person from "@assets/images/id-protect_person.png";

import "./IdProtectDoubleIcon.css";

import { Icon, IconList } from "@kidslivesafe/sc-react";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

interface IdProtectDoubleIconProps {
  type: "wallet" | "home" | "person" | "building";
}

const IdProtectDoubleIcon: React.FC<IdProtectDoubleIconProps> = ({ type }) => {
  const currentBreakpoint = useCurrentBreakpoint();

  const selectImage = (type: string) => {
    switch (type) {
      case "wallet":
        return wallet;
      case "building":
        return building;
      case "person":
        return person;
      case "home":
        return home;
    }
  };

  return (
    <div className="id-protect-double-icon_container">
      <div className="id-protect-double-icon_img-container">
        <img
          width={currentBreakpoint === "desktop" ? "44" : "27"}
          src={selectImage(type)}
          alt=""
          className="id-protect-double-icon_img"
        />
        <div className="id-protect-icon-color">
          <Icon
            size={currentBreakpoint === "desktop" ? 31 : 19}
            name={IconList.IconIdProtectCircleCheck}
          ></Icon>
        </div>
      </div>
    </div>
  );
};

export default IdProtectDoubleIcon;
