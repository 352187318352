import visa from "../assets/global/images/credit_card_type--visa.svg";
import masterCard from "../assets/global/images/credit_card_type--masterCard.svg";
import americanExpress from "../assets/global/images/credit_card_type--americanExpress.svg";
import discover from "../assets/global/images/credit_card_type--discover.svg";

export function generateCreditCardLogo(cardType: string | undefined) {
  if (cardType) {
    if (cardType.includes("Visa")) {
      return visa;
    } else if (cardType.includes("MasterCard")) {
      return masterCard;
    } else if (cardType.includes("AmericanExpress")) {
      return americanExpress;
    } else if (cardType.includes("DiscoverCard")) {
      return discover;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
