import {
  Container,
  Layout,
  Padding,
  Spotlight,
  Text,
} from "@kidslivesafe/sc-react";
import React from "react";

import monitoringStatic from "../../assets/global/images/dashboard_monitoring--static.png";

const SpacerMonitoring: React.FC = () => {
  return (
    <Padding
      breakpointStyles={{
        mobile: { space: "none" },
      }}
    >
      <Container containerId="dashboard__spacer-multi-search__container">
        <Padding space="md">
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "md", alignItems: "center" }}
          >
            <Text tag="h2" textAlign="center">
              Set up Report Monitoring
            </Text>
            <Text textAlign="center">
              Get notified when Report information changes by setting up
              monitoring.
            </Text>
            <Spotlight pillshape>
              <Padding space="md">
                <Layout
                  type="flex"
                  flexProps={{ gap: "xs", alignItems: "center" }}
                >
                  <Text fontWeight="semibold" size="sm" unresponsive>
                    MONITORING
                  </Text>
                  <img src={monitoringStatic} alt="toggle--on" />
                </Layout>
              </Padding>
            </Spotlight>
            <Text textAlign="center">
              Once you are on a report page, toggle the monitoring switch to
              the ON position.
            </Text>
          </Layout>
        </Padding>
      </Container>
    </Padding>
  );
};

export default SpacerMonitoring;
