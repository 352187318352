import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectNeighborhoodReport,
  selectNeighborhoodReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  LineItemSummary,
  Padding,
  Table,
  Text,
} from "@kidslivesafe/sc-react";

import { renderArrayOfObjectsPrep } from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { formatAddress, generateList } from "@kidslivesafe/sc-foundation";
import {
  NeighborhoodReport,
  Offender,
} from "../../../features/reports/reportTypes/neighborhoodReportTypes";

const OffenderList: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  let pulledReport = useAppSelector(
    selectNeighborhoodReport,
  ) as NeighborhoodReport;
  const neighborhoodReportStatus = useAppSelector(
    selectNeighborhoodReportStatus,
  );

  const loading = neighborhoodReportStatus === "loading";

  const offenderSummary = renderArrayOfObjectsPrep(
    pulledReport?.offenders || [],
    [
      "totalOffenders",
      "distance",
      "latitude",
      "longitude",
      "createDate",
      "offenderID",
      "name",
      "zipCode",
      "state",
      "city",
    ],
    [
      {
        key: ["offense"],
        keyFormat: () => "Offense(s)",
        valueFormat: (value, currentObject: Offender) => {
          const parsedOffense =
            new DOMParser().parseFromString(currentObject.offense, "text/html")
              .body.textContent || "";

          return parsedOffense;
        },
      },
      {
        key: ["address"],
        valueFormat: (value, currentObject: Offender) => {
          let formattedAddress = {
            addressLine1: value,
            city: currentObject.city,
            state: currentObject.state,
            zip: currentObject.zipCode,
          };
          return formatAddress({ address: formattedAddress });
        },
      },
    ],
  );

  return (
    <Container
      containerId={containerId}
      containerTitle={`Offender List ${
        loading
          ? ""
          : `(${
              pulledReport && pulledReport.offendersCount
                ? pulledReport.offendersCount
                : 0
            })`
      }`}
      tooltipContent="The Offender List below includes all registered offenders within a 5-mile radius of the search location. Expand any entry to view the most current record information."
    >
      {loading ? (
        <LoadingContainerLineItem
          lineItemHeader
          lineItemSubHeader
          lineItemSubHeaderLine2
          numberToLoad={3}
          children
        />
      ) : (
        <CollapsableItemList numberToShow={300}>
          {pulledReport.offenders && pulledReport.offenders.length > 0 ? (
            pulledReport.offenders.map((offender, index) => (
              <ContainerLineItem
                key={`${offender.offenderID}_${index}`}
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    <Text>{offender.name.split(", ").reverse().join(" ")}</Text>
                  </Layout>
                }
                lineItemSubHeader={generateList(
                  [
                    {
                      value: offender.distance
                        ? `${offender.distance} miles away`
                        : null,
                      label: "",
                    },
                    {
                      value: offender.age ? `Age: ${offender.age}` : null,
                      label: "",
                    },
                  ],
                  " | ",
                )}
                lineItemSubHeaderLine2={generateList(
                  [
                    {
                      value: offender.offense
                        ? offenderSummary[index]["Offense(s)"]
                        : null,
                      label: "",
                    },
                  ],
                  "",
                )}
                lineItemSubheaderNoWrap
                pictureUrl={`https://photo.familywatchdog.us/OffenderPhoto/OffenderPhoto.aspx?id=${offender.offenderID}`}
                isOpen={printReady || index === 0 ? true : false}
              >
                {offenderSummary[index] ? (
                  <>
                    <LineItemSummary backgroundColor={false} />
                    <Padding left="md" right="md">
                      <Table
                        tableDataObject={offenderSummary[index]}
                        orderOfProperties={["Offense(s)"]}
                      />
                    </Padding>
                  </>
                ) : null}
              </ContainerLineItem>
            ))
          ) : (
            <ContainerLineItem
              lineItemHeader={
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                >
                  <Text color="success" tag="div">
                    <Icon name={IconList.IconCircleCheck} size={18} />
                  </Text>
                  <Text>0 Offenders Found</Text>
                </Layout>
              }
            />
          )}
        </CollapsableItemList>
      )}
    </Container>
  );
};

export default OffenderList;
