import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux getters
import {
  selectPropertyReport,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// types
import { PropertyOwnershipHistory } from "../../../features/reports/reportTypes";

// components
import { Container, Padding, Text, ShowMore } from "@kidslivesafe/sc-react";

import PropTimelineComponent from "../../../components/UIComponents/PropTimelineComponent/PropTimelineComponent";

const PropertyTimeline: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);

  const loading = propertyReportStatus === "loading";

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [displayTimelineArray, setTimelineArray] = useState<
    PropertyOwnershipHistory[]
  >([]);

  const timelineArray: PropertyOwnershipHistory[] = [];

  pulledReport?.propertyOwnershipHistory?.forEach((element, index) => {
    if (element.saleDateShort) {
      timelineArray.push(element);
    } else {
      if (element.propertyOwnerships) {
        const mortDateArray: string[] = [];
        //find earliest mortgage date if no sale date
        element.propertyOwnerships.forEach((prop, mortgageIndex) => {
          if (prop.mortgages && prop.mortgages.length >= 1) {
            prop.mortgages?.forEach(mort => {
              if (mort.mortgageRecordingDate) {
                mortDateArray.push(mort.mortgageRecordingDate);
              }
            });
          }
        });
        const earliestmortDate = mortDateArray.slice(-1);
        const mortDate = earliestmortDate.toString();
        const propWithMort = { ...element };
        propWithMort.saleDateShort = mortDate;
        propWithMort.showEst = true;
        propWithMort.propertyOwnerships = element.propertyOwnerships;

        if (mortDate) {
          timelineArray.push(propWithMort);
        }
      }
    }
  });
  //handle expand of timeline array
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    if (printReady) {
      setIsExpanded(true);
    }

    setTimelineArray(
      isExpanded || printReady ? timelineArray : timelineArray.slice(0, 3),
    );
  }, [isExpanded, pulledReport, printReady]);
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Property Timeline ${
          loading
            ? ""
            : `(${
                pulledReport
                  ? pulledReport.propertyReportSummary
                      ?.propertyTransactionsDisplayCount
                  : 0
              })`
        }`}
        containerId={containerId}
      >
        {loading ? (
          <PropTimelineComponent
            timelineArray={[]}
            loading={true}
          ></PropTimelineComponent>
        ) : timelineArray.length > 0 ? (
          <>
            <Padding space="md">
              <Text size="base" fontWeight="bold">
                {pulledReport?.propertyReportSummary?.ownershipTimelineOverview}
              </Text>
            </Padding>
            <Padding right="md" left="sm">
              <PropTimelineComponent
                timelineArray={displayTimelineArray}
              ></PropTimelineComponent>
              {timelineArray && timelineArray.length > 3 ? (
                <Padding bottom="md">
                  <ShowMore
                    howManyMore={timelineArray.length - 3}
                    open={isExpanded}
                    handleClick={handleExpand}
                  />
                </Padding>
              ) : null}
            </Padding>
          </>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No Results found for Property Transactions
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default PropertyTimeline;
