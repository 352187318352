import React from "react";
import { Text, AnchorLink } from "@kidslivesafe/sc-react";

import "./SchoolRatingCircle.css";

export interface SchoolRatingCircleProps {
  rating: string;
  url?: string;
}

const SchoolRatingCircle: React.FC<SchoolRatingCircleProps> = ({
  rating,
  url,
}) => {
  const handleOpenGreatSchool = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <div>
      {url ? (
        <>
          <div
            className="score-ellipse"
            onClick={() => {
              handleOpenGreatSchool(url);
            }}
          >
            <span className="score-content">
              <p className="actual-score">{rating ? rating : "?"}</p>
              <p className="out-of-score">/10</p>
            </span>
          </div>
          <AnchorLink
            color="primary"
            fontWeight="normal"
            href={url}
            size="xs"
            target="_blank"
            passThroughClassess="rating-label"
            hoverUnderline
          >
            GreatSchools <br /> Ratings
          </AnchorLink>
        </>
      ) : (
        <>
          <div className="score-ellipse disabled">
            <span className="score-content">
              <p className="actual-score">{rating ? rating : "?"}</p>
              <p className="out-of-score">/10</p>
            </span>
          </div>
          <Text
            color="subtle"
            passThroughClassess="rating-label"
            textAlign="center"
          >
            GreatSchools <br /> Ratings
          </Text>
        </>
      )}
    </div>
  );
};

export default SchoolRatingCircle;
