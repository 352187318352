import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  deleteNeighborhoodWatchMonitoringAreaAsync,
  selectMonitoring,
  selectMonitoringStatus,
  selectNeighborhoodMonitoringAreas,
  selectPropertyMonitoring,
  selectTotalAllowedMonitoredReports,
  setRenameReport,
  setToggleMonitoringModal,
  togglePropertyMonitoringAsync,
} from "../../features/monitoring/monitoringSlice";

import {
  Container,
  Text,
  Layout,
  Monitoring,
  ContainerLineItem,
  ReportLink,
  LoadingPlaceholder,
  Padding,
  MonitoringContainer,
  Icon,
  IconList,
  AnchorLink,
  Modal,
  Tab,
  Spotlight,
  MultiSearch,
} from "@kidslivesafe/sc-react";

import {
  NeighborhoodMonitoringInterface,
  PeopleMonitoringInterface,
  PropertyMonitoringInterface,
} from "../../features/monitoring/monitoringAPI";
import {
  formatAddress,
  formatName,
  fullCapsText,
  generateList,
  generatePropertyReportHighlights,
  parseDate,
} from "../../utils";
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../hooks/peopleReportHook";
import { useToggleMonitoringDispatch } from "../../hooks/monitoringHook";
import { selectAccountType } from "../../features/authentication/authenticationSlice";
import { PullNeighborhoodReportParams } from "../../features/reports/reportTypes";
import { useNeighborhoodReportDispatch } from "../../hooks/neighborhoodReportHook";
import { useMultiSearchProps } from "../../hooks/multiSearchPropsHook";
import { selectBrandInfo } from "../../features/brand/brandSlice";
import {
  PullPropertyReportParams,
  usePropertySearchDispatch,
} from "../../hooks/propertyReportHook";

const MonitoringContainerDashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  const monitoring = useAppSelector(selectMonitoring);

  const neighborhoodMonitoring = useAppSelector(
    selectNeighborhoodMonitoringAreas,
  );
  const propertyMonitoring = useAppSelector(selectPropertyMonitoring);
  const monitoringStatus = useAppSelector(selectMonitoringStatus);
  const totalAllowedMonitoring = useAppSelector(
    selectTotalAllowedMonitoredReports,
  );

  const brandInfo = useAppSelector(selectBrandInfo);

  const pullPeopleReport = usePeopleReportDispatch();
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();
  const pullPropertyReport = usePropertySearchDispatch();
  const { toggleMonitoring } = useToggleMonitoringDispatch();

  const accountType = useAppSelector(selectAccountType);

  const [tabs, setTabs] = React.useState<Tab[]>([
    "people",
    "property",
    "phone",
    "social",
  ]);
  const [monitoringModal, setMonitoringModal] = React.useState(false);

  useEffect(() => {
    const whichTabs = (type: Tab | null) => {
      switch (type) {
        case "neighborhood":
          setTabs(["neighborhood", "people"]);
          break;
        case "property":
          brandInfo.acronym === "pf"
            ? setTabs(["property"])
            : setTabs(["people"]);
          break;
        default:
          setTabs(["people", "property"]);
          break;
      }
    };
    whichTabs(accountType);
  }, [accountType, brandInfo]);

  const multiSearchProps = useMultiSearchProps(
    { footer: false, resetInput: true, buttonText: "NEW SEARCH", tabs: tabs },
    "monitoring-container",
  );

  const handleAddMonitoringClick = () => {
    setMonitoringModal(true);
  };

  type MonitoringType =
    | PeopleMonitoringInterface
    | NeighborhoodMonitoringInterface
    | PropertyMonitoringInterface;

  const sortedData: (PeopleMonitoringInterface | undefined)[] = monitoring
    ? [...monitoring].sort(
        (a, b) => Number(b.createdDate) - Number(a.createdDate),
      )
    : [];

  if (sortedData && sortedData?.length < totalAllowedMonitoring) {
    sortedData.push(undefined);
  }

  function sortMonitoringArray(
    monitoringArray: (MonitoringType | null)[],
  ): (MonitoringType | null)[] {
    return monitoringArray.sort((a, b) => {
      if (!a) return 1;
      if (!b) return -1;
      if (!a && !b) return 0;

      const getTimestamp = (item: MonitoringType): number => {
        if (item.type === "neighborhood") {
          return item.createdAt ? new Date(item.createdAt).getTime() : 0;
        }

        if (item.createdDate) {
          return new Date(
            parseDate(item.createdDate.toString(), "down-to-the-second"),
          ).getTime();
        }

        return item.version
          ? new Date(parseDate(item.version, "down-to-the-second")).getTime()
          : 0;
      };

      const timeA = getTimestamp(a);
      const timeB = getTimestamp(b);

      return timeB - timeA;
    });
  }

  let monitoringArray: any = [];

  monitoringArray = monitoringArray.concat(neighborhoodMonitoring);
  monitoringArray = monitoringArray.concat(monitoring);
  monitoringArray = monitoringArray.concat(propertyMonitoring);
  if (monitoringArray.length < totalAllowedMonitoring) {
    monitoringArray.push(null);
  }
  monitoringArray = sortMonitoringArray(monitoringArray);

  const renderMonitoring = (
    report:
      | PeopleMonitoringInterface
      | NeighborhoodMonitoringInterface
      | PropertyMonitoringInterface
      | undefined,
    index: number,
  ) => {
    if (report && report.type === "people") {
      return (
        <MonitoringContainer
          key={report.firstName + report.version + index}
          active
          newInfo={report.hasChanges}
          fixedHeight={false}
        >
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{ gap: "xxs", alignItems: "center" }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconPeople} size={24} />
                  <Text size="sm" fontWeight="semibold">
                    {`Person ${sortedData && sortedData.length - sortedData.indexOf(report) - 1}`}
                  </Text>
                </Layout>
                <Monitoring
                  handleMonitoring={toggleMonitoring}
                  monitoringInput={{
                    reportToken: report.reportToken,
                    version: report.version,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ReportLink<PullPeopleReportParams>
                  handleClick={pullPeopleReport}
                  inputValue={report.reportToken}
                  text={formatName(report)}
                  noWrap
                />
                <Text size="xs">
                  {generateList(
                    [
                      {
                        value: formatAddress(report)
                          ? formatAddress(report, "with-comma")
                          : null,
                        label: "",
                      },
                      {
                        value: report.age ? report.age : null,
                        label: "Age ",
                      },
                    ],
                    " | ",
                  )}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdDate
                        ? parseDate(report.createdDate, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                    // NW TODO: Add last viewed
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    } else if (report && report.type === "neighborhood") {
      return (
        <MonitoringContainer key={report.id} active fixedHeight={false}>
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    alignItems: "center",
                  }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconNeighborhoodWatch} size={24} />
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      alignItems: "center",
                      wrap: "wrap",
                    }}
                  >
                    <Text size="sm" fontWeight="semibold" textWrap="wrap">
                      {report.nickname}
                    </Text>
                    <AnchorLink
                      size="xs"
                      color="primary"
                      role="button"
                      hoverUnderline
                      handleClick={() => {
                        dispatch(setRenameReport(report));
                        dispatch(setToggleMonitoringModal(true));
                      }}
                    >
                      (rename)
                    </AnchorLink>
                  </Layout>
                </Layout>
                <Monitoring
                  handleMonitoring={(dto: { id: number }) =>
                    dispatch(deleteNeighborhoodWatchMonitoringAreaAsync(dto))
                  }
                  monitoringInput={{
                    id: report.id,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ContainerLineItem
                  lineItemHeader={
                    <Layout type="flex" flexProps={{ alignItems: "center" }}>
                      <ReportLink<PullNeighborhoodReportParams>
                        handleClick={pullNeighborhoodReport}
                        inputValue={{
                          addressSearch: {
                            address: report.address,
                            city: report.city,
                            state: report.state,
                            zipCode: report.zipCode,
                          },
                          searchMethod: "address",
                        }}
                        text={fullCapsText(
                          formatAddress(
                            {
                              addressLine1: report.address,
                              city: report.city,
                              state: report.state,
                              postalCode: report.zipCode,
                            },
                            "with-comma",
                          ),
                        )}
                        noWrap
                      />
                    </Layout>
                  }
                  lineItemHeaderNoWrap
                  noPadding
                />
                <Text size="xs">
                  {generateList(
                    [
                      // NW TODO: Add Nearby Offenders
                      {
                        value: report.latestOffendersCount
                          ? report.latestOffendersCount
                          : null,
                        label: " Offenders Nearby",
                        order: "reverse",
                      },
                      {
                        value: report.radius ? report.radius : null,
                        label: " Mile Radius",
                        order: "reverse",
                      },
                    ],
                    " | ",
                  )}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdAt
                        ? parseDate(report.createdAt, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                    // NW TODO: Add last viewed
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    } else if (report && report.type === "property") {
      return (
        <MonitoringContainer
          key={`${report.type}-${report.addressLine1}`}
          active
          fixedHeight={false}
          newInfo={report.hasChanges}
        >
          <Padding space="md">
            <Layout type="flex" flexProps={{ gap: "md", direction: "column" }}>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    alignItems: "center",
                  }}
                  passThroughClassess="dashboard__monitoring-container__title"
                >
                  <Icon name={IconList.IconProperty} size={24} />
                  <Layout
                    type="flex"
                    flexProps={{
                      gap: "xxs",
                      alignItems: "center",
                      wrap: "wrap",
                    }}
                  >
                    <Text size="sm" fontWeight="semibold" textWrap="wrap">
                      {report.nickname}
                    </Text>
                    <AnchorLink
                      size="xs"
                      color="primary"
                      role="button"
                      hoverUnderline
                      handleClick={() => {
                        dispatch(setRenameReport(report));
                        dispatch(setToggleMonitoringModal(true));
                      }}
                    >
                      (rename)
                    </AnchorLink>
                  </Layout>
                </Layout>
                <Monitoring
                  handleMonitoring={dto =>
                    dispatch(togglePropertyMonitoringAsync(dto))
                  }
                  monitoringInput={{
                    addressLine1: report.addressLine1,
                    addressLine2: report.addressLine2,
                    city: report.city,
                    state: report.state,
                    zip: report.zip,
                    nickname: report.nickname,
                    version: report.version,
                    bedrooms: report.bedrooms,
                    totalBaths: report.totalBaths,
                    sqft: report.sqft,
                    lotAcres: report.lotAcres,
                  }}
                  initialState={true}
                />
              </Layout>
              <Layout
                type="flex"
                flexProps={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  direction: "column",
                }}
              >
                <ContainerLineItem
                  lineItemHeader={
                    <Layout type="flex" flexProps={{ alignItems: "center" }}>
                      <ReportLink<PullPropertyReportParams>
                        handleClick={pullPropertyReport}
                        inputValue={{
                          addressLine1: report.addressLine1,
                          addressLine2: report.addressLine2,
                          city: report.city,
                          state: report.state,
                          zip: report.zip,
                        }}
                        text={fullCapsText(
                          formatAddress(
                            {
                              addressLine1: report.addressLine1,
                              addressLine2: report.addressLine2,
                              city: report.city,
                              state: report.state,
                              postalCode: report.zip,
                            },
                            "with-comma",
                          ),
                        )}
                        noWrap
                      />
                    </Layout>
                  }
                  lineItemHeaderNoWrap
                  noPadding
                />
                <Text size="xs">
                  {generatePropertyReportHighlights({
                    propertyDetails: {
                      bedrooms: report.bedrooms,
                      totalBaths: report.totalBaths,
                      buildingSquareFeet: report.sqft,
                      acres: report.lotAcres,
                    },
                  })}
                </Text>
              </Layout>
              <Text size="xxs" tag="i" textAlign="center">
                {generateList(
                  [
                    {
                      value: report.createdDate
                        ? parseDate(report.createdDate, "MM/DD/YYYY")
                        : null,
                      label: "set on ",
                    },
                  ],
                  " | ",
                )}
              </Text>
            </Layout>
          </Padding>
        </MonitoringContainer>
      );
    } else {
      let classArray = ["page-dashboard__monitoring__add-monitoring"];
      const passThroughClasses = classArray.join(" ");

      return (
        <MonitoringContainer key={index} fixedHeight={false}>
          <Layout
            type="flex"
            passThroughClassess={passThroughClasses}
            flexProps={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Padding space="md">
              <AnchorLink
                role="button"
                hoverUnderline
                fontWeight="semibold"
                handleClick={handleAddMonitoringClick}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    alignItems: "center",
                  }}
                >
                  <Icon name={IconList.IconPlus} size={20} /> ADD MONITORED
                  REPORT {index} of {totalAllowedMonitoring}
                </Layout>
              </AnchorLink>
            </Padding>
          </Layout>
        </MonitoringContainer>
      );
    }
  };

  return (
    <>
      <Padding left="md" right="md">
        <Container
          containerTitle="Report Monitoring"
          tooltipContent={`${brandInfo.name} allows you to monitor up to 10 reports. You will receive an email alerting you when we detect report information has changed.`}
          containerId="dashboard__monitoring"
        >
          <Padding space="md">
            <Padding
              bottom="md"
              breakpointStyles={{ mobile: { bottom: "xl" } }}
            >
              <Text size="sm" textAlign="left">
                Get notified when report information changes by setting up
                monitoring. Once you are on the report page, toggle the
                monitoring switch to the ON position.
              </Text>
            </Padding>
            <Layout
              type="flex"
              flexProps={{ direction: "column", gap: "md" }}
              breakpointStyles={{
                desktop: {
                  type: "grid",
                  gridProps: { templateColumns: "2-column", gap: "md" },
                },
              }}
            >
              {monitoringStatus === "loading" ? (
                Array(2)
                  .fill(null)
                  .map((_, index) => (
                    <MonitoringContainer key={index}>
                      <ContainerLineItem
                        key={index}
                        lineItemHeader={
                          <LoadingPlaceholder width={"10rem"} height={"20px"} />
                        }
                        lineItemDetails={
                          <Layout
                            type="flex"
                            flexProps={{
                              gap: "xxs",
                              direction: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <LoadingPlaceholder
                              width={"55px"}
                              height={"13px"}
                            />
                            <LoadingPlaceholder
                              width={"50px"}
                              height={"13px"}
                            />
                          </Layout>
                        }
                        lineItemSubHeader={
                          <LoadingPlaceholder width={"12rem"} />
                        }
                      />
                    </MonitoringContainer>
                  ))
              ) : monitoringStatus === "failed" ? (
                <Text>Failed to load monitoring</Text>
              ) : monitoringArray ? (
                monitoringArray.map((_: any, index: number) => {
                  return renderMonitoring(monitoringArray[index], index + 1);
                })
              ) : (
                <Text>You are not currently monitoring anyone.</Text>
              )}
            </Layout>
          </Padding>
        </Container>
      </Padding>
      <Modal
        open={monitoringModal}
        setOpen={() => {
          setMonitoringModal(!monitoringModal);
        }}
        size="expanded-width"
        xClose
        useShowModal={false}
        scrollable={false}
      >
        <Padding space="md" left="none" right="none">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
              alignItems: "center",
            }}
          >
            <Text tag="h2">Add Monitored Report</Text>
            <Text textAlign="center">
              On the report page toggle the monitoring switch to the ON position
            </Text>
          </Layout>
          <Padding top="lg" bottom="lg">
            <Spotlight passThroughClasses="dashboard-monitoring__spotlight">
              <MultiSearch {...multiSearchProps} nonResponsive />
            </Spotlight>
          </Padding>
        </Padding>
      </Modal>
    </>
  );
};

export default MonitoringContainerDashboard;
