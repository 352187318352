import React from "react";
import {
  LoadingPlaceholder,
  ContainerLineItem,
  Padding,
  CollapsableItemList,
} from "@kidslivesafe/sc-react";

interface LoadingContainerLineItemProps {
  lineItemHeader?: boolean;
  lineItemSubHeader?: boolean;
  lineItemSubHeaderLine2?: boolean;
  lineItemDetails?: boolean;
  pictureUrl?: boolean;
  children?: boolean | React.ReactNode;
  numberToLoad?: number;
}

const LoadingContainerLineItem: React.FC<LoadingContainerLineItemProps> = ({
  lineItemHeader = false,
  lineItemSubHeader = false,
  lineItemSubHeaderLine2 = false,
  lineItemDetails = false,
  pictureUrl = false,
  numberToLoad = 1,
  children,
}) => {
  return (
    <CollapsableItemList numberToShow={100}>
      {Array(numberToLoad)
        .fill(null)
        .map((_, index) => (
          <ContainerLineItem
            key={index}
            pictureUrl={
              pictureUrl ? "https://via.placeholder.com/150" : undefined
            }
            lineItemHeader={
              lineItemHeader && (
                <LoadingPlaceholder width="10rem" height="20px" />
              )
            }
            lineItemSubHeader={
              lineItemSubHeader && (
                <LoadingPlaceholder width="8rem" height="12px" />
              )
            }
            lineItemSubHeaderLine2={
              lineItemSubHeaderLine2 && (
                <LoadingPlaceholder width="8rem" height="12px" />
              )
            }
            lineItemDetails={
              lineItemDetails && (
                <LoadingPlaceholder width="3rem" height="14px" />
              )
            }
            loading
          >
            {typeof children === "boolean" ? (
              children ? (
                <Padding space="md">
                  <LoadingPlaceholder width={"10rem"} height={"32px"} />
                </Padding>
              ) : null
            ) : (
              children
            )}
          </ContainerLineItem>
        ))}
    </CollapsableItemList>
  );
};

export default LoadingContainerLineItem;
