import React, { useState } from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  AnchorLink,
  Tab,
  Modal,
  Margin,
} from "@kidslivesafe/sc-react";
import {
  useAppDispatch,
  useAppSelector,
  useHandlePageNavigate,
} from "../../../../hooks/hooks";
import { selectBrandInfo } from "../../../../features/brand/brandSlice";

import {
  setTab,
  toggleOpen,
} from "../../../../features/multisearch/multisearchSlice";

import "./manage-membership.css";
import {
  selectDownsellDetails,
  selectIDPSubscription,
  selectPrimarySubscription,
} from "../../../../features/subscriptions/subscriptionSlice";
import {
  selectIdProtectData,
  selectMemberData,
} from "../../../../features/authentication/authenticationSlice";
import { formatPhoneNumber, parseDate } from "../../../../utils";
import { useCancelSubscription } from "../../../../hooks/cancelHook";

const ManageMembership: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();
  const dispatch = useAppDispatch();

  const brandInfo = useAppSelector(selectBrandInfo);
  const subscriptionInfo = useAppSelector(selectPrimarySubscription);
  const idpSubscription = useAppSelector(selectIDPSubscription);
  const memberInfo = useAppSelector(selectMemberData);
  const cancelSubscription = useCancelSubscription();
  const downsellDetails = useAppSelector(selectDownsellDetails);
  const idProtectData = useAppSelector(selectIdProtectData);

  const [idProtectModal, setIdProtectModal] = useState<boolean>(false);

  const handleMultiSearchOpen = (tab: Tab) => {
    dispatch(setTab(tab));
    dispatch(toggleOpen());
  };

  const handleIdProtectGetStarted = () => {
    if (idProtectData.isIdProtectAvailable || idProtectData.idProtectMember) {
      pageNavigate("/id-protect");
      return;
    } else if (
      !idProtectData.isIdProtectAvailable ||
      !idProtectData.idProtectMember
    ) {
      setIdProtectModal(true);
      return;
    }
  };

  const renderIDPSubscription = () => {
    if (idpSubscription) {
      return (
        <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
          <Text tag="h3">ID Protect Plan Details</Text>
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "xs",
            }}
          >
            <Text>
              <Text tag="b">Membership Type: </Text>
              Monthly Subscription
            </Text>

            <Text>
              <Text tag="b">Amount: </Text>${idpSubscription?.price}
            </Text>

            <Text>
              <Text tag="b">Status: </Text>
              {idpSubscription.active ? "Active" : "Canceled"}
            </Text>

            {idpSubscription.active && (
              <Text>
                <Text tag="b">Next Bill Date: </Text>
                {idpSubscription?.billingDate}
              </Text>
            )}
          </Layout>
        </Layout>
      );
    } else {
      return null;
    }
  };

  const renderMembershipStatus = () => {
    if (subscriptionInfo?.cancelDate) {
      return (
        <>
          <Text fontWeight="semibold">
            <Text tag="span" color="error">
              CANCELED
            </Text>
            . You have access to all of your membership benefits until{" "}
            {parseDate(memberInfo.expirationDate!, "MM/DD/YYYY")} after which
            your membership will no longer be active. There will be no new
            charges to your credit card.
          </Text>
        </>
      );
    } else {
      return (
        <Layout type="flex" flexProps={{ direction: "column", gap: "xxs" }}>
          <Text>
            <Text tag="b">Membership Type: </Text>
            Monthly Subscription
          </Text>

          <Text>
            <Text tag="b">Plan: </Text>
            Monthly Subscription
          </Text>

          <Text>
            <Text tag="b">Amount: </Text>${subscriptionInfo?.price}
          </Text>

          <Text>
            <Text tag="b">Status: </Text>
            Active
          </Text>

          <Text>
            <Text tag="b">Next Bill Date: </Text>
            {memberInfo.billingDate}
          </Text>
          <Text>
            <Text tag="b">Card Ending: </Text>
            {memberInfo.lastFour}
          </Text>
          <Text>
            <Text tag="b">Order ID: </Text>
            {memberInfo.orderId}
          </Text>
        </Layout>
      );
    }
  };

  return (
    <>
      <ReportTitle reportTitle="Manage Membership" />
      <Sheet>
        <Padding top="xl" bottom="xl" left="md" right="md">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
            }}
          >
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "sm",
              }}
            >
              <Text tag="h2">Need Assistance?</Text>
              <Text size="base">
                Our helpful support team is a convenient phone call away 7-days
                a week. For personal assistance, please call us at{" "}
                <AnchorLink
                  inline
                  phoneNumber
                  hoverUnderline
                  href={brandInfo.phoneNumber}
                >
                  {formatPhoneNumber(brandInfo.phoneNumber)}
                </AnchorLink>
                .
              </Text>
              <Text size="base">
                You may also reach us by email use the{" "}
                <AnchorLink
                  href={`${brandInfo.homeUrl}/help-center/contact`}
                  inline
                  hoverUnderline
                >
                  Contact Us
                </AnchorLink>{" "}
                page.
              </Text>
            </Layout>
            <div className="page-manage-membership_divider" />
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "lg",
              }}
            >
              <Text tag="h2">Membership Information</Text>
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "md" }}
              >
                <Text tag="h3">Plan Benefits</Text>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    gap: "xs",
                  }}
                >
                  <Text>
                    <AnchorLink
                      inline
                      hoverUnderline
                      role="button"
                      handleClick={() => pageNavigate("/")}
                    >
                      Monitoring Reports
                    </AnchorLink>
                    : Set up monitoring and be the first to know if information
                    changes.
                  </Text>

                  <Text>
                    <AnchorLink
                      inline
                      hoverUnderline
                      role="button"
                      handleClick={() => handleMultiSearchOpen("people")}
                    >
                      People Search
                    </AnchorLink>
                    : Access profiles of millions of people in our nationwide
                    database.
                  </Text>

                  <Text>
                    <AnchorLink
                      inline
                      hoverUnderline
                      role="button"
                      handleClick={() => handleMultiSearchOpen("property")}
                    >
                      Property Search
                    </AnchorLink>
                    : Access details about million of properties nationwide.
                  </Text>

                  <Text>
                    <AnchorLink
                      inline
                      hoverUnderline
                      role="button"
                      handleClick={() => handleMultiSearchOpen("property")}
                    >
                      Phone Search
                    </AnchorLink>
                    : Stay protected and informed and avoid telemarketing scams.
                  </Text>

                  {/* <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "none",
                    alignItems: "flex-start",
                  }}
                >
                  <AnchorLink
                    inline
                    hoverUnderline
                    role="button"
                    handleClick={() => pageNavigate("/neighborhood-watch")}
                  >
                    Neighborhood Watch
                  </AnchorLink>
                  <Text>
                    : Know your environment and protect your loved ones.
                  </Text>
                 */}
                </Layout>
              </Layout>
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "md" }}
              >
                <Text tag="h3">{brandInfo.name} Plan Details</Text>
                {renderMembershipStatus()}
              </Layout>
              {renderIDPSubscription()}
              <Text size="sm" tag="i">
                Please note that{" "}
                <Text tag="b">{subscriptionInfo?.billingDescriptor}</Text> will
                appear as the merchant name on your credit card or bank
                statement.
              </Text>
              <Text>
                <AnchorLink
                  inline
                  hoverUnderline
                  role="button"
                  handleClick={() => pageNavigate("/update-credit-card")}
                >
                  Update Credit Card
                </AnchorLink>
              </Text>
              <Text>
                To cancel, please call us toll-free at{" "}
                <Text color="primary" tag="span">
                  {formatPhoneNumber(brandInfo.phoneNumber)}
                </Text>{" "}
                so that we may assist you. Our automated system is available
                24/7, 365-days a year and we have live agent support 7-days a
                week from 5am - 9pm PT. To cancel online, please{" "}
                <AnchorLink
                  hoverUnderline
                  inline
                  role="button"
                  handleClick={() => {
                    if (downsellDetails?.isDownsold) {
                      // this will cancel the subscription and navigate to /cancel-confirmation on fulfillment
                      cancelSubscription();
                    } else {
                      pageNavigate("/cancel-offer");
                    }
                  }}
                >
                  click here
                </AnchorLink>
                .
              </Text>
              <Text>
                <AnchorLink
                  inline
                  hoverUnderline
                  role="button"
                  handleClick={handleIdProtectGetStarted}
                >
                  ID Protect
                </AnchorLink>
                : Safeguard your identity and financial security with ID
                Protect. You’ll get identity monitoring and alerts, professional
                restoration, and so much more!
              </Text>
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
      <Modal
        open={idProtectModal}
        setOpen={() => {
          setIdProtectModal(!idProtectModal);
        }}
        xClose
        outSideClose
      >
        <Margin top="xxl">
          <Padding top="lg">
            <Layout type="flex" flexProps={{ direction: "column", gap: "lg" }}>
              <Text fontWeight="bold" tag="h2" textAlign="center">
                Sorry, ID Protect is unavailable to purchase at this time
              </Text>
              <Text textAlign="center">
                Please contact member support at{" "}
                <AnchorLink role="link" phoneNumber inline>
                  {formatPhoneNumber(brandInfo.phoneNumber)}
                </AnchorLink>
              </Text>
            </Layout>
          </Padding>
        </Margin>
      </Modal>
    </>
  );
};

export default ManageMembership;
