import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  AnchorLink,
  Select,
  Button,
  TextArea,
} from "@kidslivesafe/sc-react";

import "./cancel-feedback.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { useAppDispatch, useHandlePageNavigate } from "../../../../hooks/hooks";
import { sendNoteAsync } from "../../../../features/subscriptions/subscriptionSlice";

const CancelFeedback: React.FC = () => {
  const dispatch = useAppDispatch();

  const pageNavigate = useHandlePageNavigate();
  const [feedbackText, setFeedbackText] = React.useState("");
  const [feedbackError, setFeedbackError] = React.useState(false);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectedReasonError, setSelectedReasonError] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );

  const currentBreakpoint = useCurrentBreakpoint();

  const renderError = () => {
    return errorMessage ? (
      <Text color="error" size="xs">
        {errorMessage}
      </Text>
    ) : null;
  };

  const handleFeedbackSubmit = () => {
    let error = {
      feedbackError: false,
      selectedReasonError: false,
    };
    if (feedbackText.length === 0) {
      setFeedbackError(true);
      error.feedbackError = true;
    }

    if (selectedReason.length === 0) {
      setSelectedReasonError(true);
      error.selectedReasonError = true;
    }

    if (error.feedbackError || error.selectedReasonError) {
      if (error.feedbackError && error.selectedReasonError) {
        setErrorMessage(
          "Please select a reason for canceling your account and how we can improve our service.",
        );
      } else if (error.feedbackError) {
        setErrorMessage("Please let us know how we can improve our service.");
      } else if (error.selectedReasonError) {
        setErrorMessage("Please select a reason for canceling your account.");
      }
    } else {
      dispatch(
        sendNoteAsync({ content: `${selectedReason} - ${feedbackText}` }),
      );
      pageNavigate("/cancel-value");
    }
  };

  return (
    <>
      <ReportTitle reportTitle="Customer Feedback" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            <Text tag="h3">
              Please take a moment to share with us why you are canceling your
              account and how we can improve our service.
            </Text>
            <Select
              onOptionSelected={setSelectedReason}
              label="- Select Reason -"
              error={selectedReasonError}
              handleError={setSelectedReasonError}
              options={[
                {
                  label: "I got the information I was looking for",
                  value: "I got the information I was looking for",
                },
                {
                  label: "Couldn't find what I was looking for",
                  value: "Couldn't find what I was looking for",
                },
                {
                  label: "Unsatisfied with the service",
                  value: "Unsatisfied with the service",
                },
                { label: "The cost", value: "The cost" },
              ]}
              value={selectedReason}
            />
            {renderError()}
            <TextArea
              id="page-cancel-feedback-textarea"
              value={feedbackText}
              handleChange={setFeedbackText}
              placeholder="We want to hear from you. Please tell us how we can improve our service."
              error={feedbackError}
              handleError={setFeedbackError}
            />
            <Layout
              type="flex"
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Button
                handleClick={() => {
                  pageNavigate("/");
                }}
                buttonText="Return to Dashboard"
              />
              <AnchorLink
                handleClick={() => {
                  handleFeedbackSubmit();
                }}
                hoverUnderline
                role="button"
              >
                Submit and Continue
              </AnchorLink>
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default CancelFeedback;
