type DateFormat =
  | "month"
  | "year"
  | "month-year"
  | "year-month"
  | "MM/DD/YYYY"
  | "utc:MM/DD/YYYY"
  | "MM/YYYY"
  | "month-day-year"
  | "down-to-the-minute"
  | "down-to-the-second"
  | "MM/DD/YYYY at HH:mm"
  | "utc:month-day-year";

export function parseDate(date: string | number, format: DateFormat): string {
  let dateObj: Date;

  date = date.toString();

  // Check if date is in format YYYYMMDDHHMMSS
  if (date.length === 14 && /^\d+$/.test(date)) {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const hour = date.substring(8, 10);
    const minute = date.substring(10, 12);
    const second = date.substring(12, 14);
    dateObj = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`);
    // Check if date is in format YYYYMMDD
  } else if (date.length === 8 && /^\d+$/.test(date)) {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    dateObj = new Date(`${month}/${day}/${year}`);
    // Check if date is in format MM, YYYY
  } else if (
    /^(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+\d{1,2},\s+\d{4}$/.test(
      date,
    )
  ) {
    const yearMatch = date.match(/\d{4}/);
    if (yearMatch) {
      const year = parseInt(yearMatch[0], 10);
      const monthAbbr = date.substring(0, 3);
      const monthIndex =
        new Date(Date.parse(`${monthAbbr} 1, 2000`)).getMonth() + 1;
      const dayMatch = date.match(/\d{1,2}/);
      const day = dayMatch ? parseInt(dayMatch[0], 10) : 1;
      dateObj = new Date(`${monthIndex}/${day}/${year}`);
    } else {
      throw new Error("Invalid date format");
    }
    // Check if date is in format MM/YYYY or YYYY
  } else if (date.split("/").length === 2) {
    let [month, year] = date.split("/");
    dateObj = new Date(`${month}/01/${year}`);
  } else if (date.split("/").length === 1) {
    dateObj = new Date(`01/01/${date}`);
    // Check if date is in format /Date(1672099200000+0000)/
  } else if (date.includes("/Date(") && date.includes(")/")) {
    const dateInMs = parseInt(
      date.replace("/Date(", "").replace("+0000)/", ""),
    );
    dateObj = new Date(dateInMs);
    // Fallback to standard date parsing
  } else {
    dateObj = new Date(date);
  }

  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear().toString();

  switch (format) {
    case "month":
      return month;
    case "year":
      return year;
    case "month-year":
      return `${month} ${year}`;
    case "year-month":
      return `${year} ${month}`;
    case "month-day-year":
      return dateObj.toLocaleDateString("default", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    case "MM/DD/YYYY":
      return dateObj.toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    case "utc:MM/DD/YYYY":
      return dateObj.toLocaleDateString("default", {
        timeZone: "utc",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    case "MM/YYYY":
      return dateObj.toLocaleDateString("default", {
        year: "numeric",
        month: "2-digit",
      });
    case "down-to-the-minute":
      return dateObj.toLocaleString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    case "down-to-the-second":
      return dateObj.toLocaleString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    case "MM/DD/YYYY at HH:mm":
      return dateObj
        .toLocaleString("default", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        .replace(", ", " at ");
    case "utc:month-day-year":
      return dateObj.toLocaleDateString("default", {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    default:
      throw new Error("Invalid date format");
  }
}
