import React from "react";

import {
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  AnchorLink,
  Button,
  Icon,
  IconList,
} from "@kidslivesafe/sc-react";

import { selectDownsellDetails } from "../../../../features/subscriptions/subscriptionSlice";

import "./cancel-value.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { useAppSelector, useHandlePageNavigate } from "../../../../hooks/hooks";
import { selectIdProtectData } from "../../../../features/authentication/authenticationSlice";
import { useCancelSubscription } from "../../../../hooks/cancelHook";
import { selectBrandInfo } from "../../../../features/brand/brandSlice";

const CancelValue: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();

  const currentBreakpoint = useCurrentBreakpoint();
  const cancelSubscription = useCancelSubscription();
  const downsellDetails = useAppSelector(selectDownsellDetails);
  const idProtectDetails = useAppSelector(selectIdProtectData);
  const brandInfo = useAppSelector(selectBrandInfo);

  const renderContent = () => {
    if (idProtectDetails?.idProtectMember) {
      return (
        <>
          <Layout
            type="flex"
            flexProps={{ gap: "xxs", direction: "row", alignItems: "center" }}
          >
            <Text color="error" tag="span">
              <Icon size={20} name={IconList.IconWarning} />
            </Text>
            <Text tag="h3">Lose Identity Protection</Text>
          </Layout>
          <Layout type="flex" flexProps={{ gap: "sm", direction: "column" }}>
            <Text size="lg" fontWeight="semibold">
              How will you{" "}
              <Text size="lg" tag="i" fontWeight="semibold">
                protect yourself
              </Text>{" "}
              from Identity Theft?
            </Text>
            <Text>
              The Identity Theft Resource Center reports a new record high of
              data breaches exposing more than{" "}
              <Text tag="i">178 million records</Text>.
            </Text>
            <Text>
              Your membership includes ID Protect to help safeguard your idenity
              and financial security by monitoring activity on your credit cards
              and bank accounts. We send you alerts when your Social Security
              Number has been used and detected. We also provide you with $1
              Million Identity Theft Insurance and professional assistance in
              restoring your identity when there has been a breach.
            </Text>
            <Text>
              Early detection is your best defense against Identity Theft. By
              canceling, we will no longer monitor and alert you of potential
              security threats.
            </Text>
          </Layout>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ReportTitle reportTitle="Cancellation" />
      <Sheet>
        <Padding
          top={currentBreakpoint === "desktop" ? "xl" : "md"}
          left="md"
          right="md"
          bottom="md"
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "md",
            }}
          >
            {brandInfo.acronym === "pf" ? (
              <>
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <Text color="error" tag="span">
                    <Icon size={20} name={IconList.IconWarning} />
                  </Text>
                  <Text tag="h3">Lose Access to All Reports</Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text size="lg" tag="i" fontWeight="semibold">
                    How well do you know the property you are looking to buy or
                    rent?
                  </Text>
                  <Text>
                    When considering buying or renting a property, it's
                    essential to look beyond the price tag and curb appeal. Have
                    you explored its full ownership history, past sales, or any
                    potential liens? Hidden issues, such as recent refinancing
                    or a suspicious resident profile, could impact its value.
                    Understanding the neighborhood’s market trends and how the
                    property compares to others nearby is also crucial. With
                    Property Focus, you can uncover these vital details, helping
                    you make informed decisions and avoid surprises.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text size="lg" tag="i" fontWeight="semibold">
                    Do you really know everything about your own property or
                    building?
                  </Text>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "sm", direction: "column" }}
                  >
                    <Text>
                      Have you reviewed the full lot and building details,
                      checked for any liens, and kept track of your property's
                      assessed value and taxes? Are you aware of market value
                      fluctuations and what information is accessible through
                      public records relating to your property? With Property
                      Focus, you can also stay up to date on your neighborhood’s
                      profile and see what others might be viewing about your
                      property, giving you full visibility and ensuring you're
                      always informed.
                    </Text>
                  </Layout>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <Text color="error" tag="span">
                    <Icon size={20} name={IconList.IconWarning} />
                  </Text>
                  <Text tag="h3">Lose Report Monitoring</Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text>
                    Monitoring reports can provide valuable insights, alerting
                    you to changes in public records. It can even help protect
                    you from fraud by notifying you of any unauthorized changes
                    or suspicious activity related to your property, helping you
                    stay one step ahead and safeguard your investment. With
                    Property Focus, you can stay informed about these changes
                    and better understand the full picture of your property.{" "}
                  </Text>
                </Layout>
              </>
            ) : (
              <>
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <Text color="error" tag="span">
                    <Icon size={20} name={IconList.IconWarning} />
                  </Text>
                  <Text tag="h3">Lose COMPREHENSIVE People Reports</Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text size="lg" tag="i" fontWeight="semibold">
                    Do you really know the people in your life?
                  </Text>
                  <Text>
                    With People Reports you can quickly and easily look up the
                    full criminal history, financial records, and contact info
                    on the people you meet. Your searches are private and secure
                    - no one will ever know you looked them up.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ gap: "sm", direction: "column" }}
                >
                  <Text size="lg" tag="i" fontWeight="semibold">
                    Did you run people reports on yourself and family members?
                  </Text>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "sm", direction: "column" }}
                  >
                    <Text>
                      We encourage you to get the most out of your membership by
                      running reports on yourself and relatives. Check the
                      accuracy of the information. Look for incorrect
                      information that might indicate the work of an identity
                      thief.
                    </Text>
                    <Text>
                      Also, consider activating People Monitoring on your
                      reports so we can alert you if we detect any changes.
                    </Text>
                    <Text>
                      By canceling, you will lose quick and easy access to
                      public records for you, your family, and people you
                      associate with.
                    </Text>
                  </Layout>
                </Layout>
              </>
            )}
            {renderContent()}
            <Layout
              type="flex"
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Button
                handleClick={() => {
                  pageNavigate("/");
                }}
                buttonText="Run a Search"
              />
              <AnchorLink
                handleClick={() => {
                  if (downsellDetails?.isDownsold) {
                    // this will cancel the subscription and navigate to /cancel-confirmation on fulfillment
                    cancelSubscription();
                  } else {
                    pageNavigate("/cancel-offer");
                  }
                }}
                role="button"
                hoverUnderline
              >
                {downsellDetails?.isDownsold ? "No Thanks, Cancel" : "Continue"}
              </AnchorLink>
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default CancelValue;
