import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import {
  selectBrandInfo,
  selectCaptchaSiteKey,
} from "../../../features/brand/brandSlice";
import {
  selectResetPasswordEmailSent,
  selectAdminStatus,
} from "../../../features/admin/adminSlice";
import { sendResetPasswordEmailAsync } from "../../../features/admin/adminSlice";
import ReCAPTCHA from "react-google-recaptcha";

import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

import {
  Padding,
  Layout,
  Button,
  Container,
  ReportTitle,
  Text,
  Input,
  Sheet,
} from "@kidslivesafe/sc-react";

import { verifyEmail } from "../../../utils";
import { selectMemberData } from "../../../features/authentication/authenticationSlice";

import "./resetPassword.css";
import { Form } from "react-router-dom";

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { username, id, customerId } = useAppSelector(selectMemberData);
  const brandInfo = useAppSelector(selectBrandInfo);
  const currentBreakpoint = useCurrentBreakpoint();
  const emailSent = useAppSelector(selectResetPasswordEmailSent);
  let adminStatus = useAppSelector(selectAdminStatus);

  const captchaSiteKey = useAppSelector(selectCaptchaSiteKey);
  const recaptcha = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (username) {
      setEmail(username);
    }
  }, [username]);

  useEffect(() => {
    setLoading(adminStatus === "loading");
  }, [adminStatus]);

  const handleSubmitEmail = (email: string | null) => {
    if (adminStatus === "loading") return;
    if (!email) {
      setError(true);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const verified = verifyEmail(email);
    if (!verified) {
      setError(true);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const customer = {
      username,
      id,
      customerId,
    };

    const captchaValue = recaptcha.current
      ? recaptcha.current.getValue()
      : null;
    if (!captchaValue) {
      setCaptchaError(true);
      setErrorMessage(
        "Please prove that you're not a robot by selecting the captcha.",
      );
      return;
    } else {
      dispatch(sendResetPasswordEmailAsync({ email, customer, captchaValue }));
    }
  };

  return (
    <>
      <ReportTitle reportTitle="Reset Password" />
      <Sheet>
        {!emailSent ? (
          <Padding top="lg" bottom="lg" right="md" left="md">
            <Layout
              type="flex"
              flexProps={{ direction: "column", alignItems: "center" }}
            >
              <Padding
                bottom="md"
                breakpointStyles={{ mobile: { bottom: "lg" } }}
              >
                <Text>
                  To reset your password, enter the email address associated
                  with your account. We will email you a link that will allow
                  you to reset your password.
                </Text>
              </Padding>
            </Layout>
            <Form>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "sm",
                  alignItems: "center",
                }}
                breakpointStyles={{
                  desktop: {
                    type: "flex",
                    flexProps: {
                      direction: "column",
                      gap: "xxs",
                      alignItems: "center",
                    },
                  },
                }}
              >
                <Input
                  id="email-address"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  handleChange={setEmail}
                  handleError={() => {
                    setError(false);
                    setErrorMessage("");
                  }}
                  error={error}
                  fill={
                    currentBreakpoint !== "desktop"
                      ? "fill"
                      : "not-fill-min-width"
                  }
                ></Input>
                <ReCAPTCHA
                  className={
                    currentBreakpoint === "desktop"
                      ? "page-reset-password-recaptcha"
                      : ""
                  }
                  size="normal"
                  sitekey={captchaSiteKey}
                  ref={recaptcha}
                />
                {(error || captchaError) && errorMessage && (
                  <Layout
                    type="flex"
                    flexProps={{ alignSelf: "flex-start" }}
                    breakpointStyles={{
                      desktop: {
                        type: "flex",
                        flexProps: { alignSelf: "center" },
                      },
                    }}
                  >
                    <Text
                      color="error"
                      size="xs"
                      passThroughClassess={
                        currentBreakpoint === "desktop"
                          ? "page-reset-password-error__width"
                          : ""
                      }
                    >
                      {errorMessage}
                    </Text>
                  </Layout>
                )}
                <Button
                  buttonText="Reset Password"
                  color="primary"
                  handleClick={handleSubmitEmail}
                  inputValue={email}
                  size="md"
                  loading={loading}
                />
              </Layout>
            </Form>
          </Padding>
        ) : (
          <Padding top="lg" bottom="lg" right="md" left="md">
            <Container>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  alignContent: "center",
                  gap: "md",
                }}
              >
                <Text textAlign="left" size="xl" fontWeight="bold">
                  Your Password Reset Email has been sent!
                </Text>
                <Text tag="span">
                  Please check your inbox for a message from {brandInfo.name}.
                  Follow the{" "}
                  <Text tag="span" fontWeight="semibold">
                    Reset Password
                  </Text>{" "}
                  link provided and update your password.
                </Text>
              </Layout>
            </Container>
          </Padding>
        )}
      </Sheet>
    </>
  );
};

export default ResetPassword;
