import React, { useEffect } from "react";
import { verifyCookie } from "../../features/authentication/authenticationAPI";
import { logoutWithRedirect } from "../../features/authentication/authenticationSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { sendMemberEventAsync } from "../../features/admin/adminSlice";


export interface GatekeeperProps { logMemberEvent?: boolean; pageName: string; }

const gateKeeper = <P extends {}>(Component: React.ComponentType<P>) => {
  const Gatekeeper: React.FC<P & GatekeeperProps> = props => {
    const dispatch = useAppDispatch();
    const handleLogout = () => {
      dispatch(logoutWithRedirect());
    };

    useEffect(() => {
      if(props.logMemberEvent)
      {
        sendMemberEvent();
      }
      verifyCookie()
        .then((res: any) => {
          if (!res || res.success === false) {
            handleLogout();
          }
        })
        .catch(error => {
          handleLogout();
          console.log("Error verifying Authentication:", error);
        });
    });

    const sendMemberEvent = () => {
      dispatch(
        sendMemberEventAsync({
          EventCategory: props.pageName,
          EventAction: "page"
          }),
        );
    };

    return <Component {...props} />;
  };
  return Gatekeeper;
};

export default gateKeeper;
