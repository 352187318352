import React, { useEffect } from "react";

import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Padding,
  Layout,
  Sheet,
  Text,
  MultiSearch,
  Button,
  LoadingPlaceholder,
  Select,
  Tag,
} from "@kidslivesafe/sc-react";

import "./people-search.css";

import { formatAddress, formatName } from "../../../utils";

import {
  selectPeopleSearchStatus,
  selectTeaserReports,
} from "../../../features/reports/reportsSlice";
import { usePeopleReportDispatch } from "../../../hooks/peopleReportHook";
import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";
import { TeaserReport } from "../../../features/reports/reportTypes";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import {
  selectMultiSearchError,
  selectPeopleSearchParams,
} from "../../../features/multisearch/multisearchSlice";

const PeopleSearch: React.FC = () => {
  const [sort, setSort] = React.useState<string | undefined>(undefined);
  const [sortedReports, setSortedReports] = React.useState<
    TeaserReport[] | undefined
  >(undefined);

  const peopleSearch = useAppSelector(selectPeopleSearchParams);
  const mulitSearchError = useAppSelector(selectMultiSearchError);

  const multiSearchProps = useMultiSearchProps(
    {
      footer: false,
      tabs: ["people"],
      buttonStyle: "secondary",
      buttonText: "Refine Search",
      setPeopleSearchValue: peopleSearch,
      setMultiSearchError: mulitSearchError,
    },
    "people-search",
  );

  const pullPeopleReport = usePeopleReportDispatch();
  const teaserReports = useAppSelector(selectTeaserReports);
  const currentBreakpoint = useCurrentBreakpoint();

  const peopleSearchStatus = useAppSelector(selectPeopleSearchStatus);
  const loading = peopleSearchStatus === "loading";

  useEffect(() => {
    if (teaserReports) {
      setSortedReports([...teaserReports]);
      setSort("best-match");
    }
  }, [teaserReports]);

  useEffect(() => {
    if (teaserReports && sort) {
      let newSortedReports = [...teaserReports];
      switch (sort) {
        case "best-match":
          newSortedReports = [...teaserReports];
          break;
        case "first-name":
          newSortedReports.sort((a, b) =>
            a.names[0]?.firstName.localeCompare(b.names[0]?.firstName ?? ""),
          );
          break;
        case "last-name":
          newSortedReports.sort((a, b) =>
            a.names[0]?.lastName.localeCompare(b.names[0]?.lastName ?? ""),
          );
          break;
        case "age-low":
          newSortedReports.sort((a, b) => {
            const ageA = a.age ? parseInt(a.age) : null;
            const ageB = b.age ? parseInt(b.age) : null;
            return (ageA ?? 0) - (ageB ?? 0);
          });
          break;
        case "age-high":
          newSortedReports.sort((a, b) => {
            const ageA = a.age ? parseInt(a.age) : null;
            const ageB = b.age ? parseInt(b.age) : null;
            return (ageB ?? 0) - (ageA ?? 0);
          });
          break;
        case "city":
          newSortedReports.sort((a, b) =>
            a.cities[0]?.city.localeCompare(b.cities[0]?.city ?? ""),
          );
          break;
        case "state":
          newSortedReports.sort((a, b) =>
            a.cities[0]?.state.localeCompare(b.cities[0]?.state ?? ""),
          );
          break;
        default:
          break;
      }
      setSortedReports(newSortedReports);
    }
  }, [sort, teaserReports]);

  return (
    <>
      <MultiSearch {...multiSearchProps} />
      <Sheet>
        <Padding space="md" top="xs">
          <Layout type="flex" flexProps={{ direction: "column", gap: "xs" }}>
            {teaserReports &&
            sortedReports &&
            sortedReports.length > 0 &&
            !loading ? (
              <>
                <Layout
                  type="flex"
                  flexProps={{ justifyContent: "space-between" }}
                >
                  <Text fontWeight="semibold">
                    Top{" "}
                    <Text color="accent" tag="span" fontWeight="bold">
                      {teaserReports?.length}
                    </Text>{" "}
                    Matches Found
                  </Text>
                  <Layout type="flex" flexProps={{ gap: "xs" }}>
                    <Text fontWeight="semibold" textWrap="nowrap">
                      Sort By:
                    </Text>
                    <Select
                      value={sort}
                      selectStyle="flat"
                      onOptionSelected={value => setSort(value)}
                      options={[
                        { value: "best-match", label: "Best Match" },
                        { value: "first-name", label: "First Name" },
                        { value: "last-name", label: "Last Name" },
                        { value: "age-low", label: "Age: Low - High" },
                        { value: "age-high", label: "Age: High - Low" },
                        { value: "city", label: "City" },
                        { value: "state", label: "State" },
                      ]}
                    />
                  </Layout>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "xs" }}
                >
                  <>
                    {sortedReports.map((report, index) => (
                      <Container key={`${report.names[0].firstName}-${index}`}>
                        <Padding
                          space="md"
                          breakpointStyles={{
                            desktop: {
                              space: "md",
                              left: "xl",
                              right: "xl",
                            },
                          }}
                        >
                          <Layout
                            type="flex"
                            flexProps={{
                              direction: "column",
                              gap: "md",
                            }}
                            breakpointStyles={{
                              desktop: {
                                type: "grid",
                                gridProps: {
                                  templateColumns: "3fr-1fr",
                                  gap: "md",
                                  alignItems: "center",
                                },
                              },
                            }}
                          >
                            <Layout
                              type="flex"
                              flexProps={{
                                direction: "column",
                                gap: "md",
                              }}
                            >
                              <Layout
                                type="flex"
                                flexProps={{
                                  direction: "column",
                                  alignItems: "center",
                                }}
                                breakpointStyles={{
                                  desktop: {
                                    type: "flex",
                                    flexProps: {
                                      direction: "column",
                                      alignItems: "flex-start",
                                    },
                                  },
                                }}
                              >
                                <Layout
                                  type="flex"
                                  flexProps={{
                                    gap: "xs",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    size="lg"
                                    fontWeight="bold"
                                    color="primary"
                                  >
                                    {formatName(report.names[0])}
                                    {report.age && report.deceased !== "Yes"
                                      ? `, ${report.age}`
                                      : ""}
                                  </Text>
                                  {report.deceased === "Yes" ? (
                                    <div>
                                      <Tag type="deceased" tagText="deceased" />
                                    </div>
                                  ) : null}
                                </Layout>

                                <Text size="xxs" tag="i" textAlign="center">
                                  {report.names
                                    .slice(1)
                                    .map((name, index, arr) =>
                                      index >= 3
                                        ? null
                                        : formatName(name) +
                                          (index < Math.min(2, arr.length - 1)
                                            ? ", "
                                            : ""),
                                    )}
                                </Text>
                              </Layout>
                              <Layout
                                type="flex"
                                flexProps={{
                                  direction: "column",
                                  gap: "xs",
                                }}
                                breakpointStyles={{
                                  desktop: {
                                    type: "grid",
                                    gridProps: {
                                      templateColumns: "2-column",
                                      gap: "md",
                                      justifyItems: "flex-start",
                                    },
                                  },
                                }}
                              >
                                {report.cities && report.cities.length > 0 ? (
                                  <Layout
                                    type="flex"
                                    flexProps={{
                                      direction: "column",
                                    }}
                                    breakpointStyles={{
                                      desktop: {
                                        type: "flex",
                                      },
                                    }}
                                  >
                                    <Padding right="xxs">
                                      <Text size="xs" color="subtle">
                                        Locations:
                                      </Text>
                                    </Padding>

                                    <Layout
                                      type="flex"
                                      flexProps={{
                                        direction: "column",
                                      }}
                                    >
                                      <Text
                                        textWrap="nowrap"
                                        size="sm"
                                        passThroughClassess="page-people-search__relative-text-line"
                                        tag="div"
                                      >
                                        {report.cities.map((city, index) =>
                                          index >= 3 ? null : (
                                            <Text
                                              key={`${city.city}-${index}`}
                                              size="sm"
                                              tag={
                                                currentBreakpoint !== "desktop"
                                                  ? "span"
                                                  : undefined
                                              }
                                            >
                                              {formatAddress(
                                                city,
                                                "with-comma",
                                              )}
                                              {index <
                                                Math.min(
                                                  2,
                                                  report.cities.length - 1,
                                                ) &&
                                                currentBreakpoint !==
                                                  "desktop" &&
                                                ", "}
                                            </Text>
                                          ),
                                        )}
                                      </Text>
                                      {report.cities.length > 3 ? (
                                        <Text size="xxs" tag="i">
                                          +{report.cities.length - 3} more
                                        </Text>
                                      ) : null}
                                    </Layout>
                                  </Layout>
                                ) : null}
                                {report.relatives &&
                                report.relatives.length > 0 ? (
                                  <Layout
                                    type="flex"
                                    flexProps={{
                                      direction: "column",
                                    }}
                                    breakpointStyles={{
                                      desktop: {
                                        type: "flex",
                                      },
                                    }}
                                  >
                                    <Padding right="xxs">
                                      <Text size="xs" color="subtle">
                                        Relatives:
                                      </Text>
                                    </Padding>

                                    <Layout
                                      type="flex"
                                      flexProps={{
                                        direction: "column",
                                      }}
                                    >
                                      <Text
                                        size="sm"
                                        textWrap="nowrap"
                                        passThroughClassess="page-people-search__relative-text-line"
                                        tag="div"
                                      >
                                        {report.relatives.map(
                                          (relative, index) =>
                                            index >= 3 ? null : (
                                              <Text
                                                key={`${
                                                  relative.age
                                                }-${formatName(
                                                  relative,
                                                )}-${index}`}
                                                size="sm"
                                                tag={
                                                  currentBreakpoint !==
                                                  "desktop"
                                                    ? "span"
                                                    : undefined
                                                }
                                              >
                                                {formatName(relative)}
                                                {index <
                                                  Math.min(
                                                    2,
                                                    report.relatives.length - 1,
                                                  ) &&
                                                  currentBreakpoint !==
                                                    "desktop" &&
                                                  ", "}
                                              </Text>
                                            ),
                                        )}
                                      </Text>
                                      {report.relatives.length > 3 ? (
                                        <Text size="xxs" tag="i">
                                          +{report.relatives.length - 3} more
                                        </Text>
                                      ) : null}
                                    </Layout>
                                  </Layout>
                                ) : null}
                              </Layout>
                            </Layout>

                            <Button
                              buttonText="View Report"
                              handleClick={() =>
                                pullPeopleReport(report.reportToken)
                              }
                              size={
                                currentBreakpoint === "desktop" ? "sm" : "md"
                              }
                              passThroughClassess="page-people-search__view-report-button"
                            />
                          </Layout>
                        </Padding>
                      </Container>
                    ))}
                  </>
                </Layout>
              </>
            ) : mulitSearchError &&
              mulitSearchError.startsWith("Too many results found") ? (
              <Padding top="xl">
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "md" }}
                >
                  <Text tag="h1" textAlign="center">
                    Too Many Results Found
                  </Text>
                  <Text size="lg" textAlign="center">
                    Please refine your search or try another one
                  </Text>
                </Layout>
              </Padding>
            ) : mulitSearchError &&
              mulitSearchError.startsWith("City not found") ? (
              <Padding top="xl">
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "md" }}
                >
                  <Text tag="h1" textAlign="center">
                    City Not Found
                  </Text>
                  <Text size="lg" textAlign="center">
                    Please check city spelling or try a different city name.
                  </Text>
                </Layout>
              </Padding>
            ) : loading ? (
              <>
                <Layout
                  type="flex"
                  flexProps={{ justifyContent: "space-between" }}
                >
                  <Text fontWeight="semibold">
                    Top{" "}
                    <Text color="accent" tag="span" fontWeight="bold">
                      <LoadingPlaceholder width="2rem" height="16px" />
                    </Text>{" "}
                    Matches Found
                  </Text>
                  <Layout type="flex" flexProps={{ gap: "xs" }}>
                    <Text fontWeight="semibold" textWrap="nowrap">
                      Sort By:
                    </Text>
                    <Select
                      value={sort}
                      selectStyle="flat"
                      onOptionSelected={value => setSort(value)}
                      options={[
                        { value: "best-match", label: "Best Match" },
                        { value: "first-name", label: "First Name" },
                        { value: "last-name", label: "Last Name" },
                        { value: "age-low", label: "Age - Low to High" },
                        { value: "age-high", label: "Age - High to Low" },
                        { value: "city", label: "City" },
                        { value: "state", label: "State" },
                      ]}
                    />
                  </Layout>
                </Layout>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Container key={`loading-${index}`}>
                    <Padding
                      space="md"
                      breakpointStyles={{
                        desktop: {
                          space: "md",
                          left: "xl",
                          right: "xl",
                        },
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          gap: "md",
                        }}
                        breakpointStyles={{
                          desktop: {
                            type: "grid",
                            gridProps: {
                              templateColumns: "3fr-1fr",
                              gap: "md",
                              alignItems: "center",
                            },
                          },
                        }}
                      >
                        <Layout
                          type="flex"
                          flexProps={{
                            direction: "column",
                            gap: "md",
                          }}
                        >
                          <Layout
                            type="flex"
                            flexProps={{
                              direction: "column",
                              alignItems: "center",
                            }}
                            breakpointStyles={{
                              desktop: {
                                type: "flex",
                                flexProps: {
                                  direction: "column",
                                  alignItems: "flex-start",
                                },
                              },
                            }}
                          >
                            <Layout
                              type="flex"
                              flexProps={{
                                gap: "xs",
                                alignItems: "center",
                              }}
                            >
                              <LoadingPlaceholder width="8rem" height="20px" />
                            </Layout>

                            <LoadingPlaceholder width="12rem" height="12px" />
                          </Layout>
                          <Layout
                            type="flex"
                            flexProps={{
                              direction: "column",
                              gap: "xs",
                            }}
                            breakpointStyles={{
                              desktop: {
                                type: "grid",
                                gridProps: {
                                  templateColumns: "2-column",
                                  gap: "md",
                                  justifyItems: "flex-start",
                                },
                              },
                            }}
                          >
                            <Layout
                              type="flex"
                              flexProps={{
                                direction: "column",
                              }}
                              breakpointStyles={{
                                desktop: {
                                  type: "flex",
                                },
                              }}
                            >
                              <Text size="xs" color="subtle">
                                Locations:
                              </Text>
                              <Layout
                                type="flex"
                                flexProps={{
                                  direction: "column",
                                }}
                              >
                                <Text
                                  textWrap="nowrap"
                                  size="sm"
                                  passThroughClassess="page-people-search__relative-text-line"
                                  tag="div"
                                >
                                  <Layout
                                    type="flex"
                                    flexProps={{ gap: "xxxs" }}
                                    breakpointStyles={{
                                      desktop: {
                                        type: "flex",
                                        flexProps: {
                                          gap: "xxxs",
                                          direction: "column",
                                        },
                                      },
                                    }}
                                  >
                                    <LoadingPlaceholder
                                      width="10rem"
                                      height="14px"
                                      noGrow
                                    />
                                    <LoadingPlaceholder
                                      width="10rem"
                                      height="14px"
                                      noGrow
                                    />
                                    <LoadingPlaceholder
                                      width="10rem"
                                      height="14px"
                                      noGrow
                                    />
                                  </Layout>
                                </Text>
                                <LoadingPlaceholder
                                  width="4rem"
                                  height="10px"
                                />
                              </Layout>
                            </Layout>

                            <Layout
                              type="flex"
                              flexProps={{
                                direction: "column",
                              }}
                              breakpointStyles={{
                                desktop: {
                                  type: "flex",
                                },
                              }}
                            >
                              <Text size="xs" color="subtle">
                                Relatives:
                              </Text>
                              <Layout
                                type="flex"
                                flexProps={{
                                  direction: "column",
                                }}
                              >
                                <Text
                                  size="sm"
                                  textWrap="nowrap"
                                  passThroughClassess="page-people-search__relative-text-line"
                                  tag="div"
                                >
                                  <Layout
                                    type="flex"
                                    flexProps={{ gap: "xxxs" }}
                                    breakpointStyles={{
                                      desktop: {
                                        type: "flex",
                                        flexProps: {
                                          gap: "xxxs",
                                          direction: "column",
                                        },
                                      },
                                    }}
                                  >
                                    <LoadingPlaceholder
                                      width="10rem"
                                      height="14px"
                                      noGrow
                                    />
                                    <LoadingPlaceholder
                                      width="10rem"
                                      height="14px"
                                      noGrow
                                    />
                                    <LoadingPlaceholder
                                      width="10rem"
                                      height="14px"
                                      noGrow
                                    />
                                  </Layout>
                                </Text>
                                <LoadingPlaceholder
                                  width="4rem"
                                  height="10px"
                                />
                              </Layout>
                            </Layout>
                          </Layout>
                        </Layout>

                        <Button
                          buttonText="View Report"
                          handleClick={() => {}}
                          size={currentBreakpoint === "desktop" ? "sm" : "md"}
                          disabled
                          passThroughClassess="page-people-search__view-report-button"
                        />
                      </Layout>
                    </Padding>
                  </Container>
                ))}
              </>
            ) : (
              <Padding
                top="xl"
                breakpointStyles={{
                  mobile: {
                    top: "xl",
                    left: "md",
                    right: "md",
                  },
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{ direction: "column", gap: "lg" }}
                >
                  <Text tag="h1" fontWeight="bold" textAlign="center">
                    0 matches found
                  </Text>
                  <Text size="lg" textAlign="center">
                    Sorry, we were unable to find any results for{" "}
                    <Text tag="span" size="lg">
                      {peopleSearch?.firstName} {peopleSearch?.lastName}
                    </Text>
                    . in our public records database. Try again using these
                    tips:
                  </Text>
                  <ol className="page-people-search__ordered-list">
                    <Text tag="li">Check spelling. Any mistakes?</Text>
                    <Text tag="li">
                      Try using a full legal name like WIlliam instead of Bill.
                    </Text>
                    <Text tag="li">
                      If searching for a married individual who has changed
                      their last name then try using their previous last name.
                    </Text>
                    <Text tag="li">
                      Try a different state or broaden the search to include all
                      states.
                    </Text>
                  </ol>
                </Layout>
              </Padding>
            )}
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default PeopleSearch;
