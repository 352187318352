import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  LoadingPlaceholder,
  Padding,
  ShowMore,
  Table,
  Text,
} from "@kidslivesafe/sc-react";

import {
  AutoReportType,
  BrandsInformation,
} from "../../../features/reports/reportTypes";
import {
  capFirstLetter,
  parseDate,
  renderArrayOfObjectsPrep,
} from "../../../utils";
import { generateList } from "@kidslivesafe/sc-foundation";

const TitleBrandsReported: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [checkedBrands, setCheckedBrands] = useState<BrandsInformation[]>([]);
  const [failedBrands, setFailedBrands] = useState<BrandsInformation[]>([]);

  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);

  // pulledReport = testData as AutoReportType; //TODO: remove this line

  useEffect(() => {
    const checkedBrands: BrandsInformation[] = [];
    const failedBrands: BrandsInformation[] = [];

    if (
      pulledReport &&
      pulledReport.vehicleTitle &&
      pulledReport.vehicleTitle.brandsInformation
    ) {
      pulledReport.vehicleTitle.brandsInformation.forEach(brand => {
        if (brand.records) {
          failedBrands.push(brand);
        } else {
          checkedBrands.push(brand);
        }
      });
    }

    setCheckedBrands(checkedBrands);
    setFailedBrands(failedBrands);
  }, [pulledReport]);

  const loading = autoReportStatus === "loading";
  const numberToShow = 10;

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedBrands = isExpanded
    ? checkedBrands
    : checkedBrands.slice(0, numberToShow);

  const displayedFailedBrands = isExpanded
    ? failedBrands
    : failedBrands.slice(0, numberToShow);

  const renderBrands = displayedBrands.map(
    (brand: BrandsInformation, index: number) => {
      return (
        <Layout
          key={`${brand.code}_${index}--checked`}
          type="flex"
          flexProps={{ gap: "xxs", alignItems: "center" }}
        >
          <Text color="success" tag="div">
            <Icon name={IconList.IconYes} size={12} />
          </Text>
          <Text size="sm">{brand.name}</Text>
        </Layout>
      );
    },
  );

  const renderBrandName = (brand: BrandsInformation) => {
    if (brand.records && brand.disposition !== "TO BE DETERMINED") {
      return generateList(
        [
          {
            value: brand.name,
            label: "",
          },
          {
            value: brand.disposition,
            label: "",
          },
        ],
        " - ",
      );
    } else {
      return brand.name;
    }
  };

  const renderFailedBrands = displayedFailedBrands.map(
    (brand: BrandsInformation, index: number) => {
      return (
        <Layout
          type="flex"
          flexProps={{ gap: "xxs", alignItems: "center" }}
          key={`${brand.code}_summary_${index}--failed`}
        >
          <Text color="error" tag="div">
            <Icon name={IconList.IconWarning} size={20} />
          </Text>
          <Text fontWeight="bold">{renderBrandName(brand)}</Text>
        </Layout>
      );
    },
  );

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Title Brands Reported ${
          loading
            ? ""
            : `(${
                pulledReport &&
                pulledReport.vehicleSummary &&
                pulledReport.vehicleSummary.titleBrandCount
                  ? pulledReport.vehicleSummary.titleBrandCount
                  : 0
              })`
        }`}
      >
        <Container altColor="grey">
          <Padding space="md">
            <Text size="xs">Source: NMVTIS</Text>
          </Padding>
          {loading ? (
            <Padding space="md" top="none">
              <LoadingPlaceholder height={100} />
            </Padding>
          ) : pulledReport &&
            (checkedBrands.length > 0 || failedBrands.length > 0) ? (
            <Padding space="md" top="none">
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "sm" }}
              >
                {renderFailedBrands}
                {failedBrands && failedBrands.length > numberToShow && (
                  <ShowMore
                    handleClick={handleExpand}
                    open={isExpanded}
                    howManyMore={failedBrands.length - numberToShow}
                  />
                )}
                {checkedBrands && checkedBrands.length > 0 ? (
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    <Text color="success" tag="div">
                      <Icon name={IconList.IconCircleCheck} size={20} />
                    </Text>
                    <Text fontWeight="bold">
                      {failedBrands.length === 0
                        ? `${checkedBrands.length}/${checkedBrands.length} Brands Checked and 0 Reported`
                        : `${checkedBrands.length} Additional Brands
                    Checked`}
                    </Text>
                  </Layout>
                ) : null}
                <Padding left="md" right="md">
                  <Layout
                    type="grid"
                    gridProps={{
                      gap: "xs",
                      templateColumns: "2-column",
                    }}
                  >
                    {renderBrands}
                  </Layout>
                </Padding>
                {checkedBrands && checkedBrands.length > numberToShow && (
                  <ShowMore
                    handleClick={handleExpand}
                    open={isExpanded}
                    howManyMore={checkedBrands.length - numberToShow}
                  />
                )}
              </Layout>
            </Padding>
          ) : (
            <Text size="sm" fontWeight="bold">
              No results found for Title Brands
            </Text>
          )}
        </Container>
        {loading ? null : pulledReport && failedBrands.length > 0 ? (
          <CollapsableItemList isOpen={printReady}>
            {failedBrands.flatMap((brand, index) => {
              return brand.records
                ? brand.records.map((record, idx) => {
                    const brandRecords = renderArrayOfObjectsPrep(
                      [brand],
                      ["code"],
                      [
                        {
                          key: ["vehicleBrandDate"],
                          keyFormat: () => "Date Applied",
                          valueFormat: value => parseDate(value, "utc:MM/DD/YYYY"),
                        },
                        {
                          key: ["entityName"],
                          keyFormat: () => "State",
                        },
                        {
                          key: ["disposition"],
                          keyFormat: () => "Vehicle Disposition",
                        },
                        {
                          key: ["name"],
                          keyFormat: () => "Vehicle Brand Name",
                        },
                      ],
                    );

                    return (
                      <ContainerLineItem
                        lineItemHeader={
                          <Layout
                            type="block"
                            flexProps={{ gap: "xxs", alignItems: "center" }}
                          >
                            <Text tag="span">{renderBrandName(brand)}</Text>{" "}
                            <Text color="error" tag="span">
                              <Icon
                                name={IconList.IconWarning}
                                size={18}
                                className="page-auto-report__title-brands__svg"
                              />
                            </Text>
                          </Layout>
                        }
                        lineItemSubHeader={generateList(
                          [
                            {
                              value: brand.vehicleBrandDate
                                ? parseDate(brand.vehicleBrandDate, "year")
                                : null,
                              label: "",
                            },
                            {
                              value: record
                                ? capFirstLetter(
                                    record.reportingEntityAbstract.entityName,
                                  )
                                : null,
                              label: "",
                            },
                          ],
                          " | ",
                        )}
                        isOpen={printReady ? true : false}
                        key={`${brand.code}_${index}_${idx}--failed`}
                      >
                        {brandRecords[0] ? (
                          <Table
                            tableDataObject={brandRecords[0]}
                            orderOfProperties={[
                              "Vehicle Disposition",
                              "Date Applied",
                              "State",
                              "Vehicle Brand Name",
                              "Description",
                            ]}
                          />
                        ) : null}
                      </ContainerLineItem>
                    );
                  })
                : [];
            })}
          </CollapsableItemList>
        ) : null}
      </Container>
    </Padding>
  );
};

export default TitleBrandsReported;
