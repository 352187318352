import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import { generateList, parseDate, formatName } from "../../../utils";

// components
import {
  Container,
  CollapsableItemList,
  ContainerLineItem,
  Text,
  Padding,
  ReportLink,
} from "@kidslivesafe/sc-react";

import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

const ResidentHistory: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";

  const pullPeopleReport = usePeopleReportDispatch();
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Resident History ${
          loading
            ? ""
            : `(${pulledReport?.residents ? pulledReport.residents.length : 0})`
        }`}
        containerId={containerId}
        newInfo={reportDifferences?.residents}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : pulledReport?.residents && pulledReport.residents[0] ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport.residents.map((resident, index) => (
              <ContainerLineItem
                lineItemHeader={
                  <>
                    {resident.name?.reportToken ? (
                      <ReportLink<PullPeopleReportParams>
                        text={formatName(resident.name)}
                        inputValue={resident.name.reportToken}
                        handleClick={pullPeopleReport}
                      />
                    ) : resident.name ? (
                      <Text>{formatName(resident.name)}</Text>
                    ) : null}
                  </>
                }
                lineItemSubHeader={generateList(
                  [
                    {
                      value: resident.dateFirstSeen
                        ? parseDate(resident.dateFirstSeen, "month-year")
                        : null,
                      label: "",
                    },
                    {
                      value: resident.dateLastSeen
                        ? parseDate(resident.dateLastSeen, "month-year")
                        : null,
                      label: "",
                    },
                  ],
                  " - ",
                )}
                isOpen={printReady ? true : false}
                key={index}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              No results found for Resident History
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default ResidentHistory;
