import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import authenticationReducer from "../features/authentication/authenticationSlice";
import subscriptionSlice from "../features/subscriptions/subscriptionSlice";
import reportsSlice from "../features/reports/reportsSlice";
import brandSlice from "../features/brand/brandSlice";
import monitoringSlice from "../features/monitoring/monitoringSlice";
import multiSearchSlice from "../features/multisearch/multisearchSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logMiddleware } from "./middleware";
import adminSlice from "../features/admin/adminSlice";

const keyPrefix = "brand:";

const authPersistConfig = {
  key: "auth",
  storage,
  // whitelist: ["customerId", "accountType"], // only customerId and accountType will be persisted
  keyPrefix: keyPrefix,
  blacklist: ["status"],
};

const subscriptionPersistConfig = {
  key: "subscription",
  storage,
  keyPrefix: keyPrefix,
};

const reportsPersistConfig = {
  key: "report",
  storage,
  keyPrefix: keyPrefix,
  blacklist: [
    "socialSearchStatus",
    "phoneSearchStatus",
    "peopleReportStatus",
    "neighborhoodMapModalContent",
    "neighborhoodMapModal",
  ],
};

const multiSearchPersistConfig = {
  key: "multiSearch",
  storage,
  keyPrefix: keyPrefix,
};

const monitoringPersistConfig = {
  key: "monitoring",
  storage,
  whitelist: [
    "totalAllowedMonitoredReports",
    "monitoredReports",
    "neighborhoodMonitoringAreas",
    "propertyMonitoredReports",
  ],
  blacklist: ["maxMonitoringReached"],
  keyPrefix: keyPrefix,
};

const adminPersistConfig = {
  key: "admin",
  storage,
  whitelist: ["emailVerificationRequired"],
  blacklist: ["emailSent", "passwordReset"],
  keyPrefix: keyPrefix,
};

const rootReducer = {
  counter: counterReducer,
  authentication: persistReducer(authPersistConfig, authenticationReducer),
  subscription: persistReducer(subscriptionPersistConfig, subscriptionSlice),
  reports: persistReducer(reportsPersistConfig, reportsSlice),
  admin: persistReducer(adminPersistConfig, adminSlice),
  brand: brandSlice,
  monitoring: persistReducer(monitoringPersistConfig, monitoringSlice),
  multiSearch: persistReducer(multiSearchPersistConfig, multiSearchSlice),
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    process.env.REACT_APP_NODE_ENV === "production" // if in production, don't use redux-logger, might still need to set this up
      ? getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
          },
        })
      : getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
          },
        }).concat(logMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
