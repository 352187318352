import React from "react";

import { useAppSelector, useHandlePageNavigate } from "../../../hooks/hooks";

import { selectBrandInfo } from "../../../features/brand/brandSlice";
import {
  Button,
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
} from "@kidslivesafe/sc-react";

import "./logout.css";

const Logout: React.FC = () => {
  const navigate = useHandlePageNavigate();
  const brandInfo = useAppSelector(selectBrandInfo);
  const handleExternalNavigation = (url: string) => {
    window.location.href = url;
  };

  return (
    <>
      <ReportTitle reportTitle="Logout" />
      <Sheet>
        <Padding space="md" top="xxl">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
            }}
          >
            <Text tag="h2" fontWeight="semibold" textAlign="center">
              Thank you for using {brandInfo.name}
            </Text>
            <Text textAlign="center" textWrap="balance">
              Please remember, we update our report database daily. Make sure
              your Report Monitoring is set so you will get regular updates if
              the information changes.
            </Text>

            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "xs",
                alignItems: "center",
              }}
            >
              <Button
                buttonText="Return To Home Page"
                handleClick={() => handleExternalNavigation(brandInfo.homeUrl)}
              />
              <Button
                buttonText="Member Login"
                handleClick={() => navigate("/login")}
                color="secondary"
              />
            </Layout>
          </Layout>
        </Padding>
      </Sheet>
    </>
  );
};

export default Logout;
