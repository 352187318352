import React, { useEffect, useState } from "react";
import {
  Padding,
  Layout,
  Sheet,
  Text,
  Margin,
  Modal,
  Button,
  Tab,
  MultiSearch,
  Input,
  Container,
} from "@kidslivesafe/sc-react";

import MostRecentReport from "./MostRecentReport";
import ActivityHistoryContainer from "./ActivityHistoryContainer";
import MonitoringContainer from "./MonitoringContainer";
import SpacerMultiSearch from "./SpacerMultiSearch";
import IdProtect from "./IdProtectDash";
import SpacerMonitoring from "./SpacerMonitoring";

import "./dashboard.css";
import {
  selectMemberExpired,
  selectIdProtectData,
  selectMemberData,
  selectAccountType,
} from "../../features/authentication/authenticationSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  createCustomerRedisTokenAsync,
  selectAdminStatus,
  sendEmailVerificationAsync,
} from "../../features/admin/adminSlice";
import { selectBrandInfo } from "../../features/brand/brandSlice";
import { useMultiSearchProps } from "../../hooks/multiSearchPropsHook";
import { selectEmailVerificationRequired } from "../../features/admin/adminSlice";

import { scrollToHashElement } from "../../utils";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import NeighborhoodWatchSearch from "../../components/UIComponents/NeighborhoodWatchSearch/NeighborhoodWatchSearch";
import {
  selectRenameReport,
  selectToggleMonitoringModal,
  setToggleMonitoringModal,
  updateNeighborhoodWatchMonitoringAreaAsync,
  updatePropertyMonitoringAsync,
} from "../../features/monitoring/monitoringSlice";
import { useReactivateCheckHook } from "../../hooks/reactivateHook";
import { useLocation } from "react-router-dom";
import MostRecentPropertyFocus from "../../components/UIComponents/_BrandSpecific/PropertyFocus/MostRecentPropertyFocus";

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const brandInfo = useAppSelector(selectBrandInfo);
  const idProtectData = useAppSelector(selectIdProtectData);
  const memberExpired = useAppSelector(selectMemberExpired);
  const { customerId } = useAppSelector(selectMemberData);
  const adminStatus = useAppSelector(selectAdminStatus);
  const accountType = useAppSelector(selectAccountType);
  const emailVerificationRequired = useAppSelector(
    selectEmailVerificationRequired,
  );
  const [nickname, setNickname] = useState<string>("");
  const [nicknameError, setNickNameError] = useState<boolean>(false);

  const renameReport = useAppSelector(selectRenameReport);

  const monitoringModal = useAppSelector(selectToggleMonitoringModal);

  const modalLoading = adminStatus === "loading"; // talk to cody why its in useEffect
  const currentBreakpoint = useCurrentBreakpoint();
  const reactivateCheck = useReactivateCheckHook();

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [tabs, setTabs] = React.useState<Tab[]>([
    "people",
    "property",
    "phone",
    "social",
  ]);

  const [reactivateCheckFired, setReactivateCheckFired] =
    useState<boolean>(false);

  const whichTabs = (type: Tab | "neighborhood" | null) => {
    switch (type) {
      case "auto":
        setTabs(["auto"]);
        break;
      default:
        setTabs(["people", "property", "phone", "social"]);
        break;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramValue = params.get("reactivated");
    if (paramValue === "true" && !reactivateCheckFired) {
      setReactivateCheckFired(true);
      reactivateCheck();
    }
  }, [location.search, reactivateCheck, reactivateCheckFired]);

  useEffect(() => {
    whichTabs(accountType);
  }, [accountType]);

  const multiSearchProps = useMultiSearchProps(
    { footer: false, resetInput: true, buttonText: "SEARCH NOW", tabs: tabs },
    "dashboard",
  );

  useEffect(() => {
    if (adminStatus === "loading") {
      setLoading(true);
    }
  }, [adminStatus]);

  //if there is an anchor hash, will scroll
  useEffect(() => {
    const scrollHeight = currentBreakpoint === "desktop" ? 75 : 80;

    // Scroll only if there's a hash in the URL
    const handleScroll = () => {
      if (window.location.hash) {
        // Ensure a delay so that the page is fully rendered
        setTimeout(() => {
          scrollToHashElement(scrollHeight);
        }, 700);
      }
    };

    // Call on initial render or breakpoint change
    handleScroll();

    // Optional: Handle hash change events dynamically
    window.addEventListener("hashchange", handleScroll);

    return () => {
      // Cleanup the event listener
      window.removeEventListener("hashchange", handleScroll);
    };
  }, [currentBreakpoint]); // Re-run effect when breakpoint changes

  const handleReactivateMembership = async () => {
    const createCustomerRedisTokenAsyncResponse = await dispatch(
      createCustomerRedisTokenAsync({ customerId }),
    );
    if (
      createCustomerRedisTokenAsync.fulfilled.match(
        createCustomerRedisTokenAsyncResponse,
      )
    ) {
      const uuid = createCustomerRedisTokenAsyncResponse.payload.token;
      window.location.href =
        brandInfo.homeUrl + "/reactivate?uuid=" + uuid + "&beta=true";
    }
    //TODO: display message if redis token setting fails? not sure
  };

  const handleSendEmailVerification = async () => {
    const createCustomerRedisTokenAsyncResponse = await dispatch(
      createCustomerRedisTokenAsync({ customerId }),
    );
    if (
      createCustomerRedisTokenAsync.fulfilled.match(
        createCustomerRedisTokenAsyncResponse,
      )
    ) {
      const token = createCustomerRedisTokenAsyncResponse.payload.token;
      const sendEmailVerificationResponse = await dispatch(
        sendEmailVerificationAsync({ customerId: customerId, token: token }),
      );
      if (
        sendEmailVerificationAsync.fulfilled.match(
          sendEmailVerificationResponse,
        )
      ) {
        setEmailSent(true);
      }
    }
  };

  const handleSubmitNickName = (nickname: string) => {
    if (nickname.length > 15) {
      setNickNameError(true);
    } else {
      setNickNameError(false);
    }
  };

  return (
    <>
      {accountType === "neighborhood" ? (
        <Container customClassName="page-dashboard_input-container">
          <Sheet>
            <Padding breakpointStyles={{ desktop: { bottom: "lg" } }}>
              <Padding bottom="sm">
                <Text tag="h3" textAlign="center">
                  Neighborhood Watch Search
                </Text>
              </Padding>
              <NeighborhoodWatchSearch
                showNeighborhoodSearchLocation
                showNeighborhoodSearchName
              />
            </Padding>
          </Sheet>
        </Container>
      ) : null}
      <Sheet>
        {accountType === "auto" ? (
          <Padding
            space="none"
            breakpointStyles={{ desktop: { left: "md", right: "md" } }}
          >
            <MultiSearch {...multiSearchProps} />
          </Padding>
        ) : null}

        <Padding top="md" bottom="md">
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            {brandInfo.acronym === "pf" ? (
              <MostRecentPropertyFocus />
            ) : (
              <MostRecentReport />
            )}
            {accountType === "auto" ? null : (
              <>
                {brandInfo.acronym === "pf" ? null : <SpacerMultiSearch />}

                {idProtectData.isIdProtectAvailable ||
                idProtectData.idProtectMember ? (
                  <Padding left="md" right="md">
                    <IdProtect />
                  </Padding>
                ) : null}

                <MonitoringContainer />
              </>
            )}
            <ActivityHistoryContainer />
          </Layout>
        </Padding>
      </Sheet>
      {accountType === "auto" ? null : <SpacerMonitoring />}
      <Modal open={memberExpired} setOpen={() => {}} size="base">
        <Padding space="md">
          <Margin top="xxl">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "lg",
                alignItems: "center",
              }}
            >
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "sm",
                }}
              >
                <div>
                  <Text textAlign="center" tag="h2" fontWeight="semibold">
                    Membership Status:{" "}
                  </Text>
                  <Text tag="h2" textAlign="center" color="error">
                    EXPIRED
                  </Text>
                </div>
                <Text textAlign="center" size="sm">
                  Your membership has expired. Reactivate now to gain access to
                  all of your reports and monitors.
                </Text>
              </Layout>
              <Button
                buttonText="Reactivate Membership"
                handleClick={handleReactivateMembership}
                clicked={loading}
                loading={loading}
              />
            </Layout>
          </Margin>
        </Padding>
      </Modal>
      <Modal
        open={emailVerificationRequired}
        setOpen={() => {}}
        size="fit-content"
        xClose
      >
        <Padding space="md">
          <Margin top="xxl" bottom="xxl">
            {!emailSent ? (
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "lg",
                  alignItems: "center",
                }}
              >
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    gap: "lg",
                  }}
                >
                  <Text tag="h2" textAlign="center">
                    {" "}
                    Verify Email
                  </Text>
                  <Text textAlign="center" size="sm">
                    You must verify your email to access more reports. Please
                    click OK and we will send you a verification email.
                  </Text>
                </Layout>
                <Button
                  buttonText="Send Email"
                  handleClick={handleSendEmailVerification}
                  clicked={modalLoading}
                  loading={modalLoading}
                />
              </Layout>
            ) : (
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "lg",
                }}
              >
                <Text tag="h2" textAlign="center">
                  {" "}
                  Verify Email
                </Text>
                <Text textAlign="center" size="sm">
                  Your Email has been sent.
                </Text>
              </Layout>
            )}
          </Margin>
        </Padding>
      </Modal>
      <Modal
        open={monitoringModal}
        setOpen={() => {
          dispatch(setToggleMonitoringModal(!monitoringModal));
          setNickname("");
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding space="md">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
              alignItems: "center",
            }}
          >
            <Text tag="h2">Set New Name</Text>
            <Text size="xs" tag="i">
              Please use 15 characters maximum
            </Text>
            <Layout
              type="flex"
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Input
                id="neighborhood-report__monitoring-modal"
                value={nickname}
                handleChange={input => {
                  setNickname(input);
                  handleSubmitNickName(input);
                }}
                type="text"
                placeholder={
                  renameReport && renameReport.type === "property"
                    ? "Property"
                    : "Neighborhood"
                }
                fill="fill"
                error={nicknameError}
              />
              <Button
                buttonText="Set Name"
                fill="fill"
                disabled={nicknameError}
                handleClick={() => {
                  if (renameReport) {
                    if (renameReport.type === "neighborhood") {
                      dispatch(
                        updateNeighborhoodWatchMonitoringAreaAsync({
                          address: renameReport.address,
                          addressLine2: renameReport.addressLine2,
                          city: renameReport.city,
                          state: renameReport.state,
                          zipCode: renameReport.zipCode,
                          nickname: nickname,
                          radius: 5,
                          id: renameReport.id,
                          latestOffendersCount:
                            renameReport?.latestOffendersCount,
                        }),
                      );
                    } else if (renameReport.type === "property") {
                      dispatch(
                        updatePropertyMonitoringAsync({
                          addressLine1: renameReport.addressLine1,
                          addressLine2: renameReport.addressLine2,
                          city: renameReport.city,
                          state: renameReport.state,
                          zip: renameReport.zip,
                          nickname: nickname,
                        }),
                      );
                    }
                  }
                  dispatch(setToggleMonitoringModal(!monitoringModal));
                  setNickname("");
                }}
              />
            </Layout>
          </Layout>
        </Padding>
      </Modal>
    </>
  );
};

export default Dashboard;
