import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

// components
import {
  Layout,
  Text,
  Map,
  LoadingPlaceholder,
  Padding,
  Button,
  LineItemSummary,
  Table,
  Modal,
} from "@kidslivesafe/sc-react";

// redux getters
import {
  selectNeighborhoodMapModal,
  selectNeighborhoodMapModalContent,
  selectNeighborhoodSearch,
  selectNeighborhoodSearchStatus,
  setNeighborhoodMapModal,
  setNeighborhoodMapModalContent,
} from "../../../features/reports/reportsSlice";
import { selectMapboxKey } from "../../../features/brand/brandSlice";

// utils
import { renderArrayOfObjectsPrep } from "../../../utils";

// types
import {
  NeighborhoodSearch,
  SearchOffender,
} from "../../../features/reports/reportTypes";
import { formatAddress } from "@kidslivesafe/sc-foundation";

interface PinData {
  latitude: number;
  longitude: number;
  popup: React.ReactNode;
  element?: React.ReactNode;
}

const MapOfNames: React.FC<{}> = () => {
  const pulledReport = useAppSelector(
    selectNeighborhoodSearch,
  ) as NeighborhoodSearch;
  const neighborhoodSearchStatus = useAppSelector(
    selectNeighborhoodSearchStatus,
  );
  const [mapPins, setMapPins] = React.useState<PinData[]>([]);
  const openOffenderModal = useAppSelector(selectNeighborhoodMapModal);
  const neighborhoodMapModalContent = useAppSelector(
    selectNeighborhoodMapModalContent,
  );
  const dispatch = useAppDispatch();

  const loading = neighborhoodSearchStatus === "loading";

  const mapboxToken = useAppSelector(selectMapboxKey);

  const offenderSummary = renderArrayOfObjectsPrep(
    pulledReport?.offenders || [],
    [
      "totalOffenders",
      "distance",
      "latitude",
      "longitude",
      "createDate",
      "offenderID",
      "name",
      "zipCode",
      "state",
      "city",
    ],
    [
      {
        key: ["offense"],
        keyFormat: () => "Offense(s)",
      },
      {
        key: ["address"],
        valueFormat: (value, currentObject: SearchOffender) => {
          let formattedAddress = {
            addressLine1: value,
            city: currentObject.city,
            state: currentObject.state,
            zip: currentObject.zipCode,
          };
          return formatAddress({ address: formattedAddress });
        },
      },
    ],
  );

  const renderOffenderDetails = (offender: SearchOffender, index: number) => {
    return (
      <Layout
        type="flex"
        flexProps={{ direction: "column", alignItems: "center" }}
      >
        <img
          src={`https://photo.familywatchdog.us/OffenderPhoto/OffenderPhoto.aspx?id=${offender.offenderID}`}
          alt={`picture_${offender.name}`}
          height={173.25}
          width={130.5}
        />
        <Padding top="xs">
          <Text fontWeight="bold" tag="h2" textAlign="center">
            {offender.name.split(", ").reverse().join(" ")}
          </Text>
        </Padding>
        <LineItemSummary backgroundColor={false} />
        <Padding left="md" right="md">
          <Table
            tableDataObject={offenderSummary[index]}
            orderOfProperties={["Offense(s)"]}
          />
        </Padding>
      </Layout>
    );
  };
  useEffect(() => {
    /* Map Prep */

    const prepareOffenderPinData = (offenders: SearchOffender[]) => {
      const pinData = offenders
        .map((offender, index) => {
          const latitude = Number(offender.latitude);
          const longitude = Number(offender.longitude);

          // Check if the coordinates are valid within the US, and if so, return the pin data
          if (
            !isNaN(latitude) &&
            !isNaN(longitude) &&
            latitude !== 0 &&
            longitude !== 0 &&
            latitude >= 24.396308 &&
            latitude <= 49.384358 &&
            longitude >= -125.0 &&
            longitude <= -66.93457
          ) {
            return {
              latitude: latitude,
              longitude: longitude,
              popup: (
                <Layout type="flex" flexProps={{ direction: "row", gap: "xs" }}>
                  <img
                    src={`https://photo.familywatchdog.us/OffenderPhoto/OffenderPhoto.aspx?id=${offender.offenderID}`}
                    alt={`picture_${offender.name}`}
                    height={77}
                    width={61.8}
                  />
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      alignItems: "flex-start",
                    }}
                    passThroughClassess="page-neighborhood-report__popup-container"
                  >
                    <Text fontWeight="bold" size="sm">
                      {offender.name.split(", ").reverse().join(" ")}
                    </Text>
                    <Text
                      size="xxs"
                      passThroughClassess="page-neighborhood-report__popup-overflow"
                    >
                      {offender.city}, {offender.state} {offender.zipCode}
                    </Text>
                    <Text
                      size="xxs"
                      passThroughClassess="page-neighborhood-report__popup-overflow"
                    >
                      {offender.offense}
                    </Text>
                    <Padding top="xxs">
                      <Button
                        buttonText={"View Details"}
                        handleClick={() => {
                          dispatch(
                            setNeighborhoodMapModalContent({ offender, index }),
                          );
                          dispatch(setNeighborhoodMapModal(true));
                        }}
                        color="secondary"
                        size="xs"
                      />
                    </Padding>
                  </Layout>
                </Layout>
              ),
            };
          } else {
            // Skip this entry if the coordinates are invalid
            return null;
          }
        })
        // Remove null entries
        .filter((pin): pin is Exclude<typeof pin, null> => pin !== null);
      return pinData;
    };
    if (pulledReport) {
      if (pulledReport.nameSearch) {
        setMapPins([...prepareOffenderPinData(pulledReport.offenders)]);
      }
    }
  }, [pulledReport, dispatch]);

  return (
    <>
      <>
        {loading ? (
          <LoadingPlaceholder width="100%" height="500px" />
        ) : (
          pulledReport &&
          pulledReport.offenders &&
          pulledReport.offenders.length > 0 && (
            <Map
              mapboxToken={mapboxToken}
              markerData={pulledReport.offenders ? mapPins : undefined}
              center={
                pulledReport.offenders &&
                !isNaN(Number(pulledReport.offenders[0].latitude)) &&
                !isNaN(Number(pulledReport.offenders[0].longitude)) &&
                Number(pulledReport.offenders[0].latitude) !== 0 &&
                Number(pulledReport.offenders[0].longitude) !== 0
                  ? [
                      Number(pulledReport.offenders[0].longitude),
                      Number(pulledReport.offenders[0].latitude),
                    ]
                  : undefined
              }
            />
          )
        )}
      </>
      <Modal
        open={openOffenderModal}
        setOpen={() => dispatch(setNeighborhoodMapModal(!openOffenderModal))}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding>
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
              alignItems: "center",
            }}
          >
            {neighborhoodMapModalContent &&
              renderOffenderDetails(
                neighborhoodMapModalContent.offender,
                neighborhoodMapModalContent.index,
              )}
          </Layout>
        </Padding>
      </Modal>
    </>
  );
};

export default MapOfNames;
