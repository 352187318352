import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectNeighborhoodSearch,
  selectNeighborhoodSearchStatus,
} from "../../../features/reports/reportsSlice";
import {
  Container,
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  LineItemSummary,
  Padding,
  Select,
  Table,
  Text,
} from "@kidslivesafe/sc-react";

import { fullCapsText, renderArrayOfObjectsPrep } from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { formatAddress, generateList } from "@kidslivesafe/sc-foundation";
import {
  NeighborhoodSearch,
  SearchOffender,
} from "../../../features/reports/reportTypes/neighborhoodReportTypes";

const ListOfOffenderNames: React.FC<{
  printReady?: boolean;
}> = ({ printReady }) => {
  const [sort, setSort] = React.useState<string | undefined>(undefined);
  const [sortedReports, setSortedReports] = React.useState<
    SearchOffender[] | undefined
  >(undefined);

  const pulledReport = useAppSelector(
    selectNeighborhoodSearch,
  ) as NeighborhoodSearch;
  const neighborhoodSearchStatus = useAppSelector(
    selectNeighborhoodSearchStatus,
  );

  const loading = neighborhoodSearchStatus === "loading";

  useEffect(() => {
    if (pulledReport && pulledReport.offenders) {
      setSortedReports([...pulledReport.offenders]);
      setSort("best-match");
    }
  }, [pulledReport]);

  useEffect(() => {
    if (pulledReport && pulledReport.offenders && sort) {
      let newSortedReports = [...pulledReport.offenders];
      switch (sort) {
        case "best-match":
          newSortedReports = [...pulledReport.offenders];
          break;
        case "name":
          newSortedReports.sort((a, b) =>
            a.name
              .split(", ")
              .reverse()
              .join(" ")
              .localeCompare(b.name.split(", ").reverse().join(" ") ?? ""),
          );
          break;
        case "state":
          newSortedReports.sort((a, b) =>
            (a.state ?? "").localeCompare(b.state ?? ""),
          );
          break;
        default:
          break;
        case "zip":
          newSortedReports.sort((a, b) =>
            (a.zipCode ?? "").localeCompare(b.zipCode ?? ""),
          );
          break;
      }
      setSortedReports(newSortedReports);
    }
  }, [sort, pulledReport]);

  const offenderSummary = renderArrayOfObjectsPrep(
    pulledReport?.offenders || [],
    [
      "totalOffenders",
      "distance",
      "latitude",
      "longitude",
      "createDate",
      "offenderID",
      "name",
      "zipCode",
      "state",
      "city",
    ],
    [
      {
        key: ["offense"],
        keyFormat: () => "Offense(s)",
        valueFormat: (value, currentObject: SearchOffender) => {
          const parsedOffense =
            new DOMParser().parseFromString(currentObject.offense, "text/html")
              .body.textContent || "";

          return parsedOffense;
        },
      },
      {
        key: ["address"],
        valueFormat: (value, currentObject: SearchOffender) => {
          let formattedAddress = {
            addressLine1: value,
            city: currentObject.city,
            state: currentObject.state,
            zip: currentObject.zipCode,
          };
          return formatAddress({ address: formattedAddress });
        },
      },
    ],
  );

  return (
    <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
      <Layout type="flex" flexProps={{ justifyContent: "space-between" }}>
        <Text fontWeight="semibold">
          Top{" "}
          <Text color="accent" tag="span" fontWeight="bold">
            {pulledReport &&
              pulledReport.offenders &&
              pulledReport.offenders.length}
          </Text>{" "}
          Matches Found
        </Text>
        <Layout type="flex" flexProps={{ gap: "xs" }}>
          <Text fontWeight="semibold" textWrap="nowrap">
            Sort By:
          </Text>
          <Select
            value={sort}
            selectStyle="flat"
            onOptionSelected={value => setSort(value)}
            options={[
              { value: "best-match", label: "Best Match" },
              { value: "name", label: "Name" },
              { value: "state", label: "State" },
              { value: "zip", label: "Zip" },
            ]}
          />
        </Layout>
      </Layout>
      {loading ? (
        <>
          <Container>
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              lineItemSubHeaderLine2
              numberToLoad={1}
              children
            />
          </Container>
          <Container>
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              lineItemSubHeaderLine2
              numberToLoad={1}
              children
            />
          </Container>
          <Container>
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              lineItemSubHeaderLine2
              numberToLoad={1}
              children
            />
          </Container>
        </>
      ) : (
        <>
          {sortedReports && sortedReports.length > 0 ? (
            sortedReports.map((offender, index) => (
              <Container key={`${offender.name}_${offender.offenderID}`}>
                <ContainerLineItem
                  key={`${offender.offenderID}_${index}`}
                  lineItemHeader={
                    <Layout
                      type="flex"
                      flexProps={{ gap: "xxs", alignItems: "center" }}
                    >
                      <Text>
                        {fullCapsText(
                          offender.name.split(",").reverse().join(" "),
                        )}
                      </Text>
                    </Layout>
                  }
                  lineItemSubHeader={fullCapsText(
                    generateList(
                      [
                        { value: offender.address, label: "" },
                        {
                          value: generateList(
                            [
                              { value: offender.city, label: "" },
                              { value: offender.state, label: "" },
                            ],
                            ", ",
                          ),
                          label: "",
                        },
                        { value: offender.zipCode, label: "" },
                      ],
                      " ",
                    ),
                  )}
                  lineItemSubHeaderLine2={fullCapsText(
                    generateList(
                      [
                        {
                          value: offender.offense
                            ? offenderSummary[index]["Offense(s)"]
                            : null,
                          label: "",
                        },
                      ],
                      "",
                    ),
                  )}
                  lineItemSubheaderNoWrap
                  pictureUrl={`https://photo.familywatchdog.us/OffenderPhoto/OffenderPhoto.aspx?id=${offender.offenderID}`}
                  isOpen={printReady || index === 0 ? true : false}
                >
                  {offenderSummary[index] ? (
                    <>
                      <LineItemSummary backgroundColor={false} />
                      <Padding left="md" right="md">
                        <Table
                          tableDataObject={offenderSummary[index]}
                          orderOfProperties={["Offense(s)"]}
                        />
                      </Padding>
                    </>
                  ) : null}
                </ContainerLineItem>
              </Container>
            ))
          ) : (
            <Container>
              <ContainerLineItem
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    <Text color="success" tag="div">
                      <Icon name={IconList.IconCircleCheck} size={18} />
                    </Text>
                    <Text>0 Offenders Found</Text>
                  </Layout>
                }
              />
            </Container>
          )}
        </>
      )}
    </Layout>
  );
};

export default ListOfOffenderNames;
