import React from "react";

import "./notSupported.css";

import {
  Container,
  Padding,
  Layout,
  Text,
  Sheet,
  AnchorLink,
} from "@kidslivesafe/sc-react";

import Logo from "@assets/images/brand-logo.svg";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

import chrome from "../../../assets/global/images/browser_type--chrome.png";
import edge from "../../../assets/global/images/browser_type--edge.png";
import firefox from "../../../assets/global/images/browser_type--firefox.png";
import safari from "../../../assets/global/images/browser_type--safari.png";

const NotSupported: React.FC = () => {
  const currentBreakPoint = useCurrentBreakpoint();

  return (
    <>
      <Sheet>
        <Layout
          type="flex"
          flexProps={{ direction: "column", alignItems: "flex-start" }}
        >
          <Padding space="md">
            <div className="page-not-supported-brand-logo-container">
              <img
                width={currentBreakPoint === "desktop" ? "147" : "88"}
                src={Logo}
                alt="Logo"
              />
            </div>
          </Padding>
        </Layout>
      </Sheet>

      <Sheet>
        <Padding space="md">
          <div
            className={
              currentBreakPoint === "desktop"
                ? "page-not-supported-sheet-form-container"
                : ""
            }
          >
            <Layout type="flex" flexProps={{ direction: "column", gap: "lg" }}>
              <Container bodyBorderRadius>
                <Padding space="xl">
                  <Text tag="h1">
                    Did you know that your browser is out of date or does not
                    support WebGL?
                  </Text>
                  <Padding bottom="xl" top="xl">
                    <Text size="sm" fontWeight="semibold">
                      Unfortunately, our site will not work with your current
                      browser. To get the best experience using our site, you
                      will need to upgrade to a newer version or other web
                      browser, or enable Hardware Acceleration.{" "}
                    </Text>
                  </Padding>
                  <Text size="sm" fontWeight="semibold">
                    Here are the most popular browsers you can use with our
                    site:
                  </Text>
                  <Padding top="md">
                    <Layout
                      type="flex"
                      flexProps={{
                        direction: "row",
                        gap: "xl",
                        justifyContent: "center",
                        wrap: "wrap",
                        alignItems: "baseline",
                      }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={edge} alt="Microsoft Edge logo" width={44} />
                        <AnchorLink
                          fontWeight="semibold"
                          hoverUnderline={true}
                          href="https://www.microsoft.com/en-us/edge/download?form=MA13FJ"
                        >
                          Microsoft Edge
                        </AnchorLink>
                      </Layout>
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={firefox}
                          alt="Mozilla Firefox logo"
                          width={44}
                        />
                        <AnchorLink
                          fontWeight="semibold"
                          hoverUnderline={true}
                          href="https://www.mozilla.org/en-US/firefox/"
                        >
                          Mozilla Firefox
                        </AnchorLink>
                      </Layout>
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={chrome}
                          alt="Microsoft Edge logo"
                          width={44}
                        />
                        <AnchorLink
                          fontWeight="semibold"
                          hoverUnderline={true}
                          href="https://www.google.com/chrome/"
                        >
                          Google Chrome
                        </AnchorLink>
                      </Layout>
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={safari}
                          alt="Microsoft Edge logo"
                          width={44}
                        />
                        <AnchorLink
                          fontWeight="semibold"
                          hoverUnderline={true}
                          href="https://support.apple.com/en-us/102665"
                        >
                          Apple Safari
                        </AnchorLink>
                      </Layout>
                    </Layout>
                  </Padding>
                </Padding>
              </Container>
            </Layout>
          </div>
        </Padding>
      </Sheet>
    </>
  );
};

export default NotSupported;
