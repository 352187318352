import React, {useEffect} from "react";

const LoadTestError: React.FC = () => {

  useEffect(() => {
    throw new Error("This is a simulated error on page load.");
  })

  return(
    <>
    <p>If you see this, it is NOT working</p>
    </>
  );
};

export default LoadTestError;
