import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Padding,
  Text,
  SectionTitle,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
} from "@kidslivesafe/sc-react";

import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import {
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
  convertStateAbbreviation,
  formatPhoneNumber,
  convertToFormattedNumber,
  formatName,
} from "../../../utils";
import { Bankruptcy, Judgment } from "../../../features/reports/reportTypes";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { usePhoneReportDispatch } from "../../../hooks/phoneReportHook";

const FinancialInfo: React.FC<{ sectionId: string; printReady?: boolean }> = ({
  sectionId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );

  const loading = peopleReportStatus === "loading";

  const pullPeopleReport = usePeopleReportDispatch();
  const pullPhoneReport = usePhoneReportDispatch();

  /* generate bankruptcy Object */
  const bankruptcyObjects = renderArrayOfObjectsPrep(
    pulledReport?.bankruptcies || [],
    [],
    [
      {
        key: ["debtorsDetails"],
        keyFormat: () => "Debtors",
        valueFormat: (value, currentObject: Bankruptcy) => {
          const jsxElements = [];
          for (let i = 0; i < currentObject.debtorsDetails.length; i++) {
            const debtor = currentObject.debtorsDetails[i];
            jsxElements.push(
              debtor.reportToken ? (
                <ReportLink<PullPeopleReportParams>
                  key={`debtor-${i}`}
                  smallText
                  inputValue={debtor.reportToken}
                  handleClick={pullPeopleReport}
                  text={debtor.fullName}
                />
              ) : (
                <Text
                  passThroughClassess="sc-table-row__detail"
                  key={`debtor-${i}`}
                  size="sm"
                >
                  {debtor.fullName}
                </Text>
              ),
            );
          }

          return <>{jsxElements}</>;
        },
      },
      {
        key: ["courtAddress"],
        keyFormat: () => "Subject's Address",
      },
      {
        key: ["attorneyPhone", "courtPhone", "trusteePhone"],
        valueFormat: (value, currentObject: Bankruptcy) => {
          return (
            <ReportLink
              smallText
              inputValue={value}
              handleClick={pullPhoneReport}
              text={formatPhoneNumber(value)}
            />
          );
        },
      },
    ],
  );

  /* generate judgment Object */
  const judgmentObjects = renderArrayOfObjectsPrep(
    pulledReport?.judgments || [],
    ["debtorsDetails"],
    [
      {
        key: ["courtAddress"],
        keyFormat: () => "Subject's Address",
      },
      {
        key: ["totalJudgmentAmount"],
        valueFormat: value => `$${convertToFormattedNumber(value)}`,
      },
      {
        key: ["businessName"],
        keyFormat: () => "Owner",
      },
      {
        key: ["courtPhone"],
        valueFormat: (value, currentObject: Judgment) => {
          return (
            <ReportLink
              smallText
              inputValue={value}
              handleClick={pullPhoneReport}
              text={formatPhoneNumber(value)}
            />
          );
        },
      },
    ],
  );

  /* generate lien Object */
  const lienObjects = renderArrayOfObjectsPrep(
    pulledReport?.liens || [],
    ["reportToken"],
    [
      {
        key: ["totalLienAmount"],
        valueFormat: value => `$${convertToFormattedNumber(value)}`,
      },
      {
        key: ["courtPhone"],
        valueFormat: (value, currentObject: Judgment) => {
          return (
            <ReportLink
              smallText
              inputValue={value}
              handleClick={pullPhoneReport}
              text={formatPhoneNumber(value)}
            />
          );
        },
      },
    ],
  );

  /* generate forclosure Object */
  const forclosureObjects = renderArrayOfObjectsPrep(
    pulledReport?.propertyForeclosures || [],
    [],
    [],
  );

  /* generate eviction Object */
  const evictionObjects = renderArrayOfObjectsPrep(
    pulledReport?.evictions || [],
    [],
    [],
  );

  /* generate uccFiling Object */
  const uccFilingObjects = renderArrayOfObjectsPrep(
    pulledReport?.uccFilings || [],
    ["reportToken"],
    [],
  );

  return (
    <>
      <>
        <SectionTitle
          sectionTitle="Financial Information"
          headingLevel={2}
          sectionId={sectionId}
        />
        {/* Bankrupcty */}
        <Container
          containerTitle={`Possible Bankruptcies ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.bankruptcies
                    ? pulledReport.bankruptcies.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.bankruptcies}
          tooltipContent="There are two main types of personal bankruptcies: Chapter 7 bankruptcy involves the liquidation of assets to repay debts, typically leading to a complete discharge of most unsecured debts, while Chapter 13 bankruptcy creates a debt repayment plan over several years, allowing the debtor to retain their assets and make structured payments to creditors."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.bankruptcies &&
            pulledReport.bankruptcies[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.bankruptcies.map((bankruptcy, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    bankruptcy.chapter
                      ? `CHAPTER ${bankruptcy.chapter} BANKRUPTCY`
                      : `BANKRUPTCY`
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: bankruptcy.fileDate
                          ? parseDate(bankruptcy.fileDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: bankruptcy.courtDistrict
                          ? convertStateAbbreviation(bankruptcy.courtDistrict)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {bankruptcyObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[]}
                        tableDataObject={bankruptcyObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Bankruptcies Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        {/* Judgments */}
        <Container
          containerTitle={`Possible Judgments ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.judgments
                    ? pulledReport.judgments.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.judgments}
          tooltipContent="Judgments are legal rulings issued by courts in favor of creditors to enforce repayment of debts, often leading to consequences such as wage garnishment or property liens to satisfy outstanding financial obligations."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.judgments &&
            pulledReport.judgments[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.judgments.map((judgment, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    judgment.filingType
                      ? judgment.filingType
                      : judgment.deedCategoryType?.toUpperCase()
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: judgment.recordingDate
                          ? parseDate(judgment.recordingDate, "year")
                          : judgment.filingDate
                            ? parseDate(judgment.filingDate, "year")
                            : null,
                        label: "",
                      },
                      {
                        value: judgment.filingLocation
                          ? convertStateAbbreviation(judgment.filingLocation)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {judgmentObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={["Owner"]}
                        tableDataObject={judgmentObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Judgments Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        {/* Liens */}
        <Container
          containerTitle={`Possible Liens ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.liens
                    ? pulledReport.liens.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.liens}
          tooltipContent="Liens are legal claims placed on a person's property or assets by creditors to secure unpaid debts, potentially affecting property sales and indicating financial obligations."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport && pulledReport.liens && pulledReport.liens[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.liens.map((lien, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    lien.filingType
                      ? lien.filingType
                      : lien.kindOfTax
                        ? lien.kindOfTax
                        : lien.totalLienAmount
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: lien.filingDate
                          ? parseDate(lien.filingDate, "year")
                          : lien.recordingDate
                            ? parseDate(lien.recordingDate, "year")
                            : null,
                        label: "",
                      },
                      {
                        value: lien.courtAddress
                          ? convertStateAbbreviation(lien.courtAddress)
                          : null,
                        label: "",
                      },
                      {
                        value: lien.filingLocation
                          ? convertStateAbbreviation(lien.filingLocation)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {lienObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[]}
                        tableDataObject={lienObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Liens Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        {/* Foreclosure */}
        <Container
          containerTitle={`Possible Foreclosures ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.propertyForeclosures
                    ? pulledReport.propertyForeclosures.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.propertyForeclosures}
          tooltipContent="Foreclosures are legal procedures in which lenders reclaim and sell properties from borrowers who have defaulted on mortgage payments, typically leading to property ownership changes due to financial difficulties."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.propertyForeclosures &&
            pulledReport.propertyForeclosures[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.propertyForeclosures.map((foreclosure, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    foreclosure.fullAddress
                      ? foreclosure.fullAddress.split(",")[0]
                      : null
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: foreclosure.filingDate
                          ? parseDate(foreclosure.filingDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: foreclosure.filingDate
                          ? parseDate(foreclosure.filingDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: foreclosure.fullAddress
                          ? convertStateAbbreviation(foreclosure.fullAddress)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {forclosureObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[]}
                        tableDataObject={forclosureObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Foreclosures Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        {/* Eviction */}
        <Container
          containerTitle={`Possible Evictions ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.evictions
                    ? pulledReport.evictions.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.evictions}
          tooltipContent="Evictions are legal processes used by landlords to remove tenants from rental properties due to various reasons, such as unpaid rent or lease violations, often resulting in court proceedings and potential consequences for tenants' housing history."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.evictions &&
            pulledReport.evictions[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.evictions.map((eviction, index) => (
                <ContainerLineItem
                  lineItemHeader={eviction.fullName ? eviction.fullName : null}
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: eviction.filingDate
                          ? parseDate(eviction.filingDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: eviction.filingState
                          ? convertStateAbbreviation(eviction.filingState)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {evictionObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[]}
                        tableDataObject={evictionObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No Evictions Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
        {/* UCC Filing */}
        <Container
          containerTitle={`Possible UCC Filings ${
            loading
              ? ""
              : `(${
                  pulledReport && pulledReport.uccFilings
                    ? pulledReport.uccFilings.length
                    : 0
                })`
          }`}
          newInfo={peopleReportMonitoringDifferences?.uccFilings}
          tooltipContent="UCC filings are official records used to document a creditor's claim on a debtor's personal property used as collateral, providing transparency and clarity in secured transactions and financial obligations."
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport &&
            pulledReport.uccFilings &&
            pulledReport.uccFilings[0] ? (
            <CollapsableItemList isOpen={printReady}>
              {pulledReport.uccFilings.map((uccFiling, index) => (
                <ContainerLineItem
                  lineItemHeader={
                    uccFiling.securedParties
                      ? uccFiling.securedParties
                      : uccFiling.debtors
                        ? uccFiling.debtors
                        : null
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: uccFiling.filingDate
                          ? parseDate(uccFiling.filingDate, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: uccFiling.filingOfficeAddress
                          ? convertStateAbbreviation(
                              uccFiling.filingOfficeAddress,
                            )
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {uccFilingObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[]}
                        tableDataObject={uccFilingObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          ) : (
            <Padding space="md">
              <Text tag="b" size="sm">
                No UCC Filings Found{" "}
                {pulledReport
                  ? `for ${formatName(pulledReport, "abbreviated")}`
                  : null}
              </Text>
            </Padding>
          )}
        </Container>
      </>
    </>
  );
};

export default FinancialInfo;
