import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";

import {
  cancelSubscriptionAsync,
  getSubscriptionDetailsAsync,
  selectSubscriptionStatus,
} from "../features/subscriptions/subscriptionSlice";

const useCancelSubscription = () => {
  const dispatch = useAppDispatch();

  const apiStatus = useAppSelector(selectSubscriptionStatus);
  const pageNavigate = useHandlePageNavigate();

  const cancelSubscription = () => {
    if (apiStatus === "loading") return;
    dispatch(cancelSubscriptionAsync()).then(action => {
      if (cancelSubscriptionAsync.fulfilled.match(action)) {
        dispatch(getSubscriptionDetailsAsync());
        pageNavigate("/cancel-confirmation");
      }
    });
  };

  return cancelSubscription;
};

export { useCancelSubscription };
