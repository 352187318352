// demo package imports
import "@kidslivesafe/sc-scss/lib/global.css";
import "@assets/brand.css";
import favicon from "@assets/images/favicon.ico";
import "@kidslivesafe/sc-scss/lib/Container.css";
import "@kidslivesafe/sc-scss/lib/Margin.css";
import "@kidslivesafe/sc-scss/lib/Padding.css";
import "@kidslivesafe/sc-scss/lib/Text.css";
import "@kidslivesafe/sc-scss/lib/Layout.css";
import "@kidslivesafe/sc-scss/lib/Tag.css";
import "@kidslivesafe/sc-scss/lib/Utilities.css"; // for Color Component
import "@kidslivesafe/sc-scss/lib/SectionTitle.css";
import "@kidslivesafe/sc-scss/lib/NavigateToSection.css";
import "@kidslivesafe/sc-scss/lib/ReportLink.css";
import "@kidslivesafe/sc-scss/lib/CollapsableItemList.css";
import "@kidslivesafe/sc-scss/lib/ContainerLineItem.css";
import "@kidslivesafe/sc-scss/lib/ShowMore.css";
import "@kidslivesafe/sc-scss/lib/Table.css";
import "@kidslivesafe/sc-scss/lib/Tooltip.css";
import "@kidslivesafe/sc-scss/lib/Map.css";
import "@kidslivesafe/sc-scss/lib/LineItemSummary.css";
import "@kidslivesafe/sc-scss/lib/Sticky.css";
import "@kidslivesafe/sc-scss/lib/ReportTitle.css";
import "@kidslivesafe/sc-scss/lib/Sheet.css";
import "@kidslivesafe/sc-scss/lib/ReportSummary.css";
import "@kidslivesafe/sc-scss/lib/Monitoring.css";
import "@kidslivesafe/sc-scss/lib/DownloadReport.css";
import "@kidslivesafe/sc-scss/lib/Button.css";
import "@kidslivesafe/sc-scss/lib/Input.css";
import "@kidslivesafe/sc-scss/lib/AnchorLink.css";
import "@kidslivesafe/sc-scss/lib/MenuButton.css";
import "@kidslivesafe/sc-scss/lib/Header.css";
import "@kidslivesafe/sc-scss/lib/Footer.css";
import "@kidslivesafe/sc-scss/lib/MultiSearch.css";
import "@kidslivesafe/sc-scss/lib/Select.css";
import "@kidslivesafe/sc-scss/lib/LoadingPlaceholder.css";
import "@kidslivesafe/sc-scss/lib/Spotlight.css";
import "@kidslivesafe/sc-scss/lib/TextArea.css";
import "@kidslivesafe/sc-scss/lib/MonitoringContainer.css";
import "@kidslivesafe/sc-scss/lib/Modal.css";
import "@kidslivesafe/sc-scss/lib/MinFooter.css";
import "@kidslivesafe/sc-scss/lib/CheckBox.css";
import "@kidslivesafe/sc-scss/lib/ImageCarousel.css";
import "@kidslivesafe/sc-scss/lib/StreetView.css";
import "@kidslivesafe/sc-scss/lib/GradientBackground.css";

import "./App.css";

import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { useEffect } from "react";

import TagManager from "react-gtm-module";

import { Provider as RollbarProvider } from "@rollbar/react";
import { useAppSelector } from "./hooks/hooks";
import { selectBrandInfo } from "./features/brand/brandSlice";
import { selectCustomerId } from "./features/authentication/authenticationSlice";
import {
  selectMemberExperienceData,
  selectAccountType,
} from "./features/authentication/authenticationSlice";

function App() {
  useEffect(() => {
    const sc_brand = process.env.REACT_APP_SC_BRAND || "agnostic";
    document.body.className =
      sc_brand === "agnostic" ? "agnostic" : `${sc_brand}-theme`;

    const link = document.createElement("link");
    const oldLink = document.querySelector('link[rel="icon"]');

    link.rel = "icon";
    link.href = favicon;

    if (oldLink) {
      document.head.removeChild(oldLink);
    }

    document.head.appendChild(link);

    return () => {
      document.body.className = "";
    };
  }, []);

  // Initialize GTM
  const brandInfo = useAppSelector(selectBrandInfo);
  const customerId = useAppSelector(selectCustomerId);
  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  const accountType = useAppSelector(selectAccountType);
  if (brandInfo.gtmId !== "") {
    const tagManagerArgs: any = {
      gtmId: "GTM-" + brandInfo.gtmId,
      dataLayer: {},
    };
    if (customerId) {
      tagManagerArgs.dataLayer.customerId = customerId;
    }
    if (memberExperienceData?.variant) {
      tagManagerArgs.dataLayer.experimentVariable =
        memberExperienceData.variant;
    }
    if (accountType) {
      tagManagerArgs.dataLayer.accountType = accountType;
    }
    TagManager.initialize(tagManagerArgs);
  } else {
    console.log("GTM not initialized");
  }

  // setup Rollbar
  let rollbarEnabled =
    brandInfo.rollbarId !== "" &&
    process.env.REACT_APP_ROLLBAR_ENABLED === "true"
      ? true
      : false;
  if (!rollbarEnabled) {
    console.log("Rollbar not enabled");
  }
  let env = process.env.REACT_APP_NODE_ENV;
  const rollbarConfig = {
    accessToken: brandInfo.rollbarId,
    captureUncaught: true,
    captureUnhandledRejections: true,
    scrubTelemetryInputs: true,
    scrubFields: ['login-password'],
    enabled: rollbarEnabled,
    environment: env,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
      environment: env,
      person: {
        id: customerId,
      },
    },
  };

  return (
    <div className="App">
      <RollbarProvider config={rollbarConfig}>
        <RouterProvider router={router} />
      </RollbarProvider>
    </div>
  );
}

export default App;
