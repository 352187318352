import {
  AnchorLink,
  Header,
  Layout,
  Margin,
  Text,
} from "@kidslivesafe/sc-react";
import React from "react";

import Logo from "@assets/images/brand-logo--header.svg";
import { useAppSelector } from "../../../hooks/hooks";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { brandPossessive } from "@kidslivesafe/sc-foundation";
import { parseDate } from "../../../utils";

interface PrintableComponentProps {
  children: React.ReactNode;
  printReady?: boolean;
  reportType?: string;
  header?: React.ReactNode;
  subtitle?: React.ReactNode;
  date?: number | string;
}

// Declare the component with the forwardRef function
const PrintableComponent = React.forwardRef<
  HTMLDivElement,
  PrintableComponentProps
>(({ children, printReady, reportType, header, subtitle, date }, ref) => {
  const sc_brand = process.env.REACT_APP_SC_BRAND || "agnostic";
  const brandInfo = useAppSelector(selectBrandInfo);
  let printClassName =
    sc_brand === "agnostic" ? "agnostic" : `${sc_brand}-theme`;

  const backgroundColor = printReady ? "#e7ecf2" : "";

  const renderDisclaimer = () => {
    switch (reportType) {
      case "auto":
        return (
          <>
            <Text fontWeight="bold" size="xxs">
              This {brandInfo.name} Vehicle History Report is based solely on
              information supplied to {brandInfo.name} and available as of{" "}
              {date ? parseDate(date, "MM/DD/YYYY") : null}. Other information
              about this vehicle, including problems, may not have been reported
              to {brandInfo.name}. Use this report as one important tool, along
              with a vehicle inspection, test drive and more formal title
              search, to make an informed decision concerning this vehicle
              before proceeding.
            </Text>
            <Text size="xxs">
              We provide affordable, immediate access to public record
              information. It is PROHIBITED by law to use our service or the
              information contained on our website to make decisions about
              employment, insurance, consumer credit, tenant screening, or for
              any other purpose subject to the Fair Credit Reporting Act, 15 USC
              1681 et seq. {brandInfo.name} does not provide private
              investigator services or consumer reports and is not a consumer
              reporting agency as defined by the Fair Credit Reporting Act.
              Please be EXTREMELY careful when reviewing a person's criminal
              history, relatives mortgages and liens.  Please DO NOT use this
              information without further investigating the accuracy of the
              information. The information available on our website may not be
              complete, accurate, or current. You may not use our site or
              service or the information provided to make decisions about
              employment, admission, consumer credit, insurance, tenant
              screening or any other purpose that would require FCRA
              compliance.  For more information, please review{" "}
              <AnchorLink
                href={`${brandInfo.homeUrl}/help-center/terms`}
                inline
                hoverUnderline
                size="xxs"
              >
                {brandPossessive(brandInfo.name)} Terms of Use
              </AnchorLink>{" "}
              .
            </Text>
          </>
        );
      case "property":
        if (brandInfo.acronym === "pf") {
          return (
            <>
              <Text size="xxs">
                The information in our reports and on our website may not
                necessarily be 100% accurate, complete, or current and may
                include AI generated content. It should not replace your own
                research, especially for sensitive information like owners,
                residents, mortgages, and liens. Some sources such as public
                municipalities may be slow to update their information
                digitally. Therefore, we cannot guarantee the accuracy,
                completeness or timeliness of all results.
              </Text>
              <Text size="xxs">
                It is PROHIBITED by law to use our service or the information
                contained on our website to make decisions about employment,
                insurance, consumer credit, tenant screening, or for any other
                purpose subject to the Fair Credit Reporting Act, 15 USC 1681 et
                seq. Property Focus does not provide private investigator
                services or consumer reports, and is not a consumer reporting
                agency as defined by the{" "}
                <AnchorLink
                  href={`${brandInfo.homeUrl}/help-center/fcra`}
                  inline
                  color="base"
                  size="xxs"
                >
                  Fair Credit Reporting Act
                </AnchorLink>
                . For more information about our prohibited and permitted uses
                please review our{" "}
                <AnchorLink
                  href={`${brandInfo.homeUrl}/help-center/dos-and-donts`}
                  inline
                  color="base"
                  size="xxs"
                >
                  Do’s and Don’ts
                </AnchorLink>{" "}
                and our 
                <AnchorLink
                  href={`${brandInfo.homeUrl}/help-center/terms`}
                  inline
                  color="base"
                  size="xxs"
                >
                  Terms of Use
                </AnchorLink>
                .
              </Text>
            </>
          );
        } else {
          return (
            <>
              <Text size="xs" fontWeight="bold">
                The following PDF report is restricted from use for:
              </Text>
              <Text tag="div">
                <Text fontWeight="bold" size="xs">
                  Employment Screening or Purposes:
                </Text>
                <Text size="xs">
                  You may not use this information when evaluating a person for
                  employment, reassignment, promotion, or retention.
                </Text>
              </Text>
              <Text tag="div">
                <Text fontWeight="bold" size="xs">
                  Impersonation, Harassment or Confrontation of an individual:
                </Text>
                <Text size="xs">
                  You may not use this information in any way to impersonate,
                  gain access relating to the identity or harass any individual.
                </Text>
              </Text>
              <Text tag="div">
                <Text fontWeight="bold" size="xs">
                  Hiring of Domestic or Household Workers:
                </Text>
                <Text size="xs">
                  Including, but not limited to, nannies and domestic workers.
                </Text>
              </Text>
              <Text tag="div">
                <Text fontWeight="bold" size="xs">
                  Tenant Screening:
                </Text>
                <Text size="xs">
                  Including, but not limited to, leasing a residential or
                  commercial space.
                </Text>
              </Text>
              <Text tag="div">
                <Text fontWeight="bold" size="xs">
                  Educational Qualification:
                </Text>
                <Text size="xs">
                  Including, but not limited to, a person's qualifications for
                  an educational program or scholarship.
                </Text>
              </Text>
              <Text tag="div">
                <Text fontWeight="bold" size="xs">
                  Credit or Insurance:
                </Text>
                <Text size="xs">
                  Accessing the risk of existing credit obligations of an
                  individual and/or determining eligibility for issuing credit
                  or insurance.
                </Text>
              </Text>
              <Text size="xs">
                Using this information in these ways violates both our Terms of
                Use and the law, and can lead to possible criminal penalties. We
                reserve the right to terminate user accounts and/or report
                violators to law enforcement as appropriate.
              </Text>
              <Text size="xs">
                We provide affordable, immediate access to public record
                information. It is PROHIBITED by law to use our service or the
                information contained on our website to make decisions about
                employment, insurance, consumer credit, tenant screening, or for
                any other purpose subject to the Fair Credit Reporting Act, 15
                USC 1681 et seq. {brandInfo.name} does not provide private
                investigator services or consumer reports and is not a consumer
                reporting agency as defined by the Fair Credit Reporting Act.
                Please be EXTREMELY careful when reviewing a person's criminal
                history, relatives mortgages and liens.  Please DO NOT use this
                information without further investigating the accuracy of the
                information. The information available on our website may not be
                complete, accurate, or current. You may not use our site or
                service or the information provided to make decisions about
                employment, admission, consumer credit, insurance, tenant
                screening or any other purpose that would require FCRA
                compliance.  For more information, please review{" "}
                <AnchorLink
                  href={`${brandInfo.homeUrl}/help-center/terms`}
                  inline
                  hoverUnderline
                  size="xs"
                >
                  {brandPossessive(brandInfo.name)} Terms of Use
                </AnchorLink>
                .
              </Text>
            </>
          );
        }
      default:
        return (
          <>
            <Text size="xs" fontWeight="bold">
              The following PDF report is restricted from use for:
            </Text>
            <Text tag="div">
              <Text fontWeight="bold" size="xs">
                Employment Screening or Purposes:
              </Text>
              <Text size="xs">
                You may not use this information when evaluating a person for
                employment, reassignment, promotion, or retention.
              </Text>
            </Text>
            <Text tag="div">
              <Text fontWeight="bold" size="xs">
                Impersonation, Harassment or Confrontation of an individual:
              </Text>
              <Text size="xs">
                You may not use this information in any way to impersonate, gain
                access relating to the identity or harass any individual.
              </Text>
            </Text>
            <Text tag="div">
              <Text fontWeight="bold" size="xs">
                Hiring of Domestic or Household Workers:
              </Text>
              <Text size="xs">
                Including, but not limited to, nannies and domestic workers.
              </Text>
            </Text>
            <Text tag="div">
              <Text fontWeight="bold" size="xs">
                Tenant Screening:
              </Text>
              <Text size="xs">
                Including, but not limited to, leasing a residential or
                commercial space.
              </Text>
            </Text>
            <Text tag="div">
              <Text fontWeight="bold" size="xs">
                Educational Qualification:
              </Text>
              <Text size="xs">
                Including, but not limited to, a person's qualifications for an
                educational program or scholarship.
              </Text>
            </Text>
            <Text tag="div">
              <Text fontWeight="bold" size="xs">
                Credit or Insurance:
              </Text>
              <Text size="xs">
                Accessing the risk of existing credit obligations of an
                individual and/or determining eligibility for issuing credit or
                insurance.
              </Text>
            </Text>
            <Text size="xs">
              Using this information in these ways violates both our Terms of
              Use and the law, and can lead to possible criminal penalties. We
              reserve the right to terminate user accounts and/or report
              violators to law enforcement as appropriate.
            </Text>
            <Text size="xs">
              We provide affordable, immediate access to public record
              information. It is PROHIBITED by law to use our service or the
              information contained on our website to make decisions about
              employment, insurance, consumer credit, tenant screening, or for
              any other purpose subject to the Fair Credit Reporting Act, 15 USC
              1681 et seq. {brandInfo.name} does not provide private
              investigator services or consumer reports and is not a consumer
              reporting agency as defined by the Fair Credit Reporting Act.
              Please be EXTREMELY careful when reviewing a person's criminal
              history, relatives mortgages and liens.  Please DO NOT use this
              information without further investigating the accuracy of the
              information. The information available on our website may not be
              complete, accurate, or current. You may not use our site or
              service or the information provided to make decisions about
              employment, admission, consumer credit, insurance, tenant
              screening or any other purpose that would require FCRA
              compliance.  For more information, please review{" "}
              <AnchorLink
                href={`${brandInfo.homeUrl}/help-center/terms`}
                inline
                hoverUnderline
                size="xs"
              >
                {brandPossessive(brandInfo.name)} Terms of Use
              </AnchorLink>{" "}
              .
            </Text>
          </>
        );
    }
  };

  return (
    <div
      className={printClassName}
      ref={ref}
      style={{ backgroundColor: backgroundColor }}
    >
      {printReady ? (
        <>
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              justifyContent: "space-between",
            }}
            passThroughClassess="sc-printable-container"
          >
            <Header
              displayButtons={false}
              menuButtonLinks={[]}
              logoUrl={Logo}
            />
            <Layout
              type="flex"
              flexProps={{
                gap: "xxs",
                direction: "column",
                alignItems: "center",
              }}
            >
              {header && <Text tag="h1">{header}</Text>}
              {subtitle && (
                <Text tag="div" fontWeight="bold">
                  {subtitle}
                </Text>
              )}
              {date && (
                <Text size="xs">
                  Report Date {parseDate(date, "MM/DD/YYYY")}
                </Text>
              )}
            </Layout>

            <Margin bottom="xl">
              <Layout
                type="flex"
                flexProps={{ gap: "xs", direction: "column" }}
              >
                {renderDisclaimer()}
              </Layout>
            </Margin>
          </Layout>
          <div className="page-break"></div>
        </>
      ) : null}
      {children}
    </div>
  );
});

export default PrintableComponent;
