import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

// utils
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
} from "../../../utils";

// types
import { PropertyLien } from "../../../features/reports/reportTypes";

// components
import {
  Container,
  Padding,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
  Text,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

// pull report hook
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

const Liens: React.FC<{ containerId: string; printReady?: boolean }> = ({
  containerId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );

  const loading = propertyReportStatus === "loading";
  const pullPeopleReport = usePeopleReportDispatch();

  /* generate liens Object */
  const lienObjects = renderArrayOfObjectsPrep(
    pulledReport?.liens || [],
    [],
    [
      {
        key: ["nameDetails"],
        keyFormat: () => {
          return "Name";
        },
        valueFormat: (value, currentObject: PropertyLien) => {
          const jsxElements =
            currentObject.nameDetails?.reportToken &&
            currentObject.nameDetails.fullName ? (
              <ReportLink<PullPeopleReportParams>
                inputValue={currentObject.nameDetails.reportToken}
                handleClick={pullPeopleReport}
                text={currentObject.nameDetails.fullName}
                smallText
              />
            ) : (
              <Text passThroughClassess="sc-table-row__detail">
                {currentObject.nameDetails?.fullName}
              </Text>
            );

          return <>{jsxElements}</>;
        },
      },
    ],
  );
  return (
    <>
      {pulledReport?.liens && pulledReport.liens[0] ? (
        <Container
          containerTitle={`Liens at Address ${
            loading
              ? ""
              : `(${pulledReport.liens ? pulledReport.liens.length : 0})`
          }`}
          containerId={containerId}
          tooltipContent="A lien at an address means there's a legal claim on the property, often due to unpaid debts or obligations. This can affect property ownership and transactions. Types include mortgage, tax, and judgment liens."
          newInfo={reportDifferences?.liens}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : (
            <CollapsableItemList numberToShow={5} isOpen={printReady}>
              {pulledReport.liens.map((lien, index) => (
                <ContainerLineItem
                  lineItemHeader={`${
                    lien.filingType
                      ? lien.filingType
                      : lien.damarDocumentType
                        ? lien.damarDocumentType
                        : "UNSPECIFIED"
                  }`}
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: lien.filingDate
                          ? parseDate(lien.filingDate, "year")
                          : lien.recordingDate
                            ? parseDate(lien.recordingDate, "year")
                            : lien.taxLienDate
                              ? parseDate(lien.taxLienDate, "year")
                              : null,
                        label: "",
                      },
                      {
                        value: lien.plaintiffBusinessName
                          ? lien.plaintiffBusinessName
                          : lien.totalLienAmount
                            ? "$" + lien.totalLienAmount
                            : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {lienObjects[index] ? (
                    <Padding space="md">
                      <Table
                        orderOfProperties={[]}
                        tableDataObject={lienObjects[index]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            </CollapsableItemList>
          )}
        </Container>
      ) : null}
    </>
  );
};

export default Liens;
