import React, { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import {
  savePasswordAsync,
  selectPasswordReset,
  selectAdminStatus,
} from "../../../features/admin/adminSlice";
import { useAppDispatch } from "../../../hooks/hooks";
import {
  logoutAsync,
  selectMemberData,
} from "../../../features/authentication/authenticationSlice";

import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

import "./NewPassword.css";

import {
  Padding,
  Layout,
  Button,
  Container,
  ReportTitle,
  Text,
  Input,
  Sheet,
} from "@kidslivesafe/sc-react";
import { formatPhoneNumber } from "../../../utils";

const NewPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false);
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] =
    useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const currentBreakpoint = useCurrentBreakpoint();
  const brandInfo = useAppSelector(selectBrandInfo);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const passwordReset = useAppSelector(selectPasswordReset);
  const { customerId } = useAppSelector(selectMemberData);

  let adminStatus = useAppSelector(selectAdminStatus);

  useEffect(() => {
    setLoading(adminStatus === "loading");
  }, [adminStatus]);

  const getURLParameter = function (name: string) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

  const verifyPassword = () => {
    let passFormat = /^[A-Za-z0-9_!@]{6,16}$/;
    if (password.length < 6) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText(
        "Password must be at least 6 characters long.",
      );
      return false;
    } else if (password.length > 16) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText(
        "Password must be not more than 16 characters long.",
      );
      return false;
    } else if (!password.match(passFormat)) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText(
        "Password may only contain letters (ABcd), numbers (123), underscore (_), exclamation (!), @. No spaces.",
      );
      return false;
    } else if (password !== confirmPassword) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      setConfirmPasswordErrorText("Passwords must match.");
      return false;
    } else {
      return true;
    }
  };

  const handleConfirmPassword = async () => {
    const verifiedPassword = verifyPassword();
    const supportNum = formatPhoneNumber(brandInfo.phoneNumber);

    if (verifiedPassword) {
      const token = getURLParameter("token");
      const savePasswordAsyncResponse = await dispatch(
        savePasswordAsync({ password, token }),
      );
      if (savePasswordAsync.rejected.match(savePasswordAsyncResponse)) {
        //Talk about how to handle
        setPasswordError(true);
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText(
          `There was an error updating your password. Please contact our Member Support Team at ${supportNum} for assistance.`,
        );
      }
    }
  };

  const handleRedirect = () => {
    if (customerId) {
      navigate("/");
    } else {
      dispatch(logoutAsync());
      navigate("/login");
    }
  };

  return (
    <>
      <ReportTitle
        reportTitle={
          !passwordReset ? "Set New Password" : "Password Confirmation"
        }
      />
      <Sheet>
        {!passwordReset ? (
          <Padding top="lg" bottom="lg" right="md" left="md">
            <Layout
              type="flex"
              flexProps={{ direction: "column", alignItems: "center" }}
            >
              <Padding
                bottom="md"
                breakpointStyles={{ mobile: { bottom: "lg" } }}
              >
                <Text>
                  Please provide a new secure password. Your new password must
                  be 6-16 characters in length. It may contain letters (ABcd),
                  numbers (123), underscore (_), exclamation (!), @, but no
                  spaces, please.
                </Text>
              </Padding>
            </Layout>
            <Form>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  gap: "sm",
                  alignItems: "center",
                }}
              >
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter New Password"
                  value={password}
                  handleChange={setPassword}
                  handleError={() => {
                    setPasswordError(false);
                    setConfirmPasswordError(false);
                  }}
                  fill={
                    currentBreakpoint !== "desktop"
                      ? "fill"
                      : "not-fill-min-width"
                  }
                  error={passwordError}
                ></Input>
                <Input
                  id="confirm-password"
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  handleChange={setConfirmPassword}
                  handleError={() => {
                    setPasswordError(false);
                    setConfirmPasswordError(false);
                  }}
                  fill={
                    currentBreakpoint !== "desktop"
                      ? "fill"
                      : "not-fill-min-width"
                  }
                  error={confirmPasswordError}
                ></Input>
                {(passwordError || confirmPasswordError) && (
                  <Layout
                    type="flex"
                    flexProps={{ alignSelf: "flex-start" }}
                    breakpointStyles={{
                      desktop: {
                        type: "flex",
                        flexProps: { alignSelf: "center" },
                      },
                    }}
                  >
                    <Text
                      color="error"
                      size="xs"
                      passThroughClassess={
                        currentBreakpoint === "desktop"
                          ? "page-new-password-error__max-width"
                          : ""
                      }
                    >
                      {confirmPasswordErrorText}
                    </Text>
                  </Layout>
                )}
                <Button
                  buttonText="Set New Password"
                  color="primary"
                  handleClick={handleConfirmPassword}
                  size="md"
                  loading={loading}
                />
              </Layout>
            </Form>
          </Padding>
        ) : (
          <Padding top="lg" bottom="lg" right="md" left="md">
            <Container>
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  alignContent: "center",
                  gap: "md",
                }}
              >
                <Text textAlign="left" size="xl" fontWeight="bold">
                  Your password has been changed!
                </Text>
                <Text>
                  You may now use your new password whenever logging into the{" "}
                  {brandInfo.name} member site.
                </Text>
                <Layout type="flex" flexProps={{ justifyContent: "center" }}>
                  <Button
                    buttonText={
                      customerId ? "Return to Dashboard" : "Go to Member Login"
                    }
                    color="primary"
                    handleClick={handleRedirect}
                    size="md"
                  />
                </Layout>
              </Layout>
            </Container>
          </Padding>
        )}
      </Sheet>
    </>
  );
};

export default NewPassword;
