import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useNeighborhoodSearchDispatch } from "../../hooks/neighborhoodReportHook";

interface TESTPullNeighborhoodSearchProps {}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TestPullNeighborhoodSearch: React.FC<
  TESTPullNeighborhoodSearchProps
> = () => {
  const pullNeighborhoodSearch = useNeighborhoodSearchDispatch();
  const query = useQuery();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    const nameSearch = {
      firstName: query.get("firstName")!,
      lastName: query.get("lastName")!,
    };

    if (
      nameSearch.firstName &&
      nameSearch.lastName &&
      !hasFetchedReport.current
    ) {
      pullNeighborhoodSearch(nameSearch);
      hasFetchedReport.current = true;
    }
  }, [query, pullNeighborhoodSearch]);

  return <div>Pulling Report</div>;
};

export default TestPullNeighborhoodSearch;
