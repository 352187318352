import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Layout,
  Text,
  Padding,
  Margin,
  Icon,
  IconList,
  Container,
  Tag,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";

import "./PropTimelineComponent.css";
import {
  Mortgage,
  PropertyOwnershipHistory,
  PropertyOwnership,
} from "../../../features/reports/reportTypes";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

interface PropTimelineComponentProps {
  timelineArray: PropertyOwnershipHistory[];
  loading?: boolean;
}

const PropTimelineComponent: React.FC<PropTimelineComponentProps> = ({
  timelineArray,
  loading,
}) => {
  const brandInfo = useAppSelector(selectBrandInfo);

  return (
    <div className="timeline">
      {loading ? (
        <Container customClassName="timelineContainer">
          <Padding space="sm">
            <Layout type="grid">
              <LoadingPlaceholder width={"5rem"} height={"20px"} />
              <Margin top="xxs">
                <Layout type="flex" flexProps={{ justifyContent: "center" }}>
                  <div></div>
                </Layout>
              </Margin>
              <Container>
                <Margin bottom="md">
                  <Padding bottom="sm" className="content">
                    <Padding
                      top="sm"
                      bottom="sm"
                      left="sm"
                      right="sm"
                      className="sale-header"
                    >
                      <Layout
                        type="flex"
                        flexProps={{
                          gap: "xs",
                          direction: "row",
                          alignItems: "baseline",
                        }}
                      >
                        <LoadingPlaceholder width={"10rem"} height={"20px"} />
                      </Layout>
                    </Padding>
                    <div className="owner">
                      <Padding top="sm" right="sm" left="sm">
                        <LoadingPlaceholder width={"5rem"} height={"20px"} />
                        <LoadingPlaceholder width={"5rem"} height={"10px"} />
                      </Padding>
                      <Margin top="sm" left="sm" right="sm">
                        <Container customClassName="finance-block">
                          <Padding space="xs">
                            <Margin bottom="xs">
                              <Layout
                                type="flex"
                                flexProps={{
                                  gap: "xs",
                                  direction: "row",
                                  alignItems: "center",
                                }}
                              >
                                <LoadingPlaceholder
                                  width={"5rem"}
                                  height={"20px"}
                                />
                              </Layout>
                            </Margin>
                            <LoadingPlaceholder
                              width={"5rem"}
                              height={"20px"}
                            />
                            <br />
                            <LoadingPlaceholder
                              width={"5rem"}
                              height={"20px"}
                            />
                            <br />
                            <LoadingPlaceholder
                              width={"5rem"}
                              height={"20px"}
                            />
                            <br />
                            <LoadingPlaceholder
                              width={"5rem"}
                              height={"20px"}
                            />
                            <br />
                            <LoadingPlaceholder
                              width={"5rem"}
                              height={"20px"}
                            />
                            <br />
                            <LoadingPlaceholder
                              width={"5rem"}
                              height={"20px"}
                            />
                          </Padding>
                        </Container>
                      </Margin>
                    </div>
                  </Padding>
                </Margin>
              </Container>
            </Layout>
          </Padding>
        </Container>
      ) : (
        timelineArray.map((sale, index) => (
          <Container customClassName="timelineContainer" key={index}>
            <Layout type="grid">
              <Text
                size="sm"
                fontWeight="semibold"
                color="primary"
                textAlign="right"
                passThroughClassess="sale-date"
                textWrap="nowrap"
              >
                {sale.saleDateShort && sale.showEst ? (
                  <>
                    {sale.saleDateShort}
                    <br></br>
                    <i>(est.)</i>
                  </>
                ) : sale.saleDateShort ? (
                  <>{sale.saleDateShort}</>
                ) : null}
              </Text>
              <Margin top="xxs">
                <Layout type="flex" flexProps={{ justifyContent: "center" }}>
                  <Icon
                    name={IconList.IconPropertyBullet}
                    className="timeline-bullet"
                    size={10}
                  ></Icon>
                </Layout>
              </Margin>
              <Container>
                <Margin bottom="md">
                  <Padding className="content">
                    <Padding
                      top="sm"
                      bottom="sm"
                      left="sm"
                      right="sm"
                      className="sale-header"
                    >
                      <Layout
                        type="flex"
                        flexProps={{
                          gap: "xs",
                          direction: "row",
                          alignItems: "baseline",
                        }}
                      >
                        <Tag tagText="sale" type="sale" />
                        <Text size="sm" fontWeight="bold">
                          {sale.saleAmount
                            ? sale.saleAmount
                            : "Price Undisclosed"}
                        </Text>
                        {sale.saleDate ? (
                          <Text tag="i" size="xs">
                            ({sale.saleDate.split(" ").join(" ")})
                          </Text>
                        ) : null}
                      </Layout>
                    </Padding>
                    {sale.propertyOwnerships
                      ? sale.propertyOwnerships.map(
                          (ownership: PropertyOwnership, saleIndex) => (
                            <Padding
                              left="sm"
                              right="sm"
                              key={`ownerTransactionSummary-${saleIndex}`}
                            >
                              <div className="owner" key={saleIndex}>
                                <Padding top="sm" bottom="sm">
                                  {saleIndex !== 0 ? (
                                    <Text
                                      tag="i"
                                      fontWeight="semibold"
                                      size="xs"
                                    >
                                      Additional Transaction
                                    </Text>
                                  ) : null}
                                  {ownership.ownerTransactionSummary ? (
                                    <Layout
                                      type="flex"
                                      flexProps={{
                                        direction: "row",
                                        gap: "xxs",
                                      }}
                                    >
                                      <Text size="xs">Buyer:</Text>
                                      <Text size="xs" tag="span">
                                        {ownership.ownerTransactionSummary}
                                      </Text>
                                    </Layout>
                                  ) : null}
                                  {ownership.sellerTransactionSummary ? (
                                    <Layout
                                      type="flex"
                                      flexProps={{
                                        direction: "row",
                                        gap: "xxs",
                                      }}
                                    >
                                      <Text size="xs">Seller: </Text>
                                      <Text size="xs" tag="span">
                                        {ownership.sellerTransactionSummary}
                                      </Text>
                                    </Layout>
                                  ) : null}
                                </Padding>
                                {ownership.mortgages
                                  ? ownership.mortgages.map(
                                      (mortgage: Mortgage, mortgageIndex) => (
                                        <Padding
                                          left="sm"
                                          right="sm"
                                          bottom="sm"
                                          key={mortgageIndex}
                                        >
                                          <Container customClassName="finance-block">
                                            <Padding space="xs">
                                              <Margin bottom="xs">
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Tag
                                                    tagText="finance"
                                                    type={
                                                      [
                                                        "qpr",
                                                        "pdc",
                                                        "pf",
                                                      ].includes(
                                                        brandInfo.acronym,
                                                      )
                                                        ? "sale"
                                                        : "inverted"
                                                    }
                                                  />
                                                  <Text
                                                    size="xs"
                                                    fontWeight="bold"
                                                  >
                                                    {mortgage.mortgageAmount
                                                      ? mortgage.mortgageAmount
                                                      : "Price Unknown"}
                                                  </Text>
                                                  {mortgage.mortgageRecordingDate ? (
                                                    <Text tag="i" size="xxs">
                                                      (
                                                      {
                                                        mortgage.mortgageRecordingDate
                                                      }
                                                      )
                                                    </Text>
                                                  ) : null}
                                                </Layout>
                                              </Margin>

                                              {mortgage.mortgageLoanType ? (
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                  }}
                                                >
                                                  <Text
                                                    size="xs"
                                                    fontWeight="semibold"
                                                  >
                                                    Loan Type:
                                                  </Text>
                                                  <Text size="xs">
                                                    {mortgage.mortgageLoanType}
                                                  </Text>
                                                </Layout>
                                              ) : null}
                                              {mortgage.mortgageTerm ? (
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                  }}
                                                >
                                                  <Text
                                                    size="xs"
                                                    fontWeight="semibold"
                                                  >
                                                    Loan Term:
                                                  </Text>
                                                  <Text size="xs">
                                                    {mortgage.mortgageTerm}
                                                  </Text>
                                                </Layout>
                                              ) : null}
                                              {mortgage.mortgageInterestRateType ? (
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                  }}
                                                >
                                                  <Text
                                                    size="xs"
                                                    fontWeight="semibold"
                                                  >
                                                    Rate Type:
                                                  </Text>
                                                  <Text size="xs">
                                                    {
                                                      mortgage.mortgageInterestRateType
                                                    }
                                                  </Text>
                                                </Layout>
                                              ) : null}
                                              {mortgage.mortgageInterestRate ? (
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                  }}
                                                >
                                                  <Text
                                                    size="xs"
                                                    fontWeight="semibold"
                                                  >
                                                    Interest Rate:
                                                  </Text>
                                                  <Text size="xs">
                                                    {
                                                      mortgage.mortgageInterestRate
                                                    }
                                                  </Text>
                                                </Layout>
                                              ) : null}
                                              {mortgage.mortgageDeedType ? (
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                  }}
                                                >
                                                  <Text
                                                    size="xs"
                                                    textWrap="nowrap"
                                                    fontWeight="semibold"
                                                  >
                                                    Deed Type:
                                                  </Text>
                                                  <Text size="xs">
                                                    {mortgage.mortgageDeedType}
                                                  </Text>
                                                </Layout>
                                              ) : null}
                                              {mortgage.mortgageLender ? (
                                                <Layout
                                                  type="flex"
                                                  flexProps={{
                                                    gap: "xs",
                                                    direction: "row",
                                                  }}
                                                >
                                                  <Text
                                                    size="xs"
                                                    fontWeight="semibold"
                                                  >
                                                    Lender:
                                                  </Text>
                                                  <Text size="xs">
                                                    {
                                                      mortgage.mortgageLender
                                                        .firstName
                                                    }{" "}
                                                    {
                                                      mortgage.mortgageLender
                                                        .lastName
                                                    }
                                                  </Text>
                                                </Layout>
                                              ) : null}
                                            </Padding>
                                          </Container>
                                        </Padding>
                                      ),
                                    )
                                  : null}
                              </div>
                            </Padding>
                          ),
                        )
                      : null}
                  </Padding>
                </Margin>
              </Container>
            </Layout>
          </Container>
        ))
      )}
    </div>
  );
};

export default PropTimelineComponent;
