export function scrollToHashElement(offsetHeight: number) {
  var hash = window.location.hash;
  if (hash) {
    var element = document.querySelector(hash);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offsetHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
}
