import React from "react";
import {
  Container,
  Padding,
  Layout,
  Text,
  Margin,
  Monitoring,
  AnchorLink,
} from "@kidslivesafe/sc-react";

import { useHandlePageNavigate } from "../../hooks/hooks";
import { selectIdProtectData } from "../../features/authentication/authenticationSlice";
import { useAppSelector } from "../../hooks/hooks";

const IdProtect: React.FC = () => {
  const pageNavigate = useHandlePageNavigate();
  const idProtectData = useAppSelector(selectIdProtectData);

  const handleIdProtectToggle = () => {
    pageNavigate("/id-protect");
  };
  return (
    <Container
      containerTitle="Personal Profile"
      containerId="dashboard__id-protect__container"
    >
      <Padding space="md">
        <Layout
          type="flex"
          flexProps={{
            direction: "column",
            gap: "md",
          }}
        >
          <Text size="sm">
            Manage and protect your personal information by taking advantage of
            ID Protect.
          </Text>
          <Margin bottom="sm" breakpointStyles={{ desktop: { bottom: "md" } }}>
            <Layout
              type="flex"
              flexProps={{
                justifyContent: "space-between",
              }}
            >
              <Monitoring
                handleMonitoring={handleIdProtectToggle}
                monitoringInput={"nothing"}
                monitoringTitle="ID Protect Status"
                monitoringTitleSize="base"
                initialState={idProtectData.idProtectMember}
              />
              {!idProtectData.idProtectMember ? (
                <AnchorLink
                  size="xs"
                  role="button"
                  handleClick={handleIdProtectToggle}
                  hoverUnderline
                  fontWeight="semibold"
                >
                  Learn More
                </AnchorLink>
              ) : null}
            </Layout>
          </Margin>
        </Layout>
      </Padding>
    </Container>
  );
};

export default IdProtect;
