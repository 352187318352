import tMobile from "../assets/global/images/phone_carrier--t-mobile.svg";
import att from "../assets/global/images/phone_carrier--att.svg";
import verizon from "../assets/global/images/phone_carrier--verizon.svg";
import boost from "../assets/global/images/phone_carrier--boost.png";
import telnyx from "../assets/global/images/phone_carrier--telnyx.png";
import qwest from "../assets/global/images/phone_carrier--qwest.png";
import sprint from "../assets/global/images/phone_carrier--sprint.svg";
import usCellular from "../assets/global/images/phone_carrier--us-cellular.png";
import frontier from "../assets/global/images/phone_carrier--frontier.svg";
import cSpire from "../assets/global/images/phone_carrier--c-spire.png";
import comcast from "../assets/global/images/phone_carrier--comcast.svg";
import cox from "../assets/global/images/phone_carrier--cox.svg";
import spectrum from "../assets/global/images/phone_carrier--spectrum.svg";
import lumen from "../assets/global/images/phone_carrier--lumen.svg";

export function generateCarrierLogo(carrier: string, logo: boolean = true) {
  if (carrier) {
    if (carrier.includes("AT&T")) {
      return logo ? att : "AT&T";
    } else if (carrier.includes("T-MOBILE")) {
      return logo ? tMobile : "T-Mobile";
    } else if (carrier.includes("VERIZON")) {
      return logo ? verizon : "Verizon";
    } else if (carrier.includes("BOOST")) {
      return logo ? boost : "Boost";
    } else if (carrier.includes("TELNYX")) {
      return logo ? telnyx : "Telnyx";
    } else if (carrier.includes("QWEST")) {
      return logo ? qwest : "Qwest";
    } else if (carrier.includes("SPRINT") || carrier.includes("Shentel")) {
      return logo ? sprint : carrier.includes("Shentel") ? "Shentel" : "Sprint";
    } else if (carrier.includes("U.S. CELLULAR")) {
      return logo ? usCellular : "UScellular";
    } else if (carrier.includes("FRONTIER")) {
      return logo ? frontier : "Frontier";
    } else if (carrier.includes("C Spire")) {
      return logo ? cSpire : "C Spire";
    } else if (carrier.includes("COMCAST")) {
      return logo ? comcast : "Comcast";
    } else if (carrier.includes("COX")) {
      return logo ? cox : "Cox";
    } else if (carrier.includes("SPECTRUM")) {
      return logo ? spectrum : "Spectrum";
    } else if (carrier.includes("LUMEN")) {
      return logo ? lumen : "Lumen";
    } else {
      return null;
    }
  } else {
    return null;
  }
}
