import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAutoReport,
  selectAutoReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Layout,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";

import {
  AutoReportType,
  Warranty,
} from "../../../features/reports/reportTypes";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { generateList } from "@kidslivesafe/sc-foundation";

const Warranties: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const [warranties, setWarranties] = React.useState<Warranty[]>([]);

  let pulledReport = useAppSelector(selectAutoReport) as AutoReportType;
  const autoReportStatus = useAppSelector(selectAutoReportStatus);

  // pulledReport = testData as AutoReportType; //TODO: remove this line

  const loading = autoReportStatus === "loading";

  useEffect(() => {
    if (pulledReport && pulledReport.warranties) {
      setWarranties(pulledReport.warranties);
    }
  }, [pulledReport]);

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Warranties ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.warranties
                  ? pulledReport.warranties.length
                  : 0
              })`
        }`}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={3}
          />
        ) : pulledReport && pulledReport.warranties && pulledReport.warranties.length > 0 ? (
          <CollapsableItemList
            numberToShow={5}
            isOpen={printReady ? true : false}
          >
            {warranties.map((warranty, index) => (
              <ContainerLineItem
                key={index}
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xs", alignItems: "center" }}
                  >
                    <Text>{warranty.type}</Text>
                  </Layout>
                }
                lineItemSubHeader={generateList(
                  [
                    {
                      value: warranty.months,
                      label: "s",
                      order: "reverse",
                    },
                    {
                      value: warranty.miles,
                      label: "s",
                      order: "reverse",
                    },
                  ],
                  "/",
                )}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" fontWeight="bold">
              No results found for Warranties
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default Warranties;
