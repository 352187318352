export const fullCapsText = (text: string) => {
  return text.toUpperCase();
};

export const capFirstLetter = (text: string) => {
  let textArr = text.split(" ");
  let newText = "";
  textArr.forEach(word => {
    newText += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
  });
  return newText.trim();
};
