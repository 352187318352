import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "../../hooks/hooks";
import { setCookieAsync } from "../../features/admin/adminSlice";
import {
  saveUser,
  saveUsername,
  setExpired,
} from "../../features/authentication/authenticationSlice";
import { getSubscriptionDetailsAsync } from "../../features/subscriptions/subscriptionSlice";
import {
  getMonitoringAsync,
  getNeighborhoodWatchMonitoringAreasAsync,
  getPropertyMonitoringAsync,
} from "../../features/monitoring/monitoringSlice";
import { getReportHistoryAsync } from "../../features/reports/reportsSlice";

interface AUTHSyncFromLegacyProps {}

const AuthSyncFromLegacy: React.FC<AUTHSyncFromLegacyProps> = () => {
  const { uuid } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleCookieSet();
  });

  const handleCookieSet = async () => {
    if (uuid) {
      const setCookieAsyncResponse = await dispatch(
        setCookieAsync({ uuid: uuid }),
      );
      if (setCookieAsync.fulfilled.match(setCookieAsyncResponse)) {
        console.log("XAuth successful");

        let user = setCookieAsyncResponse.payload;

        dispatch(saveUsername(user.username));
        // need to save user and counter legacy namingScheme
        dispatch(
          saveUser({
            id: user.memberData.id,
            customerId: user.memberData.customerId,
            firstname: user.memberData.firstname,
            lastname: user.memberData.lastname,
            username: user.memberData.username,
            expirationDate: user.memberData.expirationDate,
            createDate: user.memberData.createDate,
            fromLegacy: true, // flag used for monitoring modal. TODO : remove when legacy membersite gone
          }),
        );

        // get subscription details
        const subscriptionAction = await dispatch(
          getSubscriptionDetailsAsync(),
        );
        if (getSubscriptionDetailsAsync.fulfilled.match(subscriptionAction)) {
          // console.log("Subscription details fetched successfully");
          const { accessExpirationDate } =
            subscriptionAction.payload.subscriptionDetails;
          if (
            accessExpirationDate &&
            Date.parse(accessExpirationDate) < Date.now()
          ) {
            dispatch(setExpired());
          }
        }

        // get report history
        dispatch(getReportHistoryAsync());
        // trigger get monitoring
        dispatch(getMonitoringAsync());
        dispatch(getNeighborhoodWatchMonitoringAreasAsync());
        dispatch(getPropertyMonitoringAsync())

        setTimeout(() => {
          navigate("/");
        }, 0);
      } else {
        console.log("Login fail");
        setTimeout(() => {
          navigate("/login"); //redirect to login
        }, 0);
      }
    }
  };

  return <div>Syncing</div>;
};

export default AuthSyncFromLegacy;
