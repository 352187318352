import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  login,
  LoginParams,
  logout,
  getMemberExperienceData,
  setMemberExperienceData,
  SetMemberExperienceParams,
} from "./authenticationAPI";

import { getSubscriptionDetailsAsync } from "../subscriptions/subscriptionSlice";
import { addIdProtectAsync } from "../admin/adminSlice";

export interface MemberData {
  createDate?: string;
  customerId?: number;
  expirationDate?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  orderId?: number;
  lastFour?: string;
  cardType?: string;
  isCardExpired?: boolean;
  subscriptionStatus?: "ACTIVE" | "INACTIVE" | null;
  billingDate?: string;
  postalCode?: string;
  id?: number;
  fromLegacy?: boolean;
}

export interface MemberExperience {
  pdfDownload?: string;
  report1?: string;
  report2?: string;
  variant?: string;
  siteJabberPrompt?: string;
  [key: string]: any; // allow for any additional fields
}

export interface AuthenticationState {
  customerId: number | null;
  status: "idle" | "loading" | "failed";
  accountType: "people" | "property" | "phone" | "neighborhood" | "auto" | null;
  idProtectMember: boolean;
  isIdProtectAvailable: boolean;
  memberData: MemberData;
  memberExperience: MemberExperience;
  expired: boolean;
  homeUrl: string;
  reviewsUrl: string;
  memberSupport: boolean;
  idpPasswordCheck: boolean;
  rememberUsername: boolean;
}

const initialState: AuthenticationState = {
  customerId: null,
  idProtectMember: false,
  isIdProtectAvailable: false,
  status: "idle",
  accountType: null,
  memberData: {
    createDate: "",
    customerId: 0,
    expirationDate: "",
    firstname: "",
    lastname: "",
    username: "",
    orderId: 0,
    lastFour: "",
    cardType: "",
    isCardExpired: false,
    subscriptionStatus: null,
    billingDate: "",
    postalCode: "",
  },
  memberExperience: {},
  expired: false,
  homeUrl: "",
  reviewsUrl: "",
  memberSupport: false,
  idpPasswordCheck: false,
  rememberUsername: false,
};

export const loginAsync = createAsyncThunk<any, LoginParams, {}>(
  "authentication/login",
  async credentials => {
    return login(credentials);
  },
);

export const logoutAsync = createAsyncThunk(
  "authentication/logout",
  async () => {
    return logout();
  },
);

export const getMemberExperienceDataAsync = createAsyncThunk<any>(
  "authenticate/getMemberExperienceData",
  async () => {
    return getMemberExperienceData();
  },
);

export const setMemberExperienceDataAsync = createAsyncThunk<
  any,
  SetMemberExperienceParams
>("authenticate/setMemberExperienceData", async memberExperienceParams => {
  return setMemberExperienceData(memberExperienceParams);
});

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    clearExpired: state => {
      state.expired = false;
    },
    setExpired: state => {
      state.expired = true;
    },
    saveUser: (state, action) => {
      state.memberData = action.payload;
      state.customerId = action.payload.customerId;
    },
    saveUsername: (state, action) => {
      state.rememberUsername = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      /* loginAsync */
      .addCase(loginAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.customerId = action.payload.memberData.customerId;
        state.memberData = action.payload.memberData;
        state.expired = action.payload.expired;
        state.homeUrl = action.payload.homeUrl;
        state.reviewsUrl = action.payload.reviewsUrl;
        state.memberSupport = action.payload.memberSupport;
        state.idpPasswordCheck = action.payload.idpPasswordCheck;
      })
      .addCase(loginAsync.rejected, state => {
        state.status = "failed";
      })
      /* logoutAsync */
      .addCase(logoutAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(logoutAsync.fulfilled, state => {
        state.customerId = null;
        state.accountType = null;
        state.memberData = {
          createDate: "",
          customerId: 0,
          expirationDate: "",
          firstname: "",
          lastname: "",
          username: state.rememberUsername ? state.memberData.username : "",
          orderId: undefined,
          lastFour: "",
          cardType: "",
          isCardExpired: false,
          subscriptionStatus: null,
          billingDate: "",
          postalCode: "",
          id: undefined,
          fromLegacy: undefined, // TODO : remove when legacy membersite gone
        };
        state.expired = false;
        state.homeUrl = "";
        state.reviewsUrl = "";
        state.memberSupport = false;
        state.idpPasswordCheck = false;
        state.status = "idle";
        state.memberExperience = {};

        if ("caches" in window) {
          caches.keys().then(names => {
            names.forEach(name => caches.delete(name));
          });
        }
      })
      .addCase(logoutAsync.rejected, state => {
        state.status = "failed";
      })
      /* handle getSubscriptionDetailsAsync */
      .addCase(getSubscriptionDetailsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getSubscriptionDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.accountType = action.payload.accountType;

        // set member card data on memberData state
        if (action.payload.memberCardData) {
          state.memberData = {
            ...state.memberData,
            lastFour: action.payload.memberCardData.lastFour,
            cardType: action.payload.memberCardData.cardType,
            isCardExpired: action.payload.memberCardData.isCardExpired,
            subscriptionStatus:
              action.payload.memberCardData.subscriptionStatus,
            postalCode: action.payload.memberCardData.postalCode,
          };
        }

        if (action.payload.primarySubscription) {
          state.memberData = {
            ...state.memberData,
            billingDate: action.payload.primarySubscription.billingDate,
            orderId: action.payload.primarySubscription.lastOrderId,
          };
        }

        // set id protect member on state
        state.idProtectMember = action.payload.idProtectMember ? true : false;
        state.isIdProtectAvailable = action.payload.isIdProtectAvailable;
      })
      .addCase(getSubscriptionDetailsAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(addIdProtectAsync.fulfilled, state => {
        state.idProtectMember = true;
      })
      .addCase(getMemberExperienceDataAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getMemberExperienceDataAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.memberExperienceData?.result) {
          state.memberExperience =
            action.payload.memberExperienceData.result.contents;
        } else {
          state.memberExperience = {};
        }
      })
      .addCase(getMemberExperienceDataAsync.rejected, state => {
        state.status = "failed";
      });
  },
});

export const { clearExpired, setExpired, saveUsername, saveUser } =
  authenticationSlice.actions;

export const logoutWithRedirect = () => (dispatch: any) => {
  dispatch(logoutAsync());
  window.location.href = "/login";
};

export const logoutNoRedirect = () => (dispatch: any) => {
  dispatch(logoutAsync());
};

/* Getters */
export const selectCustomerId = (state: RootState) =>
  state.authentication.customerId;
export const selectMemberData = (state: RootState) =>
  state.authentication.memberData;
export const selectHomeUrl = (state: RootState) => state.authentication.homeUrl;
export const selectAuthStatus = (state: RootState) =>
  state.authentication.status;
export const selectAccountType = (state: RootState) =>
  state.authentication.accountType;
export const selectUsername = (state: RootState) =>
  state.authentication.memberData.username;
export const selectRememberUsername = (state: RootState) =>
  state.authentication.rememberUsername;
export const selectMemberExpired = (state: RootState) =>
  state.authentication.expired;
export const selectMemberExperienceData = (state: RootState) =>
  state.authentication.memberExperience;

export const selectIdProtectData = createSelector(
  (state: RootState) => state.authentication.idProtectMember,
  (state: RootState) => state.authentication.isIdProtectAvailable,
  (idProtectMember: boolean, isIdProtectAvailable: boolean) => ({
    idProtectMember,
    isIdProtectAvailable,
  }),
);

export default authenticationSlice.reducer;
