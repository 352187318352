import React, { useState, useEffect } from "react";
import { useAppSelector, useHandlePageNavigate } from "../../../hooks/hooks";
import { verifyEmailAsync } from "../../../features/admin/adminSlice";
import { useAppDispatch } from "../../../hooks/hooks";
import { selectMemberData } from "../../../features/authentication/authenticationSlice";

import {
  Padding,
  Layout,
  Button,
  Container,
  ReportTitle,
  Text,
  Sheet,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { formatPhoneNumber } from "../../../utils";
import { setEmailVerificationRequired } from "../../../features/admin/adminSlice";
const VerifyEmail: React.FC = () => {
  const [verified, setVerified] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const { phoneNumber } = useAppSelector(selectBrandInfo);

  const dispatch = useAppDispatch();

  const navigate = useHandlePageNavigate();

  setTimeout(() => {
    setLoading(false);
  }, 1500);

  const { customerId } = useAppSelector(selectMemberData);

  const getURLParameter = function (name: string) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };

  useEffect(() => {
    const verifyToken = async () => {
      let token = getURLParameter("token");
      const verifyEmailResponse = await dispatch(verifyEmailAsync({ token }));
      if (verifyEmailAsync.fulfilled.match(verifyEmailResponse)) {
        dispatch(setEmailVerificationRequired(false));
        setVerified(true);
      } else {
        setVerified(false);
      }
    };

    verifyToken();
  }, [dispatch]);

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <ReportTitle reportTitle="Email Verified" />
      <Sheet>
        <Padding top="lg" bottom="lg" right="md" left="md">
          <Container>
            <Padding space="lg">
              <Layout
                type="flex"
                flexProps={{
                  direction: "column",
                  alignContent: "center",
                  gap: "md",
                }}
              >
                {loading ? (
                  <LoadingPlaceholder height={50} />
                ) : verified === true ? (
                  <Text textAlign="center" size="xl" fontWeight="bold">
                    Your Email has been successfully verified.
                  </Text>
                ) : verified === false ? (
                  <Text textAlign="center" size="xl" fontWeight="bold">
                    There was an error verifying your email. Please contact our
                    Member Support Team at {formatPhoneNumber(phoneNumber)} for
                    assistance.
                  </Text>
                ) : null}

                <Layout type="flex" flexProps={{ justifyContent: "center" }}>
                  <Button
                    buttonText={
                      customerId ? "Return to Dashboard" : "Go to Member Login"
                    }
                    color="primary"
                    handleClick={handleRedirect}
                    size="md"
                  />
                </Layout>
              </Layout>
            </Padding>
          </Container>
        </Padding>
      </Sheet>
    </>
  );
};

export default VerifyEmail;
