import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { usePhoneReportDispatch } from "../../hooks/phoneReportHook";

interface TESTPullPhoneReportProps {}

const TestPullPhoneReport: React.FC<TESTPullPhoneReportProps> = () => {
  const pullPeopleReport = usePhoneReportDispatch();
  const { phoneNumber } = useParams();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    if (phoneNumber && !hasFetchedReport.current) {
      pullPeopleReport(phoneNumber);
      hasFetchedReport.current = true;
    }
  }, [phoneNumber, pullPeopleReport]);

  return <div>Pulling Report</div>;
};

export default TestPullPhoneReport;
